import { Injectable } from '@angular/core';
import { ChartConfiguration } from 'chart.js';
import { heatingValueLabel } from 'shared/constants';
import { ChartHelper } from 'shared/helper/chart-helper';
import { ColorHelper } from 'shared/helper/color-helper';
import { HelperService } from 'shared/helper/helper.service';
import { IconHelper } from 'shared/helper/icon-helper';
import { LanguageKeyHelper } from 'shared/helper/language-key-helper';
import {
  AnalyticalCategories,
  ConsumptionTypeButtonMapping,
} from 'shared/interfaces/consumption-type-button.interface';
import { ConsumptionTypes } from 'shared/models/consumption-types.interface';
import {
  HeatingChilds,
  HeatingDisplayAllExcludeChilds,
  HeatingTempExcludeChilds,
} from 'shared/models/heating.type';
import { ChartHelperService } from 'shared/ui/chart/chart-helper.service';
import { ChartItem } from 'shared/ui/chart/chart.interface';
import { FilterDateRange } from 'shared/ui/filter/filter-tab.interface';
import { TranslateService } from '@ngx-translate/core';

@Injectable()
export class LineChartService {
  constructor(
    private chartHelperService: ChartHelperService,
    private translateService: TranslateService
  ) {}
  getLineChartData(
    data: ChartItem[],
    filterBy: string,
    selectedYear: FilterDateRange,
    analyticalCategories: AnalyticalCategories
  ): ChartConfiguration['data'] {
    try {
      const datasets: any[] = [];
      let labels: string[] = [];
      if (data && selectedYear) {
        labels = data[0]?.data
          .filter((item) => item.year >= selectedYear.from && item.year <= selectedYear.until)
          .map((item) => item.year);

        const allowedConsumptionTypes = ConsumptionTypeButtonMapping.find(
          (item) => item.analyticalCategories === analyticalCategories
        ) || { consumptionType: [] as ConsumptionTypes[] };

        let displayData = data?.filter(
          (item) =>
            (item.value === filterBy || filterBy === HelperService.allLabel) &&
            !HeatingDisplayAllExcludeChilds.includes(item.value || item.label) &&
            allowedConsumptionTypes.consumptionType.includes(
              (item.value as ConsumptionTypes) || item.label
            ) &&
            !ChartHelper.areAllDataValuesUndefined(item.data)
        );

        if (filterBy === heatingValueLabel) {
          displayData = data?.filter(
            (item) =>
              HeatingChilds.includes(item.value || item.label) &&
              !ChartHelper.areAllDataValuesUndefined(item.data) &&
              item.value !== heatingValueLabel
          );
        }

        displayData?.forEach((item) => {
          datasets.push(
            this.chartHelperService.getDataSetSkeleton(
              ChartHelper.getValuesByFilterDateRange(item.data, selectedYear),
              this.translateService.instant(
                LanguageKeyHelper.get(item.label) ?? item.label
              ) as string,
              filterBy === heatingValueLabel ? ChartHelper.getColor(item.label) || '' : item.color
            )
          );
        });
      }

      return {
        datasets,
        labels,
      };
    } catch (e) {
      console.error('getLineChartData', e);
      return {
        datasets: [],
        labels: [],
      };
    }
  }

  getDashboardFilterButtons(
    data: ChartItem[],
    displayAllButton: boolean = false,
    analyticalCategories: AnalyticalCategories | undefined
  ): ChartItem[] {
    try {
      if (!data) {
        return [];
      }

      const heating: ChartItem = {
        label: LanguageKeyHelper.get(heatingValueLabel) || heatingValueLabel,
        value: heatingValueLabel,
        color: ColorHelper.get('heatingAndHotWater') || '',
        icon: IconHelper.get('heatingAndHotWater') || '',
        active: false,
        data: [],
        childs: data?.filter(
          (item) => HeatingChilds.includes(item.value || item.label) && item.data.length > 0
        ),
      };

      const allowedConsumptionTypes = ConsumptionTypeButtonMapping.find(
        (item) => item.analyticalCategories === analyticalCategories
      ) || { consumptionType: [] as ConsumptionTypes[] };

      const result: ChartItem[] = [
        ...data
          .filter(
            (item) =>
              allowedConsumptionTypes?.consumptionType.includes(
                (item.value as ConsumptionTypes) || (item.label as ConsumptionTypes)
              ) &&
              !HeatingChilds.includes(item.value || item.label) &&
              !HeatingTempExcludeChilds.includes(item.value || item.label) &&
              !ChartHelper.areAllDataValuesUndefined(item.data)
          )
          .map((item) => ({
            ...item,
            label: LanguageKeyHelper.get(item.label) || item.label,
            value: item.value,
          })),
      ];

      const allButton: ChartItem = ChartHelper.getSelectAllFilter();

      result.unshift(heating);
      if (displayAllButton) {
        result.unshift(allButton);
      }

      return result;
    } catch (e) {
      console.error('getDashboardFilterButtons', e);
      return [];
    }
  }
}
