import { Component } from '@angular/core';

import { HelperService } from 'shared/helper/helper.service';

@Component({
  selector: 'ista-daytona-page-not-found',
  templateUrl: './page-not-found.component.html',
  styleUrls: ['./page-not-found.component.scss'],
})
export class PageNotFoundComponent {
  constructor() {
    HelperService.scrollToTop();
  }
}
