import { Component, Input } from '@angular/core';

import { ValueItem } from 'shared/models/value-item.interface';
import { UnitWithPrefix } from '../../../models/unit.enum';

@Component({
  selector: 'ista-daytona-average-display',
  templateUrl: './average-display.component.html',
  styleUrls: ['./average-display.component.scss'],
})
export class AverageDisplayComponent {
  @Input() header = '';
  @Input() label = 'common.average';
  @Input() valueUnit!: ValueItem | undefined;
  @Input() classValue = '';
  public unit: UnitWithPrefix = UnitWithPrefix.EMISSION_KG_PER_SQM;
}
