import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input } from '@angular/core';
import { ChartConfiguration, ChartType } from 'chart.js';
import * as _ from 'lodash';

import { ConsumptionTypes } from 'shared/models/consumption-types.interface';
import { AbstractChartComponent } from '../abstract-chart.component';
import { ChartItem } from '../chart.interface';
import { LineChartOptions } from './line-chart-options';
import { LineChartService } from 'shared/ui/chart/line-chart/line-chart.service';
import { ChartHelperService } from 'shared/ui/chart/chart-helper.service';

/* eslint-disable @typescript-eslint/member-ordering */
@Component({
  selector: 'ista-daytona-line-chart',
  templateUrl: './line-chart.component.html',
  styleUrls: ['./line-chart.component.scss'],
  providers: [ChartHelperService, LineChartService],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LineChartComponent extends AbstractChartComponent {
  @Input() displayFilterButtons = true;
  get displayAllButton(): boolean {
    return this._displayAllButton;
  }
  @Input() set displayAllButton(value: boolean) {
    this._displayAllButton = value;
    this._refreshData();
  }
  public lineChartOptions = LineChartOptions;
  public lineChartType: ChartType = 'line';
  public lineChartData!: ChartConfiguration['data'];
  public filterButtons: ChartItem[] = [];
  public selectedFilter!: ChartItem;
  private _displayAllButton = false;

  constructor(private readonly ref: ChangeDetectorRef, private lineChartService: LineChartService) {
    super();
  }

  public setFilter(item: ChartItem): void {
    this.selectedFilter = item;
    const newLineChartData = this.lineChartService.getLineChartData(
      this.transformedData,
      this.selectedFilter?.value || this.selectedFilter?.label || '',
      this.selectedYear,
      this.labelConfig.analyticalCategory || 'consumption'
    );
    if (!_.isEqual(this.lineChartData, newLineChartData)) this.lineChartData = newLineChartData;
    this.labelConfig.consumptionTypes = (item?.value as ConsumptionTypes) || 'unknown';
    this.labelConfig = _.cloneDeep(this.labelConfig);
    this.ref.detectChanges();
  }

  public override _refreshData(): void {
    super._refreshData();
    this.filterButtons = this.lineChartService.getDashboardFilterButtons(
      this.data,
      this.displayAllButton,
      this.labelConfig?.analyticalCategory
    );

    if (this.filterButtons && this.filterButtons.length > 0) {
      this.setFilter(this.getPreviousSelectedChartItem(this.filterButtons));
    } else {
      const newLineChartData = this.lineChartService.getLineChartData(
        this.transformedData,
        this.selectedFilter?.value || this.selectedFilter?.label || '',
        this.selectedYear,
        this.labelConfig.analyticalCategory || 'consumption'
      );
      if (!_.isEqual(this.lineChartData, newLineChartData)) {
        this.lineChartData = newLineChartData;
      }
      this.ref.detectChanges();
    }
  }

  private getPreviousSelectedChartItem(chartItems: ChartItem[]): ChartItem {
    let result;
    if (this.selectedFilter) {
      result = chartItems.find((item) => item.value === this.selectedFilter.value);
    }
    return result ? result : chartItems[0];
  }
}
