import { Injectable } from '@angular/core';

import { FilterDateRange } from '../ui/filter/filter-tab.interface';
import {
  PortfolioCard,
  PortfolioCardValueItem,
} from '../ui/portfolio-card/portfolio-card.interface';

@Injectable({
  providedIn: 'root',
})
export class PortfolioCardHelperService {
  public static getPortfolioCardDataBySelectedYear(
    portfolioData: PortfolioCard | undefined,
    globalSelectedYear: FilterDateRange | undefined
  ): PortfolioCardValueItem | undefined {
    if (!portfolioData) {
      return undefined;
    }

    if (!globalSelectedYear) {
      return undefined;
    }

    return portfolioData.data.find((item) => item.year === globalSelectedYear?.from);
  }

  public static getPortfolioCardData(
    portfolioData: PortfolioCard | undefined,
    globalSelectedYear: number
  ): PortfolioCardValueItem | undefined {
    if (!portfolioData) {
      return undefined;
    }

    const data = portfolioData.data;

    let maxDateIndex = -1;
    let maxDate = new Date();

    for (let i = 0; i < data.length; i++) {
      const currentDate = new Date(data[i].year);
      if (currentDate <= maxDate && currentDate.getFullYear() <= globalSelectedYear) {
        maxDate = currentDate;
        maxDateIndex = i;
      }
    }

    if (maxDateIndex !== -1) {
      return portfolioData.data[maxDateIndex];
    } else {
      return {
        value: {
          value: 0,
        },
        year: `${globalSelectedYear}-01-01'`,
      };
    }
  }

  public static getPortfolioCardDataByTimestamp(
    portfolioData: PortfolioCard | undefined,
    timestamp: string | undefined
  ): PortfolioCardValueItem | undefined {
    if (!portfolioData) {
      return undefined;
    }

    if (!timestamp) {
      return undefined;
    }

    // const transformedTimestamp = new Date(timestamp).getFullYear();
    return portfolioData.data.find((item) => item.year === timestamp);
  }
}
