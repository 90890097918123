import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';

import { ButtonModule } from '../basic/button/button.module';
import { DropdownModule } from '../basic/dropdown/dropdown.module';
import { InputModule } from '../basic/input/input.module';
import { PageStepperModule } from '../page-stepper/page-stepper.module';
import { PaginationComponent } from './pagination.component';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    DropdownModule,
    InputModule,
    ButtonModule,
    PageStepperModule,
  ],
  declarations: [PaginationComponent],
  exports: [PaginationComponent],
})
export class PaginationModule {}
