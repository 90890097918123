<div #eFileContainer class="result-efile-container">
  <div
    [ngClass]="{
      error: file.generalValidationHints.length > 0,
      'grid--col-2':
        (file.generalValidationHints.length <= 0 && !!existsEFileBilling(file)) ||
        (file.generalValidationHints.length > 0 && !existsEFileBilling(file)),
      'grid--col-3': file.generalValidationHints.length > 0 && !!existsEFileBilling(file)
    }"
    class="grid grid--gap-8 error-align mt-2"
  >
    <img
      *ngIf="file.generalValidationHints.length > 0"
      alt="warning"
      class="icon"
      src="assets/icons/warning.svg"
      title="warning"
    />
    <span (click)="isOpen = !isOpen" [innerText]="file.fileName" class="filename"></span>
    <button
      (click)="isOpen = !isOpen"
      *ngIf="existsEFileBilling(file)"
      class="flat-link button-more-info"
    >
      <img
        [class.close]="!isOpen"
        alt="more information"
        src="assets/icons/dropdown-close.svg"
        title="more information"
      />
    </button>
    <div class="max-h-32 overflow-y-auto" dir="rtl">
      <div dir="ltr">
        <ng-container *ngIf="file.generalValidationHints.length > 0">
          <div>
            <p
              *ngFor="let hint of file.generalValidationHints"
              [innerText]="'errorMsg.fileUpload.eFile.' + $any(hint.validationHintCode) | translate"
              class="error"
            ></p>
          </div>
        </ng-container>
        <ng-container *ngIf="isOpen">
          <ng-container *ngFor="let billing of file.detectedBillings">
            <ista-daytona-file-upload-result-billing
              [detectedBilling]="billing"
            ></ista-daytona-file-upload-result-billing>
          </ng-container>
        </ng-container>
      </div>
    </div>
  </div>
</div>
