import { AbstractControl, AsyncValidatorFn, ValidationErrors } from '@angular/forms';
import { filter, first, map, Observable } from 'rxjs';
import { Store } from '@ngrx/store';
import { RootState } from 'app/+state/app.reducer';
import { selectTreeNavigationSortedGroups } from 'core/+state/core.selectors';
import { GroupHelper } from 'core/groups-tree-navigation/utils/group-helper';
import { GROUP_NAME_EXISTS_ERROR_KEY } from '../constants';

export class CustomValidators {
  static isGroupNameAlreadyExists(store: Store<RootState>): AsyncValidatorFn {
    return (control: AbstractControl<string>): Observable<ValidationErrors | null> =>
      store.select(selectTreeNavigationSortedGroups).pipe(
        filter(Boolean),
        first(),
        map((groups) => GroupHelper.flattenGroups(groups)),
        map((groups) => groups.map((g) => g.name.toLowerCase())),
        map((names) => {
          const isExist = names.includes(control.value.toLowerCase());
          return isExist ? { [GROUP_NAME_EXISTS_ERROR_KEY]: true } : null;
        })
      );
  }
}
