import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { combineLatest, ObservableInput, of, throwError } from 'rxjs';
import { LoggerService } from 'shared/helper/logger.service';
import { NotificationType } from 'shared/ui/notification/notification.interface';
import { NotificationService } from 'shared/ui/notification/notification.service';

@Injectable({
  providedIn: 'root',
})
export class ErrorHandlerService {
  constructor(
    private readonly notificationService: NotificationService,
    private readonly translateService: TranslateService // private injector: Injector
  ) {
    // IMPORTANT: this is a workaround. Using this.injector.get(TranslateService) to invoke methods from the
    // TranslatorService as done in the method below also is part of this workaround.
    // This workaround is required when both of the following conditions are true:
    // 1. This service is injected in an httpInterceptor
    // 2. This service needs the TranslatorService
    // not applying this workaround will lead to an empty object injected. Debugging this is tough.
    // At the moment, this solution is not used anyway, but keep this comment for the future.
    // const translate = injector.get(TranslateService);
  }

  public handleError(
    error: HttpErrorResponse | any,
    attemptedActionKey?: string
  ): ObservableInput<any> {
    let errorKey = 'errorMsg.UNKNOWN_ERROR';
    if (error?.status === 0) {
      errorKey = 'errorMsg.HTTP_FAILURE';
      LoggerService.error('An error occurred:', error.error);
    } else {
      if (error.status >= 400 && error.status < 500) {
        // expected error (status code 4xx)
        errorKey =
          'errorMsg.' + ((error.error?.errorCode || error.error?.message) as string) ||
          'errorMsg.UNKNOWN_ERROR';
      } else if (error.status >= 500) {
        // unexpected server error (status code 5xx)
        errorKey = 'errorMsg.UNEXPECTED_ERROR';
      }

      LoggerService.error(
        `Backend returned code ${error.status as string}, body was: `,
        error?.error || error
      );
    }

    combineLatest([
      this.translateService.get('errorMsg.HEADER_LABEL_ERROR'),
      this.translateService.get(errorKey),
      attemptedActionKey
        ? this.translateService.get('errorMsg.attemptedAction.' + attemptedActionKey)
        : of(''),
    ]).subscribe(([header, msg, attemptedAction]) => {
      this.notificationService.addNotification({
        headline: header as string,
        text:
          (attemptedAction as string) +
          ' ' +
          this.setErrorParameters(msg as string, error?.error?.errorParameters as string[]),
        type: NotificationType.WARNING,
      });
    });
    return throwError(() => new Error('Something bad happened; please try again later.'));
  }

  public displayError(errorTranslateKey: string, errorParameters?: string[]): void {
    combineLatest([
      this.translateService.get('errorMsg.HEADER_LABEL_ERROR'),
      this.translateService.get('errorMsg.' + errorTranslateKey),
    ]).subscribe(([header, msg]) => {
      this.notificationService.addNotification({
        headline: header as string,
        text: this.setErrorParameters(msg as string, errorParameters),
        type: NotificationType.WARNING,
      });
    });
  }

  private setErrorParameters(msg: string, parameters?: string[]): string {
    if (!msg || !parameters || !Array.isArray(parameters)) {
      return msg;
    }
    let currentIndex = 0;

    // Use a regular expression to match placeholders enclosed in curly braces
    const regex = /{}/g;

    // Use the replace() method with a callback function
    return msg.replace(regex, () => {
      if (currentIndex < parameters.length) {
        return parameters[currentIndex++];
      }

      // If there are no more values in the array, leave the placeholder unchanged
      return '{}';
    });
  }
}
