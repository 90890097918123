import { Component, Input } from '@angular/core';
import { ChartConfiguration, ChartType } from 'chart.js';
import { AbstractChartComponent } from '../abstract-chart.component';
import { BarChartOptions } from './bar-chart-options';
import { BarChartMapper } from 'shared/ui/chart/bar-chart/bar-chart.mapper';

@Component({
  selector: 'ista-daytona-bar-chart',
  templateUrl: './bar-chart.component.html',
  styleUrls: ['./bar-chart.component.scss'],
})
export class BarChartComponent extends AbstractChartComponent {
  @Input() unitLabel!: string | undefined;
  @Input() label?: string;
  public barChartData!: ChartConfiguration['data'];
  public barChartOptions = BarChartOptions;
  public barType: ChartType = 'bar';

  constructor() {
    super();
  }

  public override _refreshData(): void {
    super._refreshData();
    this.barChartData = BarChartMapper.chartItemToDoughnutChart(this.transformedData[0]);
  }
}
