import {
  AfterViewInit,
  Directive,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  Output,
  SimpleChanges,
} from '@angular/core';
import { filter, fromEvent, Subject, switchMap, takeUntil, timer } from 'rxjs';

@Directive({
  selector: '[drag]',
})
export class DragDirective implements AfterViewInit, OnChanges, OnDestroy {
  @Input() enableDrag = false;
  @Output() dragStart = new EventEmitter<DragEvent>();
  @Output() dragOver = new EventEmitter<DragEvent>();
  @Output() dragEnd = new EventEmitter<DragEvent>();
  destroy$ = new Subject<void>();

  constructor(private elementRef: ElementRef<HTMLElement>) {}

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.hasOwnProperty('enableDrag') && changes['enableDrag']) {
      this.elementRef.nativeElement.draggable =
        (changes['enableDrag']?.currentValue as boolean) ?? false;
    }
  }

  registerDragEvents(): void {
    // handle long press on Mouse to Add Style
    const mouseUp$ = fromEvent(this.elementRef.nativeElement, 'mouseup').pipe(
      filter(() => this.enableDrag)
    );
    mouseUp$
      .pipe(takeUntil(this.destroy$))
      .subscribe(() => this.elementRef.nativeElement.classList.remove('drag-start'));

    fromEvent(this.elementRef.nativeElement, 'mousedown')
      .pipe(
        switchMap(() => timer(200).pipe(takeUntil(mouseUp$))),
        filter(() => this.enableDrag),
        takeUntil(this.destroy$)
      )
      .subscribe(() => {
        this.elementRef.nativeElement.classList.add('drag-start');
      });

    // drag Events
    fromEvent(this.elementRef.nativeElement, 'dragstart')
      .pipe(
        filter(() => this.enableDrag),
        takeUntil(this.destroy$)
      )
      .subscribe((event) => this.dragStart.emit(event as DragEvent));

    fromEvent(this.elementRef.nativeElement, 'dragover')
      .pipe(takeUntil(this.destroy$))
      .subscribe((event) => {
        this.elementRef.nativeElement.classList.remove('drag-start');
        this.dragOver.emit(event as DragEvent);
      });

    fromEvent(this.elementRef.nativeElement, 'dragend')
      .pipe(
        filter(() => this.enableDrag),
        takeUntil(this.destroy$)
      )
      .subscribe((event) => {
        this.elementRef.nativeElement.classList.remove('drag-start');
        this.dragEnd.emit(event as DragEvent);
      });
  }

  ngAfterViewInit(): void {
    this.registerDragEvents();
  }
}
