import { Environment } from './environment.interface';

export const environment: Environment = {
  production: true,
  apiUrl: window.location.protocol + '//' + window.location.host + '/daytona-api',
  useAuthentification: true,
  redirectUri: window.location.origin,
  trackingEnv: 'prod',
  reportingInfoboxTimeperiod: 90 * 24 * 60 * 60 * 1000,
};
