import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input } from '@angular/core';
import { HelperService } from 'shared/helper/helper.service';
import { ValueItem } from 'shared/models/value-item.interface';
import { FormatNumberPipe } from 'shared/pipes/format-number.pipe';
import { TransformedValueItem, Unit, UnitWithPrefix } from '../../../models/unit.enum';
import { naValueDisplay, nullValueDisplay } from 'core/constants';

/* eslint-disable @typescript-eslint/member-ordering */
@Component({
  selector: 'ista-daytona-value-unit',
  templateUrl: './value-unit.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ValueUnitComponent {
  @Input() classContainer!: string;
  @Input() classValue!: string;
  @Input() classUnit!: string;
  @Input() overwriteUnit!: UnitWithPrefix;
  public nullValueDisplay = nullValueDisplay;
  private transformedValue!: TransformedValueItem;

  constructor(
    private readonly formatNumberPipe: FormatNumberPipe,
    private readonly cdr: ChangeDetectorRef
  ) {}

  private _value!: ValueItem | undefined | null;

  get value(): ValueItem | undefined | null {
    return this._value;
  }

  @Input() set value(val: ValueItem | undefined | null) {
    this._value = val;
    this.cdr.detectChanges();
  }

  private _displayValue = true;

  get displayValue(): boolean {
    return this._displayValue;
  }

  @Input() set displayValue(value: boolean) {
    this._displayValue = value;
    if (!value) {
      this.nullValueDisplay = naValueDisplay;
      this.cdr.markForCheck();
    }
  }

  public getTransformedValue(): TransformedValueItem {
    // @todo: caching transformedValue
    if (this.value) {
      this.transformedValue = this.formatNumberPipe.transform(this.value.value, this.value.unit);
    }

    if (this.overwriteUnit) {
      this.transformedValue.unitWithPrefix = this.overwriteUnit;
    }

    return this.transformedValue;
  }

  public getFormatNumberDigits(): string {
    if (
      this.transformedValue !== undefined &&
      this.transformedValue !== null &&
      this.transformedValue.value !== undefined &&
      this.transformedValue.value !== null &&
      this.transformedValue.value > -9 &&
      this.transformedValue.value < 9
    ) {
      if (Math.abs(this.transformedValue?.value).toString().length === 1) {
        return '1.0-0';
      }
      return '1.1-1';
    }

    return '1.0-0';
  }

  public getEnumValue(unit: Unit): string {
    const key = unit as string;
    return HelperService.getUnitEnumValue(key);
  }
}
