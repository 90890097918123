<ista-daytona-input
  #inputField
  (deleteButtonClicked)="this.deleteButtonClicked.emit($event)"
  (ngModelChange)="onInputChange($event)"
  [(ngModel)]="searchStr"
  [inputHeight]="inputHeight.SMALL"
  [placeholder]="placeholder"
  [type]="inputType.SEARCH"
  addBoxShadow
  class="block"
  data-cy="input-search"
></ista-daytona-input>
