<div
  [class.flex]="!stretch"
  [class.tab-group-wrapper--sticky-style]="stickyStyle"
  class="shadow-tab tab-group-wrapper"
>
  <mat-tab-group
    (selectedTabChange)="onTabChange($event)"
    [mat-stretch-tabs]="stretch"
    [selectedIndex]="defaultSelectedTabIndex"
  >
    @for (tab of tabs; track tab.label) {
      <mat-tab>
        <ng-template mat-tab-label>
          @if (tab.icon) {
            @if (tab.icon.isSvgIcon) {
              <mat-icon
                [svgIcon]="tab.icon.name"
                [ngClass]="{
                  'stroke-primary-1000': tab.icon.isStrokeRelated && !stickyStyle,
                  'fill-primary-1000': tab.icon.isFillRelated && !stickyStyle,
                  'stroke-primary-500': tab.icon.isStrokeRelated && ($index === defaultSelectedTabIndex || stickyStyle),
                  'fill-primary-500': tab.icon.isFillRelated && ($index === defaultSelectedTabIndex || stickyStyle),
                  'icon-size-solid-8': tab.icon.isIconSolid && !stickyStyle,
                  'icon-size-8': !tab.icon.isIconSolid && !stickyStyle,
                }"
                class="tab-custom-svg"
              >
              </mat-icon>
            } @else {
              <mat-icon
                [ngClass]="{
                  'icon-size-solid-8': tab.icon.isIconSolid && !stickyStyle,
                  'icon-size-8': !tab.icon.isIconSolid && !stickyStyle,
                }"
                class="tab-custom-icon"
              >{{ tab.icon.name }}
              </mat-icon>
            }
          }
          @if (tab.label; as label) {
            <span
              [attr.data-cy]="tab.cyAttribute"
              class="tab-group-wrapper__tab-label text-3xl font-extrabold"
              [class.text-primary-1000]="!stickyStyle && $index !== defaultSelectedTabIndex"
            >{{ label | translate }}</span
            >
          }
        </ng-template>
      </mat-tab>
    }
  </mat-tab-group>
  @if (!stretch) {
    <div class="ml-auto flex items-center">
      <ng-content></ng-content>
    </div>
  }
</div>
