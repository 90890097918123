import { NgModule } from '@angular/core';
import { StoreModule } from '@ngrx/store';
import {
  routerReducer,
  RouterStateSerializer,
  StoreRouterConnectingModule,
} from '@ngrx/router-store';
import { ReducedRouteSerialzer } from './reduced-route-serialzer';

export const routerStateConfig = {
  stateKey: 'router',
};

@NgModule({
  imports: [
    StoreModule.forFeature(routerStateConfig.stateKey, routerReducer),
    StoreRouterConnectingModule.forRoot(routerStateConfig),
  ],
  exports: [StoreModule, StoreRouterConnectingModule],
  providers: [
    {
      provide: RouterStateSerializer,
      useClass: ReducedRouteSerialzer,
    },
  ],
})
export class NgrxStoreRouterModule {}
