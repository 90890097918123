import { HttpErrorResponse } from '@angular/common/http';
import { ErrorHandler, Injectable } from '@angular/core';

import { ErrorHandlerService } from './error-handler.service';

@Injectable()
export class GlobalErrorHandler implements ErrorHandler {
  constructor(private readonly _errorHandler: ErrorHandlerService) {}

  /**
   * This global error handler handles all errors which haven't been caught where else. This
   * assures, that for every error a message is shown to the user. It is still better to catch the
   * errors where they are thrown and to handle them more specifically and to provide more use-case
   * specific details to the user.
   *
   * @param error
   */
  handleError(error: HttpErrorResponse | UncaughtPromiseError | any): void {
    console.error('GlobalErrorHandler.handleError', error);
    // if error.rejection is set, it's an UncaughtPromiseError
    const errorObject = (error.rejection ? error.rejection : error) as HttpErrorResponse;

    // Display a notification for the error
    this._errorHandler.handleError(errorObject);
  }
}
