import { InfoIconCard } from 'shared/ui/info-icon-card/info-icon-card.interface';

export class Utils {
  static generateRandomId(): string {
    return `${Date.now()}`;
  }

  static getFormatNumberDigits(value?: string | number | null): string {
    if (value && typeof value === 'number' && value > -9 && value < 9) {
      if (Math.abs(value).toString().length === 1) {
        return '1.0-0';
      }
      return '1.1-1';
    }

    return '1.0-0';
  }

  static getBarsDisplayedYearsKeys(
    value: Record<string, any>,
    globalSelectedYear: number
  ): string[] {
    const years = Object.keys(value).sort((a, b) => b.localeCompare(a));
    const globalSelectedYearIndex = years.indexOf(`${globalSelectedYear}`);
    const end = Math.min(years.length - 1, globalSelectedYearIndex + 2);
    return years.filter((value, index) => index >= globalSelectedYearIndex && index <= end);
  }

  static getPortfolioInformationGlossarId(infoCard: InfoIconCard): string {
    switch (infoCard.label) {
      case 'dashboard.object':
        return '19';
      case 'dashboard.units':
        return '13';
      case 'dashboard.area':
        return '12';
      default:
        return '19';
    }
  }
}
