<ng-container [ngSwitch]="type">
  <button
    (click)="click()"
    *ngSwitchCase="'secondary'"
    [class.ista-button--small]="small"
    [color]="color"
    [disabled]="disabled"
    class="ista-button"
    mat-stroked-button
  >
    <ng-content></ng-content>
    <mat-icon *ngIf="icon" class="icon-size-6">{{ icon }}</mat-icon>
    <span>{{ label }}</span>
  </button>
  <button
    (click)="click()"
    *ngSwitchCase="'tertiary'"
    [class.ista-button--small]="small"
    [color]="color"
    [disabled]="disabled"
    class="ista-button"
    mat-button
  >
    <ng-content></ng-content>
    <mat-icon *ngIf="icon" class="icon-size-6">{{ icon }}</mat-icon>
    <span>{{ label }}</span>
  </button>
  <button
    (click)="click()"
    *ngSwitchCase="'icon'"
    [color]="color"
    [disabled]="disabled"
    mat-icon-button
  >
    <mat-icon *ngIf="icon" [matBadgeHidden]="true" class="icon-size-6" matBadge="8" matBadgeColor="warn">{{ icon }}
    </mat-icon>
  </button>

  <button
    (click)="click()"
    *ngSwitchDefault
    [class.ista-button--small]="small"
    [color]="color"
    [disabled]="disabled"
    class="ista-button"
    mat-flat-button
  >
    <ng-content></ng-content>
    <mat-icon *ngIf="icon" class="icon-size-6">{{ icon }}</mat-icon>
    <span>{{ label }}</span>
  </button>
</ng-container>
