<div [ngClass]="class" [ngSwitch]="type" [style.marginBottom]="bottomOffset">
  <ista-daytona-filter-tab
    (clickedFilter)="clickedFilter.emit($event)"
    *ngSwitchDefault
    [config]="$any(config)"
    [displayBorderBottom]="displayBorderBottom"
    [displayTimeSeries]="displayTimeSeries"
    [smallVersion]="reducedVersion"
    [timeMode]="timeMode"
  ></ista-daytona-filter-tab>
  <ista-daytona-filter-sub-tab
    (clickedFilter)="clickedFilter.emit($event)"
    *ngSwitchCase="filterType.SECONDARY"
    [config]="$any(config)"
    [selectedFilterPortfolioAnalysisChart]="selectedFilterPortfolioAnalysisChart"
  ></ista-daytona-filter-sub-tab>
  <ista-daytona-filter-page
    (clickedFilter)="clickedFilter.emit($event)"
    *ngSwitchCase="filterType.PAGE"
    [config]="$any(config)"
    [displayDataImport]="displayDataImport"
    [displayDeleteObject]="displayDeleteObject"
    [displayEditData]="displayEditData"
  >
    <ng-content></ng-content
    >
  </ista-daytona-filter-page>
  <ista-daytona-filter-tab-small
    (clickedFilter)="clickedFilter.emit($event)"
    *ngSwitchCase="filterType.SMALL"
    [config]="$any(config)"
  ></ista-daytona-filter-tab-small>
</div>
