import { createAction, props } from '@ngrx/store';

import { DashboardInfo } from '../dashboard.interface';

/*
export const loadDashboard = createAction(
  '[Dashboard] load Dashboard single year'
);
*/

export const storeDashboardRequestStatus = createAction(
  '[Dashboard] Store Dashboard Request Status',
  props<{ loading: boolean }>()
);

export const storeDashboardData = createAction(
  '[Dashboard] Store Dashboard Data',
  props<{ dashboard: DashboardInfo; dashboardEnvironmentId: number }>()
);

export const updateDashboardData = createAction('[Dashboard] Update Data');

export const dashboardLoadSuccess = createAction('[Dashboard] Load Success');

export const dashboardLoadFailure = createAction(
  '[Dashboard] Load Failure',
  props<{ error: any }>()
);
export const resetDashboardState = createAction('[Dashboard] Reset State');
