import { Component, Input } from '@angular/core';

import { ColorStyle } from 'shared/models/color-style.enum';
import { IconType } from '../icon/icon-type.enum';

@Component({
  selector: 'ista-daytona-content-card',
  templateUrl: './content-card.component.html',
  styleUrls: ['./content-card.component.scss'],
})
export class ContentCardComponent {
  @Input() headline = '';
  @Input() text = '';
  @Input() showLinkText = true;
  @Input() linkTo = '';
  @Input() openNewWindow = false;
  @Input() colorStyle = ColorStyle.WHITE;
  public iconType = IconType;
}
