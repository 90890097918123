import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Tag } from './interface';
import { environment } from 'environments/environment';

@Injectable()
export class TagsHttpService {
  constructor(private httpClient: HttpClient) {}

  getAllTags(envId: number): Observable<Tag[]> {
    return this.httpClient.get<Tag[]>(
      `${environment.apiUrl}/api/${envId}/environment-settings/tags`
    );
  }

  createTag(envId: number, name: string): Observable<Tag> {
    return this.httpClient.post<Tag>(
      `${environment.apiUrl}/api/${envId}/environment-settings/tags`,
      {
        name,
      }
    );
  }

  updateTag(envId: number, tagId: number, newName: string): Observable<Tag> {
    return this.httpClient.post<Tag>(
      `${environment.apiUrl}/api/${envId}/environment-settings/tags/${tagId}`,
      {
        newName,
      }
    );
  }

  deleteTag(envId: number, tagId: number): Observable<unknown> {
    return this.httpClient.delete<unknown>(
      `${environment.apiUrl}/api/${envId}/environment-settings/tags/${tagId}`
    );
  }
}
