import { Directive, HostListener, Input } from '@angular/core';

@Directive({
  selector: '[scrollTo]',
})
export class ScrollToDirective {
  @Input() scrollTo!: string;

  @HostListener('click', ['$event'])
  onClick(event: Event): void {
    event.preventDefault();
    const targetElement = this.getElementById();
    if (targetElement) {
      targetElement.scrollIntoView({ behavior: 'smooth' });
    }
  }

  private getElementById(): HTMLElement | null {
    return document.getElementById(this.scrollTo);
  }
}
