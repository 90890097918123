import { Component } from '@angular/core';

import { HelperService } from 'shared/helper/helper.service';

@Component({
  selector: 'ista-daytona-imprint',
  templateUrl: './imprint.component.html',
  styleUrls: ['./imprint.component.scss'],
})
export class ImprintComponent {
  constructor() {
    HelperService.scrollToTop();
  }
}
