<div class="result-info-container">
  <div class="grid result-grid">
    <ng-container *ngIf="fileUpload.type === fileUploadType.EXCEL; else eFileItem">
      <p [innerHTML]="'file-upload.result.excel.subheadline' | translate"></p>
      <div class="max-h-72 overflow-y-auto excel-results">
        <div class="table w-full">
          <div class="tr">
            <div class="th">{{ 'file-upload.result.excel.table-h1' | translate }}</div>
            <div class="th">{{ 'file-upload.result.excel.table-h2' | translate }}</div>
            <div class="th">{{ 'file-upload.result.excel.table-h3' | translate }}</div>
          </div>
          <ng-container *ngFor="let rowResult of excelRowResults">
            <div *ngFor="let rowHint of rowResult.validationHints" class="tr">
              <div [class.error]="isExcelErrorFile" [innerText]="rowResult.rowNumber" class="td"></div>
              <div [class.error]="isExcelErrorFile" class="td"></div>
              <div [class.error]="isExcelErrorFile" [innerText]="'file-upload.result.excel.hints.'+rowHint | translate"
                   class="td"></div>
            </div>
            <ng-container *ngFor="let cellResult of rowResult.cellResults">
              <div *ngFor="let cellHint of cellResult.validationHints" class="tr">
                <div [class.error]="isExcelErrorFile" [innerText]="rowResult.rowNumber" class="td"></div>
                <div [class.error]="isExcelErrorFile" [innerText]="cellResult.columnString" class="td"></div>
                <div [class.error]="isExcelErrorFile"
                     [innerText]="'file-upload.result.excel.hints.'+cellHint | translate" class="td"></div>
              </div>
            </ng-container>
          </ng-container>
        </div>
      </div>
      <div class="button-group--horizontal">
        <ista-daytona-button
          (buttonClicked)="close()"
          [label]="'file-upload.result.excel.close-button-label' | translate"
          class="mr-1"
          type="tertiary"
        ></ista-daytona-button>
      </div>
    </ng-container>

    <ng-template #eFileItem>
      <p [innerHTML]="'file-upload.result.esatz.subheadline' | translate : { dataTag }"></p>
      <div class="max-h-72 overflow-y-auto esatz-results">
        <div class="table w-full">
          <div class="tr">
            <div class="th">{{ 'file-upload.result.esatz.table-h1' | translate }}</div>
            <div class="th">{{ 'file-upload.result.esatz.table-h2' | translate }}</div>
            <div class="th">{{ 'file-upload.result.esatz.table-h3' | translate }}</div>
            <div class="th"></div>
          </div>
          <div *ngFor="let file of eFiles" class="tr">
            <div class="td">
              <ista-daytona-file-upload-result-efile
                [file]="file"
              ></ista-daytona-file-upload-result-efile>
            </div>
            <div
              [innerText]="getEFileSize(file)"
              [ngClass]="{
                    error: existsEFileErrors(file),
                    unknown: getEFileSize(file) === '-'
                  }"
              class="td mt-2"
            ></div>
            <div
              [innerText]="getEFileDate(file) ? (getEFileDate(file) | date) : '-'"
              [ngClass]="{
                    error: existsEFileErrors(file),
                    unknown: getEFileDate(file) === 0
                  }"
              class="td mt-2"
            ></div>
            <div class="td"></div>
          </div>
        </div>
      </div>
      <div class="button-group--horizontal">
        <ista-daytona-button
          (buttonClicked)="abort()"
          [label]="'file-upload.result.esatz.back-button-label' | translate"
          class="mr-1"
          type="tertiary"
        ></ista-daytona-button>
        <ista-daytona-button
          (buttonClicked)="uploadValidData.emit(null)"
          [disabled]="isDataValid === fileUploadValidStatus.INVALID"
          [label]="'file-upload.result.esatz.valid-button-label' | translate"
        ></ista-daytona-button>
      </div>
    </ng-template>
  </div>
</div>
