import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { IconModule } from './../icon/icon.module';
import { InputComponent } from './input.component';

@NgModule({
  imports: [CommonModule, IconModule],
  declarations: [InputComponent],
  exports: [InputComponent],
})
export class InputModule {}
