import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { BasicModule } from 'shared/ui/basic/basic.module';
import { UiModule } from 'shared/ui/ui.module';
import { ContactComponent } from './contact/contact.component';
import { FooterComponent } from './footer/footer.component';
import { ImprintComponent } from './imprint/imprint.component';
import { PageNotFoundComponent } from './page-not-found/page-not-found.component';
import { PrivacyModule } from './privacy/privacy.module';

const imExport = [FooterComponent, ImprintComponent, PageNotFoundComponent];

@NgModule({
  imports: [
    ContactComponent,
    CommonModule,
    RouterModule,
    BasicModule,
    ReactiveFormsModule,
    PrivacyModule,
    TranslateModule,
    UiModule,
  ],
  declarations: [...imExport],
  exports: [...imExport],
})
export class StaticPagesModule {}
