import {
  booleanAttribute,
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';
import { MatIconButton } from '@angular/material/button';
import { ThemePalette } from '@angular/material/core';
import { MatBadge, MatBadgePosition, MatBadgeSize } from '@angular/material/badge';
import { MatIcon } from '@angular/material/icon';
import { CustomIcon } from 'shared/interfaces/custom-icon.interface';

// for more Info, please Check the API https://material.angular.io/components/badge/api
// more Examples, check please https://ista-ui.gitlab.ista.net/ui-angular-lib/main/#/badges
@Component({
  selector: 'ista-daytona-badge-button',
  templateUrl: 'badge-button.component.html',
  standalone: true,
  imports: [MatIconButton, MatBadge, MatIcon],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BadgeButtonComponent {
  @Input({ required: true }) icon!: CustomIcon;
  @Input() color?: ThemePalette = undefined;
  @Input() badgeColor: ThemePalette = 'warn';
  @Input({ transform: booleanAttribute }) badgeHidden = false;
  @Input() badgeContent = '';
  @Input() badgePosition: MatBadgePosition = 'above after';
  @Input() badgeSize: MatBadgeSize = 'medium';
  @Input() disabled = false;
  @Output() buttonClick = new EventEmitter<void>();
}
