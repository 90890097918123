<div class="average-container grid gap-1">
  <h4 [innerText]="header" class="font-extrabold text-xl text-base-700"></h4>
  <div class="border border-base-200 bg-base-50 py-2 px-6">
    <h5 [innerText]="label | translate" class="text-xl font-extrabold text-base-700 text-center mb-1"></h5>
    <ista-daytona-value-unit
      [classUnit]="'text-base-700 font-extrabold text-xl'"
      [classValue]="'text-4xl font-extrabold ' + classValue"
      [overwriteUnit]="unit"
      [value]="valueUnit"
      class="text-center"
    ></ista-daytona-value-unit>
  </div>
</div>
