import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Group, SiteOfGroup } from '../groups/groups.interface';
import { environment } from 'environments/environment';

@Injectable()
export class GroupsTreeNavigationHttpService {
  constructor(private httpClient: HttpClient) {}

  getUnassignedSites(envId: number): Observable<SiteOfGroup[]> {
    return this.httpClient.get<SiteOfGroup[]>(
      `${environment.apiUrl}/api/${envId}/sites/unassigned`
    );
  }

  createGroup(envId: number, name: string, parentId?: number): Observable<number> {
    return this.httpClient.post<number>(`${environment.apiUrl}/api/${envId}/groups`, {
      name,
      parentId,
    });
  }

  getSiteHierarchy(envId: number, siteId: number): Observable<Group | undefined> {
    return this.httpClient.get<Group>(
      `${environment.apiUrl}/api/${envId}/sites/${siteId}/hierarchy`
    );
  }

  deleteSite(envId: number, siteId: number): Observable<unknown> {
    return this.httpClient.delete(`${environment.apiUrl}/api/${envId}/portfolio/site/${siteId}`);
  }

  deleteGroup(envId: number, groupId: number): Observable<unknown> {
    return this.httpClient.delete(`${environment.apiUrl}/api/${envId}/groups/${groupId}`);
  }

  updateSiteGroup(envId: number, siteId: number, groupId: number): Observable<unknown> {
    return this.httpClient.post<unknown>(
      `${environment.apiUrl}/api/${envId}/sites/${siteId}/group`,
      {
        groupId,
      }
    );
  }

  updateGroupParentId(envId: number, groupId: number, parentId: number): Observable<unknown> {
    return this.httpClient.post<number>(`${environment.apiUrl}/api/${envId}/groups/${groupId}`, {
      parentId,
    });
  }

  getSite(envId: number, siteId: number): Observable<SiteOfGroup> {
    return this.httpClient.get<SiteOfGroup>(
      `${environment.apiUrl}/api/${envId}/portfolio/site/${siteId}`
    );
  }
}
