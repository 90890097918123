<div class="content-card" [ngClass]="colorStyle">
  <div class="inner-content">
    <h2 [innerHTML]="headline"></h2>
    <p class="text" [innerHTML]="text"></p>
    <div class="link">
      <a [href]="linkTo"
        ><span>{{ showLinkText ? ('contentCard.linkText' | translate) : '' }}</span>
        <ista-daytona-icon [iconType]="iconType.ARROW_RIGHT"></ista-daytona-icon
      ></a>
    </div>
  </div>
</div>
