<div class="textarea-container" [ngClass]="classes">
  <div class="label" *ngIf="label">
    <label [attr.for]="uuid" tabindex="-1" [class.disabled]="isDisabled">{{ label }}</label>
  </div>
  <textarea
    rows="6"
    [class.disabled]="isDisabled"
    [value]="value || ''"
    [disabled]="isDisabled"
    [attr.data-qa]="dataQa"
    (input)="value = $any($event.target)?.value"
  ></textarea>
</div>
