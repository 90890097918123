/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
export const DoughnutOptions = {
  /*
  use the following config to disable the doughnut chart animations if needed.
  animation: {
    animateRotate: false,
    animateScale: false,
    duration: 0
  },*/
  transitions: {
    active: { animation: { duration: 0 } },
  },
  plugins: {
    legend: {
      display: false,
    },
  },
  aspectRatio: 1,
  layout: {
    padding: {
      left: 0,
      right: 0,
      top: 0,
      bottom: 0,
    },
  },
  cutout: 135,
  maintainAspectRatio: false,
  showTooltips: false,
  events: [],
};
