/* eslint-disable @typescript-eslint/member-ordering */
import { ChangeDetectorRef, Component, Input } from '@angular/core';
import { ChartConfiguration, ChartType } from 'chart.js';
import * as _ from 'lodash';

import { UnitWithPrefix } from '../../../models/unit.enum';
import { AbstractMultiChartComponent } from '../abstract-multi-chart.component';
import { ChartItem } from '../chart.interface';
import { BarHorizontalMultiChartOptions } from './bar-horizontal-multi-chart-options';
import {
  BarHorizontalMultiChartService,
} from 'shared/ui/chart/bar-horizontal-multi-chart/bar-horizontal-multi-chart.service';
import { ChartHelperService } from 'shared/ui/chart/chart-helper.service';

@Component({
  selector: 'ista-daytona-bar-horizontal-multi-chart',
  templateUrl: './bar-horizontal-multi-chart.component.html',
  styleUrls: ['./bar-horizontal-multi-chart.component.scss'],
  providers: [ChartHelperService, BarHorizontalMultiChartService],
})
export class BarHorizontalMultiChartComponent extends AbstractMultiChartComponent {
  @Input() label = '';
  @Input() displayFilterButtons = true;
  public barChartData!: ChartConfiguration['data'];
  public barChartOptions = BarHorizontalMultiChartOptions;
  public barType: ChartType = 'bar';
  public overwriteUnit!: UnitWithPrefix;

  constructor(
    private readonly ref: ChangeDetectorRef,
    private barHorizontalMultiChartService: BarHorizontalMultiChartService
  ) {
    super();
  }

  private _selectedFilter!: ChartItem;

  // @Input() labelConfig!: LabelConfig;
  get selectedFilter(): ChartItem {
    return this._selectedFilter;
  }

  @Input() set selectedFilter(data: ChartItem | undefined) {
    if (data && !_.isEqual(data, this._selectedFilter)) {
      this._selectedFilter = data;
      this.overwriteUnit = UnitWithPrefix.EMISSION_KG_PER_SQM;
      this._refreshData();
    }
  }

  public override _refreshData(): void {
    super._refreshData();
    const chartData = {
      ...this.barHorizontalMultiChartService.getMultiBarChartData(
        this.transformedData,
        this.selectedFilter?.value || this.selectedFilter?.label,
        this.selectedYear,
        this.overwriteColor,
        this.overwriteLabels
      ),
    };
    if (!_.isEqual(chartData, this.barChartData)) {
      this.barChartData = _.cloneDeep(chartData);
      this.ref.detectChanges();
    }
  }
}
