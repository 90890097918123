<button
  [class.button-data-add--sticky-style]="stickyStyle"
  [dropdownTriggerFor]="dropdown"
  class="button-data-add flex items-center justify-center font-extrabold text-blue-900 text-lg gap-2"
>
  <mat-icon class="icon-size-8 text-inherit">add_circle</mat-icon>
  <span class="leading-none">{{ 'data-add-dropdown.label' | translate }}</span>
</button>
<ista-daytona-dropdown #dropdown class="hidden">
  <div class="p-2">
    <div (click)="showExcelImportOverlay()" class="dropdown-item h5 p-4 h-auto" role="button">
      {{ 'data-add-dropdown.excel-import' | translate }}
    </div>
    <div (click)="showFilesImportOverlay()" class="dropdown-item h5 p-4 h-auto" role="button">
      {{ 'data-add-dropdown.data-file' | translate }}
    </div>
    <div class="dropdown-item h5 p-4 h-auto">
      <a routerLink="/data-upload">{{ 'data-add-dropdown.more-info' | translate }}</a>
    </div>
  </div>
</ista-daytona-dropdown>
