import { ColorHelper } from 'shared/helper/color-helper';

/* eslint-disable @typescript-eslint/restrict-plus-operands */
/* eslint-disable prefer-arrow/prefer-arrow-functions */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable object-shorthand */
/* eslint-disable @typescript-eslint/no-unsafe-return */

type IndexAxis = 'y' | 'x';
const iAxis: IndexAxis = 'y';

export const BarHorizontalChartOptions = {
  plugins: {
    legend: {
      display: false,
      labels: {
        boxHeight: 16,
        boxWidth: 16,
        color: ColorHelper.get('label'),
        padding: 24,
        font: {
          size: 14,
          family: 'TheSansE5-Plain',
        },
      },
    },
    labels: {
      display: false,
    },
  },
  aspectRatio: 1,
  maintainAspectRatio: false,
  indexAxis: iAxis,
  responsive: true,
  scales: {
    x: {
      grid: {
        color: ColorHelper.get('grid'),
        drawTicks: false,
      },
      ticks: {
        color: ColorHelper.get('label'),
        padding: 16,
        font: {
          family: 'TheSansE5-SemiBold',
          size: 14,
        },
      },
    },
    y: {
      grid: { color: ColorHelper.get('grid'), drawTicks: false },
      ticks: {
        padding: 16,
        font: {
          family: 'TheSansE5-SemiBold',
          size: 14,
        },
        color: ColorHelper.get('label'),
      },
    },
  },
};
