import {
  AfterViewInit,
  booleanAttribute,
  ChangeDetectionStrategy,
  Component,
  DestroyRef,
  ElementRef,
  inject,
  Input,
} from '@angular/core';
import { BreadcrumbComponent, DividerStyle } from '@ista-ui/angular';
import { DirectivesModule } from 'shared/directives/directives.module';
import { AsyncPipe, NgClass } from '@angular/common';
import { BreadcrumbStore } from 'shared/ui/breadcrumb-navigation/breadcrumb.store';
import { delay } from 'rxjs';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

@Component({
  selector: 'ista-daytona-breadcrumb-navigation',
  standalone: true,
  imports: [BreadcrumbComponent, DirectivesModule, AsyncPipe, NgClass],
  templateUrl: './breadcrumb-navigation.component.html',
  styleUrls: ['./breadcrumb-navigation.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BreadcrumbNavigationComponent implements AfterViewInit {
  @Input({ transform: booleanAttribute }) isSticky = false;
  selectBreadcrumbItems$ = this.breadcrumbsStore.selectBreadcrumbItems$;
  selectIsScrollVisible$ = this.breadcrumbsStore.selectIsScrollVisible$;
  protected readonly DividerStyle = DividerStyle;
  private destroyRef = inject(DestroyRef);

  constructor(private breadcrumbsStore: BreadcrumbStore, private el: ElementRef<HTMLDivElement>) {}

  ngAfterViewInit(): void {
    this.selectBreadcrumbItems$
      .pipe(delay(100), takeUntilDestroyed(this.destroyRef))
      .subscribe(() => this.setScrollToEnd());
  }

  private setScrollToEnd(): void {
    if (this.el) {
      const overflowWrapper = this.el.nativeElement.querySelector(
        '.breadcrumb-comp .overflow'
      ) as HTMLDivElement;
      if (overflowWrapper) {
        this.breadcrumbsStore.updateScrollWidth(overflowWrapper.scrollWidth);
        overflowWrapper.scrollLeft = overflowWrapper.scrollWidth;
      }
    }
  }
}
