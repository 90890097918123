import { Component, HostListener, Input } from '@angular/core';
import { OverlayService } from './overlay.service';

@Component({
  selector: 'ista-daytona-overlay',
  templateUrl: './overlay.component.html',
  styleUrls: ['./overlay.component.scss'],
})
export class OverlayComponent {
  @Input() showExcelDataImportCard = false;
  @Input() showFilesDataImportCard = false;
  @Input() showDeleteObjectCard = false;

  constructor(private readonly overlayService: OverlayService) {}

  @HostListener('window:keydown', ['$event'])
  onKeydownHandler(event: KeyboardEvent): void {
    if (event.key === 'Escape') {
      this.closeWindow();
    }
  }
  public closeWindow(): void {
    this.overlayService.close();
  }
}
