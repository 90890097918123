import {
  HttpEvent,
  HttpEventType,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, tap } from 'rxjs';

import { LoggerService } from 'shared/helper/logger.service';
import { LoadingState, LoadStatus } from './load-status.interface';
import { LoadStatusService } from './load-status.service';

@Injectable({
  providedIn: 'root',
})
export class LoadStatusIntereptor implements HttpInterceptor {
  constructor(private readonly loadStatusService: LoadStatusService) {}

  public intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(req).pipe(
      tap((event) => {
        switch (event.type) {
          case HttpEventType.UploadProgress:
          case HttpEventType.DownloadProgress:
            const status: LoadStatus = {
              id: 'eins',
              progress: event.total ? Math.round((100 * event.loaded) / event.total) : 0,
              state: LoadingState.PROGRESS,
            };
            this.loadStatusService.setProgressStatus(status);
            break;
          case HttpEventType.Response:
            this.loadStatusService.setProgressStatus({
              id: 'eins',
              progress: 100,
              state: LoadingState.DONE,
            });
            break;
          case HttpEventType.Sent:
            // this.loadStatusService.setInitalState();
            break;
          default:
            // this.setInitalState();
            LoggerService.log('default');
            break;
        }
      })
    );
  }
}
