import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { EMPTY, Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { AuthService } from './auth/auth.service';

@Injectable()
export class ErrorCatchingInterceptor implements HttpInterceptor {
  constructor(
    // IMPORTANT: do not inject services which themselves depend on TranslateService (e.g. ErrorHandlerService) unless
    // you inject the TranslateService inside the injected service using the Angular Injector (see ErrorHandlerService),
    // otherwise the injected TranslateService will be an empty object. At the moment it is out commented anyway.
    // private readonly _errorHandler: ErrorHandlerService
    private readonly _authService: AuthService
  ) {}
  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    return next.handle(request).pipe(
      catchError((error: HttpErrorResponse) => {
        if (error.status === 401) {
          // if at any backend request a 401 is thrown, it means no valid token is available and the user should login again
          this._authService.logout();
          return EMPTY;
        }

        // Error handling could be done here but turns out that it is not flexible enough.
        // Each caller should handle errors by themselves because context information can't be passed to the interceptor.
        // For example, the attempted action can't be passed from the caller to the interceptor.
        // this._errorHandler.handleError(error);

        return throwError(() => error);
      })
    );
  }
}
