import { ColorHelper } from 'shared/helper/color-helper';
import { ChartConfiguration } from 'chart.js';

/* eslint-disable @typescript-eslint/restrict-plus-operands */
/* eslint-disable prefer-arrow/prefer-arrow-functions */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable object-shorthand */
/* eslint-disable @typescript-eslint/no-unsafe-return */

type IndexAxis = 'y' | 'x';
const iAxis: IndexAxis = 'y';

export const BarHorizontalMultiChartOptions: ChartConfiguration['options'] = {
  plugins: {
    legend: {
      display: false,
      labels: {
        boxHeight: 16,
        boxWidth: 16,
        color: ColorHelper.get('label'),
        padding: 24,
        font: {
          size: 14,
          family: 'TheSansE5-Plain',
        },
      },
    },
    // labels: {
    //   display: false,
    // },
  },
  aspectRatio: 1,
  maintainAspectRatio: false,
  indexAxis: iAxis,
  responsive: true,
  // categoryPercentage: 1.0,
  // barPercentage: 0.8,
  scales: {
    x: {
      grid: {
        drawTicks: false,
        color: ColorHelper.get('grid'),
      },
      ticks: {
        color: ColorHelper.get('label'),
        padding: 16,
        font: {
          family: 'TheSansE5-SemiBold',
          size: 14,
        },
      },
      grace: '5%',
    },
    y: {
      grid: { drawTicks: false, color: ColorHelper.get('grid') },
      ticks: {
        color: ColorHelper.get('label'),
        padding: 16,
        font: {
          family: 'TheSansE5-SemiBold',
          size: 14,
        },
      },
    },
  },
};
