<div [ngClass]="{ active: isSelected }" [attr.data-qa]="dataQa">
  <mat-radio-button
    [color]="color"
    [ngClass]="{ isFocused: isFocused, hasError: hasError }"
    [checked]="isSelected"
    [disabled]="isDisabled"
    [value]="labelId"
    (change)="handleChange()"
    [name]="uuid"
    #matRadioButton
  >
    <div class="info" [ngClass]="{ disabled: isDisabled }">
      @if(label) {
        <span [innerHTML]="label"></span>
      }
    </div>
  </mat-radio-button>
</div>
