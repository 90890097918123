/* eslint-disable @typescript-eslint/member-ordering */
import { ChangeDetectorRef, Component, Input } from '@angular/core';
import { ChartConfiguration, ChartType } from 'chart.js';
import * as _ from 'lodash';

import { AbstractChartComponent } from '../abstract-chart.component';
import { ChartItem } from '../chart.interface';
import { BarHorizontalStackedChartOptions } from './bar-horizontal-stacked-chart-options';
import { BarHorizontalStackedChartService } from 'shared/ui/chart/bar-horizontal-stacked-chart/bar-horizontal-stacked-chart.service';
import { ChartHelperService } from 'shared/ui/chart/chart-helper.service';

@Component({
  selector: 'ista-daytona-bar-horizontal-stacked-chart',
  templateUrl: './bar-horizontal-stacked-chart.component.html',
  styleUrls: ['./bar-horizontal-stacked-chart.component.scss'],
  providers: [ChartHelperService, BarHorizontalStackedChartService],
})
export class BarHorizontalStackedChartComponent extends AbstractChartComponent {
  @Input() label = '';
  @Input() displayFilterButtons = true;
  // @Input() labelConfig!: LabelConfig;
  get selectedFilter(): ChartItem {
    return this._selectedFilter;
  }
  @Input() set selectedFilter(data: ChartItem | undefined) {
    if (data && !_.isEqual(data, this._selectedFilter)) {
      this._selectedFilter = data;
      this._refreshData();
    }
  }
  get selectedStackedFilter(): ChartItem[] {
    return this._selectedStackedFilter;
  }
  @Input() set selectedStackedFilter(value: ChartItem[]) {
    if (!_.isEqual(value, this._selectedStackedFilter)) {
      this._selectedStackedFilter = value;
      this._refreshData();
    }
  }
  public barChartData!: ChartConfiguration['data'];
  public barChartOptions = BarHorizontalStackedChartOptions;
  public barType: ChartType = 'bar';
  private _selectedFilter!: ChartItem;
  private _selectedStackedFilter!: ChartItem[];

  constructor(
    private readonly ref: ChangeDetectorRef,
    private barHorizontalStackedChartService: BarHorizontalStackedChartService
  ) {
    super();
  }

  public override _refreshData(): void {
    super._refreshData();
    if (this.selectedStackedFilter) {
      const chartData = this.barHorizontalStackedChartService.getBarStackedChartData(
        this.transformedData,
        this.selectedStackedFilter.map((item) => item.value ?? item.label),
        this.selectedYear
      );

      if (!_.isEqual(chartData, this.barChartData)) {
        this.barChartData = _.cloneDeep(chartData);
        this.ref.markForCheck();
      }
    }
  }
}
