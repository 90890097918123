import { Injectable } from '@angular/core';
import { Overlay, OverlayConfig, OverlayRef } from '@angular/cdk/overlay';
import { NoEnvironmentAvailableOverlayComponent } from 'core/environment/no-environment-available-overlay/no-environment-available-overlay.component';
import { ComponentPortal } from '@angular/cdk/portal';

@Injectable()
export class NoEnvironmentAvailableOverlayService {
  overlayRef!: OverlayRef;
  constructor(private overlay: Overlay) {
    this.overlayRef = this.createOverlayRef();
  }

  createOverlayRef(): OverlayRef {
    return this.overlay.create(this.getOverlayConfig(840, 840));
  }

  open(): void {
    this.overlayRef.attach(new ComponentPortal(NoEnvironmentAvailableOverlayComponent));
    this.overlayRef.backdropClick().subscribe(() => this.overlayRef.detach() as unknown);
  }

  close(): void {
    this.overlayRef.detach();
  }

  private getOverlayConfig(minHeight: number, minWidth: number): OverlayConfig {
    const positionStrategy = this.overlay
      .position()
      .global()
      .centerHorizontally()
      .centerVertically();

    return new OverlayConfig({
      hasBackdrop: true,
      backdropClass: 'overlay-background',
      panelClass: '',
      scrollStrategy: this.overlay.scrollStrategies.block(),
      positionStrategy,
      minHeight,
      minWidth,
    });
  }
}
