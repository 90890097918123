/* eslint-disable @typescript-eslint/member-ordering */
import { Component, Input } from '@angular/core';

@Component({
  selector: 'ista-daytona-bar-horizontal-chart-mini',
  templateUrl: './bar-horizontal-chart-mini.component.html',
  styleUrls: ['./bar-horizontal-chart-mini.component.scss'],
})
export class BarHorizontalChartMiniComponent {
  @Input() color = '#000';
  @Input() minLabel = '&lt;12';
  @Input() maxLabel = '&lt;=52';
  @Input() label = 'kg CO₂/m²/a';
  @Input() title = '';
  public dividers = new Array(9);
  public maxValue = 0;
  public displayData: number[] = [];

  private _data: number[] = [];

  get data(): number[] {
    return this._data;
  }

  @Input() set data(value: number[]) {
    this._data = value;
    this.setDisplayData();
  }

  public setDisplayData(): void {
    this.maxValue = Math.max(...this._data) + 5;
    this.displayData = this._data.map((value) => (value / this.maxValue) * 100);
  }

  public getLeftPadding(index: number): number {
    const max = 100;
    const paddingLeft = 4;
    const frame = max / this.data.length;
    return index * frame + paddingLeft;
  }

  getRoundedValue(valueIndex: number): number {
    const value = this._data[valueIndex] ?? 0;
    return Math.floor(value);
  }
}
