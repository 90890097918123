<div *ngIf="chartData" class="chart--legend">
  <div
    *ngFor="let legend of chartData?.datasets"
    class="flex items-center"
    data-cy="line-chart-legend--items"
  >
    <div [style.background-color]="legend.backgroundColor" class="rounded-lg w-4 h-4 mr-2"></div>
    <span [innerText]="legend?.label || '' | translate" class="text-base text-base-500"></span>
  </div>
</div>
