import { Component, OnDestroy } from '@angular/core';
import { ReplaySubject, Subscription } from 'rxjs';

import { Notification, NotificationType } from './notification.interface';
import { NotificationService } from './notification.service';

@Component({
  selector: 'ista-daytona-notification',
  templateUrl: './notification.component.html',
  styleUrls: ['./notification.component.scss'],
})
export class NotificationComponent implements OnDestroy {
  public notifications: Notification[] = [];
  public displayedNotification: Notification | undefined;
  public actualNotifiction!: Notification;
  public notification$ = new ReplaySubject<Notification>();
  public notificationIcon!: string;
  public notificationType = NotificationType;
  public actualPageIndex = 1;
  private subs = new Subscription();

  constructor(private readonly notificationService: NotificationService) {
    this.subs.add(
      this.notificationService.getNotification$().subscribe((notification) => {
        if (!this.existsNotification(notification)) {
          this.notifications.push(notification);
          this.displayedNotification = this.notifications[0];
        }
      })
    );
  }

  public get icon(): string {
    const nextItem: Notification | undefined = this.displayedNotification;
    const prefix = '/assets/icons/notification/';
    switch (nextItem?.type) {
      case NotificationType.WARNING:
        return prefix + 'Warning.svg';
      case NotificationType.ALERT:
        return prefix + 'BellRinging.svg';
      default:
        return prefix + 'CheckCircle.svg';
    }
  }

  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }

  public closeNotification(): void {
    if (this.notifications.length > 1) {
      this.notifications = [];
      this.displayedNotification = undefined;
    } else {
      this.notifications.shift();
      this.displayedNotification = this.notifications[0];
    }
  }

  public setNotificationByIndex(index: number): void {
    const indexLen = index - 1;
    if (indexLen >= 0 && indexLen < this.notifications.length) {
      this.displayedNotification = this.notifications[indexLen];
    }
  }

  private existsNotification(notification: Notification): boolean {
    return !!this.notifications.find(
      (item) => item?.headline === notification?.headline && item?.text === notification?.text
    );
  }
}
