import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class RequestTrackingService {
  private requests: { [requestNameSpace: string]: number } = {};

  public registerRequest(requestNameSpace: string): number {
    if (this.requests[requestNameSpace] !== undefined) {
      this.requests[requestNameSpace]++;
    } else {
      this.requests[requestNameSpace] = 0;
    }
    return this.requests[requestNameSpace];
  }

  public isRequestStillValid(requestNameSpace: string, requestId: number): boolean {
    return this.requests[requestNameSpace] === requestId;
  }
}
