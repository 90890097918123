import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { SafePipe } from 'shared/pipes/safe.pipe';

import { FormatNumberPipe } from './format-number.pipe';
import { NullToUndefinedPipe } from './null-to-undefined.pipe';

@NgModule({
  imports: [CommonModule],
  declarations: [FormatNumberPipe, NullToUndefinedPipe, SafePipe],
  exports: [FormatNumberPipe, NullToUndefinedPipe, SafePipe],
  providers: [FormatNumberPipe, NullToUndefinedPipe],
})
export class PipesModule {}
