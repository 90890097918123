import { ConsumptionTypes } from '../models/consumption-types.interface';

export type AnalyticalCategories = 'consumption' | 'costs' | 'emissions';
export interface ConsumptionTypeButton {
  consumptionType: ConsumptionTypes[];
  analyticalCategories: AnalyticalCategories;
  heatingChilds?: ConsumptionTypes[];
}
export const ConsumptionTypeButtonMapping: ConsumptionTypeButton[] = [
  {
    analyticalCategories: 'consumption',
    consumptionType: ['heatingAndHotWater', 'electricity', 'water', 'waste', 'districtCooling'],
  },
  {
    analyticalCategories: 'emissions',
    consumptionType: ['heatingAndHotWater', 'electricity', 'water', 'waste'],
  },
  {
    analyticalCategories: 'costs',
    consumptionType: ['heatingAndHotWater', 'electricity', 'water', 'waste'],
  },
];
