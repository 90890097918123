import { Component, Input } from '@angular/core';

import { ColorStyle } from 'shared/models/color-style.enum';

@Component({
  selector: 'ista-daytona-card',
  templateUrl: './card.component.html',
  styleUrls: ['./card.component.scss'],
})
export class CardComponent {
  @Input() shouldAddOffsetTop = true;
  @Input() displayBoxShadow = false;
  @Input() minWidth = '0';
  @Input() colorStyle = ColorStyle.WHITE;
}
