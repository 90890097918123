import { MasterdataCardContentInterface } from 'app/features/portfolio/portfolio-site-masterdata/interfaces/masterdata-card-content.interface';
import { ObjectMasterdataInterface } from 'app/features/portfolio/portfolio-site-masterdata/interfaces/object-masterdata.interface';
import { PortfolioCardContentInterface } from 'app/features/portfolio/portfolio-site-masterdata/interfaces/portfolio-card-content.interface';
import { PortfolioCardInputInterface } from 'app/features/portfolio/portfolio-site-masterdata/interfaces/portfolio-card-input.interface';
import { PortfolioCardHelperService } from 'shared/helper/portfolio-card-helper.service';
import { PortfolioCard } from 'shared/ui/portfolio-card/portfolio-card.interface';
import { Unit } from 'shared/models/unit.enum';

import { GroupDetail } from '../portfolio-group/group-detail.interface';
import { SiteDetails } from 'app/features/portfolio/portfolio-site-masterdata/interfaces/site-details.interface';
import { InfoIconCard } from 'shared/ui/info-icon-card/info-icon-card.interface';
import { cloneDeep } from 'lodash';
import { groupPortfolioDataInfoCard, sitePortfolioDataInfoCard } from 'shared/constants';

export class PortfolioMasterdataMapper {
  static getMasterdataCardData(data: ObjectMasterdataInterface): MasterdataCardContentInterface[] {
    const cardContent: MasterdataCardContentInterface[] = [];
    cardContent.push({
      header: 'portfolio.masterdataHeader.objectName',
      value: data.objectName,
    });
    cardContent.push({
      header: 'portfolio.masterdataHeader.istaPropertyNumber',
      value: data.istaObjectNo,
    });
    cardContent.push({
      header: 'portfolio.masterdataHeader.postalCode',
      value: data.postalCode,
    });
    cardContent.push({
      header: 'portfolio.masterdataHeader.city',
      value: data.city,
    });
    cardContent.push({
      header: 'portfolio.masterdataHeader.street',
      value: data.street,
    });
    return cardContent;
  }

  static mapStaticMasterdataSite(data: SiteDetails): ObjectMasterdataInterface {
    const object: ObjectMasterdataInterface = {
      objectName: '',
      istaObjectNo: '',
      postalCode: '',
      street: '',
      city: '',
    };
    object.objectName = data.externalReference !== null ? data.externalReference : '-';
    object.istaObjectNo = data.istaPropertyNumber !== null ? data.istaPropertyNumber : '-';
    object.postalCode = data.postalCode ? data.postalCode : '';
    object.city = data.city ? data.city : '';
    object.street = data?.houseNumber
      ? `${data.street || ''} ${data.houseNumber || ''}`
      : data.street || '';
    return object;
  }

  static clearStaticMasterdataSite(): ObjectMasterdataInterface {
    const object: ObjectMasterdataInterface = {
      objectName: '',
      istaObjectNo: '',
      postalCode: '',
      street: '',
      city: '',
    };
    object.objectName = '';
    object.istaObjectNo = '';
    object.postalCode = '';
    object.city = '';
    object.street = '';
    return object;
  }

  static mapToPortfolioCardContent(
    data: PortfolioCardInputInterface,
    globalSelectedYear: number
  ): PortfolioCardContentInterface[] {
    const dynamicObjectMasterData: PortfolioCard[] = [];
    if (data.numberOfSites) {
      dynamicObjectMasterData.push({
        header: {
          icon: {
            url: 'assets/icons/cards/Building.svg',
            text: 'portfolio.masterdataHeader.numberOfSites',
          },
          label: 'portfolio.masterdataHeader.numberOfSites',
        },
        data: data.numberOfSites.map((numberOfSites) => ({
          year: numberOfSites.timeStamp,
          value: {
            value: numberOfSites.value,
          },
        })),
      });
    }
    dynamicObjectMasterData.push({
      header: {
        icon: {
          url: 'assets/icons/cards/Room.svg',
          text: 'portfolio.masterdataHeader.numberOfUnits',
        },
        label: 'portfolio.masterdataHeader.numberOfUnits',
      },
      data: data.numberOfUnits.map((numberOfUnits) => ({
        year: numberOfUnits.timeStamp,
        value: {
          value: numberOfUnits.value,
        },
      })),
    });
    dynamicObjectMasterData.push({
      header: {
        icon: {
          url: 'assets/icons/cards/Nutzeinheit.svg',
          text: 'portfolio.masterdataHeader.space',
        },
        label: 'portfolio.masterdataHeader.space',
      },
      data: data.space.map((space) => ({
        year: space.timeStamp,
        value: {
          value: space.value,
          unit: Unit.SQM,
        },
      })),
    });
    return dynamicObjectMasterData.map((item) => ({
      header: item.header,
      value: PortfolioCardHelperService.getPortfolioCardData(item, globalSelectedYear),
    }));
  }

  static mapMeteringData(data: SiteDetails): MasterdataCardContentInterface[][] {
    return data.meteringPoints.map((meteringPoint) => [
      {
        header: 'portfolio.meteringPointHeader.meteringPointNumber',
        value: meteringPoint.reference,
      },
      {
        header: 'portfolio.meteringPointHeader.meteringPointDescription',
        value: meteringPoint.description,
      },
    ]);
  }

  static mapSiteDetailToPortfolioCard(
    response: SiteDetails,
    globalSelectedYear: number
  ): PortfolioCardInputInterface {
    const timeStamp = `${globalSelectedYear}-01-01`;
    return {
      numberOfUnits:
        response.numberOfUnits.length > 0 ? response.numberOfUnits : [{ timeStamp, value: 0 }],
      space: response.space.length > 0 ? response.space : [{ timeStamp, value: 0 }],
    };
  }

  static mapGroupDetailToPortfolioInfoCard(selectedData: GroupDetail): InfoIconCard[] {
    const sitePortfolio = cloneDeep(groupPortfolioDataInfoCard);

    sitePortfolio[0].value = selectedData?.numberOfSites;
    sitePortfolio[1].value = selectedData?.numberOfUnits;
    sitePortfolio[2].value = selectedData?.space;
    sitePortfolio[2].unit = Unit.SQM;

    return sitePortfolio;
  }

  static mapSiteDetailToPortfolioInfoCard(selectedData: SiteDetails): InfoIconCard[] {
    const sitePortfolio = cloneDeep(sitePortfolioDataInfoCard);

    sitePortfolio[0].value = selectedData?.numberOfUnits.length
      ? selectedData.numberOfUnits[0].value
      : 0;
    sitePortfolio[1].value = selectedData?.space.length ? selectedData.space[0].value : 0;
    sitePortfolio[1].unit = Unit.SQM;

    return sitePortfolio;
  }

  static mapGroupDetailToPortfolioCard(
    response: GroupDetail,
    globalSelectedYear: number
  ): PortfolioCardInputInterface {
    const timeStamp = `${globalSelectedYear}-01-01`;
    return {
      numberOfUnits: [{ timeStamp, value: response.numberOfUnits }],
      numberOfSites: [{ timeStamp, value: response.numberOfSites }],
      space: [{ timeStamp, value: response.space }],
    };
  }

  static mapResponseToPortfolioInputForSite(
    response: SiteDetails,
    globalSelectedYear: number
  ): PortfolioCardInputInterface {
    const timeStamp = `${globalSelectedYear}-01-01`;
    return {
      numberOfUnits:
        response.numberOfUnits.length > 0 ? response.numberOfUnits : [{ timeStamp, value: 0 }],
      space: response.space.length > 0 ? response.space : [{ timeStamp, value: 0 }],
    };
  }

  static getInputForGeoApi(response: SiteDetails): string {
    const country = 'Deutschland';
    const streetname = response.street?.toLowerCase() || '';
    const houseNumber: string = response.houseNumber?.toLowerCase() || '';
    const street = houseNumber.length ? streetname + ' ' + houseNumber : streetname;
    const plz = response.postalCode;
    const city = response.city;
    const address = `${street} ${plz} ${city} ${country}`;
    return address;
  }
}
