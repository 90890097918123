import { ChartConfiguration } from 'chart.js';

import { ColorHelper } from 'shared/helper/color-helper';

export const LineChartOptions: ChartConfiguration['options'] = {
  plugins: {
    legend: {
      display: false,
      position: 'bottom',
      align: 'end',
      labels: {
        boxHeight: 16,
        boxWidth: 16,
        color: ColorHelper.get('label'),
        padding: 24,
        font: {
          size: 16,
          family: 'TheSansE5-Plain',
        },
      },
    },
  },
  scales: {
    y: {
      title: {
        color: ColorHelper.get('label'),
      },
    },
    x: {
      title: {
        color: ColorHelper.get('label'),
      },
    },
  },
  maintainAspectRatio: false,
};
