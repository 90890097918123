import { ValueItem } from '../../models/value-item.interface';
import { Unit } from '../../models/unit.enum';
import { ConsumptionTypes } from '../../models/consumption-types.interface';
import { CardHeaderConfig } from '../../models/card-header-config.interface';
import { AnalyticalCategories } from '../../interfaces/consumption-type-button.interface';

export interface BarItem {
  label: string;
  value: ValueItem;
}

export interface MonitoringCardData {
  id: number;
  color: string;
  header: CardHeaderConfig;
  siteCount?: number;
  bars: BarItem[];
  unit?: Unit;
}

export enum MonitoringCardPortfolioType {
  electricity = 'electricity',
  waste = 'waste',
  water = 'water',
  heatingAndHotWater = 'heatingAndHotWater',
  emissions = 'emissions',
  costs = 'costs',
  co2 = 'co2',
}

// export interface MonitoringCardPortfolio {
//   [key: string]: MonitoringCardData;
//   electricity: MonitoringCardData;
//   waste: MonitoringCardData;
//   water: MonitoringCardData;
//   heatingAndHotWater: MonitoringCardData;
//   emissions: MonitoringCardData;
//   costs: MonitoringCardData;
// }

export type ConsumptionTypesAndAnalyticalCategories = ConsumptionTypes | AnalyticalCategories;

export type MonitoringCardPortfolio = {
  [K in ConsumptionTypesAndAnalyticalCategories]: MonitoringCardData;
};
