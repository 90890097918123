/* eslint-disable no-console */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { Injectable } from '@angular/core';

import { environment } from 'environments/environment';

enum LogLevel {
  LOG,
  WARN,
  ERROR,
}

@Injectable({
  providedIn: 'root',
})
export class LoggerService {
  public static log(msg: string, data?: any): void {
    this.output(LogLevel.LOG, msg, data);
  }

  public static warn(msg: string, data?: any): void {
    this.output(LogLevel.WARN, msg, data);
  }

  public static error(msg: string, data?: any): void {
    this.output(LogLevel.ERROR, msg, data);
  }

  private static output(level: LogLevel, msg: string, data?: any): void {
    switch (level) {
      case LogLevel.ERROR:
        console.error(msg, data);
        break;
      case LogLevel.WARN:
        if (!environment.production) {
          console.warn(msg, data);
        }
        break;
      default:
        if (!environment.production) {
          // console.log(msg, data);
        }
    }
  }
}
