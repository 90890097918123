import { ButtonColor } from '../basic/button/button-color.enum';
import { ButtonType } from '../basic/button/button-type.enum';
import { ColorStyle } from '../../models/color-style.enum';
import { IconType } from '../basic/icon/icon-type.enum';
import { InputTypes } from '../basic/input/input-types.enum';
import { LoaderType } from '../basic/loader/loader.interface';
import { FileUploadType } from '../file-upload/file-upload.model';
import { InputHeight } from './../basic/input/input-types.enum';
import { FilterType } from './../filter/filter-tab.interface';

export abstract class BaseUi {
  public readonly _inputType = InputTypes;
  public readonly _buttonColor = ButtonColor;
  public readonly _buttonType = ButtonType;
  public readonly _iconType = IconType;
  public readonly _colorStyle = ColorStyle;
  public readonly _filterType = FilterType;
  public readonly _inputHeight = InputHeight;
  public readonly _loaderType = LoaderType;
  public readonly _fileUploadType = FileUploadType;
}
