<ista-daytona-content-card-open [labels]="['Piwik PRO Analytics Suite']">
  <p>
    {{ 'piwik.block1' | translate }}
  </p>
  <h4>{{ 'piwik.subheadline1' | translate }}</h4>
  <p>
    {{ 'piwik.block2' | translate }}
  </p>
  <ul>
    <li>
      <span
        ><span
          ><span
            ><span
              ><span>{{ 'piwik.list1' | translate }}</span></span
            ></span
          ></span
        ></span
      >
    </li>
    <li>
      <span
        ><span
          ><span
            ><span
              ><span>{{ 'piwik.list2' | translate }}</span></span
            ></span
          ></span
        ></span
      >
    </li>
  </ul>
  <h4>{{ 'piwik.subheadline2' | translate }}</h4>
  <ul>
    <li>
      <span
        ><span
          ><span
            ><span
              ><span>{{ 'piwik.list3' | translate }}</span></span
            ></span
          ></span
        ></span
      >
    </li>
  </ul>
  <h4>{{ 'piwik.subheadline3' | translate }}</h4>
  <p>
    {{ 'piwik.block3' | translate }}
  </p>
  <ul>
    <li>
      <span
        ><span
          ><span
            ><span
              ><span>{{ 'piwik.list4' | translate }}</span></span
            ></span
          ></span
        ></span
      >
    </li>
    <li>
      <span
        ><span
          ><span
            ><span
              ><span>{{ 'piwik.list5' | translate }}</span></span
            ></span
          ></span
        ></span
      >
    </li>
    <li>
      <span
        ><span
          ><span
            ><span
              ><span>{{ 'piwik.list6' | translate }}</span></span
            ></span
          ></span
        ></span
      >
    </li>
    <li>
      <span
        ><span
          ><span
            ><span
              ><span>{{ 'piwik.list7' | translate }}</span></span
            ></span
          ></span
        ></span
      >
    </li>
    <li>
      <span
        ><span
          ><span
            ><span
              ><span>{{ 'piwik.list8' | translate }}</span></span
            ></span
          ></span
        ></span
      >
    </li>
    <li>
      <span
        ><span
          ><span
            ><span
              ><span>{{ 'piwik.list9' | translate }}</span></span
            ></span
          ></span
        ></span
      >
    </li>
    <li>
      <span
        ><span
          ><span
            ><span
              ><span>{{ 'piwik.list10' | translate }}</span></span
            ></span
          ></span
        ></span
      >
    </li>
    <li>
      <span
        ><span
          ><span
            ><span
              ><span>{{ 'piwik.list11' | translate }}</span></span
            ></span
          ></span
        ></span
      >
    </li>
    <li>
      <span
        ><span
          ><span
            ><span
              ><span>{{ 'piwik.list12' | translate }}</span></span
            ></span
          ></span
        ></span
      >
    </li>
    <li>
      <span
        ><span
          ><span
            ><span
              ><span>{{ 'piwik.list13' | translate }}</span></span
            ></span
          ></span
        ></span
      >
    </li>
  </ul>
  <h4>{{ 'piwik.subheadline4' | translate }}</h4>
  <p>
    {{ 'piwik.block4' | translate }}
  </p>
  <ul>
    <li>
      <span
        ><span
          ><span
            ><span><span>Art. 6 Abs. 1 s. 1 lit. a DS-GVO</span></span></span
          ></span
        ></span
      >
    </li>
  </ul>
  <h4>{{ 'piwik.subheadline5' | translate }}</h4>
  <p>{{ 'piwik.block5' | translate }}</p>
  <h4>{{ 'piwik.subheadline6' | translate }}</h4>
  <p>
    {{ 'piwik.block6' | translate }}
  </p>
  <p>{{ 'piwik.block7' | translate }}</p>
  <h4>{{ 'piwik.subheadline7' | translate }}</h4>
  <ul>
    <li>
      <span
        ><span
          ><span
            ><span><span>Piwik PRO Sp. z o.o.</span></span></span
          ></span
        ></span
      >
    </li>
    <li>
      <span
        ><span
          ><span
            ><span><span>Piwik PRO GmbH</span></span></span
          ></span
        ></span
      >
    </li>
  </ul>
  <h4>{{ 'piwik.subheadline8' | translate }}</h4>
  <p>
    {{ 'piwik.block8' | translate }}
  </p>
  <p>
    <span class="align-left"
      ><a href="mailto:gdpr@piwik.pro" target="_blank">gdpr&#64;piwik.pro</a></span
    >
    <br />
  </p>
  <h4>{{ 'piwik.subheadline9' | translate }}</h4>
  <p>
    {{ 'piwik.block9' | translate }}
  </p>
  <p>
    {{ 'piwik.block10' | translate }}
    <u
      ><a href="https://piwik.pro/privacy-policy/" target="_blank" rel="noopener"
        >https://piwik.pro/privacy-policy/</a
      ></u
    ><br />
  </p>
  <p>
    {{ 'piwik.block11' | translate }}
    <span class="align-left"
      ><u
        ><a href="https://piwik.pro/opt-out/" target="_blank" rel="noopener"
          >https://piwik.pro/opt-out/</a
        ></u
      ></span
    >
  </p></ista-daytona-content-card-open
>
