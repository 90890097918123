import { Component, Input } from '@angular/core';

import { AbstractChartComponent } from '../abstract-chart.component';
import { ChartItem, ChartItemData } from './../chart.interface';

interface ChartItemExtended extends ChartItem {
  additionalValues?: number;
}

@Component({
  selector: 'ista-daytona-distribution-chart',
  templateUrl: './distribution-chart.component.html',
  styleUrls: ['./distribution-chart.component.scss'],
})
export class DistributionChartComponent extends AbstractChartComponent {
  @Input() label = '';
  public transformedChartItem!: ChartItemExtended[];
  private totalValue = 0;

  constructor() {
    super();
  }

  public override _refreshData(): void {
    // this.transformedChartItem = _.cloneDeep(this.data);
    // this.transformedChartItem?.forEach((item) => {
    //   let result = 0;
    //   this.getFilteredDataByYear(item.data)?.forEach(
    //     (itemData) => (result += itemData.values?.value || 0)
    //   );
    //   item.additionalValues = result;
    // });
    // this.totalValue =
    //   this.transformedChartItem?.find(
    //     (item) => item.value === HelperService.heatingLabel
    //   )?.additionalValues || 0;
    // this.transformedChartItem = this.transformedChartItem?.filter(
    //   (item) => item.value !== HelperService.heatingLabel
    // );
  }

  // public getItemWidth(value: number | undefined): number {
  //   if (!value) {
  //     return 1;
  //   }

  //   return (value / this.totalValue) * 100;
  // }

  public getItemWidth(data: ChartItemData[] | undefined): number {
    const item = data?.filter((dataItem) => dataItem.year === this.selectedYear?.from);

    return item ? item[0]?.values?.value || 0 : 0;
  }

  public isLeftRadius(index: number): boolean {
    if (index === 0) {
      return this.getItemWidth(this.data[index]?.data) > 0;
    }

    return (
      this.getItemWidth(this.data[index - 1]?.data) <= 0 &&
      this.getItemWidth(this.data[index]?.data) > 0
    );
  }

  public isRightRadius(index: number): boolean {
    if (index === this.data.length - 1) {
      return this.getItemWidth(this.data[index]?.data) > 0;
    }

    return (
      this.getItemWidth(this.data[index + 1]?.data) <= 0 &&
      this.getItemWidth(this.data[index]?.data) > 0
    );
  }
}
