import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';

import { BasicModule } from '../basic/basic.module';
import { FilterPageComponent } from './filter-page/filter-page.component';
import { FilterSubTabComponent } from './filter-sub-tab/filter-sub-tab.component';
import { FilterTabSmallComponent } from './filter-tab-small/filter-tab-small.component';
import { FilterTabComponent } from './filter-tab/filter-tab.component';
import { FilterTimeSeriesComponent } from './filter-time-series/filter-time-series.component';
import { FilterComponent } from './filter.component';
import { RouterLink } from '@angular/router';
import { DropdownModule } from 'shared/ui/basic/dropdown/dropdown.module';

@NgModule({
  imports: [CommonModule, BasicModule, TranslateModule, RouterLink, DropdownModule],
  declarations: [
    FilterComponent,
    FilterSubTabComponent,
    FilterTabComponent,
    FilterPageComponent,
    FilterTimeSeriesComponent,
    FilterTabSmallComponent,
  ],
  exports: [FilterComponent, FilterTimeSeriesComponent, FilterTabComponent, FilterComponent],
})
export class FilterModule {}
