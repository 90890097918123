<div class="w-full h-[61px] card-color-container relative mb-2">
  <div
    *ngFor="let item of displayData; let i = index"
    [style.background-color]="color"
    [style.height.%]="item"
    [style.left.%]="getLeftPadding(i)"
    [title]="title | translate: {items: getRoundedValue(i)}"
    class="bar-item absolute"
  ></div>
</div>
<div class="px-1 labels w-full flex space-between">
  <h6 [innerHTML]="minLabel"></h6>
  <h6 class="grow text-center">{{ label }}</h6>
  <h6 [innerHTML]="maxLabel"></h6>
</div>
