import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'ista-daytona-info-card',
  templateUrl: './info-card.component.html',
})
export class InfoCardComponent {
  @Input() headline = '';
  @Input() text = '';
  @Output() hideInfoboxValue = new EventEmitter<boolean>();
  showInfobox = true;

  hideInfobox(): void {
    this.showInfobox = false;
    this.hideInfoboxValue.emit(false);
  }
}
