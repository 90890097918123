import { Component } from '@angular/core';

import { HelperService } from 'shared/helper/helper.service';

@Component({
  selector: 'ista-daytona-privacy',
  templateUrl: './privacy.component.html',
  styleUrls: ['./privacy.component.scss'],
})
export class PrivacyComponent {
  constructor() {
    HelperService.scrollToTop();
  }
}
