import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { HttpRequestService } from 'core/http-request/http-request.service';
import { environment } from 'environments/environment';
import { catchError, first, Observable, throwError } from 'rxjs';

import { EFileResponse } from './efile-response.interface';
import { ExcelFileResponse } from './excelfile-response.interface';
import { FileUpload } from './file-upload.model';
import { FileUploadResponse } from './fileupload-response.interface';

@Injectable({
  providedIn: 'root',
})
export class FileUploadService {
  constructor(private readonly httRequestService: HttpRequestService) {}

  public uploadExcelFile(
    fileUpload: FileUpload
  ): Observable<FileUploadResponse<ExcelFileResponse>> {
    const url = `${environment.apiUrl}/api/###/import/excel`;

    return this.httRequestService.uploadFormData<ExcelFileResponse>(fileUpload.formData, url).pipe(
      first(),
      catchError((error: HttpErrorResponse) => throwError(() => error))
    );
  }

  public uploadEFile(fileUpload: FileUpload): Observable<FileUploadResponse<EFileResponse>> {
    const url = `${environment.apiUrl}/api/###/import/e-satz`;

    return this.httRequestService.uploadFormData<EFileResponse>(fileUpload.formData, url).pipe(
      first(),
      catchError((error: HttpErrorResponse) => throwError(() => error))
    );
  }
}
