import { TagsStore } from '../tags.store';
import { AbstractControl, AsyncValidatorFn, ValidationErrors } from '@angular/forms';
import { first, map, Observable } from 'rxjs';
import { NAME_EXISTS_ERROR_KEY, Tag } from '../interface';

export class CustomValidators {
  static createNameAlreadyExists(tagsStore: TagsStore): AsyncValidatorFn {
    return (control: AbstractControl<string>): Observable<ValidationErrors | null> =>
      tagsStore.tags$.pipe(
        first(),
        map((tags) => {
          const isExist = tags
            .map((tag) => tag.name.toLowerCase())
            .includes(control.value.toLowerCase());
          return isExist
            ? {
                [NAME_EXISTS_ERROR_KEY]: true,
              }
            : null;
        })
      );
  }

  static tagWithSameNameAlreadyExists(tagsStore: TagsStore): AsyncValidatorFn {
    return (control: AbstractControl<Tag>): Observable<ValidationErrors | null> =>
      tagsStore.tags$.pipe(
        first(),
        map((tags) => {
          const isExist = tags.some(
            (tag) => tag.name === control.value.name && tag.id !== control.value.id
          );
          return isExist
            ? {
                [NAME_EXISTS_ERROR_KEY]: true,
              }
            : null;
        })
      );
  }
}
