import { Injectable } from '@angular/core';
import { PortfolioAnalysisService } from '../portfolio-analysis.service';
import { ChartItem } from '../../chart/chart.interface';
import { CardTabItem } from 'shared/ui/card-tab/interfaces';
import { EXCLUDE_KEYS } from 'shared/ui/bar-chart-portfolio-analysis/constants';

@Injectable()
export class PortfolioAnalysisChartService {
  constructor(private portfolioAnalysisService: PortfolioAnalysisService) {}

  getConsumptionCardTabsItems(data: ChartItem[], preSelectedTabValue = ''): CardTabItem[] {
    return this.portfolioAnalysisService.getConsumptionCardTabsItems(
      data,
      EXCLUDE_KEYS,
      preSelectedTabValue
    );
  }
}
