import { Injectable } from '@angular/core';
import { ComponentStore, OnStoreInit } from '@ngrx/component-store';
import { BreadcrumbItem } from '@ista-ui/angular';
import {
  selectRouteTitle,
  selectRouteUrlWithoutQueryParams,
} from 'app/+state/router/reduced-route.selectors';
import { Store } from '@ngrx/store';
import { RootState } from 'app/+state/app.reducer';
import { combineLatest, distinctUntilChanged, takeUntil } from 'rxjs';
import { BREADCRUMB_MAX_WIDTH } from 'shared/ui/breadcrumb-navigation/constants';

export interface BreadCrumbsState {
  items: BreadcrumbItem[];
  scrollWidth: number;
}

@Injectable()
export class BreadcrumbStore extends ComponentStore<BreadCrumbsState> implements OnStoreInit {
  readonly addBreadcrumbItems = this.updater(
    (state: BreadCrumbsState, items: BreadcrumbItem[]) => ({
      ...state,
      items: [...state.items, ...items.filter((item) => item.title !== undefined)],
    })
  );

  readonly resetToRootAndAddItems = this.updater(
    (state: BreadCrumbsState, items: BreadcrumbItem[]) => {
      const rootItem = state.items.length ? state.items[0] : undefined;
      const filteredItems = items.filter((item) => item.title !== undefined);
      return {
        ...state,
        items: rootItem ? [rootItem, ...filteredItems] : [...filteredItems],
      };
    }
  );

  readonly updateScrollWidth = this.updater((state, scrollWidth: number) => ({
    ...state,
    scrollWidth,
  }));

  readonly setBreadcrumbItems = this.updater(
    (state: BreadCrumbsState, items: BreadcrumbItem[]) => ({
      ...state,
      items: (state.items = items),
    })
  );

  readonly selectBreadcrumbItems$ = this.select((state) => state.items);

  readonly selectIsScrollVisible$ = this.select(
    (state) => state.scrollWidth > BREADCRUMB_MAX_WIDTH,
    {
      equal: (a, b) => a === b,
      debounce: true,
    }
  );

  constructor(private readonly ngrxStore: Store<RootState>) {
    super({ items: [], scrollWidth: 0 });
  }

  ngrxOnStoreInit(): void {
    combineLatest([
      this.ngrxStore.select(selectRouteTitle),
      this.ngrxStore.select(selectRouteUrlWithoutQueryParams),
    ])
      .pipe(
        distinctUntilChanged(([, oldTitle], [, url]) => url === oldTitle),
        takeUntil(this.destroy$)
      )
      .subscribe(([title, url]) => {
        const route = url.split('/')[1];
        this.setBreadcrumbItems([{ title, route: `${route}` }]);
      });
  }
}
