import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ThemePalette } from '@angular/material/core';
import { TrackingWrapperService } from 'core/tracking/tracking-wrapper.service';

import { BaseUi } from '../../base-ui/base-ui';

@Component({
  selector: 'ista-daytona-button',
  templateUrl: './button.component.html',
  styleUrls: ['./button.component.scss'],
})
export class ButtonComponent extends BaseUi {
  @Input() type: 'primary' | 'secondary' | 'tertiary' | 'icon' | 'icon_small' = 'primary';
  @Input() color: ThemePalette = 'primary';
  @Input() label = '';
  @Input() disabled = false;
  @Input() small = false;
  @Input() icon?: string;

  @Output() buttonClicked = new EventEmitter<boolean>();

  constructor(private readonly _trackingService: TrackingWrapperService) {
    super();
  }

  public click(): void {
    if (!this.disabled) {
      this._trackingService.addGTM(
        {
          eventCategory: 'elementClick',
          eventLabel: this.label,
          eventAction: 'buttonClick',
        },
        ['']
      );
      this.buttonClicked.emit(true);
    }
  }
}
