<div class="imprint-container page--static">
  <h2 class="headline2 mb-l">
    {{ 'privacy.headline' | translate }}
  </h2>

  <p class="content offset" [innerHTML]="'privacy.block1' | translate"></p>

  <p class="content mb-xl" [innerHTML]="'privacy.block2' | translate"></p>

  <p class="content mb-xl">
    <span class="h3 mb-2">{{ 'privacy.subheadline1' | translate }}</span>
    <span [innerHTML]="'privacy.block3' | translate"></span>
  </p>

  <p class="content mb-xl">
    <span class="h3 mb-2">{{ 'privacy.subheadline2' | translate }}</span>
    <span class="mb-1"
      >{{ 'privacy.block4' | translate }} <br />
      <br />

      {{ 'privacy.subheadline3' | translate }}
      <ul>
        <li>{{ 'privacy.list1' | translate }}</li>
        <li>{{ 'privacy.list2' | translate }}</li>
        <li>{{ 'privacy.list3' | translate }}</li>
        <li>{{ 'privacy.list4' | translate }}</li>
        <li>{{ 'privacy.list5' | translate }}</li>
        <li>{{ 'privacy.list6' | translate }}</li>
        <li>{{ 'privacy.list7' | translate }}</li>
      </ul></span
    >
    <span [innerHTML]="'privacy.block5' | translate"> </span>
  </p>

  <p class="content mb-xl">
    <span class="h3 mb-2">{{ 'privacy.subheadline4' | translate }}</span>
    <span
      >{{ 'privacy.block6' | translate }}<br /><br />
      {{ 'privacy.block7' | translate }}<br /><br />
      {{ 'privacy.block8' | translate }}</span
    >
  </p>

  <p class="content mb-l">
    <span class="h3 mb-2">{{ 'privacy.subheadline5' | translate }}</span>
    <span>{{ 'privacy.block9' | translate }}</span>
  </p>
  <ul class="list--hide">
    <li><ista-daytona-usercentrics></ista-daytona-usercentrics></li>
    <li><ista-daytona-google-tag-manager></ista-daytona-google-tag-manager></li>
    <li><ista-daytona-zenloop></ista-daytona-zenloop></li>
    <li><ista-daytona-piwik></ista-daytona-piwik></li>
    <li><ista-daytona-hotjar></ista-daytona-hotjar></li>
  </ul>
</div>
