import {
  booleanAttribute,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  Output,
  ViewChild,
} from '@angular/core';

@Component({
  selector: 'ista-daytona-editing-item-options',
  templateUrl: 'editing-item-options.component.html',
  styleUrls: ['editing-item-options.component.scss'],
})
export class EditingItemOptionsComponent {
  @ViewChild('element') element!: ElementRef<HTMLElement>;
  @Input() item?: { id: number; name: string };
  @Input({ transform: booleanAttribute }) showDragIcon = true;
  @Output() delete = new EventEmitter<{ x: number; y: number }>();

  onDelete(): void {
    const rect = this.element.nativeElement.getBoundingClientRect();
    this.delete.emit({
      x: rect.x,
      y: rect.bottom,
    });
  }
}
