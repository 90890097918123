import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { UiModule } from 'shared/ui/ui.module';
import { GoogleTagManagerComponent } from './google-tag-manager/google-tag-manager.component';
import { PiwikComponent } from './piwik/piwik.component';
import { PrivacyComponent } from './privacy.component';
import { UsercentricsComponent } from './usercentrics/usercentrics.component';
import { ZenloopComponent } from './zenloop/zenloop.component';
import { HotjarComponent } from 'app/static-pages/privacy/hotjar/hotjar.component';
import { TranslateModule } from '@ngx-translate/core';
import { ContentCardOpenComponent } from 'shared/ui/basic/content-card-open/content-card-open.component';

@NgModule({
  imports: [CommonModule, UiModule, TranslateModule, ContentCardOpenComponent],
  declarations: [
    PrivacyComponent,
    UsercentricsComponent,
    GoogleTagManagerComponent,
    ZenloopComponent,
    PiwikComponent,
    HotjarComponent,
  ],
  exports: [PrivacyComponent],
})
export class PrivacyModule {}
