import { Injectable } from '@angular/core';
import { EnvironmentSettingsHttpService } from './environment-settings-http.service';
import { Observable, tap } from 'rxjs';
import { EnvironmentSettings } from 'core/environment-settings/interfaces';
import { Store } from '@ngrx/store';
import { RootState } from 'app/+state/app.reducer';
import { updateEnvironmentSettings } from 'core/+state/core.actions';

@Injectable({
  providedIn: 'root',
})
export class EnvironmentSettingsService {
  constructor(
    private environmentSettingsHttpService: EnvironmentSettingsHttpService,
    private readonly ngrxStore: Store<RootState>
  ) {}

  getEnvironmentSettingsAndUpdateStore(envId: number): Observable<EnvironmentSettings> {
    return this.environmentSettingsHttpService.getEnvironmentSettings(envId).pipe(
      tap((environmentSettings) =>
        this.ngrxStore.dispatch(
          updateEnvironmentSettings({
            environmentSettings,
          })
        )
      )
    );
  }

  updateEnvironmentSettingsAndUpdateStore(
    envId: number,
    settings: EnvironmentSettings
  ): Observable<EnvironmentSettings> {
    return this.environmentSettingsHttpService.updateEnvironmentSettings(envId, settings).pipe(
      tap((environmentSettings) =>
        this.ngrxStore.dispatch(
          updateEnvironmentSettings({
            environmentSettings,
          })
        )
      )
    );
  }
}
