export const HeatingChilds = [
  'heatingAndHotWater',
  'districtHeating',
  'oil',
  'gas',
  'bioMass',
  'heatingElectricity',
  'otherFuels',
];

export const HeatingTempExcludeChilds = ['hotWaterEnergy', 'spaceHeatingEnergy'];

export const HeatingDisplayAllExcludeChilds = [
  'districtHeating',
  'oil',
  'gas',
  'bioMass',
  'heatingElectricity',
  'otherFuels',
];
