import { Group } from 'core/groups/groups.interface';

export class GroupMapper {
  static flatAllGroups(groups: Group[]): Group[] {
    if (!groups.length) {
      return [];
    }
    return groups.map((group) => [group, GroupMapper.flatAllGroups(group.groups)].flat()).flat();
  }

  static getGroupsIds(groups: Group[]): number[] {
    return GroupMapper.flatAllGroups(groups).map((g) => g.id);
  }
}
