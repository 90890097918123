import { ColorHelper } from 'shared/helper/color-helper';

/* eslint-disable @typescript-eslint/restrict-plus-operands */
/* eslint-disable prefer-arrow/prefer-arrow-functions */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable object-shorthand */
/* eslint-disable @typescript-eslint/no-unsafe-return */
export const BarChartOptions = {
  plugins: {
    legend: {
      display: false,
      labels: {
        boxHeight: 16,
        boxWidth: 16,
        color: ColorHelper.get('label'),
        padding: 24,
        font: {
          size: 14,
          family: 'TheSansE5-Plain',
        },
      },
    },
    labels: {
      display: false,
    },
    tooltip: {
      callbacks: {
        // label: function (context: any) {
        //   let label = context.dataset.label || '';
        //   if (label) {
        //     label += ': ';
        //   }
        //   if (context.parsed.y !== null) {
        //     label += new Intl.NumberFormat('en-US', {
        //       style: 'currency',
        //       currency: 'USD',
        //     }).format(context.parsed.y as number);
        //   }
        //   return label;
        // },
        // labelColor: function () {
        //   return {
        //     borderColor: '#181c42',
        //     backgroundColor: '#181c42',
        //     borderWidth: 1,
        //     borderRadius: 6,
        //     color: '#fff',
        //     boxShadow: '0px 2px 10px rgba(24, 28, 66, 0.4)',
        //   };
        // },
        // labelTextColor: function () {
        //   return '#fff';
        // },
      },
    },
  },
  responsive: true,
  aspectRatio: 1,
  maintainAspectRatio: false,
  layout: {},
  categoryPercentage: 1,
  barPercentage: 0.5,
  dataset: {
    categoryPercentage: 1,
    barPercentage: 0.5,
  },
  scales: {
    x: {
      grid: {
        color: ColorHelper.get('grid'),
        drawTicks: false,
      },
      ticks: {
        color: ColorHelper.get('label'),
        padding: 16,
        font: {
          family: 'TheSansE5-SemiBold',
          size: 14,
        },
      },
      // offset = 0 Abstände zwischen den scales, aber dafür kein Abstand am Rand
      offset: 20,
    },
    y: {
      grid: { color: ColorHelper.get('grid'), drawTicks: false },
      ticks: {
        color: ColorHelper.get('label'),
        padding: 16,
        font: {
          family: 'TheSansE5-SemiBold',
          size: 14,
        },
      },
    },
  },
};
