import { booleanAttribute, ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { CustomIcon } from '../../interfaces/custom-icon.interface';
import { MatIcon } from '@angular/material/icon';
import { NgClass } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'ista-daytona-icon-label',
  templateUrl: 'icon-label.component.html',
  standalone: true,
  imports: [MatIcon, NgClass, TranslateModule],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class IconLabelComponent {
  @Input({ required: true }) label!: string;
  @Input() icon?: CustomIcon;
  @Input({ transform: booleanAttribute }) alignInCenter = false;
}
