import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { RootState } from 'app/+state/app.reducer';
import { updateSelectedLanguage } from 'core/+state/core.actions';

import { Languages } from './language.interface';

@Injectable({
  providedIn: 'root',
})
export class LanguageService {
  constructor(
    private readonly ngrxStore: Store<RootState>,
    private translateService: TranslateService
  ) {}

  saveLanguage(lang: Languages): void {
    this.translateService.use(lang.toLowerCase());
    this.ngrxStore.dispatch(
      updateSelectedLanguage({
        selectedLanguage: lang,
      })
    );
  }

  isLangGerman(lang: string): boolean {
    return lang === Languages.DE || lang.startsWith(`${Languages.DE.toLowerCase()}-`);
  }
}
