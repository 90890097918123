/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import { Injectable } from '@angular/core';
import { heatingValueLabel } from 'shared/constants';
import { ChartHelper } from 'shared/helper/chart-helper';
import { HelperService } from 'shared/helper/helper.service';
import { MedianCustomIcons } from 'shared/helper/icon-helper';
import { LanguageKeyHelper } from 'shared/helper/language-key-helper';
import { HeatingDisplayAllExcludeChilds } from 'shared/models/heating.type';
import { ChartItem } from 'shared/ui/chart/chart.interface';
import { CardTabItem, CardTabSubItem } from 'shared/ui/card-tab/interfaces';

@Injectable()
export class PortfolioAnalysisService {
  getConsumptionCardTabsItems(
    data: ChartItem[],
    excludeButtons: string[] = [],
    preSelectedTabValue = ''
  ): CardTabItem[] {
    if (!data.length) {
      return [];
    }
    const heating = this.getHeatingTabItem(data, preSelectedTabValue);
    return [
      heating,
      ...data
        .filter(
          (item) =>
            !excludeButtons.includes(item.value || item.label) &&
            item.value !== heatingValueLabel &&
            !HeatingDisplayAllExcludeChilds.includes(item.value || item.label) &&
            !ChartHelper.areAllDataValuesUndefined(item.data)
        )
        .map((item) => ({
          label: LanguageKeyHelper.get(item.label) || item.label,
          icon: MedianCustomIcons[item.value ?? item.label] ?? undefined,
          value: item.value ?? '',
          active: item.value === preSelectedTabValue,
          subTabs: [],
        })),
    ];
  }

  private getHeatingTabItem(data: ChartItem[], preSelectedTabValue = ''): CardTabItem {
    const subTabs: CardTabSubItem[] = data
      .filter((item) => this.isHeatingChild(item))
      .filter(
        (value, index, array) =>
          array.length > 2 || (array.length <= 2 && value.value !== heatingValueLabel)
      )
      .map((item, index) => ({
        label: (item.value === heatingValueLabel
          ? LanguageKeyHelper.get(HelperService.allLabel)
          : item.label) as string,
        value: item.value ?? item.label,
        active:
          item.value === preSelectedTabValue ||
          (heatingValueLabel === preSelectedTabValue && index === 0),
      }));
    return {
      label: LanguageKeyHelper.get(heatingValueLabel) || heatingValueLabel,
      value: heatingValueLabel,
      subTabs,
      icon: MedianCustomIcons[heatingValueLabel] ?? undefined,
      active: heatingValueLabel === preSelectedTabValue || !preSelectedTabValue,
    };
  }

  private isHeatingChild(item: ChartItem): boolean {
    return (
      (HeatingDisplayAllExcludeChilds.includes(item.value || item.label) ||
        item.value === heatingValueLabel) &&
      !ChartHelper.areAllDataValuesUndefined(item.data)
    );
  }
}
