import { Directive, HostBinding, HostListener, Input } from '@angular/core';
import { DeepLinkingCustomParamsKeys, DeepLinkingIds } from './deep-linking.interface';
import { LinkWithGlobalQueryParamsDirective } from 'shared/directives/link-with-global-query-params.directive';

@Directive({
  selector: '[deepLinkingSender]',
  standalone: true,
  hostDirectives: [LinkWithGlobalQueryParamsDirective],
})
export class DeepLinkingSenderDirective {
  @Input({ required: true }) redirectUrl!: string;
  @Input() targetId?: DeepLinkingIds;
  @Input() customParamsValues: string[] = [];

  constructor(private linkWithGlobalQueryParamsDirective: LinkWithGlobalQueryParamsDirective) {}

  @HostBinding('class.cursor-pointer')
  get hasTargetId(): boolean {
    return !!this.targetId;
  }

  @HostListener('mousedown', ['$event'])
  navigateTo(event: MouseEvent): void {
    if (!this.redirectUrl || !this.targetId) {
      return;
    }

    this.linkWithGlobalQueryParamsDirective.link = this.redirectUrl;
    this.linkWithGlobalQueryParamsDirective.customParams = this.getCustomParamsObject();
    this.linkWithGlobalQueryParamsDirective.overrideTarget = '_blank';
    this.linkWithGlobalQueryParamsDirective.openInNewTab(event);
  }

  private getCustomParamsObject(): Record<string, string> {
    const customParamsKeys = DeepLinkingCustomParamsKeys[this.targetId as DeepLinkingIds];
    const defaultParam = {
      target: this.targetId as DeepLinkingIds,
    };

    if (customParamsKeys.length) {
      return customParamsKeys
        .map((key, index) =>
          this.customParamsValues[index] ? { [key]: this.customParamsValues[index] } : undefined
        )
        .filter(Boolean)
        .reduce(
          (previousValue, currentValue) => ({
            ...previousValue,
            ...currentValue,
          }),
          defaultParam
        ) as Record<string, string>;
    }

    return defaultParam;
  }
}
