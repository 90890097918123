<div class="h-full bg-white border-x border-x-slate-200 pt-6 pb-3">
  <div class="flex items-end mb-5 pl-4">
    <a class="text-2xl font-extrabold text-slate-900 hover:text-primary-500" routerLink="portfolio">Portfolio</a>
    <mat-icon
      [matTooltip]="'group-tree-navigation.portfolio-tooltip' | translate"
      class="icon-size-6 ml-1 mb-1 text-slate-500 portfolio-info-icon"
      matTooltipPosition="right"
    >info
    </mat-icon>
  </div>
  <div>
    <div class="flex mb-3 pl-4 pr-2">
      <ista-daytona-badge-button #tagButton (buttonClick)="showTagDialog()"
                                 [badgeContent]="selectBadgeCount$ | async | nullToUndefined"
                                 [badgeHidden]="selectBadgeHidden$ | async"
                                 [icon]="badgeIcon"
                                 badgeColor="warn"></ista-daytona-badge-button>

      <div class="flex justify-end grow">
        @if (selectShowSearchInput$ | async) {
          <ista-daytona-input-search
            #searchInputField
            (deleteButtonClicked)="searchDeleteButtonClicked()"
            (searchInput)="search($event)"
            [debounce]="200"
          ></ista-daytona-input-search>
        } @else {
          <ista-daytona-button
            (buttonClicked)="showSearchInputField()"
            icon="search"
            type="icon"
          >
          </ista-daytona-button>
        }
      </div>
    </div>
  </div>
  <div class="text-end pr-2">
    <button (click)="toggleEditingMode()" data-cy="portfolio-finish-editing-button" mat-button>
      @if (selectIsEditingModeEnabled$ | async) {
        <span class="edit-mode-enabled">
        {{ 'common.button-group-end-text' | translate }}
        </span>
      } @else {
        <span class="read-mode-enabled">
          {{ 'common.button-group-text' | translate }}
        </span>
      }


    </button>
  </div>
  <ista-daytona-groups-tree-navigation
    [enableEditingMode]="selectIsEditingModeEnabled$ | async"
    [highlightEmptyGroups]="selectOpenAllGroups$ | async"
    [openAllGroups]="selectOpenAllGroups$ | async"
  ></ista-daytona-groups-tree-navigation>
</div>
