import { Component, Input } from '@angular/core';

import { ColorHelper } from 'shared/helper/color-helper';
import { IconHelper } from 'shared/helper/icon-helper';
import { LanguageKeyHelper } from 'shared/helper/language-key-helper';
import { ColorStyle } from '../../../models/color-style.enum';
import { ConsumptionTypesExtended } from './../../basic/label/label-mapping';

@Component({
  selector: 'ista-daytona-doughnut-chart-percent',
  templateUrl: './doughnut-chart-percent.component.html',
  styleUrls: ['./doughnut-chart-percent.component.scss'],
})
export class DoughnutChartPercentComponent {
  @Input() type: ConsumptionTypesExtended = 'all';
  @Input() value = 0;
  @Input() colorStyle: ColorStyle = ColorStyle.BLUE;
  public colorStyleEnum = ColorStyle;

  public get color(): string {
    const color = ColorHelper.get(this.type + '-full');
    return (color || ColorHelper.get('default')) ?? '';
  }

  public get imageSrc(): string {
    return IconHelper.get(this.type) || '';
  }

  public get label(): string {
    return LanguageKeyHelper.get(this.type) || '';
  }

  public get style(): any {
    return { '--p': this.value, '--c': this.color };
  }

  public get innerStyle(): any {
    return {
      '--c':
        this.colorStyle === ColorStyle.WHITE
          ? ColorHelper.get('doughnut-chart-percent-blue-light')
          : ColorHelper.get('doughnut-chart-percent-blue-dark'),
    };
  }
}
