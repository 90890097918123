import { CoreState } from '../interface';
import { Languages } from 'core/language/language.interface';
import { DEFAULT_UNASSIGNED_SITES_GROUP } from 'core/constants';

export const initialCoreState: CoreState = {
  environmentReady: false,
  availableEnvironments: [],
  selectedEnvironment: undefined,
  yearsWithData: [],
  reportingPeriods: [],
  groups: undefined,
  selectedGroup: undefined,
  allowLoadSites: true,
  groupSearchRunning: false,
  showNavigationMenu: true,
  subNavigationSelectedHeadline: '',
  globalSelectedYear: undefined,
  selectedLanguage: Languages.DE,
  environmentSettings: undefined,
  navigationSubmenuObjectIsSelected: false,
  hasNoEnvironments: false,
  selectedSite: undefined,
  selectedSiteOrGroup: undefined,
  unassignedSitesGroup: {
    ...DEFAULT_UNASSIGNED_SITES_GROUP,
  },
  forcePortfolioGroupToReload: false,
  userSettings: undefined,
  tags: [],

  treeNavigation: {
    groups: [],
    unassignedSitesGroup: {
      ...DEFAULT_UNASSIGNED_SITES_GROUP,
    },
  },
  searchTreeNavigation: undefined,
};
