import { AfterViewInit, Component, Input, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';

import { TableBaseComponent } from './table-base.class';
import {
  TabelDataGenericType,
  TableDataGeneric,
  TableDataGenericItem,
  TableHeader,
} from './table.interface';
import { DeepLinkingIds } from 'core/deep-linking/deep-linking.interface';

/* eslint-disable @typescript-eslint/member-ordering */
@Component({
  selector: 'ista-daytona-table',
  templateUrl: './table.component.html',
  styleUrls: ['./table.component.scss'],
})
export class TableComponent extends TableBaseComponent implements AfterViewInit {
  @Input() header!: TableHeader[];
  @ViewChild(MatPaginator) paginator!: MatPaginator;
  public dataSource!: MatTableDataSource<TableDataGeneric> | undefined;
  protected readonly DeepLinkingIds = DeepLinkingIds;

  constructor() {
    super();
  }

  private _data: TableDataGeneric[] = [];

  get data(): TableDataGeneric[] {
    return this._data;
  }

  @Input() set data(tableData: TableDataGeneric[]) {
    this._data = tableData;
    this.configTableElements();
  }

  private _filter = '';

  get filter(): string {
    return this._filter;
  }

  @Input() set filter(value: string) {
    this._filter = value;
    if (this.dataSource) {
      this.dataSource.filter = value.trim().toLowerCase();
      this.dataSource.paginator?.firstPage();
    }
  }

  public get headerLabels(): string[] {
    return this.header?.filter((item) => item.id).map((item) => item.id || '') || [];
  }

  ngAfterViewInit(): void {
    if (this.dataSource) {
      this.dataSource.paginator = this.paginator;
    }
  }

  public getRow(index: string, data: TableDataGeneric): TableDataGenericItem {
    return data[index];
  }

  public configTableElements(): void {
    try {
      this.dataSource = new MatTableDataSource<TableDataGeneric>();
      if (this.data?.length > 0) {
        this.dataSource = new MatTableDataSource<TableDataGeneric>(this.data);
        this.dataSource.paginator = this.paginator;
        this.dataSource.filterPredicate = (data: TableDataGeneric, filter: string): boolean => {
          let found = false;
          Object.keys(data).forEach((key) => {
            const value: TabelDataGenericType = data[key].value;
            if (value !== null && !found) {
              if (typeof value === 'string') {
                found = value.toLowerCase().includes(filter);
              } else if (typeof value === 'number') {
                found = value.toString().includes(filter);
              }
            }
          });
          return found;
        };
      }
    } catch (error) {
      console.error('Error in configTableElements', error);
    }
  }

  public getHeaderItem(index: string): TableHeader {
    return this.header.find((item) => item.id === index) || { label: '' };
  }
}
