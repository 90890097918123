<div class="tab-small-filter-container" *ngIf="config">
  <div class="inner-content">
    <div
      *ngFor="let item of config"
      class="filters--item"
      [class.active]="activeMainTab?.label === item.label"
      (click)="setActiveMainMenu(item)"
    >
      <h4>
        <span [innerHTML]="item?.label || '' | translate"></span>
      </h4>
    </div>
  </div>
</div>
