import { CommonModule, DecimalPipe } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatPaginatorIntl, MatPaginatorModule } from '@angular/material/paginator';
import { MatTableModule } from '@angular/material/table';
import { TranslateModule } from '@ngx-translate/core';

import { DirectivesModule } from '../../../directives/directives.module';
import { LoaderModule } from '../loader/loader.module';
import { TooltipModule } from '../tooltip/tooltip.module';
import { ValueUnitModule } from '../value-unit/value-unit.module';
import { MyCustomPaginatorIntl } from './mat-paginator-intl';
import { TableComponent } from './table.component';
import { IstaTableDirective } from '@ista-ui/angular';
import { PipesModule } from 'shared/pipes/pipes.module';
import { MatIcon } from '@angular/material/icon';
import { DeepLinkingSenderDirective } from 'core/deep-linking/deep-linking-sender.directive';

@NgModule({
  imports: [
    CommonModule,
    TooltipModule,
    LoaderModule,
    TranslateModule,
    ValueUnitModule,
    MatTableModule,
    MatPaginatorModule,
    DirectivesModule,
    IstaTableDirective,
    PipesModule,
    MatIcon,
    DeepLinkingSenderDirective,
  ],
  declarations: [TableComponent],
  providers: [DecimalPipe, { provide: MatPaginatorIntl, useClass: MyCustomPaginatorIntl }],
  exports: [TableComponent],
})
export class TableModule {}
