/* eslint-disable @typescript-eslint/member-ordering */
import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';

import { TrackingWrapperService } from 'core/tracking/tracking-wrapper.service';
import { HelperService } from 'shared/helper/helper.service';
import { FilterSubTab, FilterTab, FilterTabItem } from './../filter-tab.interface';

@Component({
  selector: 'ista-daytona-filter-sub-tab',
  templateUrl: './filter-sub-tab.component.html',
  styleUrls: ['./filter-sub-tab.component.scss'],
})
export class FilterSubTabComponent implements OnChanges {
  @Input() selectedFilterPortfolioAnalysisChart: FilterTab[] = [];
  @Output() clickedFilter = new EventEmitter<FilterTab>();
  @Output() selectedSubSubTab = new EventEmitter<FilterTabItem>();

  public activeMainTab: FilterTabItem | undefined;
  public activeSubTab: FilterTabItem | undefined;
  public selectedSubFilter: FilterTabItem = {
    label: 'types.all',
    value: 'heatingAndHotWater',
  };
  possibleSubFilter = [
    'heatingAndHotWater',
    'oil',
    'gas',
    'districtHeating',
    'heatingElectricity',
    'bioMass',
    'otherFuels',
  ];

  constructor(private readonly trackingService: TrackingWrapperService) {}

  private _config!: FilterSubTab;

  get config(): FilterSubTab {
    return this._config;
  }

  @Input() set config(value: FilterSubTab) {
    if (!!value && value !== this._config) {
      this._config = [...value];
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['config'] && changes['config'].currentValue !== this._config) {
      this._config = [...(changes['config'].currentValue as FilterSubTab)];
      this.updateSelectedSubFilter();
    }
  }

  updateSelectedSubFilter(): void {
    if (this.selectedFilterPortfolioAnalysisChart.length) {
      const selectedValue = this.selectedFilterPortfolioAnalysisChart[0]?.left[0]?.value;
      if (selectedValue && this.possibleSubFilter.includes(selectedValue)) {
        this.selectedSubFilter = {
          value: this.selectedFilterPortfolioAnalysisChart[0].left[0].value,
          label: this.selectedFilterPortfolioAnalysisChart[0].left[0].label,
        };
      }
    }
    this.activeSubTab = this.selectedSubFilter;
    this.setActiveTabs(this._config, true);
    this.switchTabIfActiveTabNotRelevantAnymore();
  }

  public setActiveMainMenu(item: FilterTabItem): void {
    this.activeMainTab = item;

    this.clickedFilter.emit({
      left: [item],
      selectedYear: HelperService.getSelectedYearDummy(),
    });
    this.trackingService.addGTM(
      {
        eventCategory: 'elementClick',
        eventLabel: item.value || item.label,
        eventAction: 'tabNavigationClick',
      },
      [item.value || item.label]
    );

    if (item.childs && item.childs.length > 0) {
      this.setActiveSubMenu(item.childs[0]);
    }
  }

  public setActiveSubMenu(item: FilterTabItem): void {
    this.activeSubTab = item;

    this.clickedFilter.emit({
      left: [item],
      selectedYear: HelperService.getSelectedYearDummy(),
    });
    this.trackingService.addGTM(
      {
        eventCategory: 'elementClick',
        eventLabel: item.value || item.label,
        eventAction: 'tabNavigationClick',
      },
      [item.value || item.label]
    );
  }

  private setActiveTabs(tabs: FilterTabItem[] | undefined, isMainTab: boolean): void {
    tabs?.forEach((item) => {
      if (!!item.active) {
        if (!!isMainTab) {
          this.activeMainTab = item;
        } else {
          this.activeSubTab = item;
        }
      }
      // refresh active main tab, it may has been updated, i.e., it has new children
      if (!!isMainTab && this.activeMainTab?.value === item.value) {
        this.activeMainTab = item;
      }
    });

    if (tabs) {
      if (isMainTab && !this.activeMainTab) {
        this.activeMainTab = tabs[0];
      } else if (!isMainTab && !this.activeSubTab) {
        this.activeSubTab = this.activeMainTab?.childs ? this.activeMainTab?.childs[0] : undefined;
      }
    }
  }

  /**
   * If the main menu or sub menu items change and the currently selected/active item is not
   * existing anymore, the currently selected/active item should change. This can happen for example
   * when the group is changed while being on the portfolio screen. See
   * https://ista.atlassian.net/browse/SQRE05-1085
   *
   * @private
   */
  private switchTabIfActiveTabNotRelevantAnymore(): void {
    if (this._config) {
      // first, check main tab
      let mainTabStillRelevant = false;
      this._config.forEach((item) => {
        if (item.value === this.activeMainTab?.value) {
          mainTabStillRelevant = true;
        }
      });
      // if water (chart filter) and emissions (header) are selected data has to be reloaded to default
      if (
        this._config.length === 3 &&
        this.selectedFilterPortfolioAnalysisChart[0].left[0].value === 'water'
      ) {
        mainTabStillRelevant = false;
      }
      if (!mainTabStillRelevant) {
        this.setActiveMainMenu(this._config[0]);
      }

      if (this.activeMainTab?.childs && this.activeMainTab.childs.length > 0) {
        // now check if selected sub tab is still relevant
        let subTabStillRelevant = false;
        this.activeMainTab.childs.forEach((item) => {
          if (item.value === this.activeSubTab?.value) {
            subTabStillRelevant = true;
          }
        });
        if (!subTabStillRelevant) {
          this.setActiveSubMenu(this.activeMainTab.childs[0]);
        }
      }
    }
  }
}
