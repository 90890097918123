import { ConsumptionTypes } from 'shared/models/consumption-types.interface';
import { Unit } from '../../../models/unit.enum';
import { AnalyticalCategories } from 'shared/interfaces/consumption-type-button.interface';

export type ConsumptionTypesExtended = ConsumptionTypes | 'all';

export type Page = 'dashboard' | 'portfolio' | 'all';

export interface ChartLabel {
  analyticalCategories: AnalyticalCategories;
  consumptionInfo: ConsumptionTypesExtended[];
  unit: Unit;
}

export const LabelMapping: ChartLabel[] = [
  {
    analyticalCategories: 'emissions',
    consumptionInfo: [
      'districtCooling',
      'districtHeating',
      'gas',
      'heatingAndHotWater',
      'electricity',
      'hotWaterEnergy',
      'oil',
      'spaceHeatingEnergy',
      'waste',
      'water',
      'all',
      'heatingElectricity',
      'bioMass',
      'otherFuels',
    ],
    unit: Unit.EMISSION,
  },
  {
    analyticalCategories: 'costs',
    consumptionInfo: [
      'districtCooling',
      'districtHeating',
      'gas',
      'heatingAndHotWater',
      'electricity',
      'hotWaterEnergy',
      'oil',
      'spaceHeatingEnergy',
      'waste',
      'water',
      'all',
      'heatingElectricity',
      'bioMass',
      'otherFuels',
    ],
    unit: Unit.EUR,
  },
  {
    analyticalCategories: 'consumption',
    consumptionInfo: ['water'],
    unit: Unit.CBM,
  },
  {
    analyticalCategories: 'consumption',
    consumptionInfo: ['waste'],
    unit: Unit.KG,
  },
  {
    analyticalCategories: 'consumption',
    consumptionInfo: [
      'districtCooling',
      'districtHeating',
      'gas',
      'heatingAndHotWater',
      'electricity',
      'hotWaterEnergy',
      'oil',
      'spaceHeatingEnergy',
      'heatingElectricity',
      'bioMass',
      'otherFuels',
    ],
    unit: Unit.KWH,
  },
];
