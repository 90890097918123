export interface FilterTabItem {
  label: string;
  icon?: string;
  active?: boolean;
  value?: string;
  childs?: FilterTabItem[];
}

export interface FilterDateRange {
  from: string;
  until: string;
}
export interface FilterTab {
  left: FilterTabItem[];
  selectedYear: FilterDateRange;
}

export type FilterSubTab = FilterTabItem[];

export enum FilterType {
  PRIMARY = 'primary',
  SECONDARY = 'secondary',
  PAGE = 'page',
  SMALL = 'small',
}
