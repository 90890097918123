import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { MatButtonToggle, MatButtonToggleGroup } from '@angular/material/button-toggle';
import { DisplayedPeriodType } from 'core/environment-settings/interfaces';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'ista-daytona-period-toggle',
  standalone: true,
  imports: [MatButtonToggle, MatButtonToggleGroup, TranslateModule],
  templateUrl: './period-toggle.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PeriodToggleComponent {
  @Input() environmentSettingsType!: DisplayedPeriodType;
  @Input() selectedPeriodType!: DisplayedPeriodType;

  @Output() updateSelectedPeriodType = new EventEmitter<DisplayedPeriodType>();

  // TODO: make it more generic and move to shared directory
}
