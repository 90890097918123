import { Component, Input } from '@angular/core';

import { EFileBilling } from '../../efile-response.interface';
import { FileUploadFunctionsService } from '../../file-upload-functions.service';

@Component({
  selector: 'ista-daytona-file-upload-result-billing',
  templateUrl: './file-upload-result-billing.component.html',
  styleUrls: ['./file-upload-result-billing.component.scss'],
})
export class FileUploadResultBillingComponent extends FileUploadFunctionsService {
  @Input() detectedBilling!: EFileBilling;
}
