<div
  class="w-full p-4 bg-white border border-slate-200 rounded-lg shadow-lg max-w-[278px]"
  role="alert"
>
  <div class="flex">
    <div class="mt-5 pr-5">
      <div
        class="text-slate-900 text-base"
        [innerHTML]="
          'group-tree-navigation.delete-confirm-text' | translate : { itemName: data.itemName }
        "
      ></div>
      <div class="flex flex-wrap gap-2 mt-3">
        <ista-daytona-button
          [label]="'group-tree-navigation.delete-confirm-button-text' | translate"
          class="delete-dialog-confirm-button"
          (click)="confirm()"
          [small]="true"
        ></ista-daytona-button>
        <ista-daytona-button
          [label]="'group-tree-navigation.delete-cancel-button-text' | translate"
          class="delete-dialog-cancel-button"
          type="secondary"
          [small]="true"
          (click)="closeDialog()"
        ></ista-daytona-button>
      </div>
    </div>
    <ista-daytona-button
      icon="close"
      type="icon"
      class="absolute top-0 right-1 delete-dialog-close-button"
      (click)="closeDialog()"
    ></ista-daytona-button>
  </div>
</div>
