import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { ValueUnitComponent } from './value-unit.component';

@NgModule({
  imports: [CommonModule],
  declarations: [ValueUnitComponent],
  exports: [ValueUnitComponent],
})
export class ValueUnitModule {}
