<div class="pagination-alphabet-container">
  <div class="pagination-alphabet-container--content">
    @for (item of alphabet; track item) {
      <button
        (click)="clickedItem(item)"
        [disabled]="!isLetterActive(item)"
        [class.text-primary]="isLetterActive(item)"
        class="rounded"
        color="primary"
        mat-stroked-button
      >
        <span>{{ item }}</span>
      </button>
    }
  </div>
</div>
