import { createAction, props } from '@ngrx/store';
import { PreloadingResponse } from 'core/preloading-data/preloading.interface';

import { BenchmarkingInfo } from '../benchmarking.interface';
import { CardTabSubItem } from 'shared/ui/card-tab/interfaces';

export const storeBenchmarkingData = createAction(
  '[Benchmarking] Store Benchmarking Data',
  props<{ benchmarkingData: PreloadingResponse<BenchmarkingInfo> }>()
);

export const clearBenchmarkingData = createAction('[Benchmarking] Clear Benchmarking Data');

export const updateSelectedEmissionMediaChartFilter = createAction(
  '' + '[Benchmarking] update selected Emission Media Chart Filter',
  props<{ selectedEmissionMediaChartFilter: CardTabSubItem }>()
);

export const storeBenchmarkingYearsWithData = createAction(
  '[Benchmarking] Store Benchmarking Years With Data',
  props<{ yearsWithData: number[] }>()
);

export const updateBenchmarkingData = createAction('[Benchmarking] Update Data');

export const loadFailure = createAction('[Benchmarking] Load Failure', props<{ error: Error }>());

export const loadSuccess = createAction('[Benchmarking] Load Success');
