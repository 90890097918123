import { Component, Input } from '@angular/core';
import { MatProgressSpinner, ProgressSpinnerMode } from '@angular/material/progress-spinner';

@Component({
  selector: 'ista-daytona-circle',
  templateUrl: './circle.component.html',
  imports: [
    MatProgressSpinner,
  ],
  standalone: true,
})
export class CircleComponent {
  @Input() label = '';
  @Input() color = 'primary';
  @Input() diameter = '';
  @Input() value = '0';
  @Input() circleMode: ProgressSpinnerMode = 'indeterminate';
}
