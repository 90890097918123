import { Component, Input } from '@angular/core';

@Component({
  selector: 'ista-daytona-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
})
export class FooterComponent {
  @Input() displayWave = false;
}
