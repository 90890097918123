<div
  class="tab-filter-container"
  [attr.data-id]="instanceId"
  [class.tab-filter-container--small-version]="smallVersion"
  [class.border-bottom]="!smallVersion && displayBorderBottom"
>
  <div class="left-filters" *ngIf="config?.left">
    <ng-container *ngIf="activeTab$ | async as activeTab">
      <div
        *ngFor="let item of config?.left"
        class="left-filters--item"
        data-cy="filter-tab--item"
        (click)="setActiveLeft(item)"
        [class.active]="activeTab.left[0] && activeTab.left[0].value === item.value"
        [style.padding-left.px]="config.left.length === 1 ? 0 : 16"
        [class.single]="config.left.length === 1"
      >
        <h2>
          <img [src]="item?.icon" *ngIf="item?.icon" alt="filter icon" [title]="item?.label" /><span
            [innerHTML]="item?.label || '' | translate"
          ></span>
        </h2>
      </div>
    </ng-container>
  </div>
  <ng-content></ng-content>
  <ista-daytona-filter-time-series
    *ngIf="displayTimeSeries"
    [selectedYear]="config.selectedYear"
    [mode]="timeMode"
    (clickedFilter)="setActiveRight($event)"
  ></ista-daytona-filter-time-series>
</div>
