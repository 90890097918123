import { Component } from '@angular/core';
import { Observable } from 'rxjs';

import { Environment } from './environment.interface';
import { Store } from '@ngrx/store';
import { RootState } from 'app/+state/app.reducer';
import { selectAvailableEnvironment, selectSelectedEnvironment } from '../+state/core.selectors';
import { storeSelectedEnvironment } from '../+state/core.actions';

@Component({
  selector: 'ista-daytona-environment-container',
  templateUrl: './environment-container.component.html',
})
export class EnvironmentContainerComponent {
  envs$: Observable<Environment[]> = this.ngrxStore.select(selectAvailableEnvironment);
  selectedEnv$: Observable<Environment | undefined> =
    this.ngrxStore.select(selectSelectedEnvironment);

  constructor(private readonly ngrxStore: Store<RootState>) {}

  onSelectChange(env: Environment): void {
    this.ngrxStore.dispatch(storeSelectedEnvironment({ selectedEnvironment: env }));
  }
}
