import { inject } from '@angular/core';
import { Store } from '@ngrx/store';
import { RootState } from 'app/+state/app.reducer';
import { updateSubNavigationSelectedHeadline } from 'core/+state/core.actions';

export const ResetHeadlineGuard = (): boolean => {
  const ngrxStore = inject(Store<RootState>);
  ngrxStore.dispatch(updateSubNavigationSelectedHeadline({
    subNavigationSelectedHeadline: '',
  }));

  return true;
};
