import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Injectable()
export class ChartHelperService {
  constructor(private translateService: TranslateService) {}

  getDataSetSkeleton(
    data: (number | null)[],
    label: string,
    color: string,
    categoryPercentage = 1.0,
    barPercentage = 0.8
  ): any {
    return {
      data,
      label: this.translateService.instant(label) as string,
      backgroundColor: color,
      borderColor: color,
      pointBackgroundColor: color,
      pointBorderColor: color,
      hoverBackgroundColor: color,
      fill: false,
      pointStyle: 'line',
      axis: 'y',
      maxBarThickness: 24,
      categoryPercentage,
      barPercentage,
    };
  }
}
