import { ComponentStore } from '@ngrx/component-store';
import { FilterDateRange, FilterTab, FilterTabItem } from '../filter-tab.interface';
import { Injectable } from '@angular/core';
import { Utils } from 'shared/helper/utils';
import { Observable } from 'rxjs';

interface FilterTabState {
  context: Record<string, FilterTab>;
}

@Injectable({
  providedIn: 'root',
})
export class FilterTabStoreService extends ComponentStore<FilterTabState> {
  constructor() {
    super({
      context: {},
    });
  }
  readonly selectActiveFilterTab: (id: string) => Observable<FilterTab> = (id: string) =>
    this.select((state) => {
      if (state.context.hasOwnProperty(id)) {
        return state.context[id];
      }

      return {
        left: [],
        selectedYear: {
          from: '',
          until: '',
        },
      } as FilterTab;
    });

  registerFilterTabInstanceId(id?: string): string {
    if (id && this.get().context.hasOwnProperty(id)) {
      return id;
    }
    const instanceId = id || Utils.generateRandomId();
    this.setInstanceInitialState(instanceId);
    return instanceId;
  }

  updateActiveFilterTab(id: string, filterTab: FilterTab): void {
    this.setState((state) => ({
      ...state,
      context: {
        ...state.context,
        [id]: filterTab,
      },
    }));
  }

  updateActiveFilterTabLeft(id: string, left: FilterTabItem[]): void {
    this.setState((state) => ({
      ...state,
      context: {
        ...state.context,
        [id]: {
          ...state.context[id],
          left,
        },
      },
    }));
  }

  updateActiveFilterTabSelectedYear(id: string, selectedYear: FilterDateRange): void {
    this.setState((state) => ({
      ...state,
      context: {
        ...state.context,
        [id]: {
          ...state.context[id],
          selectedYear,
        },
      },
    }));
  }

  private setInstanceInitialState(id: string): void {
    this.setState((state) => ({
      ...state,
      context: {
        ...state.context,
        [id]: {
          left: [],
          selectedYear: { from: '', until: '' },
        },
      },
    }));
  }
}
