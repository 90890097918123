import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import {
  BehaviorSubject,
  catchError,
  combineLatest,
  filter,
  first,
  firstValueFrom,
  map,
  mergeMap,
  Observable,
  of,
  switchMap,
  takeUntil,
} from 'rxjs';

import { HelperService } from 'shared/helper/helper.service';
import { LoggerService } from 'shared/helper/logger.service';
import { FileUploadResponse } from 'shared/ui/file-upload/fileupload-response.interface';
import { LoadingState } from '../load-status/load-status.interface';
import { DestroyAbstractService } from 'shared/helper/destroy-abstract.service';
import { ErrorHandlerService } from '../error-handling/error-handler.service';
import { LoadStatusService } from '../load-status/load-status.service';
import { FetchRequestType } from './fetch-request-type.enum';
import { Store } from '@ngrx/store';
import { RootState } from 'app/+state/app.reducer';
import {
  selectSelectedEnvironmentId,
  selectSelectedGroup,
  selectSelectedLanguage,
  selectUserToken,
} from '../+state/core.selectors';
import { selectSelectedSiteTableYear } from 'app/features/portfolio/+state/portfolio.selectors';
import { environment } from 'environments/environment';
import { ExcelExportRequestPayload } from './http-request-payload.interface';

@Injectable({
  providedIn: 'root',
})
export class HttpRequestService extends DestroyAbstractService {
  /* eslint-disable @typescript-eslint/member-ordering */
  private _selectedEnvId!: number;
  private _isReady$ = new BehaviorSubject<boolean>(false);
  isReadySource$ = this._isReady$.asObservable();

  constructor(
    private readonly _httpClient: HttpClient,
    private readonly _errorHandler: ErrorHandlerService,
    private readonly _loadStatusService: LoadStatusService,
    private readonly ngrxStore: Store<RootState>,
    private readonly _translateService: TranslateService
  ) {
    super();
    combineLatest([
      this.ngrxStore.select(selectSelectedEnvironmentId),
      this.ngrxStore.select(selectUserToken),
    ])
      .pipe(
        filter(([selectedEnvId, token]) => token !== null && !!selectedEnvId),
        takeUntil(this._onDestroy$)
      )
      .subscribe(([selectedEnvId]) => {
        this._selectedEnvId = selectedEnvId as number;
        this._isReady$.next(true);
      });
  }

  public async triggerExcelReport(): Promise<void> {
    const selectedYear = await firstValueFrom(this.ngrxStore.select(selectSelectedSiteTableYear));
    const selectedGroup = await firstValueFrom(this.ngrxStore.select(selectSelectedGroup));

    await this.postAndDownloadBlob<ExcelExportRequestPayload>(
      {
        requestedYears: [selectedYear?.from],
        requestedGroups: selectedGroup?.id ? [selectedGroup?.id] : [],
      },
      `${environment.apiUrl}/api/###/export/yearly-excel-report`
    );
  }

  public async postAndDownloadBlob<T>(data: T, endPoint: string, statusId?: string): Promise<void> {
    this._loadStatusService.setInProgressState(statusId || (data as string));
    try {
      const blob = await firstValueFrom(
        this._httpClient.post<Blob>(endPoint.replace('###', this._selectedEnvId.toString()), data, {
          responseType: 'blob' as 'json',
        })
      );
      this._loadStatusService.setProgressStatus({
        id: statusId || (data as string),
        progress: 0,
        state: LoadingState.DONE,
      });

      HelperService.createExcelWindowBlob(blob, 'export');
    } catch (error) {
      this._loadStatusService.setProgressStatus({
        id: statusId || (data as string),
        progress: 0,
        state: LoadingState.ABORT,
      });
      this._errorHandler.handleError(error as HttpErrorResponse);
    }
  }

  public uploadFormData<T>(
    formData: FormData,
    endPoint: string
  ): Observable<FileUploadResponse<T>> {
    return this._isReady$.pipe(
      filter((status) => status),
      first(),
      switchMap(() =>
        this._httpClient
          .post<T>(endPoint.replace('###', this._selectedEnvId.toString()), formData)
          .pipe(
            catchError((error) => {
              LoggerService.error('upload error', error);
              return of(undefined);
            }),
            map(
              (data) =>
                ({
                  error: true,
                  response: data,
                } as FileUploadResponse<T>)
            )
          )
      )
    );
  }

  public triggerImportableExcelDownload(
    endPoint: string,
    fileName: string,
    fetchRequestType: FetchRequestType
  ): Observable<LoadingState> {
    const result$ = new BehaviorSubject<LoadingState>(LoadingState.PROGRESS);
    this._isReady$
      .pipe(
        filter((status) => !!status),
        first(),
        mergeMap(() => this.ngrxStore.select(selectSelectedLanguage).pipe(first()))
      )
      .subscribe((lang) => {
        if (fetchRequestType === FetchRequestType.POST) {
          this.triggerExcelFilePerPost(endPoint, fileName, lang)
            .pipe(
              filter((status) => status === LoadingState.DONE),
              first()
            )
            .subscribe((status) => result$.next(status));
        } else {
          this.triggerExcelFilePerGet(endPoint, fileName, lang)
            .pipe(
              filter((status) => status === LoadingState.DONE),
              first()
            )
            .subscribe((status) => result$.next(status));
        }
      });

    return result$;
  }

  private triggerExcelFilePerPost(
    endPoint: string,
    fileName: string,
    language = 'DE'
  ): Observable<LoadingState> {
    const result$ = new BehaviorSubject<LoadingState>(LoadingState.PROGRESS);
    this.ngrxStore
      .select(selectSelectedGroup)
      .pipe(
        mergeMap((selectedGroup) =>
          this._httpClient.post<Blob>(
            endPoint.replace('###', this._selectedEnvId.toString()),
            {
              requestedYears: [],
              requestedGroups: selectedGroup ? [selectedGroup.id] : [],
              language,
            },

            {
              responseType: 'blob' as 'json',
            }
          )
        ),
        catchError((error) => {
          LoggerService.error('upload error', error);
          return of();
        })
      )
      .subscribe((dataBlob) => {
        HelperService.createExcelWindowBlob(dataBlob, fileName);
        result$.next(LoadingState.DONE);
      });

    return result$.asObservable();
  }

  private triggerExcelFilePerGet(
    endPoint: string,
    fileName: string,
    language = 'DE'
  ): Observable<LoadingState> {
    const result$ = new BehaviorSubject<LoadingState>(LoadingState.PROGRESS);
    this._httpClient
      .get<Blob>(
        `${endPoint.replace('###', this._selectedEnvId.toString())}?language=${language}`,
        {
          responseType: 'blob' as 'json',
        }
      )
      .pipe(
        catchError((error) => {
          LoggerService.error('upload error', error);
          return of();
        })
      )
      .subscribe((dataBlob) => {
        HelperService.createExcelWindowBlob(dataBlob, fileName);
        result$.next(LoadingState.DONE);
      });

    return result$.asObservable();
  }
}
