export enum Unit {
  DEFAULT = '',
  /** KW/h - Kilowatt per hour */
  KWH = 'KWH',
  /** Cubic meter */
  CBM = 'CBM',
  LITER = 'LITER',
  EUR = 'EUR',
  TON = 'TON',
  /** Kilogram */
  KG = 'KG',
  /** Square meter */
  SQM = 'SQM',
  KWH_PER_SQM = 'KWH_PER_SQM',
  CBM_PER_SQM = 'CBM_PER_SQM',
  LITER_PER_SQM = 'LITER_PER_SQM',
  EUR_PER_SQM = 'EUR_PER_SQM',
  TON_PER_SQM = 'TON_PER_SQM',
  KG_PER_SQM = 'KG_PER_SQM',
  PERCENT = 'PERCENT',
  EMISSION = 'EMISSION',
}

export enum UnitWithPrefix {
  EURO_K = 'Tsd. €',
  EURO_M = 'M€',
  EURO_G = 'G€',
  EURO_T = 'T€',
  EUR = '€',
  EMISSION = 'CO₂e',
  EMISSION_K = 't CO₂e',
  EMISSION_M = 'kt CO₂e',
  SQM = 'm²',
  SQM_K = 'Tsd. m²',
  SQM_M = 'Mio. m²',
  CBM_K = 'Tsd. m³',
  CBM = 'm³',
  KWH = 'kWh',
  KWH_K = 'MWh',
  KWH_M = 'GWh',
  KG = 'kg',
  KG_K = 't',
  KG_M = 'kt',
  LITER = 'l',
  TON = 't',
  KWH_PER_SQM = 'kWh/m²',
  KWH_K_PER_SQM = 'MWh/m²',
  KWH_M_PER_SQM = 'GWh/m²',
  CBM_PER_SQM = 'm³/m²',
  CBM_K_PER_SQM = 'km³/m²',
  LITER_PER_SQM = 'l/m²',
  EUR_K_PER_SQM = 'Tsd. €/m²',
  EUR_M_PER_SQM = 'M€/m²',
  EUR_G_PER_SQM = 'G€/m²',
  EUR_T_PER_SQM = 'T€/m²',
  EUR_PER_SQM = '€/m²',
  TON_PER_SQM = 't/m²',
  KG_PER_SQM = 'kg/m²',
  EMISSION_KG_PER_SQM = 'kg CO₂e/m²',
  KG_K_PER_SQM = 't/m²',
  KG_M_PER_SQM = 'kt/m²',
  PERCENT = '%',
  UNKNOWN = '?',
  DEFAULT = '',
}

export enum UnitPrefix {
  T = 'T', // tera
  G = 'G', // giga
  M = 'M', // mega
  k = 'k', // kilo
  START = '',
}

export interface ExtendedUnit {
  prefix: UnitPrefix;
  unit: Unit;
}

export interface UnitPrefixConfig {
  factor: number;
  unitPrefix: UnitPrefix;
  nextUnitPrefix: UnitPrefix | null;
}

export enum UnitFactor {
  ONE = 1,
  K = 1000,
  M = 1000000,
  G = 1000000000,
  T = 1e12,
}

export enum MathRound {
  ORIGINAL = 'original',
  ROUND_UP = 'ceil',
  ROUND_DOWN = 'floor',
}

export interface UnitMapping {
  factor: UnitFactor;
  unitWithPrefix: UnitWithPrefix;
}

export interface UnitMappingConfig {
  unit: Unit;
  maxDisplayedDigits: number;
  mapping: UnitMapping[];
  unitWithPrefixSequence: UnitWithPrefix[];
  rootUnitWithPrefix: UnitWithPrefix;
}

export interface TransformedValueItem {
  value: number | null;
  unitWithPrefix: UnitWithPrefix;
}
