import { ComponentStore, OnStateInit } from '@ngrx/component-store';
import { PortfolioGroupNavigationState } from './interfaces';
import { isEqual } from 'lodash';
import { Observable, takeUntil } from 'rxjs';
import { GroupSearch } from 'core/groups/groups.interface';
import { Injectable } from '@angular/core';
import { selectTagsInSitesAreChanged } from 'app/features/portfolio/+state/portfolio.selectors';
import { Store } from '@ngrx/store';
import { RootState } from 'app/+state/app.reducer';

@Injectable()
export class PortfolioGroupNavigationStore
  extends ComponentStore<PortfolioGroupNavigationState>
  implements OnStateInit
{
  readonly setSelectedTagsIds = this.updater((state, tagsSelectedIds: number[]) => ({
    ...state,
    tagsSelectedIds,
  }));

  readonly updateSearchTerm = this.updater((state, searchTerm: string) => ({
    ...state,
    searchTerm,
  }));

  readonly updateShouldTriggerSearch = this.updater((state, shouldTriggerSearch: boolean) => ({
    ...state,
    shouldTriggerSearch,
  }));
  readonly updateShowSearchInput = this.updater((state, showSearchInput: boolean) => ({
    ...state,
    showSearchInput,
  }));

  readonly updateIsEditingModeEnabled = this.updater((state, isEditingModeEnabled: boolean) => ({
    ...state,
    isEditingModeEnabled,
  }));

  selectSelectedTagsIds$ = this.select((state) => state.tagsSelectedIds, {
    equal: (prev, curr) => isEqual(prev, curr),
  });

  selectBadgeCount$: Observable<string> = this.select(
    this.selectSelectedTagsIds$,
    (ids) => `${ids.length}`,
    {
      equal: (prev, curr) => isEqual(prev, curr),
    }
  );

  selectSearchTerm$ = this.select((state) => state.searchTerm);

  selectShouldTriggerSearch$ = this.select((state) => state.shouldTriggerSearch);

  selectShowSearchInput$ = this.select((state) => state.showSearchInput);
  selectIsEditingModeEnabled$ = this.select((state) => state.isEditingModeEnabled);

  selectSearchGroupParams$: Observable<GroupSearch> = this.select(
    this.selectSearchTerm$,
    this.selectSelectedTagsIds$,
    this.selectIsEditingModeEnabled$,
    this.selectShouldTriggerSearch$,
    (searchTerm, tagIds, editingMode) => ({
      tagIds,
      searchTerm,
      editMode: editingMode,
    })
  );

  constructor(private ngrxStore: Store<RootState>) {
    super({
      tagsSelectedIds: [],
      searchTerm: '',
      showSearchInput: false,
      isEditingModeEnabled: false,
      shouldTriggerSearch: false,
    });
  }

  ngrxOnStateInit(): void {
    this.ngrxStore
      .select(selectTagsInSitesAreChanged)
      .pipe(takeUntil(this.destroy$))
      .subscribe((tagsAreChanged) => {
        this.updateShouldTriggerSearch(tagsAreChanged);
      });
  }

  getTagsIds(): number[] {
    return this.get().tagsSelectedIds;
  }

  getIsEditingModeEnabled(): boolean {
    return this.get().isEditingModeEnabled;
  }
}
