<div class="tab-page-container">
  <div class="left-filters" *ngIf="config?.left">
    <div
      *ngFor="let item of config?.left; let first = first"
      class="left-filters--item"
      data-cy="filter-page--item"
      (click)="setActiveLeft(item)"
      [class.active]="activeTabs.left[0] && activeTabs.left[0].label === item.label"
      [style.padding-left.px]="first ? 0 : 16"
    >
      <h2 class="h1">
        <img [src]="item?.icon" *ngIf="item?.icon" [alt]="item?.label" /><span
          [innerHTML]="item?.label || '' | translate"
        ></span>
      </h2>
    </div>
  </div>
  <ng-content></ng-content>

  <div class="right-action">
    <div class="ml-4" *ngIf="displayDeleteObject">
      <ista-daytona-button-delete-object
        icon="/assets/icons/trash.svg"
      ></ista-daytona-button-delete-object>
    </div>
    <div class="ml-4" *ngIf="displayEditData">
      <ista-daytona-button-edit icon="/assets/icons/edit.svg"></ista-daytona-button-edit>
    </div>
    <div *ngIf="displayDataImport">
      <ista-daytona-button-data-add></ista-daytona-button-data-add>
    </div>
  </div>
</div>
