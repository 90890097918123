<ista-daytona-content-card-open [labels]="['Zenloop']">
  <p>
    {{ 'zenloop.block1' | translate }}
  </p>
  <h4>{{ 'zenloop.subheadline1' | translate }}</h4>
  <p>
    zenloop GmbH<br />
    Pappelallee 78-79, 10437 Berlin, Germany
  </p>
  <h4>{{ 'zenloop.subheadline2' | translate }}</h4>
  <p>{{ 'zenloop.block2' | translate }}</p>
  <ul>
    <li>
      <span
        ><span
          ><span
            ><span
              ><span
                ><span
                  ><span>{{ 'zenloop.list1' | translate }}</span></span
                ></span
              ></span
            ></span
          ></span
        ></span
      >
    </li>
    <li>
      <span
        ><span
          ><span
            ><span
              ><span
                ><span
                  ><span>{{ 'zenloop.list2' | translate }}</span></span
                ></span
              ></span
            ></span
          ></span
        ></span
      >
    </li>
  </ul>
  <h4>{{ 'zenloop.subheadline3' | translate }}</h4>
  <ul>
    <li>
      <span
        ><span
          ><span
            ><span
              ><span
                ><span
                  ><span>{{ 'zenloop.list3' | translate }}</span></span
                ></span
              ></span
            ></span
          ></span
        ></span
      >
    </li>
  </ul>
  <h4>{{ 'zenloop.subheadline4' | translate }}</h4>
  <p>
    {{ 'zenloop.block3' | translate }}
  </p>
  <ul>
    <li>
      <span
        ><span
          ><span
            ><span
              ><span
                ><span
                  ><span>{{ 'zenloop.list4' | translate }}</span></span
                ></span
              ></span
            ></span
          ></span
        ></span
      >
    </li>
    <li>
      <span
        ><span
          ><span
            ><span
              ><span
                ><span
                  ><span>{{ 'zenloop.list5' | translate }}</span></span
                ></span
              ></span
            ></span
          ></span
        ></span
      >
    </li>
    <li>
      <span
        ><span
          ><span
            ><span
              ><span
                ><span
                  ><span>{{ 'zenloop.list6' | translate }}</span></span
                ></span
              ></span
            ></span
          ></span
        ></span
      >
    </li>
    <li>
      <span
        ><span
          ><span
            ><span
              ><span
                ><span
                  ><span>{{ 'zenloop.list7' | translate }}</span></span
                ></span
              ></span
            ></span
          ></span
        ></span
      >
    </li>
    <li>
      <span
        ><span
          ><span
            ><span
              ><span
                ><span
                  ><span>{{ 'zenloop.list8' | translate }}</span></span
                ></span
              ></span
            ></span
          ></span
        ></span
      >
    </li>
    <li>
      <span
        ><span
          ><span
            ><span
              ><span
                ><span
                  ><span>{{ 'zenloop.list9' | translate }}</span></span
                ></span
              ></span
            ></span
          ></span
        ></span
      >
    </li>
    <li>
      <span
        ><span
          ><span
            ><span
              ><span
                ><span
                  ><span>{{ 'zenloop.list10' | translate }}</span></span
                ></span
              ></span
            ></span
          ></span
        ></span
      >
    </li>
  </ul>
  <h4>{{ 'zenloop.subheadline5' | translate }}</h4>
  <p>
    {{ 'zenloop.block4' | translate }}
  </p>
  <ul>
    <li>
      <span
        ><span
          ><span
            ><span
              ><span
                ><span><span>Art. 6 Abs. 1 s. 1 lit. a DSGVO</span></span></span
              ></span
            ></span
          ></span
        ></span
      >
    </li>
  </ul>
  <h4>{{ 'zenloop.subheadline6' | translate }}</h4>
  <p>{{ 'zenloop.block5' | translate }}</p>
  <h4>{{ 'zenloop.subheadline7' | translate }}</h4>
  <p>
    {{ 'zenloop.block6' | translate }}
  </p>
  <p>
    {{ 'zenloop.block7' | translate }}
  </p>
  <h4>{{ 'zenloop.subheadline8' | translate }}</h4>
  <ul>
    <li>
      <span
        ><span
          ><span
            ><span
              ><span
                ><span
                  ><span>{{ 'zenloop.list11' | translate }}</span></span
                ></span
              ></span
            ></span
          ></span
        ></span
      >
    </li>
    <li>
      <span
        ><span
          ><span
            ><span
              ><span
                ><span
                  ><span>{{ 'zenloop.list12' | translate }}</span></span
                ></span
              ></span
            ></span
          ></span
        ></span
      >
    </li>
    <li>
      <span
        ><span
          ><span
            ><span
              ><span
                ><span
                  ><span>{{ 'zenloop.list13' | translate }}</span></span
                ></span
              ></span
            ></span
          ></span
        ></span
      >
    </li>
    <li>
      <span
        ><span
          ><span
            ><span
              ><span
                ><span
                  ><span>{{ 'zenloop.list14' | translate }}</span></span
                ></span
              ></span
            ></span
          ></span
        ></span
      >
    </li>
  </ul>
  <h4>{{ 'zenloop.subheadline9' | translate }}</h4>
  <p>
    {{ 'zenloop.block8' | translate }}
  </p>
  <p><a href="mailto:dpo@zenloop.com" target="_blank">dpo&#64;zenloop.com</a></p>
  <h4>{{ 'zenloop.subheadline10' | translate }}</h4>
  <p>
    {{ 'zenloop.block9' | translate }}
  </p>
  <p>{{ 'zenloop.block10' | translate }}</p>
  <p>
    <strong>{{ 'zenloop.block11' | translate }}</strong
    >&nbsp;<a href="https://www.zenloop.com/en/legal/privacy" target="_blank" rel="noopener"
      >https://www.zenloop.com/en/legal/privacy</a
    >
  </p></ista-daytona-content-card-open
>
