import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AuthConfig } from 'angular-oauth2-oidc';
import { firstValueFrom } from 'rxjs';

import { environment } from 'environments/environment';
import { DestroyAbstractService } from 'shared/helper/destroy-abstract.service';
import { ErrorHandlerService } from './error-handling/error-handler.service';
import { Store } from '@ngrx/store';
import { RootState } from '../+state/app.reducer';
import { storeFrontendConfig } from './+state/core.actions';

/* eslint-disable @typescript-eslint/member-ordering */
export interface AuthConfigExtended {
  auth: AuthConfig;
  mockEnabled: boolean;
}

@Injectable({
  providedIn: 'root',
})
export class FrontendConfigService extends DestroyAbstractService {
  constructor(
    private readonly ngrxStore: Store<RootState>,
    private readonly _httpClient: HttpClient,
    private readonly _errorHandler: ErrorHandlerService
  ) {
    super();
  }

  public async loadFrontendConfigValues(): Promise<void> {
    try {
      // 1. Load keycloak URL
      const keycloakUrl = await firstValueFrom(
        this._httpClient.get(environment.apiUrl + '/api/frontend-config/keycloak-url', {
          responseType: 'text',
        })
      );
      // 2. Load keycloak client ID
      const keycloakClientId = await firstValueFrom(
        this._httpClient.get(environment.apiUrl + '/api/frontend-config/keycloak-client-id', {
          responseType: 'text',
        })
      );
      // 3. Load mock enabled flag
      const keycloakMockEnabled: boolean = await firstValueFrom(
        this._httpClient.get<boolean>(
          environment.apiUrl + '/api/frontend-config/keycloak-mock-enabled'
        )
      );

      this.ngrxStore.dispatch(
        storeFrontendConfig({
          frontendConfig: {
            keycloakUrl,
            keycloakClientId,
            keycloakMockEnabled,
          },
        })
      );
    } catch (error) {
      this._errorHandler.displayError('FRONTEND_CONFIG_FAILURE');
    }
  }
}
