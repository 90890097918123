import { Injectable } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { RouterStateSnapshot, TitleStrategy } from '@angular/router';

@Injectable({
  providedIn: 'root',
})
export class TitleStrategyService extends TitleStrategy {
  constructor(private readonly _titleService: Title) {
    super();
  }

  public override updateTitle(routerState: RouterStateSnapshot): void {
    const title = this.buildTitle(routerState) || '';

    if (title !== undefined) {
      // this._userProfilService.saveValue('title', title);
      this._titleService.setTitle(`esg manager - ${title}`);
    }
  }
}
