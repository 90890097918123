<div
  class="w-full p-4 bg-white border border-slate-200 rounded-lg shadow-lg max-w-[305px]"
  role="alert"
>
  <div class="flex">
    <div class="mt-5 pr-5">
      <div
        [innerHTML]="
          'dialogs.delete-confirm.delete-confirm-text' | translate : { itemName: data.itemName }
        "
        class="text-slate-900 text-base"
      ></div>
      <div class="flex flex-wrap gap-2 mt-3">
        <ista-daytona-button
          (click)="confirm()"
          [label]="'dialogs.delete-confirm.delete-confirm-button-text' | translate"
          [small]="true"
          class="delete-dialog-confirm-button"
        ></ista-daytona-button>
        <ista-daytona-button
          (click)="closeDialog()"
          [label]="'dialogs.delete-confirm.delete-cancel-button-text' | translate"
          [small]="true"
          class="delete-dialog-cancel-button"
          type="secondary"
        ></ista-daytona-button>
      </div>
    </div>
    <ista-daytona-button
      (click)="closeDialog()"
      class="absolute top-0 right-1 delete-dialog-close-button"
      icon="close"
      type="icon"
    ></ista-daytona-button>
  </div>
</div>
