import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { StickyHeaderInterface } from 'core/sticky-header/sticky-header.interface';

@Injectable({
  providedIn: 'root',
})
export class StickyHeaderService {
  /* eslint-disable @typescript-eslint/member-ordering */
  private addItem$ = new Subject<StickyHeaderInterface<any>>();
  addItemSource$ = this.addItem$.asObservable();
  private deleteItem$ = new Subject<StickyHeaderInterface<any>>();
  deleteItemSource$ = this.deleteItem$.asObservable();
  private resetHeader$ = new Subject<void>();
  resetHeaderSource$ = this.resetHeader$.asObservable();

  addElement<COMPONENT_TYPE>(item: StickyHeaderInterface<COMPONENT_TYPE>): void {
    this.addItem$.next(item);
  }

  deleteElement(item: StickyHeaderInterface<any>): void {
    this.deleteItem$.next(item);
  }

  resetHeader(): void {
    this.resetHeader$.next();
  }
}
