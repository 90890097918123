<div class="flex gap-2 items-center text-base-500 text-base font-extrabold">
  <div [contentTemplate]="(selectIsCustomYearSelected$ | async) ? tooltipCustomYear : tooltipCalendarYear"
       [indicatorPosition]="'top'"
       [position]="position"
       class="inline-flex ml-1 tooltip-icon"
       customToolTip>
    <mat-icon class="icon-size-6 text-base-500">info</mat-icon>
  </div>
  @if (selectIsCustomYearSelected$ | async) {
    <span class="custom-year">
      {{ 'selected-period-info.custom-year' | translate }}
    </span>
  } @else {
    <span class="calendar-year">
      {{ 'selected-period-info.calendar-year' | translate }}
    </span>
  }
</div>


<ng-template #tooltipCustomYear>
  <div class="custom-tooltip text-sm custom-year-tooltip">
    <div>{{ 'selected-period-info.custom-year' | translate }}:</div>
    <div
      [innerHTML]="'selected-period-info.custom-year-placeholder' | translate: {start: selectCustomYearStartDate$ | async, end: selectCustomYearEndDate$ | async}"></div>
  </div>
</ng-template>

<ng-template #tooltipCalendarYear>
  <div class="custom-tooltip text-sm calendar-year-tooltip">
    <div>{{ 'selected-period-info.calendar-year' | translate }}:</div>
    <div
      [innerHTML]="'selected-period-info.custom-year-placeholder' | translate: {start: selectCalendarYearStartDate$ | async, end: selectCalendarYearEndDate$ | async}"></div>
  </div>
</ng-template>
