import { HttpClientModule } from '@angular/common/http';
import { ModuleWithProviders, NgModule } from '@angular/core';
import { OAuthModule, OAuthStorage } from 'angular-oauth2-oidc';
import { AuthService } from './auth.service';

/* eslint-disable prefer-arrow/prefer-arrow-functions */

// We need a factory since localStorage is not available at AOT build time
export function storageFactory(): OAuthStorage {
  return sessionStorage;
}

@NgModule({
  imports: [HttpClientModule, OAuthModule.forRoot()],
  declarations: [],
  providers: [AuthService],
})
export class AuthModule {
  static forRoot(): ModuleWithProviders<AuthModule> {
    return {
      ngModule: AuthModule,
      providers: [{ provide: OAuthStorage, useFactory: storageFactory }],
    };
  }
}
