<ng-container *ngIf="data; else isLoading">
  <div class="button-container" *ngIf="!!displayFilterButtons && filterButtons">
    <div class="button-content" *ngFor="let button of filterButtons">
      <button
        [class.active]="button.label === selectedFilter.label"
        (click)="setFilter(button)"
        data-cy="line-chart--button"
      >
        <img [src]="button.icon" *ngIf="button.icon" alt="icon" /><span
          [innerText]="button.label | translate"
        ></span>
      </button>
    </div>
  </div>
  <div class="chart-container" #chartContainer>
    <ista-daytona-label [config]="labelConfig" data-cy="chart-label"></ista-daytona-label>
    <div class="chart-container--inner">
      <canvas
        baseChart
        [data]="lineChartData"
        [options]="lineChartOptions"
        [type]="lineChartType"
        data-cy="line-chart-legend"
      ></canvas>
    </div>
  </div>
  <ista-daytona-chart-legend [chartData]="lineChartData"></ista-daytona-chart-legend>
</ng-container>

<ng-template #isLoading>
  <ista-daytona-loader [diameter]="'64'"></ista-daytona-loader>
</ng-template>
