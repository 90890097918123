<div [class.justify-center]="alignInCenter" class="flex items-center gap-2">
  @if (icon) {
    @if (icon.isSvgIcon) {
      <mat-icon
        [svgIcon]="icon.name"
        [ngClass]="{
            'icon-size-solid-6': icon.isIconSolid,
            'icon-size-6': icon.isIconSolid,
          }"
        class="custom-svg-icon"
        [class]="icon.colorClass || ''"
      >
      </mat-icon>
    } @else {
      <mat-icon
        [ngClass]="{
            'icon-size-solid-6': icon.isIconSolid,
            'icon-size-6': icon.isIconSolid,
          }"
        class="custom-icon"
        [class]="icon.colorClass || ''"
      >
        {{ icon.name }}
      </mat-icon>
    }
  }
  <span [title]="label | translate"
        class="text-base-700 overflow-ellipsis text-xl font-extrabold label-element truncate">
    {{ label | translate }}
  </span>
</div>
