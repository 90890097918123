export enum NotificationType {
  ALERT = 'alert',
  WARNING = 'warning',
  CONFIRMATION = 'confirmation',
}

export interface Notification {
  headline: string;
  subline?: string;
  text?: string;
  type: NotificationType;
}
