/* eslint-disable @typescript-eslint/no-unsafe-argument */
// import { FocusMonitor } from '@angular/cdk/a11y';
import { FocusMonitor } from '@angular/cdk/a11y';
import { Component, ElementRef, EventEmitter, forwardRef, Input, Output, ViewChild } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { LoggerService } from 'shared/helper/logger.service';
import { BaseInputComponent } from '../../base-input/base-input.component';
import { MatRadioButton } from '@angular/material/radio';
import { ThemePalette } from '@angular/material/core';
import { NgClass } from '@angular/common';

// import { MatRadioButton } from '@angular/material/radio';
// import { NgOption } from '@ng-select/ng-select';

@Component({
  selector: 'ista-daytona-radiobutton',
  templateUrl: './radiobutton.component.html',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      // eslint-disable-next-line no-use-before-define
      useExisting: forwardRef(() => RadiobuttonComponent),
      multi: true,
    },
  ],
  imports: [
    MatRadioButton,
    NgClass,
  ],
  standalone: true,
})
export class RadiobuttonComponent extends BaseInputComponent<boolean> {
  @Output() statusChanged: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Input() isSelected = false;
  @Input() infoText = '';
  @Input() labelId = '';
  @Input() hasError = false;
  @Input() isFocused = false;
  @Input() color: ThemePalette = 'primary';

  @ViewChild('matRadioButton') matRadioButton!: MatRadioButton;
  @ViewChild('matRadioButton', { read: ElementRef })
  matRadioButtonRef!: ElementRef;

  triggerEvents(): void {
    this.statusChanged.emit(true);
  }

  handleChange(): void {
    this.emitChangeEvent();
    this.blur();
  }

  private emitChangeEvent(): void {
    this.statusChanged.emit(true);
  }

  /**
   * Since our MatRadioButton instance is handling focus managment using a FocusMonitor, we have to
   * call the stopMonitoring method on the monitor instance.
   */
  private blur(): void {
    try {
      // eslint-disable-next-line @typescript-eslint/dot-notation
      (this.matRadioButton['_focusMonitor'] as FocusMonitor).stopMonitoring(
        this.matRadioButtonRef.nativeElement
      );
    } catch (error) {
      LoggerService.warn('Internal Angular Material API for FocusMonitor has changed!');
    }
  }
}
