<div class="portfolio-card-container">
  <div class="inner-content">
    <div class="header" *ngIf="header">
      <img
        *ngIf="header?.icon"
        [src]="header.icon?.url"
        [alt]="header.icon?.text"
        [title]="header.icon?.text"
        class="portfolio-card-container__icon"
        data-cy="portfolio-card--image"
      />
      <span [innerText]="header.label | translate" data-cy="portfolio-card--label"></span>
    </div>
    <div class="value" *ngIf="data?.value">
      <div class="inner-value">
        <ista-daytona-value-unit
          [value]="data?.value"
          [classContainer]="'portfolio-card__total--inner'"
          [classValue]="'portfolio-card__total--value'"
          [classUnit]="'portfolio-card__total--unit'"
          data-cy="portfolio-card--value-unit"
        ></ista-daytona-value-unit>
      </div>
    </div>
  </div>
</div>
