import { Component, EventEmitter, Input, Output } from '@angular/core';

import { FilterSubTab, FilterTab, FilterType } from './filter-tab.interface';
import { FilterTimeMode } from './filter-time-series/filter-time-mode.interface';

@Component({
  selector: 'ista-daytona-filter',
  templateUrl: './filter.component.html',
  styleUrls: ['./filter.component.scss'],
})
export class FilterComponent {
  /*
   * @TODO: please fix me with: SQCI05-1228
   * */
  @Input() timeMode!: FilterTimeMode;
  @Input() config!: FilterSubTab | FilterTab;
  @Input() type: FilterType = FilterType.PRIMARY;
  @Input() class = '';
  @Input() displayDataImport = false;
  @Input() displayDeleteObject = false;
  @Input() displayEditData = false;
  @Input() displayBorderBottom = true;
  @Input() displayTimeSeries = true;
  @Input() bottomOffset = '24px';
  @Input() reducedVersion = false;
  @Input() selectedFilterPortfolioAnalysisChart: FilterTab[] = [];
  @Output() clickedFilter = new EventEmitter<FilterTab>();
  public filterType = FilterType;
}
