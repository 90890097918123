import { ColorHelper } from '../../helper/color-helper';
import { IconHelper } from '../../helper/icon-helper';
import { LanguageKeyHelper } from '../../helper/language-key-helper';
import { Unit } from '../../models/unit.enum';
import { MonitoringCardPortfolio } from './monitoring-card-data.interface';

export type MonitoringCardsSkeletonType =
  | 'heatingAndHotWater'
  | 'electricity'
  | 'water'
  | 'waste'
  | 'emissions'
  | 'costs';

// export type monitoringCardsSkeletonType = keyof monitoringCardsSkeleton;

export type MonitoringCardType = Pick<MonitoringCardPortfolio, MonitoringCardsSkeletonType>;

export const monitoringCardsSkeleton: MonitoringCardType = {
  heatingAndHotWater: {
    id: 1,
    color: ColorHelper.get('heatingAndHotWater-light') || 'red',
    header: {
      label: LanguageKeyHelper.get('heatingAndHotWater') || 'Wärme',
      icon: {
        text: LanguageKeyHelper.get('heatingAndHotWater') || 'Wärme',
        url: IconHelper.get('heatingAndHotWater') || '',
      },
      tabLabel: 'heatingAndHotWater',
    },
    unit: Unit.KWH,
    bars: [],
  },
  electricity: {
    id: 2,
    color: ColorHelper.get('electricity-light') || 'orange',
    header: {
      label: LanguageKeyHelper.get('electricity') || 'Strom',
      icon: {
        text: LanguageKeyHelper.get('electricity') || 'Strom',
        url: IconHelper.get('electricity') || '',
      },
      tabLabel: 'electricity',
    },
    unit: Unit.KWH,
    bars: [],
  },
  water: {
    id: 3,
    color: ColorHelper.get('water-light') || 'blue',
    header: {
      label: LanguageKeyHelper.get('water') || 'Wasser',
      icon: {
        text: LanguageKeyHelper.get('water') || 'Wasser',
        url: IconHelper.get('water') || '',
      },
      tabLabel: 'water',
    },
    unit: Unit.KG,
    bars: [],
  },
  waste: {
    id: 4,
    color: ColorHelper.get('waste-light') || 'gray',
    header: {
      label: LanguageKeyHelper.get('waste') || 'Abfall',
      icon: {
        text: LanguageKeyHelper.get('waste') || 'Abfall',
        url: IconHelper.get('waste') || '',
      },
      tabLabel: 'waste',
    },
    unit: Unit.KG,
    bars: [],
  },
  emissions: {
    id: 5,
    color: ColorHelper.get('co2-light') || 'green',
    header: {
      label: LanguageKeyHelper.get('co2Emissions') || 'CO₂e Kosten',
      icon: {
        text: LanguageKeyHelper.get('co2Emissions') || 'CO₂e Kosten',
        url: IconHelper.get('co2') || '',
      },
      tabLabel: 'co2Emissions',
    },
    unit: Unit.EUR,
    bars: [],
  },
  costs: {
    id: 6,
    color: ColorHelper.get('cost') || 'green',
    header: {
      label: LanguageKeyHelper.get('costs') || 'Kosten',
      icon: {
        text: LanguageKeyHelper.get('costs') || 'Kosten',
        url: IconHelper.get('euro') || '',
      },
      tabLabel: 'costs',
    },
    unit: Unit.EUR,
    bars: [],
  },
};
