import { Component, EventEmitter, Output } from '@angular/core';

import { ButtonType } from '../../basic/button/button-type.enum';
import { FileUploadFunctionsService } from '../file-upload-functions.service';
import { FileUploadStatus, FileUploadType } from '../file-upload.model';

@Component({
  selector: 'ista-daytona-file-upload-step-result',
  templateUrl: './file-upload-step-result.component.html',
  styleUrls: ['./file-upload-step-result.component.scss'],
})
export class FileUploadStepResultComponent extends FileUploadFunctionsService {
  @Output() uploadValidData = new EventEmitter<null>();
  @Output() abortUploading = new EventEmitter<null>();
  @Output() closeWindow = new EventEmitter<null>();
  public fileUploadStatus = FileUploadStatus;
  public fileUploadType = FileUploadType;
  public buttonType = ButtonType;

  public abort(): void {
    this.abortUploading.next(null);
  }
  public close(): void {
    this.closeWindow.next(null);
  }
}
