import { Type } from '@angular/core';

export enum StickyHeaderElementType {
  H1 = 'H1',
  H2 = 'H2',
  FILTER_TAB = 'filterTab',
  BUTTON_DATA_ADD = 'buttonDataAdd',
  TAB = 'tab',
  BUTTON_DELETE_OBJECT = 'buttonDeleteObject',
}

export interface StickyHeaderInterface<COMPONENT_TYPE> {
  type?: StickyHeaderElementType;
  element?: HTMLElement;
  component?: Type<COMPONENT_TYPE>;
  inputs?: Partial<COMPONENT_TYPE>;
  innerHtml?: string;
  id: string;
}

export interface VisibilityCallback {
  element: HTMLElement;
  isVisible: boolean;
}
