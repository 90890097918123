import { Component, EventEmitter, Input, Output } from '@angular/core';

import { BaseUi } from '../base-ui/base-ui';

@Component({
  selector: 'ista-daytona-page-stepper',
  templateUrl: './page-stepper.component.html',
  styleUrls: ['./page-stepper.component.scss'],
})
export class PageStepperComponent extends BaseUi {
  @Input() minPageCount = 1;
  @Input() maxPageCount = 1;
  @Input() selectedPage = 1;
  @Input() showBoxShadow = false;
  @Output() selectPage = new EventEmitter<number>();

  public prevPage(): void {
    if (this.isPrevPagePossible()) {
      this.selectedPage--;
      this.selectPage.emit(this.selectedPage);
    }
  }

  public nextPage(): void {
    if (this.isNextPagePossible()) {
      this.selectedPage++;
      this.selectPage.emit(this.selectedPage);
    }
  }

  public isPrevPagePossible(): boolean {
    return this.selectedPage - 1 >= this.minPageCount;
  }

  public isNextPagePossible(): boolean {
    return this.selectedPage + 1 <= this.maxPageCount;
  }
}
