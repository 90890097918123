import { Component, Input } from '@angular/core';
import { combineLatest, ReplaySubject, takeUntil } from 'rxjs';

import { HttpRequestService } from 'core/http-request/http-request.service';
import { LoadingState, LoadStatus } from 'core/load-status/load-status.interface';
import { LoadStatusService } from 'core/load-status/load-status.service';
import { DestroyAbstractService } from 'shared/helper/destroy-abstract.service';
import { FilterDateRange } from '../../filter/filter-tab.interface';

/* eslint-disable @typescript-eslint/member-ordering */
@Component({
  selector: 'ista-daytona-button-export',
  templateUrl: './button-export.component.html',
})
export class ButtonExportComponent extends DestroyAbstractService {
  @Input() label = '';
  get selectedYear(): FilterDateRange {
    return this._selectedYear;
  }
  @Input() set selectedYear(date: FilterDateRange | undefined) {
    if (date) {
      this._selectedYear = date;
      this.selectedYear$.next(date);
    }
  }
  public isDisabled = false;
  public loadStatus!: LoadStatus;
  public loadingState = LoadingState;
  private selectedYear$ = new ReplaySubject<FilterDateRange>();
  private _selectedYear!: FilterDateRange;

  constructor(
    private readonly httpRequestService: HttpRequestService,
    private readonly loadStatusService: LoadStatusService
  ) {
    super();
    combineLatest([
      this.httpRequestService.isReadySource$,
      this.selectedYear$,
      this.loadStatusService.getProgressStatus(),
    ])
      .pipe(takeUntil(this._onDestroy$))
      .subscribe(([isReady, selectedYear, loadStatus]) => {
        this.loadStatus = loadStatus;
        this.isDisabled = !isReady || !selectedYear || loadStatus.state === LoadingState.PROGRESS;
      });
  }

  public export(): void {
    this.httpRequestService.triggerExcelReport();
  }
}
