import { Injectable } from '@angular/core';
import { BreadcrumbItem } from '@ista-ui/angular';
import { BreadcrumbStore } from 'shared/ui/breadcrumb-navigation/breadcrumb.store';

@Injectable()
export class BreadcrumbNavigationService {
  constructor(private breadcrumbsStore: BreadcrumbStore) {}

  addItems(items: BreadcrumbItem[]): void {
    this.breadcrumbsStore.addBreadcrumbItems(items);
  }

  resetToRootAndAddItems(items: BreadcrumbItem[]): void {
    this.breadcrumbsStore.resetToRootAndAddItems(items);
  }
}
