import { Component, Input } from '@angular/core';
import { MatProgressBar, ProgressBarMode } from '@angular/material/progress-bar';

@Component({
  selector: 'ista-daytona-bar',
  templateUrl: './bar.component.html',
  imports: [
    MatProgressBar,
  ],
  standalone: true,
})
export class BarComponent {

  @Input() color = 'primary';
  @Input() barMode: ProgressBarMode = 'indeterminate';
  @Input() barSize = '';
  @Input() value = '0';
}
