import {
  booleanAttribute,
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
  SkipSelf,
} from '@angular/core';
import { Group, SiteOfGroup } from 'core/groups/groups.interface';
import { GroupsTreeNavigationStore } from 'core/groups-tree-navigation/groups-tree-navigation.store';
import { map, shareReplay } from 'rxjs';

@Component({
  selector: 'ista-daytona-site-item',
  templateUrl: 'site-item.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SiteItemComponent {
  @Input({ required: true }) item?: SiteOfGroup;
  @Input({ required: true }) parentGroup!: Group;
  @Input({ transform: booleanAttribute }) showEditingOptions = false;
  @Input({ transform: booleanAttribute }) enableDrag = false;
  @Input({ transform: booleanAttribute }) showDragIcon = true;
  @Output() delete = new EventEmitter<{
    event: { x: number; y: number };
    site: SiteOfGroup;
  }>();
  @Output() itemClick = new EventEmitter<SiteOfGroup>();
  @Output() dragOver = new EventEmitter<{
    event: DragEvent;
    parentGroup: Group;
  }>();
  @Output() dragStart = new EventEmitter<DragEvent>();
  @Output() dropOver = new EventEmitter<DragEvent>();

  isNodeActive$ = this.groupsTreeNavigationStore.selectActiveNode$.pipe(
    map(
      (activeNode) => !!activeNode && activeNode.id === this.item?.id && activeNode.type === 'site'
    ),
    shareReplay(1)
  );

  constructor(@SkipSelf() private groupsTreeNavigationStore: GroupsTreeNavigationStore) {}

  deleteSite({ event, site }: { event: { x: number; y: number }; site: SiteOfGroup }): void {
    this.delete.emit({
      event,
      site,
    });
  }

  onItemClick(site: SiteOfGroup): void {
    this.itemClick.emit(site);
  }

  handleDragOver({ event, parentGroup }: { event: DragEvent; parentGroup: Group }): void {
    event.preventDefault();
    this.dragOver.emit({
      event,
      parentGroup,
    });
  }

  dragSiteStart(event: DragEvent): void {
    this.dragStart.emit(event);
  }

  handleDrop(event: DragEvent): void {
    this.dropOver.emit(event);
  }
}
