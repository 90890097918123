import { Injectable } from '@angular/core';

import { factorSequence, unitMapping } from './unit-mapping';
import {
  TransformedValueItem,
  Unit,
  UnitFactor,
  UnitMapping,
  UnitMappingConfig,
  UnitWithPrefix,
} from '../models/unit.enum';
import { ValueItem } from './../models/value-item.interface';
import { HelperService } from './helper.service';
import { LoggerService } from './logger.service';

@Injectable({
  providedIn: 'root',
})
export class ValueUnitService {
  public static readonly maxDigits = 3;

  public static getTransformedValueItem(value: string | number | null, unit?: Unit): ValueItem {
    return {
      value: typeof value === 'string' ? parseFloat(value) : value,
      unit: unit || Unit.DEFAULT,
    };
  }

  public static getNextUnitPrefix(unit: Unit, factor: UnitFactor): UnitWithPrefix {
    const mappingItem: UnitMappingConfig | undefined = unitMapping.find(
      (item) => item.unit === unit
    );

    if (!mappingItem) {
      return UnitWithPrefix.UNKNOWN;
    }

    const factorIndex = factorSequence.findIndex((item) => item === factor);

    if (factorIndex < 0) {
      LoggerService.error('cannot find factor in value unit service', factor);
      return UnitWithPrefix.UNKNOWN;
    }

    if (factorIndex > mappingItem.unitWithPrefixSequence.length) {
      LoggerService.error('cannot use factor in this unit', unit);
      return UnitWithPrefix.UNKNOWN;
    }

    const unitWithPrefix = mappingItem.unitWithPrefixSequence[factorIndex];

    return unitWithPrefix || UnitWithPrefix.UNKNOWN;
  }

  public static getMappedValueByFactor(value: ValueItem, factor: UnitFactor): TransformedValueItem {
    const result: TransformedValueItem = {
      value: value?.value,
      unitWithPrefix: value?.unit as unknown as UnitWithPrefix,
    };

    if (value && factor) {
      let transformedValue = value?.value;
      let transformedUnitWithPrefix = value?.unit as unknown as UnitWithPrefix;
      const mappingItem: UnitMappingConfig | undefined = unitMapping.find(
        (item) => item.unit === value.unit
      );

      if (mappingItem) {
        const factorItem: UnitMapping | undefined = mappingItem.mapping.find(
          (item) => item.factor === factor
        );
        if (factorItem && value?.value !== undefined && value?.value !== null) {
          transformedValue = HelperService.getRoundedValue(value?.value / factorItem.factor);
          transformedUnitWithPrefix = factorItem.unitWithPrefix;
        }
      }

      result.value = transformedValue;
      result.unitWithPrefix = transformedUnitWithPrefix;
    }

    return result;
  }

  public static getMappedValueAuto(value: ValueItem): TransformedValueItem {
    const result: TransformedValueItem = {
      value: value.value,
      unitWithPrefix: value.unit as unknown as UnitWithPrefix,
    };
    let transformedValue = value.value;
    let transformedUnitWithPrefix = value.unit as unknown as UnitWithPrefix;
    const mappingItem: UnitMappingConfig | undefined = unitMapping.find(
      (item) => item.unit === value.unit
    );

    if (mappingItem) {
      let isLowerThanMaxDigits =
        HelperService.getNumberCount(transformedValue) < mappingItem.maxDisplayedDigits;

      let index = 0;
      while (!isLowerThanMaxDigits && index < mappingItem.mapping.length && value.value !== null) {
        const tempValue = HelperService.getRoundedValue(
          value.value / mappingItem.mapping[index].factor
        );
        isLowerThanMaxDigits =
          HelperService.getNumberCount(tempValue) < mappingItem.maxDisplayedDigits;

        transformedValue = tempValue;

        index++;
      }

      const newIndex = index - 1 < 0 ? 0 : index - 1;
      transformedUnitWithPrefix = mappingItem?.mapping[newIndex].unitWithPrefix;
    }

    result.value = transformedValue;
    result.unitWithPrefix = transformedUnitWithPrefix || UnitWithPrefix.DEFAULT;

    return result;
  }
}
