import { ValueItem } from '../../models/value-item.interface';

export interface ChartItemData {
  year: string;
  values: ValueItem;
  additionalValues?: any;
  requiredAdditionalData?: ValueItem;
}

export interface ChartItem {
  label: string;
  value?: string;
  data: ChartItemData[];
  color: string;
  active: boolean;
  icon?: string;
  childs?: ChartItem[];
  resultType?: string;
}

export interface MultipleChartItem {
  [key: string]: ChartItem[];
}
