import { Component, Input } from '@angular/core';

import { ValueItem } from '../../models/value-item.interface';

@Component({
  selector: 'ista-daytona-co2-cost-card-total',
  templateUrl: './co2-cost-card-total.component.html',
  styleUrls: ['./co2-cost-card-total.component.scss'],
})
export class Co2CostCardTotalComponent {
  @Input() totalCostValue!: ValueItem;
  @Input() totalEmission!: ValueItem;
  @Input() carbonPrice!: ValueItem;
}
