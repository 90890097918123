export const LanguageKeyHelper = new Map([
  ['heatingAndHotWater', 'types.heatingAndHotWater'],
  ['heating', 'types.heatingAndHotWater'],
  ['electricity', 'types.electricity'],
  ['water', 'types.water'],
  ['waste', 'types.waste'],
  ['oil', 'types.oil'],
  ['gas', 'types.gas'],
  ['districtHeating', 'types.districtHeating'],
  ['districtCooling', 'types.districtCooling'],
  ['spaceHeatingEnergy', 'types.spaceHeatingEnergy'],
  ['hotWaterEnergy', 'types.hotWaterEnergy'],
  ['co2', 'types.co2'],
  ['costs', 'types.costs'],
  ['co2Emissions', 'types.co2Emissions'],
  ['consumptions', 'filter.consumptions'],
  ['emissions', 'filter.emissions'],
  ['costs', 'filter.costs'],
  ['all', 'types.all'],
  ['unknown', 'types.unknown'],
  ['heatingElectricity', 'types.heatingElectricity'],
  ['bioMass', 'types.bioMass'],
  ['otherFuels', 'types.otherFuels'],
  ['object', 'types.object'],
  ['street', 'filter.street'],
  ['object-id', 'filter.object-id'],
  ['istaPropertyNumber', 'portfolio.masterdataHeader.istaPropertyNumber'],
  ['numberOfUnits', 'portfolio.masterdataHeader.numberOfUnits'],
  ['space', 'portfolio.masterdataHeader.space'],
]);
