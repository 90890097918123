import { Injectable, OnDestroy } from '@angular/core';
import { Store } from '@ngrx/store';
import { RootState } from '../+state/app.reducer';
import { combineLatest, filter, Observable, Subject, takeUntil, tap, withLatestFrom } from 'rxjs';
import { NavigationEnd, Router, UrlTree } from '@angular/router';
import { selectGlobalSelectedYear, selectSelectedEnvironmentId } from './+state/core.selectors';
import { ENVIRONMENT_ID_QUERY_PARAM, YEAR_QUERY_PARAM } from 'core/constants';

@Injectable({
  providedIn: 'root',
})
export class GlobalQueryParamsService implements OnDestroy {
  private destroy$ = new Subject<void>();

  constructor(private store: Store<RootState>, private router: Router) {}

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  listenToRouteChanges(): Observable<unknown> {
    return this.router.events.pipe(
      filter((event) => event instanceof NavigationEnd),
      withLatestFrom(
        this.store.select(selectSelectedEnvironmentId),
        this.store.select(selectGlobalSelectedYear)
      ),
      filter(([, environmentId, globalSelectedYear]) => !!environmentId && !!globalSelectedYear),
      tap(([, environmentId, year]) => {
        const updatedUrlTree = this.setGlobalQueryParamsIntoUrl(
          environmentId as number,
          year as number
        );
        this.router.navigateByUrl(updatedUrlTree);
      }),
      takeUntil(this.destroy$)
    );
  }

  updateQueryParamsInUrl(): Observable<unknown> {
    return combineLatest([
      this.store.select(selectSelectedEnvironmentId),
      this.store.select(selectGlobalSelectedYear),
    ]).pipe(
      filter(([environmentId, globalSelectedYear]) => !!environmentId && !!globalSelectedYear),
      tap(([environmentId, year]) => {
        const updatedUrlTree = this.setGlobalQueryParamsIntoUrl(
          environmentId as number,
          year as number
        );
        this.router.navigateByUrl(updatedUrlTree);
      })
    );
  }

  setGlobalQueryParamsIntoUrl(environmentId: number, year: number): UrlTree {
    const currentUrlTree = this.router.parseUrl(this.router.url);
    return this.router.createUrlTree([], {
      queryParams: {
        ...currentUrlTree.queryParams,
        [ENVIRONMENT_ID_QUERY_PARAM]: environmentId,
        [YEAR_QUERY_PARAM]: year,
      },
      queryParamsHandling: 'merge',
      preserveFragment: true,
    });
  }
}
