import { Component, Input } from '@angular/core';
import { ColorHelper } from 'shared/helper/color-helper';

import { ConsumptionTypesExtended } from './../../basic/label/label-mapping';

@Component({
  selector: 'ista-daytona-doughnut-chart-simple',
  templateUrl: './doughnut-chart-simple.component.html',
  styleUrls: ['./doughnut-chart-simple.component.scss'],
})
export class DoughnutChartSimpleComponent {
  @Input() type: ConsumptionTypesExtended = 'all';
  @Input() value = 0;

  public get style(): any {
    return { '--p': this.value, '--c': ColorHelper.get('cost-dark') };
  }

  public get innerStyle(): any {
    return {
      '--c': '#85A1B1',
    };
  }
}
