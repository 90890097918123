import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { TabInterface } from 'shared/ui/basic/tab/tab.interface';
import { MatTabChangeEvent } from '@angular/material/tabs';

@Component({
  selector: 'ista-daytona-tab',
  templateUrl: 'tab.component.html',
  styleUrls: ['tab.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TabComponent {
  @Input() tabs: TabInterface[] = [];
  @Input() stretch = false;
  @Input() defaultSelectedTabIndex = 0;
  @Input() stickyStyle = false;
  @Output() selectedTabIndexChange = new EventEmitter<number>();

  onTabChange({ index }: MatTabChangeEvent): void {
    this.selectedTabIndexChange.emit(index);
  }
}
