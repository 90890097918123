import {
  TabelDataGenericType,
  TableCellDeepLinking,
  TableDataGeneric,
  TableHeader,
} from 'shared/ui/basic/table/table.interface';
import { GroupSite } from '../portfolio-group/group-detail.interface';
import { ConsumptionSites } from './consumption.interface';

export class ConsumptionMapper {
  public static transformSiteDataToTableDataGeneric(
    sites: ConsumptionSites[],
    tableHeader: TableHeader[]
  ): TableDataGeneric[] {
    try {
      if (sites && sites.length > 0) {
        const tableData: TableDataGeneric[] = [];
        const tableHeaderIds: string[] = tableHeader.map((item) => item.id || item.label || '');

        sites.forEach((item) => {
          const address = `${item.addresses[0].street || ''} ${
            item.addresses[0].houseNumber || ''
          } - ${item.addresses[0].postalCode || ''} ${item.addresses[0].city || ''}`.trim();
          const street =
            item.addresses[0]?.street +
            ' ' +
            (item.addresses[0]?.houseNumber ? ' ' + item.addresses[0].houseNumber : '');
          const tableDataRow: TableDataGeneric = {};
          tableHeaderIds.forEach((id, index) => {
            if (id === 'street') {
              tableDataRow[id] = {
                value: street,
                toolTip: {
                  label: '',
                  value: address,
                },
              };
            } else {
              tableDataRow[id] = {
                value: item[id] as TabelDataGenericType,
              };
            }

            tableDataRow[id] = {
              ...tableDataRow[id],
              linking:
                tableHeader[index]?.linking && tableDataRow[id]?.value
                  ? {
                      ...(tableHeader[index].linking as TableCellDeepLinking),
                      url: `${tableHeader[index]?.linking?.url as string}${item.id}`,
                    }
                  : undefined,
            };
          });

          tableData.push(tableDataRow);
        });

        return tableData;
      }
      return [];
    } catch (e) {
      console.error('transformSiteDataToTableDataGeneric', e);
      return [];
    }
  }

  public static transformGroupDataToTableDataGeneric(
    sites: GroupSite[],
    tableHeader: TableHeader[]
  ): TableDataGeneric[] {
    try {
      if (sites && sites.length > 0) {
        const tableData: TableDataGeneric[] = [];
        const tableHeaderIds: string[] = tableHeader.map((item) => item.id || item.label || '');

        sites.forEach((item) => {
          const tableDataRow: TableDataGeneric = {};
          tableHeaderIds.forEach((id, index) => {
            if (id === 'street') {
              tableDataRow[id] = {
                value: item.addresses[0].street + ' ' + item.addresses[0].houseNumber,
                toolTip: {
                  label: '',
                  value: item.addresses[0].street + ' ' + item.addresses[0].houseNumber,
                },
              };
            } else if (id === 'city') {
              tableDataRow[id] = {
                value: item.addresses[0].postalCode + ' ' + item.addresses[0].city,
                toolTip: {
                  label: '',
                  value: item.addresses[0].postalCode + ' ' + item.addresses[0].city,
                },
              };
            } else {
              tableDataRow[id] = {
                value: item[id] as TabelDataGenericType,
              };
            }

            tableDataRow[id] = {
              ...tableDataRow[id],
              linking:
                tableHeader[index]?.linking && tableDataRow[id]?.value
                  ? {
                      ...(tableHeader[index].linking as TableCellDeepLinking),
                      url: `${tableHeader[index]?.linking?.url as string}${item.id}`,
                    }
                  : undefined,
            };
          });
          tableData.push(tableDataRow);
        });

        return tableData;
      }

      return [];
    } catch (e) {
      console.error('transformGroupDataToTableDataGeneric', e);
      return [];
    }
  }
}
