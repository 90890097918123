import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { debounceTime, ReplaySubject, skip, takeUntil } from 'rxjs';

import { DestroyAbstractService } from 'shared/helper/destroy-abstract.service';
import { InputHeight, InputTypes } from '../input/input-types.enum';
import { InputComponent } from '../input/input.component';

@Component({
  selector: 'ista-daytona-input-search',
  templateUrl: './input-search.component.html',
})
export class InputSearchComponent extends DestroyAbstractService implements OnInit {
  @ViewChild('inputField') inputField!: InputComponent;
  @Input() debounce: number | undefined;
  @Input() placeholder = '';
  @Output() searchInput = new EventEmitter<string>();
  @Output() deleteButtonClicked = new EventEmitter<boolean>(false);
  public inputHeight = InputHeight;
  public inputType = InputTypes;
  public searchStr = '';
  public filterSearchStr$ = new ReplaySubject<string>();

  constructor() {
    super();
  }

  ngOnInit(): void {
    this.filterSearchStr$
      .pipe(skip(1), debounceTime(this.debounce ? this.debounce : 250), takeUntil(this._onDestroy$))
      .subscribe((searchStr) => this.searchInput.emit(searchStr));
  }

  public onInputChange(searchStr: string): void {
    this.filterSearchStr$.next(searchStr);
  }
}
