import { FormControlStatus } from '@angular/forms';
import { Observable } from 'rxjs';

export interface Tag {
  id: number;
  name: string;
  lastModified: string;
}

export interface TagForm extends Tag {
  checked: boolean;
}

export type sortFunction = 'Alphabetical' | 'Date';

export interface TagsStoreState {
  tags: Tag[];
  checkedTagIds: number[];
  showCreateInputField: boolean;
  isEditingMode: boolean;
  isDeleteInProgress: boolean;
  highlightSaveButton: boolean;
  filterString: string;
  formStatus: FormControlStatus;
  sortFunction: sortFunction;
}

export interface UnsavedChanges {
  hasUnsavedData: () => Observable<boolean>;
  highlightSaveButton: () => void;
}

export const NAME_EXISTS_ERROR_KEY = 'nameExists';
