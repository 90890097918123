import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { RootState } from 'app/+state/app.reducer';
import {
  selectCustomYearStartDate,
  selectGlobalSelectedYear,
  selectIsCustomYearSelected,
} from '../+state/core.selectors';
import { combineLatest, filter, map, Observable } from 'rxjs';

@Injectable()
export class SelectedPeriodInfoService {
  selectIsCustomYearSelected$ = this.ngrxStore.select(selectIsCustomYearSelected);
  selectCustomYearStartDate$: Observable<string> = combineLatest([
    this.ngrxStore.select(selectCustomYearStartDate),
    this.ngrxStore.select(selectGlobalSelectedYear),
  ]).pipe(
    filter(([startDate, globalSelectedYear]) => !!startDate && !!globalSelectedYear),
    map(([startDate, globalSelectedYear]) =>
      this.getFormattedDate(
        new Date(`${startDate as string}-${globalSelectedYear as number}`.replace(/-/g, '/'))
      )
    )
  );
  selectCustomYearEndDate$: Observable<string> = combineLatest([
    this.ngrxStore.select(selectCustomYearStartDate),
    this.ngrxStore.select(selectGlobalSelectedYear),
  ]).pipe(
    filter(([startDate, globalSelectedYear]) => !!startDate && !!globalSelectedYear),
    map(([startDate, globalSelectedYear]) => {
      const endDate = new Date(
        `${startDate as string}-${(globalSelectedYear as number) + 1}`.replace(/-/g, '/')
      );
      endDate.setDate(endDate.getDate() - 1);
      return this.getFormattedDate(endDate);
    })
  );
  selectCalendarYearStartDate$: Observable<string> = this.ngrxStore
    .select(selectGlobalSelectedYear)
    .pipe(
      filter(Boolean),
      map((selectedYear) => this.getFormattedDate(new Date(`01/01/${selectedYear}`)))
    );

  selectCalendarYearEndDate$: Observable<string> = this.ngrxStore
    .select(selectGlobalSelectedYear)
    .pipe(
      filter(Boolean),
      map((selectedYear) => this.getFormattedDate(new Date(`12/31/${selectedYear}`)))
    );

  constructor(private readonly ngrxStore: Store<RootState>) {}

  private getFormattedDate(date: Date): string {
    return new Intl.DateTimeFormat('en-GB', { month: '2-digit', day: '2-digit', year: '2-digit' })
      .format(date)
      .replace(/\//g, '.');
  }
}
