import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { OAuthService } from 'angular-oauth2-oidc';
import { RootState } from 'app/+state/app.reducer';
import { environment } from 'environments/environment';
import { firstValueFrom } from 'rxjs';
import { LoggerService } from 'shared/helper/logger.service';

import { storeUserToken } from '../+state/core.actions';
import { selectFrontendConfig } from '../+state/core.selectors';
import { authCodeFlowConfig } from './auth-config';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  private readonly baseUrl = environment?.apiUrl;

  constructor(
    private readonly ngrxStore: Store<RootState>,
    private readonly _httpClient: HttpClient,
    private readonly _oauthService: OAuthService
  ) {}
  public logout(): void {
    this._oauthService.logOut();
  }
  public async handleLogin(): Promise<boolean> {
    try {
      const frontendConfig = await firstValueFrom(this.ngrxStore.select(selectFrontendConfig));

      if (frontendConfig) {
        const auth = {
          ...authCodeFlowConfig,
          issuer: frontendConfig.keycloakUrl,
          clientId: frontendConfig.keycloakClientId,
        };

        if (!frontendConfig.keycloakMockEnabled && !!environment.useAuthentification) {
          // Login using real Keycloak
          this._oauthService.configure(auth);
          try {
            await this._oauthService.loadDiscoveryDocumentAndLogin();
          } catch (error) {
            LoggerService.error('error in auth', error);
          }

          // If Login was successful, store user token
          if (this._oauthService.hasValidAccessToken() && this._oauthService.hasValidIdToken()) {
            const obtainedUserToken = this._oauthService.getAccessToken();
            this.ngrxStore.dispatch(storeUserToken({ userToken: obtainedUserToken }));
            await this.setRefreshTimer();
          } else {
            return Promise.resolve(false);
          }
        } else {
          // Login using Keycloak Mock of Backend (just for local development or testing environments)
          const obtainedUserToken = await firstValueFrom(
            this._httpClient.post(
              `${this.baseUrl}/issue-admin-token`,
              {},
              {
                responseType: 'text',
              }
            )
          );
          this.ngrxStore.dispatch(storeUserToken({ userToken: obtainedUserToken }));
        }
      }
    } catch (error) {
      console.error('Fehler beim Abrufen von frontendConfig:', error);
    }

    return Promise.resolve(true);
  }

  public async refreshToken(): Promise<void> {
    try {
      const tokenResponse = await this._oauthService.refreshToken();
      this.ngrxStore.dispatch(storeUserToken({ userToken: tokenResponse.access_token }));
      this.setRefreshTimer();
    } catch (error) {
      // if refreshing the access token did not work, logout and let the user login again
      this._oauthService.logOut();
    }
  }

  public async setRefreshTimer(): Promise<void> {
    const expiryTimestamp = this._oauthService.getAccessTokenExpiration();
    const currentTimestamp = Date.now();
    const timeLeft = expiryTimestamp - currentTimestamp;
    if (timeLeft <= 60000) {
      await this.refreshToken();
    } else {
      setTimeout(() => {
        this.refreshToken();
      }, timeLeft - 60000);
    }
  }
}
