<div class="page-not-found-container">
  <img src="/assets/images/page-not-found/oops.svg" title="oops" alt="oops" />
  <div>
    <h2 class="headline2 mb-l">Oops, die Seite nach der Sie suchen ist leider nicht hier.</h2>
    <h4>Wie alles im Leben, hat alles einen Grund.</h4>
    <h4 class="mb-l">Vielleicht finden Sie ja hier wonach Sie gesucht haben:</h4>
    <div class="icon-bar">
      <a [routerLink]="'/kontakt'">
        <img src="/assets/images/page-not-found/kontakt.svg" title="kontakt" alt="kontakt" />
      </a>
      <a [routerLink]="'/dashboard'">
        <img src="/assets/images/page-not-found/dashboard.svg" title="dashboard" alt="dashboard" />
      </a>
      <a [routerLink]="'/beratung'">
        <img src="/assets/images/page-not-found/beratung.svg" title="beratung" alt="beratung" />
      </a>
    </div>
  </div>
</div>
