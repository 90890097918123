/* eslint-disable @typescript-eslint/no-unsafe-argument */
import {
  AfterViewInit,
  Component,
  ElementRef,
  Input,
  OnChanges,
  Renderer2,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import * as OSM from 'leaflet';

export const OSMSettings = {
  osmTileLayer: 'https://cartodb-basemaps-{s}.global.ssl.fastly.net/light_all/{z}/{x}/{y}.png',
  attribution: '© <a href="https://carto.com/">Carto</a>',

  // in the comment below is the default map:
  /* osmTileLayer: 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png',
	attribution:
		'&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a>',*/
};

interface Landmarks {
  name: string;
  lat: string;
  lng: string;
  label: string;
  id?: number;
}

interface MarkerIndex {
  index: number;
}

@Component({
  selector: 'ista-daytona-open-street-map',
  templateUrl: './osm.component.html',
})
export class OSMComponent implements AfterViewInit, OnChanges {
  @Input() public landmarks: Landmarks[] = [];
  @Input() public zoom = '2';
  @Input() public lat = '0';
  @Input() public lng = '0';
  @Input() public markLandmark!: MarkerIndex;
  @Input() public unmarkLandmark!: MarkerIndex;
  @ViewChild('osm') osmElement!: ElementRef<HTMLElement>;

  private map?: OSM.Map | undefined;
  private marker: OSM.Marker[] = [];
  private featureGroup: OSM.FeatureGroup = OSM.featureGroup([]);
  private imgChilds!: HTMLCollection;

  constructor(private readonly renderer: Renderer2) {}

  ngAfterViewInit(): void {
    this.map = OSM.map('osm', {
      center: { lat: Number(this.lat), lng: Number(this.lng) },
      zoom: 17,
    });

    const tiles = OSM.tileLayer(OSMSettings.osmTileLayer, {
      maxZoom: 18,
      minZoom: 3,
      attribution: OSMSettings.attribution,
    });

    this.map.on('zoomend', () => this.createBadgePane());
    this.map.on('zoomstart', () => this.deleteBadgePane());
    this.map.on('moveend', () => this.createBadgePane());

    tiles.addTo(this.map);
    OSM.control.scale().addTo(this.map);
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['landmarks']?.currentValue.length === 0) {
      this.resetMap();
    } else if (changes['landmarks'] !== undefined) {
      this.createOSMMarker(changes['landmarks'].currentValue);
    } else if (changes['markLandmark'] !== undefined) {
      const marker = changes['markLandmark'].currentValue as MarkerIndex;

      this.handleMarkerIconChange(marker.index, true);
    } else if (changes['unmarkLandmark'] !== undefined) {
      const marker = changes['unmarkLandmark'].currentValue as MarkerIndex;

      this.handleMarkerIconChange(marker.index, false);
    } else if (this.map) {
      this.map.setZoom(changes['zoom'] !== undefined ? changes['zoom'].currentValue : this.zoom);
      this.setCenterProperties(
        changes['lat'] !== undefined ? changes['lat'].currentValue : this.lat,
        changes['lng'] !== undefined ? changes['lng'].currentValue : this.lng
      );
    }
  }

  handleMarkerIconChange(index: number, useHightlight: boolean): void {
    if (index >= 0) {
      if (this.imgChilds && this.imgChilds[index]) {
        this.changeMarkerIcon(index, useHightlight);
      }
    } else {
      Array.from(this.imgChilds).forEach((child, i) => this.changeMarkerIcon(i, false));
    }
  }

  changeMarkerIcon(index: number, useHightlight: boolean): void {
    const icon = `/assets/icons/marker-icon${useHightlight ? '-red' : ''}.png`;

    this.imgChilds[index].setAttribute('src', icon);
  }

  private deleteBadgePane(): void {
    const parent = this.osmElement.nativeElement.getElementsByClassName(
      'leaflet-pane leaflet-map-pane'
    )[0];
    const existsChild = parent.getElementsByClassName('leaflet-pane einsundeins-badge-pane');

    if (existsChild) {
      Array.from(existsChild).forEach((child) => this.renderer.removeChild(parent, child));
    }
  }

  private createBadgePane(): void {
    const parent = this.osmElement.nativeElement.getElementsByClassName(
      'leaflet-pane leaflet-map-pane'
    )[0];

    this.imgChilds = this.osmElement.nativeElement.getElementsByClassName(
      'leaflet-pane leaflet-marker-pane'
    )[0]?.children;

    this.deleteBadgePane();

    // create new badge pane
    const parentDiv = this.renderer.createElement('div') as HTMLElement;

    this.renderer.addClass(parentDiv, 'leaflet-pane');
    this.renderer.addClass(parentDiv, 'einsundeins-badge-pane');
    const imgChildsCount = Array.from(this.imgChilds).length;

    for (let i = 0; i < imgChildsCount; i++) {
      const styles = getComputedStyle(this.imgChilds[i]);
      const childDiv = this.renderer.createElement('div') as HTMLElement;

      this.renderer.setStyle(childDiv, 'marginLeft', styles.marginLeft);
      this.renderer.setStyle(childDiv, 'marginTop', styles.marginTop);
      this.renderer.setStyle(childDiv, 'transform', styles.transform);
      this.renderer.setStyle(childDiv, 'zIndex', styles.zIndex);
      this.renderer.setStyle(childDiv, 'width', styles.width);
      this.renderer.setStyle(childDiv, 'height', styles.height);
      this.renderer.setStyle(childDiv, 'display', 'block');
      this.renderer.addClass(childDiv, 'badge');

      const childPar = this.renderer.createElement('p') as HTMLElement;

      childPar.textContent = `${i + 1}`;

      this.renderer.appendChild(childDiv, childPar);
      this.renderer.appendChild(parentDiv, childDiv);
    }
    this.renderer.appendChild(parent, parentDiv);
  }

  private createPopup(landmark: { name: string }): string {
    return `<div>${landmark.name}</div>`;
  }

  private setCenterProperties(lat: string, lng: string) {
    this.map?.setView({ lat: Number(lat), lng: Number(lng) });
  }

  private resetMap() {
    this.map?.removeLayer(this.featureGroup);
    this.marker = [];
    this.featureGroup = OSM.featureGroup([]);
  }

  private createOSMMarker(landmarks: Landmarks[]): void {
    this.removeMarkers();
    setTimeout(() => {
      landmarks.forEach((landmark: any) => {
        if (Number(landmark.lat) !== 0 && Number(landmark.lng) !== 0) {
          const newMarker = OSM.marker([landmark.lat, landmark.lng])
            .bindPopup(this.createPopup(landmark))
            .openPopup();

          this.marker.push(newMarker);
        }
      });

      if (this.marker.length > 0) {
        this.featureGroup = OSM.featureGroup(this.marker);
        this.map?.addLayer(this.featureGroup).fitBounds(this.featureGroup.getBounds());
      }
    }, 100);
  }

  private removeMarkers(): void {
    if (this.featureGroup) {
      this.map?.removeLayer(this.featureGroup);
      this.marker = [];
    }
  }
}
