import { Injectable } from '@angular/core';
import { UserSettingsHttpService } from './user-settings-http.service';
import { Observable, tap } from 'rxjs';
import { UserSettings } from './interfaces';
import { Store } from '@ngrx/store';
import { RootState } from 'app/+state/app.reducer';
import { updateUserSettings } from '../+state/core.actions';

@Injectable({
  providedIn: 'root',
})
export class UserSettingsService {
  constructor(
    private userSettingsHttpService: UserSettingsHttpService,
    private readonly ngrxStore: Store<RootState>
  ) {}

  getUserSettingsAndUpdateStore(): Observable<UserSettings> {
    return this.userSettingsHttpService.getUserSettings().pipe(
      tap((settings) =>
        this.ngrxStore.dispatch(
          updateUserSettings({
            settings,
          })
        )
      )
    );
  }

  updateUserSettingsAndUpdateStore(settings: UserSettings): Observable<UserSettings> {
    return this.userSettingsHttpService.updateUserSettings(settings).pipe(
      tap((settings) =>
        this.ngrxStore.dispatch(
          updateUserSettings({
            settings,
          })
        )
      )
    );
  }
}
