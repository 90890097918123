import { ValueBarDiagnoseCard } from '../ui/value-bar-diagnose-card/value-bar-diagnose-card.interface';
import { Unit } from '../models/unit.enum';
import { LanguageKeyHelper } from '../helper/language-key-helper';
import { MedianCustomIcons } from 'shared/helper/icon-helper';

export const heatingAndHotWater: ValueBarDiagnoseCard = {
  label: LanguageKeyHelper.get('heatingAndHotWater') || 'Wärme',
  value: 0,
  unit: Unit.KWH,
  icon: MedianCustomIcons['heatingAndHotWater'],
  bars: [],
  colors: ['bg-heating-500/50', 'bg-heating-500/20'],
};

export const electricity: ValueBarDiagnoseCard = {
  label: LanguageKeyHelper.get('electricity') || 'Strom',
  value: 0,
  unit: Unit.KWH,
  icon: MedianCustomIcons['electricity'],
  bars: [],
  colors: ['bg-electricity-500/50', 'bg-electricity-500/20'],
};

export const water: ValueBarDiagnoseCard = {
  label: LanguageKeyHelper.get('water') || 'Wasser',
  value: 0,
  unit: Unit.KG,
  icon: MedianCustomIcons['water'],
  bars: [],
  colors: ['bg-water-500/50', 'bg-water-500/20'],
};

export const waste: ValueBarDiagnoseCard = {
  label: LanguageKeyHelper.get('waste') || 'Abfall',
  value: 0,
  unit: Unit.KG,
  icon: MedianCustomIcons['waste'],
  bars: [],
  colors: ['bg-waste-500/50', 'bg-waste-500/20'],
};

export const emissions: ValueBarDiagnoseCard = {
  label: LanguageKeyHelper.get('co2Emissions') || 'CO₂e Kosten',
  value: 0,
  unit: Unit.EUR,
  icon: MedianCustomIcons['co2'],
  bars: [],
  colors: ['bg-co2-500/50', 'bg-co2-500/20'],
};

export const costs: ValueBarDiagnoseCard = {
  label: LanguageKeyHelper.get('costs') || 'Kosten',
  value: 0,
  unit: Unit.EUR,
  icon: MedianCustomIcons['cost'],
  bars: [],
  colors: ['bg-cost-500/50', 'bg-cost-500/20'],
};
export const AllValueBarDiagnoseCardSkeleton: Record<string, ValueBarDiagnoseCard> = {
  heatingAndHotWater,
  electricity,
  water,
  waste,
  emissions,
  costs,
};

export const consumptionDashboardValueBarDiagnoseCardSkeleton: Record<
  string,
  ValueBarDiagnoseCard
> = {
  heatingAndHotWater,
  electricity,
  water,
  waste,
};

export const emissionDashboardValueBarDiagnoseCardSkeleton: Record<string, ValueBarDiagnoseCard> = {
  heatingAndHotWater,
  electricity,
  waste,
};
