import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'ista-daytona-card-headline',
  templateUrl: 'card-headline.component.html',
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [TranslateModule],
})
export class CardHeadlineComponent {
  @Input({ required: true }) label!: string;
  @Input() customClass?: string;
}
