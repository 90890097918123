import { Component, Input } from '@angular/core';
import { Store } from '@ngrx/store';
import { NgxFileDropEntry } from 'ngx-file-drop';
import { combineLatest, first, Subject, takeUntil } from 'rxjs';

import { EnvironmentService } from '../../../core/environment/environment.service';
import { updateBenchmarkingData } from '../../../features/benchmarking/+state/benchmarking.actions';
import { BenchmarkingState } from '../../../features/benchmarking/+state/benchmarking.reducer';
import { updateDashboardData } from '../../../features/dashboard/+state/dashboard.actions';
import { DashboardState } from '../../../features/dashboard/+state/dashboard.reducer';
import { updatePortfolioData } from '../../../features/portfolio/+state/portfolio.actions';
import { PortfolioState } from '../../../features/portfolio/+state/portfolio.reducer';
import { DestroyAbstractService } from '../../helper/destroy-abstract.service';
import { HelperService } from '../../helper/helper.service';
import { OverlayService } from '../overlay/overlay.service';
import { EFileResponse } from './efile-response.interface';
import { ExcelFileResponse } from './excelfile-response.interface';
import { FileUpload, FileUploadHistory, FileUploadType } from './file-upload.model';
import { FileUploadService } from './file-upload.service';
import { FileUploadResponse } from './fileupload-response.interface';
import { resetTags } from '../../../core/+state/core.actions';
import { RootState } from '../../../+state/app.reducer';


@Component({
  selector: 'ista-daytona-file-upload',
  templateUrl: './file-upload.component.html',
  styleUrls: ['./file-upload.component.scss'],
})
export class FileUploadComponent extends DestroyAbstractService {
  @Input() showExcelDataImportCard = false;
  @Input() showFilesDataImportCard = false;
  public files: NgxFileDropEntry[] = [];
  public fileUploadResponse!: FileUploadResponse<ExcelFileResponse | EFileResponse>;
  public fileToUpload!: File | null | undefined;
  public isFileHover = false;
  public actualStep = 1;
  public fileUpload!: FileUpload;
  public fileHistory: FileUploadHistory[] = [];
  public readonly uploadTitle = 'Daten Upload';
  private readonly maxSteps = 5;
  private readonly minDisplayTime = 4000;
  private timerFinished$ = new Subject<null>();
  private uploadFinished$ = new Subject<null>();
  private abortUploadRequest$ = new Subject<null>();
  private abortTimerUpload$ = new Subject<null>();

  constructor(
    private readonly fileUploadService: FileUploadService,
    private readonly overlayService: OverlayService,
    private readonly dashboardStore: Store<DashboardState>,
    private readonly portfolioStore: Store<PortfolioState>,
    private readonly benchmarkingStore: Store<BenchmarkingState>,
    private readonly envService: EnvironmentService,
    private ngrxStore: Store<RootState>,
  ) {
    super();
  }

  public get title(): string {
    if (this.actualStep === 3) {
      return (
        this.uploadTitle +
        (this.fileUpload.type === FileUploadType.EFILE ? ': E-Datensätze' : ': Excel Daten')
      );
    }
    return this.uploadTitle;
  }

  public nextStep(): void {
    this.actualStep++;
    if (
      this.fileUpload?.type === FileUploadType.EXCEL &&
      this.actualStep === 3 &&
      (this.fileUploadResponse?.response as ExcelFileResponse)?.importWasSuccessful
    ) {
      this.actualStep = 5;
    }

    if (this.actualStep === 2 || this.actualStep === 4) {
      this.abortTimerUpload$.next(null);
      this.startTimerAndDownload();
    } else if (this.actualStep > this.maxSteps) {
      this.closeWindow();
    }
  }

  public gotoStart(): void {
    this.abortUploadRequest$.next(null);
    this.actualStep = 1;
  }

  public closeWindow(): void {
    this.abortUploadRequest$.next(null);
    this.abortTimerUpload$.next(null);
    this.overlayService.close();
  }

  public fileOver(): void {
    this.isFileHover = true;
  }

  public fileLeave(): void {
    this.isFileHover = false;
  }

  public loadErrorReport(): void {
    const csv: string[] = [];
    const eFileResponse = this.fileUploadResponse.response as unknown as EFileResponse;
    eFileResponse.parsedAndValidatedFiles.forEach((item) => {
      let row = `${item.fileName}\n`;
      let addRow = false;
      if (item.generalValidationHints.length > 0) {
        addRow = true;
        item.generalValidationHints.forEach((hint) => {
          row += `${hint.validationHintCode}\n`;
        });
      }

      if (item.detectedBillings.length > 0) {
        item.detectedBillings.forEach((billing) => {
          if (billing.validationHints.length > 0) {
            addRow = true;
            billing.validationHints.forEach((hint) => {
              row += `${hint.affectedLineNumber ?? ''}:${hint.affectedPosition ?? ''} ${
                hint.validationHintCode
              }\n`;
            });
          }
        });
      }

      if (addRow) {
        csv.push(row);
      }
    });

    HelperService.createCSVWindowBlob(csv.join('\n'), 'fehlerreport-esatz.txt');
  }

  public upload(fileUpload: FileUpload, dryRun: boolean = true): void {
    this.nextStep();

    if (fileUpload.type === FileUploadType.EFILE) {
      fileUpload.formData.set('dryRun', !!dryRun ? 'true' : 'false');
      fileUpload.formData.set('allowPartialImport', 'true');
    }
    this.fileUpload = fileUpload;

    setTimeout(() => this.timerFinished$.next(null), this.minDisplayTime);

    if (fileUpload.type === FileUploadType.EFILE) {
      this.fileUploadService
        .uploadEFile(fileUpload)
        .pipe(takeUntil(this._onDestroy$), takeUntil(this.abortUploadRequest$))
        .subscribe({
          next: (result) => {
            // this.fileHistory[historyIndex].status = result;
            this.fileUploadResponse = result;
            this.uploadFinished$.next(null);
            if (!dryRun) {
              this.update();
            }
          },
        });
    } else {
      this.fileUploadService
        .uploadExcelFile(fileUpload)
        .pipe(takeUntil(this._onDestroy$), takeUntil(this.abortUploadRequest$))
        .subscribe({
          next: (result) => {
            // this.fileHistory[historyIndex].status = result;
            this.fileUploadResponse = result;
            this.uploadFinished$.next(null);
            if (!!result.response.importWasSuccessful) {
              this.update();
            }
          },
        });
    }
  }

  public startTimerAndDownload(): void {
    combineLatest([this.timerFinished$, this.uploadFinished$])
      .pipe(
        first(),
        takeUntil(this._onDestroy$),
        takeUntil(this.abortUploadRequest$),
        takeUntil(this.abortTimerUpload$)
      )
      .subscribe(() => this.nextStep());
  }

  private async update(): Promise<void> {
    await this.envService.updateYearsAndGroups();
    this.dashboardStore.dispatch(updateDashboardData());
    this.portfolioStore.dispatch(updatePortfolioData());
    this.benchmarkingStore.dispatch(updateBenchmarkingData());
    this.ngrxStore.dispatch(resetTags());
  }
}
