<div class="flex flex-col gt-xs:flex-row p-2 min-w-52 select-wrapper">
  <mat-form-field
    *ngIf="selected !== undefined; else nothingSelected"
    class="flex-auto gt-xs:pl-3 h-12"
  >
    <mat-label *ngIf="title">{{ title }}</mat-label>

    <ng-container *ngIf="icon">
      <div
        class="w-14 mt-2 h-12 bg-gray-100 mr-4 -ml-4 border-r border-solid border-gray-300 flex justify-center items-center"
      >
        <mat-icon>{{ icon }}</mat-icon>
      </div>
    </ng-container>
    <mat-select
      (selectionChange)="setValue($event.value)"
      [(value)]="selected"
      [disabled]="disabled"
      class="mt-2"
    >
      <mat-option *ngFor="let item of items; trackby: trackbyEnvId" [value]="item.id" [title]="item.title ?? '' | translate"
      >{{ (item.translatedName ?? item.name) | translate }}
      </mat-option>
    </mat-select>
  </mat-form-field>
  <ng-template #nothingSelected>
    {{ 'common.select-placeholder' | translate }}
  </ng-template>
</div>
