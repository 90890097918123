<div
  [class.bg-white]="items.length"
  class="sticky-header-wrapper fixed top-0 -ml-8 min-h-18 z-999 flex items-center w-full"
>
  <div
    [class.w-main-navigation]="!showSubNavigationLayout"
    [ngClass]="{
      'w-sub-navigation': showSubNavigationLayout,
      'w-main-navigation-tablet navigation-lg:w-main-navigation': !showSubNavigationLayout,
    }"
    class="grid col-span-12 py-3 px-8"
  >
    <div class="grid col-start-1 col-end-10">
      <div class="flex items-center">
        <ista-daytona-breadcrumb-navigation [isSticky]="!!items.length"></ista-daytona-breadcrumb-navigation>
        <ng-container *ngFor="let item of items; trackBy: itemById">
          <ng-container [ngSwitch]="item.type">
            <ng-container *ngSwitchCase="StickyHeaderElementType.FILTER_TAB">
              <div class="inline-block">
                <ista-daytona-filter-tab
                  (clickedFilter)="outputWrapper($event, item, 'clickedFilter')"
                  [config]="getInputProp(item, 'config')"
                  [displayBorderBottom]="false"
                  [displayTimeSeries]="getInputProp(item, 'displayTimeSeries')"
                  [instanceId]="getInputProp(item, 'instanceId')"
                  [smallVersion]="getInputProp(item, 'smallVersion')"
                ></ista-daytona-filter-tab>
              </div>
            </ng-container>
            <ng-container *ngSwitchCase="StickyHeaderElementType.TAB">
              <ista-daytona-tab
                (selectedTabIndexChange)="outputWrapper($event, item, 'selectedTabIndexChange')"
                [defaultSelectedTabIndex]="getInputProp(item, 'defaultSelectedTabIndex')"
                [stickyStyle]="getInputProp(item, 'stickyStyle')"
                [stretch]="getInputProp(item, 'stretch')"
                [tabs]="getInputProp(item, 'tabs')"
              ></ista-daytona-tab>
            </ng-container>
            <ng-container *ngSwitchCase="StickyHeaderElementType.BUTTON_DATA_ADD">
              <ista-daytona-button-data-add
                [class]="getInputProp(item, 'class')"
                [stickyStyle]="getInputProp(item, 'stickyStyle')"
              ></ista-daytona-button-data-add>
            </ng-container>
            <ng-container *ngSwitchCase="StickyHeaderElementType.BUTTON_DELETE_OBJECT">
              <ista-daytona-button-delete-object
                [class]="getInputProp(item, 'class')"
                [icon]="getInputProp(item, 'icon')"
                [stickyStyle]="getInputProp(item, 'stickyStyle')"
              ></ista-daytona-button-delete-object>
            </ng-container>
            <ng-container *ngSwitchCase="StickyHeaderElementType.H1">
              <h1 [class]="getInputProp(item, 'class')" class="text-base-900 text-xl">
                {{ item.innerHtml }}
              </h1>
            </ng-container>
            <ng-container *ngSwitchCase="StickyHeaderElementType.H2">
              <h2 [class]="getInputProp(item, 'class')" class="text-base-900 text-xl">
                {{ item.innerHtml }}
              </h2>
            </ng-container>
            <ng-container *ngSwitchDefault>
              <div [class]="getInputProp(item, 'class')">
                {{ item?.innerHtml }}
              </div>
            </ng-container>
          </ng-container>
        </ng-container>
      </div>
    </div>
    <div class="grid col-start-11 col-end-12">
      <div class="flex items-center">
        <ng-content class="ml-auto"></ng-content>
      </div>
    </div>
  </div>
</div>
