import { Component, Input } from '@angular/core';
import {
  BubbleDataPoint,
  ChartConfiguration,
  ChartDataset,
  ChartType,
  ChartTypeRegistry,
  ScatterDataPoint,
} from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';

import { BenchmarkingEmissionBalance } from 'app/features/benchmarking/benchmarking.interface';
import { ColorHelper } from 'shared/helper/color-helper';
import { UnitWithPrefix } from 'shared/models/unit.enum';
import { AbstractChartComponent } from '../abstract-chart.component';
import { BarChartOptions } from '../bar-chart/bar-chart-options';
import { BenchmarkingChartHelperService } from './benchmarking-chart-helper.service';

/* eslint-disable @typescript-eslint/member-ordering */
@Component({
  selector: 'ista-daytona-benchmarking-chart',
  templateUrl: './benchmarking-chart.component.html',
  styleUrls: ['./benchmarking-chart.component.scss'],
})
export class BenchmarkingChartComponent extends AbstractChartComponent {
  @Input() labels: string[] = [];
  public barChartData!: ChartConfiguration['data'];
  public barChartOptions = BarChartOptions;
  public barType: ChartType = 'bar';
  public chartPlugins = [ChartDataLabels];
  public overwriteUnit = UnitWithPrefix.EMISSION_KG_PER_SQM;
  private averageLabels: string[] = [
    'benchmarking.legend-portfolio',
    'benchmarking.legend-marktportfolio',
  ];

  constructor() {
    super();
  }

  private _benchmarkingEmissionData!: BenchmarkingEmissionBalance;

  get benchmarkingEmissionData(): BenchmarkingEmissionBalance {
    return this._benchmarkingEmissionData;
  }

  @Input() set benchmarkingEmissionData(benchmarkingEmissionData: BenchmarkingEmissionBalance) {
    this._benchmarkingEmissionData = benchmarkingEmissionData;
    if (benchmarkingEmissionData) {
      this.transformPotsToDatasets(benchmarkingEmissionData);
    }
  }

  public override _refreshData(): void {
    super._refreshData();
    // this.barChartData = ChartHelperService.getChartData(
    //   this.transformedData[0]
    // );
  }

  public getTransformedBarChartData(): ChartConfiguration['data'] {
    return {
      labels: this?.barChartData?.labels,
      datasets: Array.isArray(this?.barChartData?.datasets)
        ? [...this?.barChartData?.datasets].slice(2)
        : [],
    };
  }

  private transformPotsToDatasets(benchmarkingEmissionData: BenchmarkingEmissionBalance): void {
    let labels: string[] = [];
    const potsLen = benchmarkingEmissionData.benchmark.pots.length;
    const dataMarker: any[] = [];

    const datasets: ChartDataset<
      keyof ChartTypeRegistry,
      (number | ScatterDataPoint | BubbleDataPoint | null)[]
    >[] = [];
    // Reverse they order of keys, since the comparison pool should be shown first
    const objectKeys = Object.keys(benchmarkingEmissionData).reverse();
    objectKeys.forEach((emissionIndex, index) => {
      if (labels.length <= 0) {
        labels = BenchmarkingChartHelperService.getXLabels(benchmarkingEmissionData, emissionIndex);
      }

      datasets.push({
        label: this.labels[index] || '',
        categoryPercentage: 0.5,
        barPercentage: 0.8,
        maxBarThickness: 16,
        backgroundColor: ColorHelper.get('barChart-column-' + (index + 1).toString()) || 'blue',
        data: benchmarkingEmissionData[emissionIndex]?.pots?.map((item) => item.share),
      });

      const potIndex = BenchmarkingChartHelperService.getPotIndex(
        benchmarkingEmissionData,
        emissionIndex
      );
      const averageValue = benchmarkingEmissionData[emissionIndex]?.average?.value;

      if (potIndex >= 0) {
        const dataMarkerItem = Array(potsLen).fill(averageValue);
        dataMarker.push(dataMarkerItem);
      }
    });

    dataMarker.forEach((item, index) => {
      datasets.push({
        label: this.averageLabels[index],
        data: item as number[],
        fill: false,
        borderWidth: 0, // todo: temporarily set to 0. Replace this by some X-Axis marker.
        pointRadius: 0,
        type: 'line',
        backgroundColor: ColorHelper.get('barChart-column-' + (index + 1).toString()) || 'blue',
        borderColor: ColorHelper.get('barChart-column-' + (index + 1).toString()) || 'blue',
      });
    });

    this.barChartData = {
      labels,
      datasets,
    };
  }
}
