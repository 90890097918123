import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { IconValueLabel } from './icon-value-label.interface';
import { TranslateModule } from '@ngx-translate/core';
import { PipesModule } from '../../pipes/pipes.module';
import { DecimalPipe, NgClass } from '@angular/common';
import { Utils } from '../../helper/utils';
import { MatIcon } from '@angular/material/icon';

@Component({
  selector: 'ista-daytona-icon-value-label',
  templateUrl: 'icon-value-label.component.html',
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [TranslateModule, PipesModule, DecimalPipe, MatIcon, NgClass],
})
export class IconValueLabelComponent {
  @Input({ required: true }) item?: IconValueLabel;

  getFormatNumberDigits(value?: string | number | null): string {
    return Utils.getFormatNumberDigits(value);
  }
}
