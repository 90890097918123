import { ConsumptionInfo } from 'app/features/portfolio/portfolio-consumption/consumption.interface';
import { ChartHelper } from 'shared/helper/chart-helper';
import { ColorHelper } from 'shared/helper/color-helper';
import { IconHelper } from 'shared/helper/icon-helper';
import { LanguageKeyHelper } from 'shared/helper/language-key-helper';
import { AnalyticalCategories } from 'shared/interfaces/consumption-type-button.interface';
import { ConsumptionTypes } from 'shared/models/consumption-types.interface';
import { ChartItem, ChartItemData } from 'shared/ui/chart/chart.interface';

export class PortfolioChartMapper {
  static getPortfolioConsumptionTransformedBarChart(
    consumption: ConsumptionInfo,
    portfolioType: AnalyticalCategories,
    getRequiredAdditionalDataKeyDependsOnPortfolioType: (
      portfolioType: AnalyticalCategories,
      childPropKey: string
    ) => string
  ): ChartItem[] {
    try {
      let isActive = true;
      const result: ChartItem[] = [];

      for (const year in consumption) {
        if (year) {
          Object.keys(consumption[year][portfolioType]?.valuesByCategory ?? {}).forEach((key) => {
            const findIndex = result.findIndex((item) => item.value === key);
            const requiredAdditionalDataKey = getRequiredAdditionalDataKeyDependsOnPortfolioType(
              portfolioType,
              key
            );

            const data: ChartItemData = {
              year,
              values: consumption[year][portfolioType]?.valuesByCategory[key as ConsumptionTypes],
              additionalValues:
                consumption[year][portfolioType]?.valuesByCategory[key as ConsumptionTypes]
                  ?.numberOfAffectedObjects,
              requiredAdditionalData: requiredAdditionalDataKey
                ? consumption[year][portfolioType]?.valuesByCategory[
                    requiredAdditionalDataKey as ConsumptionTypes
                  ]
                : undefined,
            };

            if (findIndex >= 0) {
              result[findIndex].data.push(data);
            } else {
              const color = ColorHelper.get(key) || ColorHelper.get(key + '-full') || '';
              result.push({
                label: LanguageKeyHelper.get(key) || key,
                value: key,
                active: isActive,
                color,
                icon: IconHelper.get(key) || '',
                data: [data],
              });
            }

            if (isActive) {
              isActive = false;
            }
          });
        }
      }

      return result;
    } catch (e) {
      console.error('getPortfolioConsumptionTransformedBarChart', e);
      return [];
    }
  }

  static getPortfolioMediaDistributionTransformedChart(
    consumption: ConsumptionInfo,
    portfolioType: AnalyticalCategories
  ): ChartItem[] {
    try {
      let isActive = true;
      const result: ChartItem[] = [];

      for (const year in consumption) {
        if (year) {
          Object.keys(consumption[year][portfolioType]?.sharesOfHeating?.shares ?? {}).forEach(
            (resultType) => {
              const findIndex = result.findIndex((item) => item.value === resultType);

              const data: ChartItemData = {
                year,
                values: consumption[year][portfolioType]?.sharesOfHeating?.shares[resultType],
              };

              if (findIndex >= 0) {
                result[findIndex].data.push(data);
              } else {
                const color = ChartHelper.getColor(resultType) || '';

                result.push({
                  label: LanguageKeyHelper.get(resultType) || resultType,
                  value: resultType,
                  active: isActive,
                  color,
                  icon: IconHelper.get(resultType) || '',
                  data: [data],
                });
              }

              if (isActive) {
                isActive = false;
              }
            }
          );
        }
      }

      return result;
    } catch (e) {
      console.error('getPortfolioMediaDistributionTransformedChart', e);
      return [];
    }
  }
}
