import { Component, EventEmitter, Input, Output } from '@angular/core';

import { ButtonType } from '../../basic/button/button-type.enum';
import { FileUploadFunctionsService } from '../file-upload-functions.service';

@Component({
  selector: 'ista-daytona-file-upload-step-finished',
  templateUrl: './file-upload-step-finished.component.html',
  styleUrls: ['./file-upload-step-finished.component.scss'],
})
export class FileUploadStepFinishedComponent extends FileUploadFunctionsService {
  @Input() isSuccessful = true;
  @Output() abortUploading = new EventEmitter<null>();
  @Output() loadErrorReport = new EventEmitter<null>();
  public buttonType = ButtonType;
}
