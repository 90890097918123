import { inject } from '@angular/core';
import { HelperService } from 'shared/helper/helper.service';
import { ValueItem } from 'shared/models/value-item.interface';
import { FormatNumberPipe } from 'shared/pipes/format-number.pipe';

import { TransformedValueItem, Unit } from '../../../models/unit.enum';
import { TableValueType } from './table-type.interface';
import { TableRow } from './table.interface';
import { nullValueDisplay } from 'core/constants';

/* eslint-disable @typescript-eslint/member-ordering */

export class TableBaseComponent {
  private readonly formatNumberPipe!: FormatNumberPipe;

  constructor() {
    this.formatNumberPipe = inject(FormatNumberPipe);
  }

  public getCreatedValueItem(value: null | string | number | ValueItem | undefined): ValueItem {
    const result: ValueItem = {
      value: null,
      unit: Unit.DEFAULT,
    };
    if (value === null || value === undefined) {
      return result;
    }

    if (typeof value === 'string') {
      result.value = parseFloat(value);
    } else if (typeof value === 'number') {
      result.value = value;
    } else if (value !== null) {
      result.value = value?.value;
      result.unit = value?.unit;
    }

    return result;
  }

  public getFormattedValue(
    value: string | number | ValueItem | TransformedValueItem | null | undefined
  ): string | null {
    if (value === null || value === undefined) {
      return '';
    }

    if (typeof value === 'string') {
      return value;
    }

    if (typeof value === 'number') {
      return (this.formatNumberPipe.transform(value)?.value || '')?.toString();
    }

    if (!value?.value || value.value === null) {
      return nullValueDisplay;
    }

    if (this.isValueItem(value)) {
      return `<span class="value">${
        this.formatNumberPipe.transform(value?.value)?.value?.toString() || ''
      }</span> <span class="unit">${HelperService.getUnitEnumValue(value.unit || '')}</span>`;
    }

    return `<span class="value">${
      this.formatNumberPipe.transform(value?.value)?.value?.toString() || ''
    }</span> <span class="unit">${value?.unitWithPrefix || ''}</span>`;
  }

  public trackById(index: number, item: TableRow): string {
    return item.columns[0].value as string;
  }

  public getTableValueType(value: unknown): TableValueType {
    if (value === null || value === undefined || value === 'na') {
      return 'na';
    }

    if (this.isValueItem(value as ValueItem)) {
      return 'valueItem';
    }

    if (typeof value === 'string' || typeof value === 'number') {
      return 'single';
    }

    return 'na';
  }

  private isValueItem(value: ValueItem | TransformedValueItem): value is ValueItem {
    return (value as ValueItem).unit !== undefined;
  }
}
