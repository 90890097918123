import { NgModule } from '@angular/core';
import { AddGroupComponent } from './add-group/add-group.component';
import { GroupsTreeNavigationComponent } from './groups-tree-navigation.component';
import { MatTreeModule } from '@angular/material/tree';
import { TranslateModule } from '@ngx-translate/core';
import { MatIconModule } from '@angular/material/icon';
import { AsyncPipe, NgClass, NgForOf, NgIf } from '@angular/common';
import { MatInputModule } from '@angular/material/input';
import { ReactiveFormsModule } from '@angular/forms';
import { MatMenuModule } from '@angular/material/menu';
import { MatDividerModule } from '@angular/material/divider';
import { EditingItemOptionsComponent } from 'core/groups-tree-navigation/editing-item-options/editing-item-options.component';
import { DeleteConfirmDialogComponent } from 'core/groups-tree-navigation/delete-confirm-dialog/delete-confirm-dialog.component';
import { UiModule } from 'shared/ui/ui.module';
import { MatDialogModule } from '@angular/material/dialog';
import { DeleteRevertDialogComponent } from 'core/groups-tree-navigation/delete-revert-dialog/delete-revert-dialog.component';
import { DragDirective } from 'core/groups-tree-navigation/directive/drag.directive';
import { MatTooltipModule } from '@angular/material/tooltip';
import { SiteItemComponent } from 'core/groups-tree-navigation/site-item/site-item.component';

@NgModule({
  declarations: [
    AddGroupComponent,
    GroupsTreeNavigationComponent,
    EditingItemOptionsComponent,
    DeleteConfirmDialogComponent,
    DeleteRevertDialogComponent,
    DragDirective,
    SiteItemComponent,
  ],
  exports: [GroupsTreeNavigationComponent],
  imports: [
    MatTreeModule,
    TranslateModule,
    MatIconModule,
    NgIf,
    MatInputModule,
    NgClass,
    NgForOf,
    ReactiveFormsModule,
    MatMenuModule,
    MatDividerModule,
    UiModule,
    MatDialogModule,
    MatTooltipModule,
    AsyncPipe,
  ],
})
export class GroupsTreeNavigationModule {}
