import { Component, Input } from '@angular/core';

import { LoaderType } from './loader.interface';
import { BarComponent } from 'shared/ui/basic/loader/bar/bar.component';
import { CircleComponent } from 'shared/ui/basic/loader/circle/circle.component';
import { NgSwitch, NgSwitchCase } from '@angular/common';
import { ProgressBarMode } from '@angular/material/progress-bar';
import { ProgressSpinnerMode } from '@angular/material/progress-spinner';

@Component({
  selector: 'ista-daytona-loader',
  templateUrl: './loader.component.html',
  imports: [
    BarComponent,
    CircleComponent,
    NgSwitch,
    NgSwitchCase,
  ],
  standalone: true,
})
export class LoaderComponent {
  @Input() type = LoaderType.CIRCLE;
  @Input() label = '';
  @Input() color = 'primary';
  @Input() value = '0';
  @Input() diameter = '128';
  @Input() spinnerMode: ProgressSpinnerMode = 'indeterminate';
  @Input() SpinnerSize = '128';
  @Input() barMode: ProgressBarMode = 'indeterminate';
  @Input() barSize = '';
  public loaderType = LoaderType;
}
