import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  forwardRef,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';

import { BaseInputComponent } from '../../base-input/base-input.component';
import { SelectItem } from './select.interface';
import { TranslateService } from '@ngx-translate/core';
import { Subject, takeUntil } from 'rxjs';

@Component({
  selector: 'ista-daytona-select',
  templateUrl: './select.component.html',
  styleUrls: ['./select.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      // eslint-disable-next-line no-use-before-define
      useExisting: forwardRef(() => SelectComponent),
      multi: true,
    },
  ],
})
export class SelectComponent extends BaseInputComponent<SelectItem> implements OnInit, OnDestroy {
  @Input() items: SelectItem[] = [];
  @Input() title = '';
  @Input() selected: number | undefined;
  @Input() icon = '';
  @Input() disabled = false;
  @Output() selectedItemChange = new EventEmitter<SelectItem>();
  private destroy$ = new Subject<void>();

  constructor(private translateService: TranslateService, private readonly cdr: ChangeDetectorRef) {
    super();
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  ngOnInit(): void {
    // currently there is a bug, MatSelect doesn't work as expected with NgxTranslate Pipe
    // therefore I implemented a Workaround for the Labels
    this.translateService.onLangChange.pipe(takeUntil(this.destroy$)).subscribe(() => {
      this.items = this.items.map((item) => ({
        ...item,
        translatedName: this.translateService.instant(item.name) as string,
      }));
    });
  }

  public trackbyEnvId(index: number, item: SelectItem): number {
    return item.id;
  }

  public setValue(itemId: number): void {
    this.value = this.items?.find((item) => item.id === itemId) || this.items[0];
    this.selectedItemChange.emit(this.value);
  }
}
