import { ChartItem } from '../chart.interface';
import { DoughnutChart, DoughnutChartDataset } from '../doughnut-chart/doughnut-chart.interface';
import { ColorHelper } from 'shared/helper/color-helper';

export class BarChartMapper {
  static chartItemToDoughnutChart(data: ChartItem): DoughnutChart {
    try {
      let datasetValue: DoughnutChartDataset = {
        data: [],
        backgroundColor: [],
        hoverBackgroundColor: [],
        borderWidth: 0,
        barThickness: 24,
      };
      let labels: string[] = [];
      if (Array.isArray(data?.data)) {
        datasetValue = data.data.reduce(
          (previousValue, currentValue) => ({
            ...previousValue,
            data: [...previousValue.data, currentValue.values?.value || 0],
            backgroundColor: [...previousValue.backgroundColor, ColorHelper.get('co2-full') || ''],
            hoverBackgroundColor: [
              ...(previousValue.hoverBackgroundColor ?? []),
              ColorHelper.get('co2-full') || '',
            ],
          }),
          datasetValue
        );
        labels = data.data.map((item) => item.year);
      }

      return {
        datasets: [datasetValue],
        labels,
      };
    } catch (e) {
      console.error('chartItemToDoughnutChart', e);
      return {
        datasets: [],
        labels: [],
      };
    }
  }
}
