export enum FileUploadStatus {
  READY = 'Ready',
  UPLOADING = 'Uploading',
  ERROR = 'Error',
  SUCCESSFUL = 'successful',
}

export enum FileUploadType {
  EXCEL = 'excel',
  EFILE = 'efile',
}

export interface FileUpload {
  formData: FormData;
  type: FileUploadType;
}

export interface FileUploadHistory {
  file: FileUpload;
  status: FileUploadStatus;
}

export interface FileUploadResult {
  uploadError: boolean;
  importWasSuccessful: boolean;
  rowResults: any[];
}

export enum FileUploadValidStatus {
  VALID = 'valid',
  INVALID = 'invalid',
  INVALD_TO_UPLOAD = 'invalid_to_upload',
}
