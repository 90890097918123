import { booleanAttribute, Component, EventEmitter, Input, Output } from '@angular/core';
import { NgClass } from '@angular/common';
import { MatCheckbox } from '@angular/material/checkbox';
import { ThemePalette } from '@angular/material/core';
import { PipesModule } from 'shared/pipes/pipes.module';

@Component({
  selector: 'ista-daytona-checkbox',
  templateUrl: './checkbox.component.html',
  imports: [NgClass, MatCheckbox, PipesModule],
  standalone: true,
})
export class CheckboxComponent {
  @Input() dataQa = '';
  @Input() label = '';
  @Input() color: ThemePalette = 'primary';
  @Input({ transform: booleanAttribute }) indeterminate = false;
  @Input({ transform: booleanAttribute }) isChecked = false;
  @Input({ transform: booleanAttribute }) isDisabled = false;
  @Output() update = new EventEmitter<boolean>();

  public change(value: boolean): void {
    this.update.emit(value);
  }
}
