import { ComponentPortal } from '@angular/cdk/portal';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

import { TrackingWrapperService } from 'core/tracking/tracking-wrapper.service';
import { OverlayComponent } from '../../overlay/overlay.component';
import { FilterTab } from '../filter-tab.interface';
import { FilterTabItem } from './../filter-tab.interface';

/* eslint-disable @typescript-eslint/member-ordering */
@Component({
  selector: 'ista-daytona-filter-page',
  templateUrl: './filter-page.component.html',
  styleUrls: ['./filter-page.component.scss'],
})
export class FilterPageComponent implements OnInit {
  get config(): FilterTab {
    return this._config;
  }
  @Input() set config(value: FilterTab) {
    this._config = { ...value };
    this.setActiveTabs();
  }
  @Input() displayDataImport = false;
  @Input() displayDeleteObject = false;
  @Input() displayEditData = false;
  @Output() clickedFilter = new EventEmitter<FilterTab>();
  public activeTabs: FilterTab = {
    left: [],
    selectedYear: { from: '', until: '' },
  };
  public guestPortal!: ComponentPortal<OverlayComponent>;
  private _config!: FilterTab;

  constructor(private readonly trackingService: TrackingWrapperService) {}

  ngOnInit(): void {
    this.guestPortal = new ComponentPortal(OverlayComponent);
  }

  public setActiveLeft(item: FilterTabItem): void {
    this.clickedFilter.emit({
      left: [{ value: item.value, label: item.label }],
      selectedYear: { from: '', until: '' },
    });
    this.activeTabs.left = [item];
    this.trackingService.addGTM(
      {
        eventCategory: 'elementClick',
        eventLabel: item.value || item.label,
        eventAction: 'tabNavigationClick',
      },
      [item.value || item.label]
    );
  }

  private setActiveTabs(): void {
    this.activeTabs.left = [];
    this._config.left.forEach((item) => {
      if (!!item.active) {
        this.activeTabs.left = [item];
        // this.trackingService.addGTM(
        //   {
        //     eventCategory: 'elementClick',
        //     eventLabel: item.value || item.label,
        //     eventAction: 'tabNavigationClick',
        //   },
        //   [item.value || item.label]
        // );
      }
    });
  }
}
