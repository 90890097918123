import { Injectable } from '@angular/core';

import { BenchmarkingEmissionBalance } from 'app/features/benchmarking/benchmarking.interface';

@Injectable({
  providedIn: 'root',
})
export class BenchmarkingChartHelperService {
  public static getXLabels(
    benchmarkingEmissionData: BenchmarkingEmissionBalance,
    emissionIndex: string
  ): string[] {
    return benchmarkingEmissionData[emissionIndex]?.pots?.map((item) => {
      if (item.lowerLimit === null) {
        return `< ${item.upperLimit || '?'}`;
      }

      if (item.upperLimit === null) {
        return `> ${item.lowerLimit || '?'}`;
      }

      return `${item.lowerLimit}-${item.upperLimit}`;
    });
  }

  public static getPotIndex(
    benchmarkingEmissionData: BenchmarkingEmissionBalance,
    emissionIndex: string
  ): number {
    const averageValue = benchmarkingEmissionData[emissionIndex]?.average?.value;

    return benchmarkingEmissionData[emissionIndex]?.pots.findIndex((item) => {
      if (averageValue !== null) {
        if (item.lowerLimit === null && item.upperLimit !== null) {
          return averageValue <= item.upperLimit;
        }

        if (item.lowerLimit !== null && item.upperLimit === null) {
          return averageValue >= item.lowerLimit;
        }

        if (item.lowerLimit !== null && item.upperLimit !== null) {
          return averageValue >= item.lowerLimit && averageValue <= item.upperLimit;
        }
      }
      return false;
    });
  }

  // public static getDataMarkerSkeleton(
  //   item: number[]
  // ): ChartDataset<
  //   keyof ChartTypeRegistry,
  //   (number | ScatterDataPoint | BubbleDataPoint | null)[]
  // >[] {
  //   return {
  //     label: 'top',
  //     data: item,
  //     pointRadius: 10,
  //     showLine: false,
  //     pointStyle: 'triangle',
  //     type: 'line',
  //     backgroundColor: 'rgba(0, 119, 290, 0.2)',
  //     borderColor: 'rgba(0, 119, 290, 0.6)',
  //     fill: false,
  //   };
  // }
}
