import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { first, map, mergeMap, Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { RootState } from '../+state/app.reducer';
import { selectUserToken } from './+state/core.selectors';

@Injectable()
export class HttpTokenAuthorizationInterceptor implements HttpInterceptor {
  constructor(private readonly ngrxStore: Store<RootState>) {}
  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    // content-type: Default is 'application/json', if it's required to change please change manually in headers of the Http Call
    return this.ngrxStore.select(selectUserToken).pipe(
      first(),
      map((userToken) => {
        if (!req.url.includes('https://geocode.maps.co')) {
          return userToken
            ? req.clone({
                setHeaders: {
                  Authorization: `Bearer ${userToken}`,
                },
              })
            : req;
        }
        return req;
      }),
      mergeMap((authReq) => next.handle(authReq))
    );
  }
}
