import { Component, EventEmitter, Input, Output } from '@angular/core';
import { MatButton } from '@angular/material/button';
import { NgClass, NgForOf } from '@angular/common';
import { DirectivesModule } from 'shared/directives/directives.module';
import { SmallButtonDirective } from '@ista-ui/angular';

@Component({
  selector: 'ista-daytona-pagination-alphabet',
  templateUrl: './pagination-alphabet.component.html',
  styleUrls: ['./pagination-alphabet.component.scss'],
  imports: [MatButton, NgClass, DirectivesModule, NgForOf, SmallButtonDirective],
  standalone: true,
})
export class PaginationAlphabetComponent {
  @Input() activeLetters: string[] = [];
  @Output() clicked = new EventEmitter<string>();
  public alphabet!: string[];

  constructor() {
    const alpha = Array.from(Array(26)).map((e, i) => i + 65);
    this.alphabet = alpha.map((x) => String.fromCharCode(x));
  }

  public clickedItem(value: string): void {
    this.clicked.emit(value.toLowerCase());
  }

  isLetterActive(value: string): boolean {
    return this.activeLetters.includes(value.toLowerCase());
  }
}
