<div class="pagination-container">
  <div class="item-container">
    <button [dropdownTriggerFor]="dropdown" class="select-item button button-dropdown" addBoxShadow>
      <span>Zeige {{ selectedItemCount }} Einträge</span>
      <img src="/assets/icons/dropdown-close.svg" alt="close" />
    </button>
    <div class="page-input">
      <span>Gehe zu Seite</span>
      <div class="input-container">
        <ista-daytona-input
          [(ngModel)]="manualPage"
          [inputHeight]="_inputHeight.SMALL"
          [isInvalid]="manualPageConfig.isInvalid"
          [invalidText]="manualPageConfig.invalidText"
          (setBlur)="checkPageInput()"
          (setEnter)="checkPageInput()"
        ></ista-daytona-input>
      </div>
    </div>

    <ista-daytona-dropdown #dropdown>
      <div (click)="selectItemCountValue(10)" class="dropdown-item">10</div>
      <div (click)="selectItemCountValue(25)" class="dropdown-item">25</div>
      <div (click)="selectItemCountValue(50)" class="dropdown-item">50</div>
    </ista-daytona-dropdown>
  </div>
  <div class="page-container">
    <ista-daytona-page-stepper
      [maxPageCount]="maxPageCount"
      [selectedPage]="manualPage"
      [showBoxShadow]="true"
      (selectPage)="setSelectedPage($event)"
    >
      <p class="page-info">Seite {{ selectedPage }} von {{ maxPageCount }}</p>
    </ista-daytona-page-stepper>
  </div>
</div>
