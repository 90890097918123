<div class="grid gap-1">
  <div class="chart-container">
    <ista-daytona-label [config]="labelConfig"></ista-daytona-label>
    <div class="chart-container--inner">
      <canvas [data]="barChartData" [options]="barChartOptions" [type]="barType" baseChart class="max-w-full"></canvas>
    </div>
  </div>
  <ista-daytona-label [overwriteUnit]="overwriteUnit"></ista-daytona-label>
  <ista-daytona-chart-legend [chartData]="getTransformedBarChartData()" class="mt-5"></ista-daytona-chart-legend>
</div>
