<ng-container
  *ngIf="
    (timeSeries && timeSeries.length > displayMaxFilterItems) ||
      displayDropDown ||
      !mode?.singleMode;
    else timeSeriesListing
  "
>
  <button
    [dropdownTriggerFor]="dropdown"
    class="select-item button"
    [attr.data-cy]="
      !mode || (mode && !!mode.singleMode)
        ? 'filter-time-series--selected'
        : 'filter-time-series-range--selected'
    "
    addBoxShadow
  >
    <span
      [innerText]="
        !mode || (mode && !!mode.singleMode)
          ? selectedYear?.from
          : selectedYear?.from +
            (selectedYear && !!selectedYear.from ? ' - ' : '') +
            (selectedYear?.until || selectedYear?.from)
      "
    ></span>
    <img src="/assets/icons/dropdown-close-blue.svg" alt="close" />
  </button>

  <ista-daytona-dropdown #dropdown>
    <div
      *ngFor="let item of timeSeries"
      class="dropdown-item"
      [attr.data-cy]="
        !mode || (mode && !!mode.singleMode)
          ? 'filter-time-series--option'
          : 'filter-time-series-range--option'
      "
      [class.active]="selectedYear?.from === item.from"
      (click)="setActive(item)"
    >
      <span
        [innerHTML]="
          !mode || (mode && !!mode.singleMode)
            ? item.from
            : item.from + ' - ' + (item.until || item.from)
        "
      ></span>
    </div>
  </ista-daytona-dropdown>
</ng-container>

<ng-template #timeSeriesListing>
  <div class="time-series-container">
    <button
      *ngFor="let item of timeSeries"
      class="time-filters-dropdown--item button-flat"
      data-cy="filter-time-series--selected"
      [class.active]="selectedYear?.from === item?.from"
      (click)="setActive(item)"
    >
      <span
        [innerHTML]="
          !mode || (mode && !!mode.singleMode)
            ? item.from
            : item.from + ' - ' + (item.until || item.from)
        "
      ></span>
    </button>
  </div>
</ng-template>
