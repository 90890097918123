import { CustomColorsHelper } from 'shared/helper/custom-colors-helper';

export const ColorHelper = new Map([
  ['gas-dark', CustomColorsHelper.getColorHex('gas')],
  ['gas-full', CustomColorsHelper.getColorHex('gas', '500')],
  ['gas-light', CustomColorsHelper.getColorHex('gas', '500/50')],
  ['gas', CustomColorsHelper.getColorHex('gas', '500')],
  ['districtHeating', CustomColorsHelper.getColorHex('heating-district')],
  ['heating-full', CustomColorsHelper.getColorHex('heating', '500')],
  ['oil', CustomColorsHelper.getColorHex('oil', '500')],
  ['hotWaterEnergy', '#883f3f'],
  ['districtCooling-dark', '#709DCF'],
  ['heatingElectricity', CustomColorsHelper.getColorHex('heating-electricity')],
  ['bioMass', CustomColorsHelper.getColorHex('biomass', '500')],
  ['districtCooling-full', CustomColorsHelper.getColorHex('cold', '500')],
  ['districtCooling-light', CustomColorsHelper.getColorHex('cold', '500/50')],
  ['heatingAndHotWater-dark', CustomColorsHelper.getColorHex('heating', '800')],
  ['heatingAndHotWater-full', CustomColorsHelper.getColorHex('heating', '500')],
  ['heatingAndHotWater-light', CustomColorsHelper.getColorHex('heating', '500/50')],
  ['spaceHeatingEnergy', '#F09FA0'],
  ['waste-dark', CustomColorsHelper.getColorHex('waste', '800')],
  ['waste-full', CustomColorsHelper.getColorHex('waste', '500')],
  ['waste-light', CustomColorsHelper.getColorHex('waste', '500/50')],
  ['water-dark', CustomColorsHelper.getColorHex('water', '800')],
  ['water-full', CustomColorsHelper.getColorHex('water', '500')],
  ['water-light', CustomColorsHelper.getColorHex('water', '500/50')],
  ['electricity-dark', CustomColorsHelper.getColorHex('electricity', '800')],
  ['electricity-full', CustomColorsHelper.getColorHex('electricity', '500')],
  ['electricity-light', CustomColorsHelper.getColorHex('electricity', '500/50')],
  ['co2', CustomColorsHelper.getColorHex('co2')],
  ['co2-dark', CustomColorsHelper.getColorHex('co2', '800')],
  ['co2-full', CustomColorsHelper.getColorHex('co2', '500')],
  ['co2-light', CustomColorsHelper.getColorHex('co2', '500/50')],
  ['label', '#64748B'],
  ['grid', '#E2E8F0'],
  ['doughnutInnerText', '#181C42'],
  ['spaceHeatingEnergy-1', '#4D1818'],
  ['spaceHeatingEnergy-2', '#BF3C3C'],
  ['spaceHeatingEnergy-3', '#FF5050'],
  ['spaceHeatingEnergy-4', '#FFA3A6'],
  ['spaceHeatingEnergy-5', '#E69535'],
  ['spaceHeatingEnergy-6', '#989A2B'],
  ['spaceHeatingEnergy-contrast-1', '#F09FA0'],
  ['spaceHeatingEnergy-contrast-2', '#FF5050'],
  ['spaceHeatingEnergy-contrast-3', '#802828'],
  ['background-medium', '#E1E6EC'],
  ['cost', CustomColorsHelper.getColorHex('cost', '500')],
  ['cost-dark', CustomColorsHelper.getColorHex('cost', '800')],
  ['cost-light', CustomColorsHelper.getColorHex('cost', '500/50')],
  ['total', '#BF3B3B'],
  ['barChart-column-1', CustomColorsHelper.getColorHex('co2', '500')],
  ['barChart-column-2', CustomColorsHelper.getColorHex('co2', '800')],
  ['doughnut-chart-percent-blue-light', '#E1E6EC'],
  ['doughnut-chart-percent-blue-dark', '#181c42'],
  ['light-blue', '#80B8F8'],
  ['light-red', '#DA1313'],
  ['default', '#181c42'],
  ['other', CustomColorsHelper.getColorHex('other', '500')],
  ['otherFuels', CustomColorsHelper.getColorHex('other', '500')],
]);
