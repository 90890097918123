<ista-daytona-card
  *ngIf="showExcelDataImportCard"
  [colorStyle]="colorStyle.BLUE_LIGHT"
  [displayBoxShadow]="false"
  [minWidth]="'100%'"
>
  <div class="grid gap-y-6">
    <h4>{{ 'file-upload.excel.headline' | translate }}</h4>
    <p [innerHTML]="'file-upload.excel.subheadline' | translate"></p>
    <div class="flex grid-cols-2 gap-x-6">
      <button
        (click)="downloadTemplate(false)"
        class="inline-flex items-center justify-center shadow-md shadow-blue-100/70 rounded-lg h-16 leading-10"
      >
        <div class="items-center justify-center flex gap-2 px-3 h-8 leading-8 pl-4">
          <img
            alt="Excel Template"
            class="w-auto h-8"
            src="/assets/icons/upload/file-excel.svg"
            title="Excel Template"
          />
          <span class="font-extrabold text-base text-blue-500 leading-8">{{
              'file-upload.excel.new-file' | translate
            }}</span>
          <img
            *ngIf="(downloadTemplateLoadStatus$ | async) !== loadingState.PROGRESS"
            alt=" Download Excel Template"
            class="w-auto h-8"
            src="/assets/icons/download.svg"
            title=" Download Excel Template"
          />
        </div>
        <ista-daytona-loader
          *ngIf="(downloadTemplateLoadStatus$ | async) === loadingState.PROGRESS"
          [diameter]="'24'"
        ></ista-daytona-loader>
      </button>

      <button
        (click)="downloadTemplate(true)"
        [disabled]="(loadStatus$ | async) === loadingState.PROGRESS"
        class="inline-flex items-center justify-center shadow-md shadow-blue-100/70 rounded-lg h-16 leading-10"
      >
        <div class="items-center justify-center flex gap-2 px-3 h-8 leading-8 pl-4">
          <img
            alt="Excel Template"
            class="w-auto h-8"
            src="/assets/icons/upload/file-excel.svg"
            title="Excel Template"
          />
          <span class="font-extrabold text-base text-blue-500 leading-8">{{
              'file-upload.excel.current-file' | translate
            }}</span>
          <img
            *ngIf="(loadStatus$ | async) !== loadingState.PROGRESS"
            alt=" Download Excel Template"
            class="w-auto h-8"
            src="/assets/icons/download.svg"
            title=" Download Excel Template"
          />
          <ista-daytona-loader
            *ngIf="(loadStatus$ | async) === loadingState.PROGRESS"
            [diameter]="'24'"
          ></ista-daytona-loader>
        </div>
      </button>
    </div>
    <ista-daytona-button
      (buttonClicked)="selectedFileUploadType = fileUploadType.EXCEL; fileUploadExcel.click()"
      [label]="'file-upload.excel.button-label' | translate"
    ></ista-daytona-button>
  </div>
</ista-daytona-card>
<ista-daytona-card
  *ngIf="showFilesDataImportCard"
  [colorStyle]="colorStyle.BLUE_LIGHT"
  [displayBoxShadow]="false"
  [minWidth]="'100%'"
>
  <div class="grid gap-y-6">
    <h4>{{ 'file-upload.file.headline' | translate }}</h4>
    <p>
      {{ 'file-upload.file.subheadline' | translate }}
    </p>

    <ista-daytona-button
      (buttonClicked)="selectedFileUploadType = fileUploadType.EFILE; fileUploadEFile.click()"
      [label]="'file-upload.file.button-label' | translate"
    ></ista-daytona-button>
  </div>
</ista-daytona-card>

<input
  #fileUploadExcel
  (change)="onFileSelected($event)"
  [accept]="acceptedFiles.excel"
  class="hidden"
  type="file"
/>

<input
  #fileUploadEFile
  (change)="onFileSelected($event)"
  [accept]="acceptedFiles.efile"
  class="hidden"
  multiple="accept"
  type="file"
/>
