import { Actions, createEffect, ofType } from '@ngrx/effects';
import { storeSelectedEnvironment } from './core.actions';
import { tap } from 'rxjs';
import { StickyHeaderService } from '../sticky-header/sticky-header.service';
import { Injectable } from '@angular/core';

@Injectable()
export class CoreEffects {
  resetHeader$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(storeSelectedEnvironment),
        tap(() => this.stickyHeaderService.resetHeader())
      ),
    {
      dispatch: false,
    }
  );

  constructor(private actions$: Actions, private stickyHeaderService: StickyHeaderService) {}
}
