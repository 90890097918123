import { InjectionToken } from '@angular/core';
import { ActionReducer, ActionReducerMap, combineReducers } from '@ngrx/store';
import { coreReducer } from 'core/+state/core.reducer';
import { CoreState } from 'core/+state/interface';
import { initialCoreState } from 'core/+state/constants';

export interface RootState {
  core: CoreState;
}

export const initialRootState: RootState = {
  core: initialCoreState,
};

export const rootStateReducer: ActionReducer<RootState> = combineReducers({
  core: coreReducer,
});

export const ROOT_REDUCER_TOKEN = new InjectionToken<ActionReducerMap<RootState>>('State', {
  factory: () => ({
    core: coreReducer,
  }),
});
