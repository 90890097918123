import { Component, Input } from '@angular/core';
import * as _ from 'lodash';

import { ValueUnitService } from '../../helper/value-unit.service';
import { LabelConfig } from '../basic/label/label.interface';
import { FilterDateRange } from '../filter/filter-tab.interface';
import { TransformedValueItem, UnitFactor, UnitWithPrefix } from '../../models/unit.enum';
import { MultipleChartItem } from './chart.interface';

/* eslint-disable @typescript-eslint/member-ordering */
@Component({ template: '' })
export class AbstractMultiChartComponent {
  @Input() overwriteColor: string[] = [];
  @Input() overwriteLabels: string[] = [];
  public transformedValue!: TransformedValueItem;
  public transformedData: MultipleChartItem = {};
  public transformedUnit!: UnitWithPrefix;
  public unitFactor = UnitFactor.ONE;

  private _data!: MultipleChartItem;

  get data(): MultipleChartItem {
    return this._data;
  }

  @Input() set data(value: MultipleChartItem) {
    if (!_.isEqual(value, this.data)) {
      this._data = value;
      this._refreshData();
    }
  }

  private _selectedYear: FilterDateRange = { from: '', until: '' };

  get selectedYear(): FilterDateRange {
    return this._selectedYear;
  }

  @Input() set selectedYear(year: FilterDateRange | undefined) {
    if (
      year &&
      (this._selectedYear.from !== year?.from || this._selectedYear.until !== year?.until)
    ) {
      this._selectedYear = year;
      this._refreshData();
    }
  }

  private _labelConfig: LabelConfig = {
    consumptionTypes: 'unknown',
    analyticalCategory: 'consumption',
    unitFactor: this.unitFactor,
  };

  get labelConfig(): LabelConfig {
    return this._labelConfig;
  }

  @Input() set labelConfig(config: LabelConfig) {
    this._labelConfig = { ..._.cloneDeep(config), unitFactor: this.unitFactor };
  }

  public _refreshData(): void {
    if (this.data !== undefined && this.data !== null) {
      Object.keys(this.data).forEach((key) => {
        const tempData = _.cloneDeep(this.data[key]);

        if (tempData && tempData.length > 0) {
          this.transformedData[key] = tempData?.map((item) => {
            if (item) {
              item.data = item.data?.map((itemData) => {
                const temp = ValueUnitService.getMappedValueByFactor(
                  itemData.values,
                  this.unitFactor
                );

                this.transformedUnit = temp.unitWithPrefix || this.transformedUnit;
                return {
                  ...itemData,
                  values: { value: temp.value, unit: itemData.values?.unit },
                };
              });
            }
            return item;
          });
        }
      });
    }
  }
}
