@if (selectBreadcrumbItems$ | async; as items) {
  <div class="font-bold">
    <div class="flex justify-between items-center pr-4" [class.add-filter]="selectIsScrollVisible$ | async">
      <ui-breadcrumb [dividerStyle]="DividerStyle.CHEVRON" [items]="items"
                     class="max-w-80 overflow-x-hidden breadcrumb-comp"
                     [class.filter-sticky]="isSticky"
                     [class.filter-not-sticky]="!isSticky">
      </ui-breadcrumb>
    </div>
  </div>
}

