import { createReducer, on } from '@ngrx/store';
import { DEFAULT_UNASSIGNED_SITES_GROUP } from 'core/constants';
import { GroupHelper } from 'core/groups-tree-navigation/utils/group-helper';
import * as _ from 'lodash';
import {
  addNewGroupToGroups,
  addSitesToUnassignedSites,
  addTag,
  clearLoadSitesState,
  deleteGroupFromGroup,
  deleteSiteFromGroup,
  moveAndAddGroup,
  moveAndAddGroupToRoot,
  moveAndAddSite,
  removeSelectedSite,
  renameGroup,
  resetSearchTreeNavigation,
  resetTags,
  setTags,
  storeAvailableYears,
  storeEnvironmentReadiness,
  storeEnvironments,
  storeFrontendConfig,
  storeGroups,
  storeGroupSearchStatus,
  storeReportingPeriods,
  storeSearchTreeNavigationGroups,
  storeSelectedEnvironment,
  storeSelectedGroup,
  storeSelectedGroupOrSite,
  storeSelectedSite,
  storeSitesOfGroups,
  storeUserToken,
  updateEnvironmentSettings,
  updateGlobalSelectedYear,
  updateLoadSitesState,
  updateSearchTreeNavigation,
  updateSelectedLanguage,
  updateShowNavigationMenu,
  updateSubNavigationSelectedHeadline,
  updateTags,
  updateUnassignedSites,
  updateUserSettings,
} from './core.actions';
import { initialCoreState } from 'core/+state/constants';
import { CoreStateHelper } from 'core/+state/utils/core-state-helper';

export const coreReducer = createReducer(
  initialCoreState,
  on(storeFrontendConfig, (state, action): typeof state => ({
    ...state,
    frontendConfig: action.frontendConfig,
  })),
  on(storeUserToken, (state, action): typeof state => ({
    ...state,
    userToken: action.userToken,
  })),
  on(storeEnvironmentReadiness, (state, action): typeof state => ({
    ...state,
    environmentReady: action.environmentReady,
  })),
  on(storeEnvironments, (state, action): typeof state => ({
    ...state,
    availableEnvironments: action.availableEnvironments,
    hasNoEnvironments: !action.availableEnvironments.length,
  })),
  on(storeSelectedEnvironment, (state, action): typeof state => ({
    ...state,
    selectedEnvironment: action.selectedEnvironment,
  })),
  on(storeAvailableYears, (state, action): typeof state => {
    const isExist = action.preSelectedYear && action.yearsWithData.includes(action.preSelectedYear);
    const selectedYear = isExist
      ? action.preSelectedYear
      : action.yearsWithData[action.yearsWithData.length - 1];

    return {
      ...state,
      yearsWithData: action.yearsWithData,
      globalSelectedYear: selectedYear,
    };
  }),
  on(storeReportingPeriods, (state, action): typeof state => ({
    ...state,
    reportingPeriods: action.reportingPeriods,
  })),
  on(storeGroups, (state, action): typeof state => ({
    ...state,
    groups: action.groups,
    treeNavigation: {
      ...state.treeNavigation,
      groups: action.groups,
    },
  })),
  on(resetSearchTreeNavigation, (state): typeof state => ({
    ...state,
    searchTreeNavigation: undefined,
  })),
  on(storeSearchTreeNavigationGroups, (state, action): typeof state => ({
    ...state,
    searchTreeNavigation: {
      groups: action.groups,
      unassignedSitesGroup: {
        ...DEFAULT_UNASSIGNED_SITES_GROUP,
        sites: action.unassignedSites,
      },
    },
  })),
  on(storeSelectedGroup, (state, action): typeof state => {
    const selectedGroup =
      action.selectedGroupId && state.groups
        ? GroupHelper.flattenGroups(state.groups).find((g) => g.id === action.selectedGroupId)
        : undefined;
    return {
      ...state,
      selectedGroup,
      subNavigationSelectedHeadline:
        selectedGroup?.name ?? state.subNavigationSelectedHeadline ?? '',
    };
  }),
  on(deleteSiteFromGroup, (state, { parentGroup, site }) => {
    const res = CoreStateHelper.deleteSiteFromGroup(
      parentGroup,
      site,
      state.treeNavigation,
      state.searchTreeNavigation
    );
    return {
      ...state,
      ...res,
    };
  }),
  on(storeSitesOfGroups, (state, action): typeof state => ({
    ...state,
    treeNavigation: {
      ...state.treeNavigation,
      groups: CoreStateHelper.mergeSitesIntoGroups(
        state.treeNavigation.groups ?? [],
        action.groupId,
        action.sites,
        true
      ),
    },
  })),
  on(updateLoadSitesState, (state, action): typeof state => ({
    ...state,
    allowLoadSites: action.state,
  })),
  on(clearLoadSitesState, (state): typeof state => ({
    ...state,
    allowLoadSites: true,
  })),
  on(storeGroupSearchStatus, (state, action): typeof state => ({
    ...state,
    groupSearchRunning: action.isRunning,
  })),
  on(updateShowNavigationMenu, (state, action) => ({
    ...state,
    showNavigationMenu: action.showNavigationMenu,
  })),
  on(updateSubNavigationSelectedHeadline, (state, action) => ({
    ...state,
    subNavigationSelectedHeadline: action.subNavigationSelectedHeadline,
  })),
  on(updateGlobalSelectedYear, (state, action) => ({
    ...state,
    globalSelectedYear: action.globalSelectedYear,
  })),
  on(updateSelectedLanguage, (state, action) => ({
    ...state,
    selectedLanguage: action.selectedLanguage,
  })),
  on(updateEnvironmentSettings, (state, action) => ({
    ...state,
    environmentSettings: action.environmentSettings,
  })),
  on(storeSelectedSite, (state, action): typeof state => ({
    ...state,
    selectedSite: action.selectedSite,
  })),
  on(storeSelectedGroupOrSite, (state, action): typeof state => ({
    ...state,
    selectedSiteOrGroup: action.selectedGroupOrSite,
  })),
  on(removeSelectedSite, (state) => ({ ...state, selectedSite: undefined })),
  on(moveAndAddSite, (state, action) => {
    const res = CoreStateHelper.moveAndAddSite(
      action.site,
      action.targetGroup,
      state.treeNavigation,
      state.searchTreeNavigation
    );
    return {
      ...state,
      ...res,
    };
  }),
  on(moveAndAddGroup, (state, action) => {
    const res = CoreStateHelper.moveAndAddGroup(
      action.group,
      action.targetGroup,
      state.treeNavigation,
      state.searchTreeNavigation
    );
    return {
      ...state,
      ...res,
    };
  }),
  on(moveAndAddGroupToRoot, (state, action) => {
    const res = CoreStateHelper.moveAndAddGroupToRoot(
      action.group,
      state.treeNavigation,
      state.searchTreeNavigation
    );
    return {
      ...state,
      ...res,
    };
  }),
  on(deleteGroupFromGroup, (state, action) => {
    const res = CoreStateHelper.deleteGroupFromGroup(
      action.groupId,
      state.treeNavigation,
      state.searchTreeNavigation
    );
    return {
      ...state,
      ...res,
    };
  }),
  on(addSitesToUnassignedSites, (state, action) => ({
    ...state,
    ...CoreStateHelper.addSitesToUnassignedSites(
      action.unassignedSites,
      state.treeNavigation,
      state.searchTreeNavigation
    ),
  })),
  on(updateUnassignedSites, (state, action) => ({
    ...state,
    treeNavigation: {
      ...state.treeNavigation,
      unassignedSitesGroup: {
        ...DEFAULT_UNASSIGNED_SITES_GROUP,
        sites: action.unassignedSites,
      },
    },
  })),
  on(addNewGroupToGroups, (state, action) => ({
    ...state,
    ...CoreStateHelper.addNewGroupToGroups(
      action.newGroup,
      action.parentGroupId,
      state.treeNavigation,
      state.searchTreeNavigation
    ),
  })),
  on(updateUserSettings, (state, action) => ({
    ...state,
    userSettings: action.settings,
  })),
  on(renameGroup, (state, action) => {
    let treeGroups = _.cloneDeep(state.treeNavigation.groups ?? []);
    let searchTreeGroups = _.cloneDeep(state.searchTreeNavigation?.groups ?? []);
    if (treeGroups.length) {
      treeGroups = GroupHelper.renameGroup(treeGroups, action.groupId, action.name);
    }
    if (searchTreeGroups.length) {
      searchTreeGroups = GroupHelper.renameGroup(searchTreeGroups, action.groupId, action.name);
    }
    return {
      ...state,
      treeNavigation: {
        ...state.treeNavigation,
        groups: treeGroups,
      },
      searchTreeNavigation: state.searchTreeNavigation
        ? {
            ...state.searchTreeNavigation,
            groups: searchTreeGroups,
          }
        : undefined,
    };
  }),
  on(addTag, (state, action) => ({
    ...state,
    tags: [action.tag, ...state.tags],
  })),
  on(updateTags, (state, action) => {
    const tagsSet = new Set();
    const allTags = [...action.tags, ...state.tags];
    const filteredTags = allTags
      .filter((tag) => {
        const duplicate = tagsSet.has(tag.id);
        tagsSet.add(tag.id);
        return !duplicate;
      })
      .map((tag) => ({
        ...tag,
        lastModified: new Date().toISOString(),
      }));
    return {
      ...state,
      tags: filteredTags,
    };
  }),
  on(setTags, (state, action) => ({
    ...state,
    tags: [...action.tags],
  })),
  on(resetTags, (state) => ({
    ...state,
    tags: [],
  })),
  on(updateSearchTreeNavigation, (state, action) => ({
    ...state,
    searchTreeNavigation: {
      groups: action.group,
      unassignedSitesGroup: {
        ...DEFAULT_UNASSIGNED_SITES_GROUP,
        sites: action.unassignedSites,
      },
    },
  }))
);
