import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { TranslateModule } from '@ngx-translate/core';
import { UiModule } from 'shared/ui/ui.module';

@Component({
  selector: 'ista-daytona-delete-confirm-dialog',
  templateUrl: 'delete-confirm-dialog.component.html',
  standalone: true,
  imports: [TranslateModule, UiModule],
})
export class DeleteConfirmDialogComponent {
  constructor(
    private dialogRef: MatDialogRef<DeleteConfirmDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { itemName: string }
  ) {}

  closeDialog(): void {
    this.dialogRef.close(false);
  }

  confirm(): void {
    this.dialogRef.close(true);
  }
}
