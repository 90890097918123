import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { MasterdataCardContentInterface } from 'app/features/portfolio/portfolio-site-masterdata/interfaces/masterdata-card-content.interface';

@Component({
  selector: 'ista-daytona-masterdata-card',
  templateUrl: './key-value-card.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class KeyValueCardComponent {
  @Input() content: MasterdataCardContentInterface[] = [];
}
