import { Component, Input } from '@angular/core';
import * as _ from 'lodash';

import { ValueUnitService } from '../../helper/value-unit.service';
import { LabelConfig } from '../basic/label/label.interface';
import { FilterDateRange } from '../filter/filter-tab.interface';
import { TransformedValueItem, UnitFactor, UnitWithPrefix } from '../../models/unit.enum';
import { ChartItem } from './chart.interface';

/* eslint-disable @typescript-eslint/member-ordering */
@Component({ template: '' })
export class AbstractChartComponent {
  public transformedValue!: TransformedValueItem;
  public transformedData: ChartItem[] = [];
  public transformedUnit!: UnitWithPrefix;
  public unitFactor = UnitFactor.K;

  private _data: ChartItem[] = [];

  get data(): ChartItem[] {
    return this._data;
  }

  @Input() set data(value: ChartItem[]) {
    if (!_.isEqual(value, this.data)) {
      this._data = value;
      this._refreshData();
    }
  }

  private _selectedYear: FilterDateRange = { from: '', until: '' };

  get selectedYear(): FilterDateRange {
    return this._selectedYear;
  }

  @Input() set selectedYear(year: FilterDateRange | undefined) {
    if (
      year &&
      (this._selectedYear.from !== year?.from || this._selectedYear.until !== year?.until)
    ) {
      this._selectedYear = year;
      this._refreshData();
    }
  }

  private _labelConfig: LabelConfig = {
    consumptionTypes: 'unknown',
    analyticalCategory: 'consumption',
    unitFactor: this.unitFactor,
  };

  get labelConfig(): LabelConfig {
    return this._labelConfig;
  }

  @Input() set labelConfig(config: LabelConfig) {
    this._labelConfig = { ..._.cloneDeep(config), unitFactor: this.unitFactor };
  }

  public _refreshData(): void {
    const tempData = _.cloneDeep(this.data);

    if (tempData && tempData.length > 0) {
      this.transformedData = tempData?.map((item) => {
        if (item) {
          item.data = item.data?.map((itemData) => {
            const temp = ValueUnitService.getMappedValueByFactor(itemData.values, this.unitFactor);

            this.transformedUnit = temp.unitWithPrefix || this.transformedUnit;
            return {
              ...itemData,
              values: { value: temp.value, unit: itemData.values?.unit },
            };
          });
        }
        return item;
      });
    }
  }
}
