import { Component, EventEmitter, Input, Output } from '@angular/core';

import { BaseUi } from '../base-ui/base-ui';
import { ItemCount } from './item-count.enum';

/* eslint-disable @typescript-eslint/member-ordering */
@Component({
  selector: 'ista-daytona-pagination',
  templateUrl: './pagination.component.html',
  styleUrls: ['./pagination.component.scss'],
})
export class PaginationComponent extends BaseUi {
  public readonly itemCount = [10, 25, 50, 100];
  get selectedPage(): number {
    return this._selectedPage;
  }
  @Input() set selectedPage(value: number) {
    if (value >= this.minPageCount && value <= this.maxPageCount) {
      this._selectedPage = value;
      this.manualPage = value;
    } else {
      this._selectedPage = this.minPageCount;
      this.manualPage = this.minPageCount;
    }
  }
  @Input() selectedItemCount = this.itemCount[0];
  @Input() maxPageCount = 1;
  @Input() minPageCount = 1;
  @Output() selectPage = new EventEmitter<number>();
  @Output() selectItemCount = new EventEmitter<ItemCount>();
  public manualPage!: number;
  public manualPageConfig: { isInvalid: boolean; invalidText: string } = {
    isInvalid: false,
    invalidText: '',
  };
  private _selectedPage = 1;

  public setSelectedPage(value: number): void {
    this._selectedPage = value;
    this.manualPage = this._selectedPage;
    this.clearErrorConfig();
    this.selectPage.emit(this._selectedPage);
  }

  public selectItemCountValue(value: number): void {
    this.selectItemCount.emit(value);
  }

  public checkPageInput(): void {
    if (this.manualPage >= this.minPageCount && this.manualPage <= this.maxPageCount) {
      this._selectedPage = this.manualPage;
      this.clearErrorConfig();
      this.selectPage.emit(this._selectedPage);
    } else {
      this.manualPageConfig = {
        isInvalid: true,
        invalidText: 'Seitenzahl liegt nicht im Gültigkeitsbereich.',
      };
    }
  }

  private clearErrorConfig(): void {
    this.manualPageConfig = {
      isInvalid: false,
      invalidText: '',
    };
  }
}
