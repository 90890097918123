<div class="notification--container" *ngIf="displayedNotification as notification">
  <div class="inner--content">
    <div class="header">
      <img [src]="icon" alt="notification-header" />
      <h3
        [innerText]="notification.headline"
        [ngClass]="{
          alert: notification.type === notificationType.ALERT,
          warning: notification.type === notificationType.WARNING,
          confirmation: notification.type === notificationType.CONFIRMATION
        }"
      ></h3>
    </div>

    <div class="content">
      <p [innerText]="notification.subline" *ngIf="notification.subline"></p>
      <p [innerText]="notification.text" *ngIf="notification?.text"></p>
    </div>
    <div class="page-stepper" *ngIf="notifications.length > 1">
      <ista-daytona-page-stepper
        [maxPageCount]="notifications.length"
        (selectPage)="actualPageIndex = $event; setNotificationByIndex($event)"
      >
        <p>Fehler {{ actualPageIndex }} von {{ notifications.length }}</p>
      </ista-daytona-page-stepper>
    </div>
    <button class="button--close" (click)="closeNotification()">
      <img src="/assets/icons/notification/close.svg" alt="notification" />
    </button>
  </div>
</div>
