import { Component, Input } from '@angular/core';

@Component({
  selector: 'ista-daytona-co2-cost-card-distribution',
  templateUrl: './co2-cost-card-distribution.component.html',
})
export class Co2CostCardDistributionComponent {
  @Input() modelStageColor = '';
  @Input() modelStageData: number[] = [];
}
