import { Component, Input } from '@angular/core';

import { CardHeaderConfig } from '../../models/card-header-config.interface';
import { PortfolioCardValueItem } from './portfolio-card.interface';

@Component({
  selector: 'ista-daytona-portfolio-card',
  templateUrl: './portfolio-card.component.html',
  styleUrls: ['./portfolio-card.component.scss'],
})
export class PortfolioCardComponent {
  @Input() data!: PortfolioCardValueItem | undefined;
  @Input() header!: CardHeaderConfig | undefined;
}
