<div class="bg-white border-bg-border border border-solid rounded-md h-full">
  <div class="p-4 block gap-2 content-center justify-items-center">
    <ista-daytona-card-headline [label]="'dashboard.costs.co2cost.stage-model.title'"
                                class="block mb-2"></ista-daytona-card-headline>
    <div class="flex gap-2">
      <ista-daytona-doughnut-chart-simple
        [value]="landLordShare"
      ></ista-daytona-doughnut-chart-simple>
      <div class="grid grid-cols-1 gap-2">
        <div *ngIf="!isTenantShare" class="flex flex-wrap gap-1 items-center">
          <span class="text-base-700 text-2xl font-extrabold">{{ landLordShare }}%</span>
          <span
            class="text-base-700 text-xl">{{ 'dashboard.costs.co2cost.stage-model.landlord' | translate }}
            =</span>
          <ista-daytona-value-unit
            [classUnit]="'text-base-700 text-2xl font-extrabold'"
            [classValue]="'text-base-700 text-2xl font-extrabold'"
            [value]="landlordShare?.cost"
          ></ista-daytona-value-unit>
        </div>
        <div class="flex flex-wrap gap-1 items-center">
          <span class="text-base-700 text-2xl font-extrabold">{{ tenantShareValue }}%</span>
          <span
            class="text-base-700 text-xl">{{ 'dashboard.costs.co2cost.stage-model.tenant' | translate }} =</span>
          <ista-daytona-value-unit
            [classUnit]="'text-base-700 text-2xl font-extrabold'"
            [classValue]="'text-base-700 text-2xl font-extrabold'"
            [value]="tenantShare?.cost"
          ></ista-daytona-value-unit>
        </div>
        <p *ngIf="isTenantShare" class="font-semibold text-sm text-base-700">
          {{ 'dashboard.costs.co2cost.stage-model.all-tenant-cost' | translate : { year } }}
        </p>
      </div>
    </div>
  </div>
</div>
