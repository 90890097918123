<div
  class="w-full py-4 px-8 bg-white border border-slate-200 rounded-lg shadow-lg max-w-[278px]"
  role="alert"
>
  <div class="flex gap-4">
    <mat-icon class="icon-size-8" color="primary">check_circle</mat-icon>

    <div>
      <div
        [innerHTML]="
          'dialogs.delete-revert.delete-revert-text' | translate : { itemName: data.itemName }
        "
        class="text-slate-900 text-base mb-1"
      ></div>
      <button (click)="revert()" class="text-cta text-sm font-extrabold dialog-revert-button">
        {{ 'dialogs.delete-revert.delete-revert-action-text' | translate }}
      </button>
    </div>
  </div>
</div>
