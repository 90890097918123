<div class="flex flex-col gt-xs:flex-row p-2 min-w-52 select-wrapper">
  @if (title) {
    <mat-label>{{ title }}</mat-label>
  }
  <mat-form-field
    [floatLabel]="'always'"
    class="flex-auto gt-xs:pl-3 h-12"
    appearance="fill"
  >
    <input
      data-testid="search-input"
      #searchInput
      type="text"
      [placeholder]="'environment.searchEnvironment' | translate"
      aria-label="Number"
      matInput
      [matAutocomplete]="auto"
      [formControl]="singleControl"
      [disabled]="disabled"
    />
    <mat-autocomplete
      autoActiveFirstOption #auto="matAutocomplete"
      (optionSelected)="onOptionSelected($event.option.value)"
      [displayWith]="displayFn"
    >
      @for (item of filteredSingleOptions$ | async | environmentDropdown; track item.id) {
        <mat-option [value]="item" [title]="item.title ?? ''"
        >
          {{ item.name }}
        </mat-option>
      }
    </mat-autocomplete>
    <div class="flex gap-2 items-center">
      @if (!!searchInput) {
        <button mat-icon-button (click)="clearInput()">
          <mat-icon class="icon-size-6 text-slate-600">close</mat-icon>
        </button>
      }
    </div>
  </mat-form-field>
</div>

