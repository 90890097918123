import { Directive, ElementRef, HostBinding, Renderer2 } from '@angular/core';

@Directive({
  selector: '[addBoxShadow]',
})
export class BoxShadowDirective {
  @HostBinding('class') classes = 'box-shadow';

  constructor(private readonly el: ElementRef<HTMLElement>, private readonly renderer: Renderer2) {
    this.renderer.addClass(this.el.nativeElement, 'box-shadow');
  }
}
