import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Observable } from 'rxjs';

import { environment } from 'environments/environment';
import { HttpRequestService } from 'core/http-request/http-request.service';
import { LoadingState } from 'core/load-status/load-status.interface';
import { ColorStyle } from '../../../models/color-style.enum';
import { FileUpload, FileUploadType } from '../file-upload.model';
import { FetchRequestType } from 'core/http-request/fetch-request-type.enum';

@Component({
  selector: 'ista-daytona-file-upload-step-file',
  templateUrl: './file-upload-step-file.component.html',
  styleUrls: ['./file-upload-step-file.component.scss'],
})
export class FileUploadStepFileComponent {
  @Output() selectedFileUpload = new EventEmitter<FileUpload>();
  @Input() showExcelDataImportCard = false;
  @Input() showFilesDataImportCard = false;
  public selectedFileUploadType!: FileUploadType;
  public readonly fileUploadType = FileUploadType;
  public readonly colorStyle = ColorStyle;
  public readonly acceptedFiles = {
    excel:
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel',
    efile: 'all',
  };
  public loadingState = LoadingState;
  public loadStatus$!: Observable<LoadingState>;
  public downloadTemplateLoadStatus$!: Observable<LoadingState>;

  constructor(private readonly httpRequestService: HttpRequestService) {}

  public downloadTemplate(templateWithData: boolean): void {
    const url = templateWithData ? 'importable-excel-export' : 'excel-import-template';
    const fileName = templateWithData ? 'ESG-Datenexport-(uploadfähig)' : 'Excel-Import-Template';
    const requestType = templateWithData ? FetchRequestType.POST : FetchRequestType.GET;
    if (templateWithData) {
      this.loadStatus$ = this.httpRequestService.triggerImportableExcelDownload(
        `${environment.apiUrl}/api/###/export/${url}`,
        fileName,
        requestType
      );
    } else {
      this.downloadTemplateLoadStatus$ = this.httpRequestService.triggerImportableExcelDownload(
        `${environment.apiUrl}/api/###/export/${url}`,
        fileName,
        requestType
      );
    }
  }

  public onFileSelected(event: Event): void {
    const input = event?.target as HTMLInputElement;
    if (input.files) {
      const formData = new FormData();
      const name =
        this.selectedFileUploadType === FileUploadType.EXCEL ? 'excelFile' : 'eSatzFiles';

      if (this.selectedFileUploadType === FileUploadType.EXCEL) {
        const file: File = input.files[0];
        formData.append(name, file, file.name);
      } else {
        const files = Array.from(input.files);
        files.forEach((file) => formData.append(name, file, file.name));
      }

      this.selectedFileUpload.next({
        type: this.selectedFileUploadType,
        formData,
      });
    }
  }

  public selectFileType(type: FileUploadType): void {
    this.selectedFileUploadType = type;
  }
}
