import { inject } from '@angular/core';
import { Store } from '@ngrx/store';
import { RootState } from 'app/+state/app.reducer';
import { updateSelectedEmissionMediaChartFilter } from './+state/benchmarking.actions';
import { DEFAULT_BENCHMARKING_MEDIA_CHART_FILTER } from 'app/features/benchmarking/constants';

export const ResetEmissionMediaChartFilterGuard = (): boolean => {
  const store = inject(Store<RootState>);
  store.dispatch(updateSelectedEmissionMediaChartFilter({
    selectedEmissionMediaChartFilter: DEFAULT_BENCHMARKING_MEDIA_CHART_FILTER,
  }));
  return true;
};
