import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { OverlayComponent } from './overlay.component';
import { OverlayService } from './overlay.service';
import { FileUploadModule } from 'shared/ui/file-upload/file-upload.module';
import { DeleteObjectModule } from 'app/features/portfolio/delete-object/delete-object.module';

@NgModule({
  imports: [CommonModule, FileUploadModule, DeleteObjectModule],
  declarations: [OverlayComponent],
  exports: [OverlayComponent],
  providers: [OverlayService],
})
export class OverlayModule {}
