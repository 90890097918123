<button class="button--link" [disabled]="!!isDisabled" (click)="export()" addBoxShadow>
  <div class="button--link-content">
    <span
      [innerText]="label"
      *ngIf="label"
      [class.disabled]="loadStatus && loadStatus.state === loadingState.PROGRESS"
    ></span>
    <img
      src="/assets/icons/export-button.svg"
      alt="Export"
      *ngIf="loadStatus?.state !== loadingState.PROGRESS"
    />
    <ista-daytona-loader
      [diameter]="'64'"
      *ngIf="loadStatus?.state === loadingState.PROGRESS"
    ></ista-daytona-loader>
  </div>
</button>
