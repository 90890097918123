import { Injectable } from '@angular/core';
import { ChartConfiguration } from 'chart.js';
import { ChartHelper } from 'shared/helper/chart-helper';
import { ColorHelper } from 'shared/helper/color-helper';

import { FilterDateRange } from '../../filter/filter-tab.interface';
import { ChartHelperService } from '../chart-helper.service';
import { ChartItem } from '../chart.interface';
import { TranslateService } from '@ngx-translate/core';

@Injectable()
export class BarHorizontalStackedChartService {
  constructor(
    private chartHelperService: ChartHelperService,
    private translateService: TranslateService
  ) {}
  getBarStackedChartData(
    data: ChartItem[],
    filterBy: string[],
    selectedYear: FilterDateRange
  ): ChartConfiguration['data'] {
    try {
      const datasets: any[] = [];
      let selectedYearlabels: string[] = [];

      if (data && selectedYear) {
        selectedYearlabels = data[0]?.data
          .filter((item) => item.year >= selectedYear.from && item.year <= selectedYear.until)
          .map((item) => item.year);

        const displayData = data?.filter((item) => filterBy.includes(item.value || item.label));

        displayData
          ?.filter(
            (item) => item.data.length && item.data.some((dataItem) => dataItem.values.value)
          )
          .forEach((item) => {
            datasets.push(
              this.chartHelperService.getDataSetSkeleton(
                ChartHelper.getValueByFilterDates(item.data, selectedYearlabels),
                this.translateService.instant(item.label || item.value || '') as string,
                ColorHelper.get(item.value || item.label.replace('types.', '')) || item.color
              )
            );
          });
      }

      return {
        datasets,
        labels: selectedYearlabels,
      };
    } catch (e) {
      console.error('getBarStackedChartData', e);
      return {
        datasets: [],
        labels: [],
      };
    }
  }
}
