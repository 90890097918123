import { Inject, LOCALE_ID, Pipe, PipeTransform } from '@angular/core';

import { ValueUnitService } from '../helper/value-unit.service';
import { TransformedValueItem, Unit } from '../models/unit.enum';

@Pipe({
  name: 'formatNumber',
})
export class FormatNumberPipe implements PipeTransform {
  constructor(@Inject(LOCALE_ID) public locale: string) {}

  transform(value: string | number | null, unit?: Unit): TransformedValueItem {
    const valueItem = ValueUnitService.getTransformedValueItem(value, unit);
    // valueItem.value =
    //   (getLocaleCurrencyCode(valueItem.value) as number) || valueItem.value;
    return ValueUnitService.getMappedValueAuto(valueItem);
  }
}
