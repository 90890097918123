<div class="imprint-container page--static">
  <h2 class="headline2 mb-l">{{ 'imprint.headline' | translate }}</h2>

  <p class="content mb-l">
    <strong>ista SE</strong>
    <span>Luxemburger Straße 1</span>
    <span>45131 Essen</span>
    <span>{{ 'imprint.tel-label' | translate }}: +49 (0) 201 459-02</span>
    <span>Fax: +49 (0) 201 459-3630 </span>
    <span>E-Mail: info&#64;ista.de</span>
  </p>

  <p class="content mb-xl" [innerHTML]="'imprint.block1' | translate"></p>

  <p class="content mb-xl">
    <span class="h3">{{ 'imprint.subheadline1' | translate }}</span>
    <span>{{ 'imprint.block2' | translate }}</span>
  </p>

  <p class="content mb-xl">
    <span class="h3">{{ 'imprint.subheadline2' | translate }}</span>
    <span>{{ 'imprint.block3' | translate }}</span>
  </p>
</div>
