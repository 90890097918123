import { Component, Input } from '@angular/core';
import { OverlayService } from 'shared/ui/overlay/overlay.service';

@Component({
  selector: 'ista-daytona-button-delete-object',
  templateUrl: './button-delete-object.component.html',
  styleUrls: ['./button-delete-object.component.scss'],
})
export class ButtonDeleteObjectComponent {
  @Input() icon = '';
  @Input() stickyStyle = false;
  constructor(private overlayService: OverlayService) {}

  openDeleteOverlay(): void {
    this.overlayService.open(840, 840, {
      showDeleteObjectCard: true,
    });
  }
}
