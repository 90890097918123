import { createReducer, on } from '@ngrx/store';
import { PortfolioChartDefaultMainFilter } from 'core/constants';
import * as _ from 'lodash';
import { FilterDateRange, FilterTab } from 'shared/ui/filter/filter-tab.interface';

import { Tag } from 'core/tags/interface';
import {
  ConsumptionBoardId,
  ConsumptionInfo,
} from '../portfolio-consumption/consumption.interface';
import { GroupDetail, GroupSite } from '../portfolio-group/group-detail.interface';
import { SiteDetails } from 'app/features/portfolio/portfolio-site-masterdata/interfaces/site-details.interface';
import {
  resetGroupDetails,
  resetPortfolioState,
  resetSiteDetails,
  storeConsumptionBoardData,
  storeConsumptionBoardRequestStatus,
  storeGroupDetails,
  storeGroupSites,
  storeSelectedChartSubFilter,
  storeSelectedHeaderTab,
  storeSelectedMainFilter,
  storeSelectedSiteTableYear,
  storeSiteDetails,
  storeSiteTags,
  updateEditingPortfolioNavigationFinishedState,
  updateSiteDetailsLoading,
  updateSiteGeoLocation,
  updateTagsInSitesAreChanged
} from './portfolio.actions';

export const portfolioFeatureKey = 'portfolio';
export type reloadConsumptionAfterEditingNavigationIsFinishedStateType =
  | 0 // 0 means no
  | 1 // 1 treeChangeRequireReload But user doesn't finish Editing
  | 2; // 2 means treeChangeRequireReload and User finished Editing

export interface PortfolioState {
  groupSites: GroupSite[] | undefined;
  groupData: GroupDetail | undefined;
  consumptionBoardId: ConsumptionBoardId | undefined;
  consumptionBoard: ConsumptionInfo | undefined;
  loading: boolean;
  selectedHeaderTab?: FilterTab;
  selectedMainFilter?: FilterTab;
  selectedChartSubFilter?: string;
  selectedSiteTableYear: FilterDateRange | undefined;
  tagsInSitesAreChanged: boolean;
  masterdataSite: SiteDetails | undefined;
  masterDataIsLoading: boolean;
  siteTags?: Tag[];

  reloadConsumptionAfterEditingNavigationIsFinishedState: reloadConsumptionAfterEditingNavigationIsFinishedStateType;
}

export const portfolioState: PortfolioState = {
  consumptionBoardId: undefined,
  consumptionBoard: undefined,
  loading: false,
  selectedSiteTableYear: undefined,
  selectedChartSubFilter: 'heatingAndHotWater',
  selectedMainFilter: PortfolioChartDefaultMainFilter,
  masterdataSite: undefined,
  groupSites: undefined,
  masterDataIsLoading: false,
  siteTags: undefined,
  reloadConsumptionAfterEditingNavigationIsFinishedState: 0,
  groupData: undefined,
  tagsInSitesAreChanged: false,
};

export const mergeConsumptionInfo = (
  target: ConsumptionInfo | undefined,
  source: ConsumptionInfo
): ConsumptionInfo => {
  const merged: ConsumptionInfo = { ...target };

  // eslint-disable-next-line guard-for-in
  for (const key in source) {
    merged[key] = source[key];
  }

  return merged;
};

export const portfolioReducer = createReducer(
  portfolioState,
  on(storeConsumptionBoardRequestStatus, (state, action) => ({
    ...state,
    loading: action.loading,
  })),
  on(storeConsumptionBoardData, (state, action) => ({
    ...state,
    consumptionBoardId: action.consumptionBoardId,
    consumptionBoard: _.isEqual(state.consumptionBoardId, action.consumptionBoardId)
      ? mergeConsumptionInfo(state.consumptionBoard, action.consumptionBoard)
      : action.consumptionBoard,
  })),
  on(storeSelectedHeaderTab, (state, action) => ({
    ...state,
    selectedHeaderTab: action.selectedHeaderTab,
  })),
  on(storeSelectedMainFilter, (state, action) => ({
    ...state,
    selectedMainFilter: action.selectedMainFilter,
  })),
  on(storeSelectedChartSubFilter, (state, action) => ({
    ...state,
    selectedChartSubFilter: action.selectedChartSubFilter,
  })),
  on(storeSelectedSiteTableYear, (state, action) => ({
    ...state,
    selectedSiteTableYear: action.selectedSiteTableYear,
  })),
  on(storeSiteDetails, (state, action) => ({
    ...state,
    masterdataSite: action.siteDetails,
  })),
  on(resetSiteDetails, (state) => ({
    ...state,
    masterdataSite: undefined,
  })),
  on(storeGroupDetails, (state, action) => ({
    ...state,
    groupData: action.groupDetails,
  })),
  on(resetGroupDetails, (state) => ({
    ...state,
    groupData: undefined,
  })),
  on(storeGroupSites, (state, action) => ({
    ...state,
    groupSites: action.groupSites,
  })),
  on(updateSiteGeoLocation, (state, action) => ({
    ...state,
    masterdataSite: state.masterdataSite
      ? {
          ...state.masterdataSite,
          geoData: action.siteGeoData,
        }
      : undefined,
  })),
  on(updateSiteDetailsLoading, (state, action) => ({
    ...state,
    masterDataIsLoading: action.masterDataIsLoading,
  })),
  on(updateEditingPortfolioNavigationFinishedState, (state, action) => ({
    ...state,
    reloadConsumptionAfterEditingNavigationIsFinishedState: action.reloadState,
  })),
  on(storeSiteTags, (state, action) => ({
    ...state,
    siteTags: action.tags,
  })),
  on(resetPortfolioState, (state) => ({
    ...state,
    consumptionBoardId: undefined,
    consumptionBoard: undefined,
    loading: false,
  })),
  on(updateTagsInSitesAreChanged, (state, action) => ({
    ...state,
    tagsInSitesAreChanged: action.tagsInSitesAreChanged
  }))
);
