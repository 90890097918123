import { Component, Input } from '@angular/core';

import { TableDataGeneric, TableHeader } from '../basic/table/table.interface';
import { DeepLinkingIds } from 'core/deep-linking/deep-linking.interface';
import { ReportingConsumptionTabParamLabel } from 'shared/constants';

// @TODO Refactor the Table with Ista UI Library required Changes
// @TODO separate the Filter Logic outside the Component
// @TODO use generic Type
// @TODO Table should only Have Inputs and Outputs with OnPush ChangeDetection
// @TODO Table Component should only responsible of Display Date ONLY
@Component({
  selector: 'ista-daytona-table-site',
  templateUrl: './table-site.component.html',
  styleUrls: ['./table-site.component.scss'],
})
export class TableSiteComponent {
  @Input() data!: TableDataGeneric[];
  @Input() headerData!: TableHeader[];
  public searchStr = '';
  protected readonly DeepLinkingIds = DeepLinkingIds;
  protected readonly ReportingConsumptionTabParamLabel = ReportingConsumptionTabParamLabel;

  valueChange(event: Event): void {
    this.searchStr = (event.target as HTMLInputElement).value;
  }
}
