import { ChartItem } from '../chart.interface';
import { FilterDateRange } from '../../filter/filter-tab.interface';
import { DoughnutChart, DoughnutChartDataset } from './doughnut-chart.interface';
import { ColorHelper } from 'shared/helper/color-helper';

export class DoughnutChartMapper {
  static getDoughnutChartData(data: ChartItem[], selectedYear: FilterDateRange): DoughnutChart {
    try {
      const datasets: DoughnutChartDataset = {
        data: [],
        backgroundColor: [],
        hoverBackgroundColor: [],
        borderWidth: 0,
      };
      const labels: string[] = [];
      if (data && selectedYear) {
        data?.forEach((item) => {
          const data = item.data.find((dataItem) => dataItem.year === selectedYear.from);
          const cleanLabel = item.label?.replace('Share', '');
          const color = ColorHelper.get(cleanLabel + '-full') || ColorHelper.get(cleanLabel) || '';
          datasets.data.push(data?.values?.value || 0);
          datasets.backgroundColor.push(color);
          datasets.hoverBackgroundColor?.push(color);
          labels?.push(cleanLabel);
        });
      }

      return {
        datasets: [datasets],
        labels,
      };
    } catch (e) {
      console.error('getDoughnutChartData', e);
      return {
        datasets: [],
        labels: [],
      };
    }
  }
}
