import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { BasicModule } from '../basic/basic.module';
import { PageStepperModule } from '../page-stepper/page-stepper.module';
import { NotificationComponent } from './notification.component';

@NgModule({
  imports: [CommonModule, BasicModule, PageStepperModule],
  declarations: [NotificationComponent],
  exports: [NotificationComponent],
})
export class NotificationModule {}
