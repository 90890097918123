<div
  [ngClass]="{
    'py-1 px-3': isSmall,
    'p-4': !isSmall,
  }"
  class="border border-base-200 bg-base-50 text-center truncate"
>
  @if (value) {
    @if (value | formatNumber: unit; as formattedValue) {
      @if (formattedValue.value) {
        @if (formattedValue.value | number : getFormatNumberDigits(formattedValue.value) : 'de'; as roundedValue) {
          <span
            class="font-extrabold value-card"
            [ngClass]="{
              'text-2xl': isSmall,
              'text-4xl': !isSmall,
            }"
            [innerText]="roundedValue"
            [title]="roundedValue + ' ' + formattedValue.unitWithPrefix"
          >
        </span>
        }
        @if (formattedValue.unitWithPrefix) {
          <span
            class="ml-0.5 unit-card"
            [ngClass]="{
                'text-sm': isSmall,
                'text-xl': !isSmall,
              }"
            [innerText]="formattedValue.unitWithPrefix"
          ></span>
        }
      }
    }
  } @else {
    <span
      class="font-extrabold value-card"
      [ngClass]="{
        'text-2xl': isSmall,
        'text-4xl': !isSmall,
      }"
    >
    {{ nullValueDisplay }}
  </span>
  }
</div>
