<div *ngIf="data" class="card-container">
  <div class="inner-content">
    <div class="card-top">
      <div *ngIf="data?.header" class="header">
        <img
          *ngIf="data?.header?.icon"
          [alt]="data.header.icon?.text || '' | translate"
          [src]="data.header.icon?.url"
          [title]="data.header.icon?.text || '' | translate"
          data-cy="monitoring-card-image"
        />
        <span [innerText]="data.header.label | translate" data-cy="monitoring-card-label"></span>
      </div>
      <div
        *ngIf="displaySiteCount && siteCount && siteCount > 1"
        [contentTemplate]="toolTip"
        [position]="{
              originX: 'center',
              originY: 'top',
              overlayX: 'center',
              overlayY: 'bottom',
              offsetY: -12,
           }"
        class="site-count"
        customToolTip
      >
        <span [innerText]="siteCount" data-cy="monitoring-card-site-count"></span>
        <img
          *ngIf="data?.header?.icon"
          [alt]="'dashboard.site-count' | translate"
          [src]="IconHelper.get('site-count-building') || ''"
          [title]="'dashboard.site-count' | translate"
          data-cy="monitoring-card-site-count-icon"
        />
        <ng-template #toolTip>
          <div class="custom-tooltip">
            <span [innerText]="'dashboard.site-count-tooltip' | translate"></span>
          </div>
        </ng-template>
      </div>
    </div>

    <div [style.borderColor]="data.color" class="total">
      <div class="total-inner">
        <ista-daytona-value-unit
          [classContainer]="'monitoring-card__total--inner'"
          [classUnit]="'monitoring-card__total--unit'"
          [classValue]="'monitoring-card__total--value'"
          [displayValue]="displayValue"
          [value]="getValueItemOfSelectedYear()"
          data-cy="monitoring-card-unit"
        ></ista-daytona-value-unit>
      </div>
    </div>
    <div *ngIf="displayBars" class="bar-container">
      <div
        *ngFor="let bar of getDisplayedBars(); let even = even; let index = index"
        [style.backgroundColor]="getBackgroundColor(index)"
        [style.width]="getBarWidth(bar)"
        class="bar"
        data-cy="monitoring-card-bar"
      >
        <span [innerText]="bar?.label" data-cy="monitoring-card-bar--label"></span>
      </div>
    </div>
  </div>
</div>
