import { Component } from '@angular/core';
import { ContactFormComponent } from 'shared/ui/contact-form/contact-form.component';
import { TranslateModule } from '@ngx-translate/core';
import { PipesModule } from 'shared/pipes/pipes.module';
import { HeadlineComponent } from 'shared/ui/headline/headline.component';

/* eslint-disable @typescript-eslint/unbound-method */
@Component({
  selector: 'ista-daytona-contact',
  templateUrl: './contact.component.html',
  imports: [ContactFormComponent, TranslateModule, PipesModule, HeadlineComponent],
  standalone: true,
})
export class ContactComponent {}
