import { Overlay, OverlayConfig, OverlayRef } from '@angular/cdk/overlay';
import { ComponentPortal } from '@angular/cdk/portal';
import { Injectable } from '@angular/core';

import { OverlayComponent } from './overlay.component';

@Injectable({
  providedIn: 'root',
})
export class OverlayService {
  public overlayRef!: OverlayRef;
  constructor(private overlay: Overlay) {}

  public open(
    minHeight: number = 840,
    minWidth: number = 840,
    inputs?: Partial<OverlayComponent>
  ): void {
    this.overlayRef = this.overlay.create(this.getOverlayConfig(minHeight, minWidth));
    const componentPortal = new ComponentPortal(OverlayComponent);
    const ref = this.overlayRef.attach(componentPortal);
    Object.assign(ref.instance, inputs);
    this.overlayRef.backdropClick().subscribe(() => void this.overlayRef.detach());
  }

  public close(): void {
    this.overlayRef.detach();
  }

  private getOverlayConfig(minHeight: number, minWidth: number): OverlayConfig {
    const positionStrategy = this.overlay
      .position()
      .global()
      .centerHorizontally()
      .centerVertically();

    const overlayConfig = new OverlayConfig({
      hasBackdrop: true,
      backdropClass: 'overlay-background',
      panelClass: '',
      scrollStrategy: this.overlay.scrollStrategies.block(),
      positionStrategy,
      minHeight,
      minWidth,
    });

    return overlayConfig;
  }
}
