export interface ChartScale {
  numberOfSteps: number | null;
  maxValue: number;
}

export class ChartScaleHelper {
  public static calculateScaling(
    maxNumOfSeps: number,
    minNumOfSteps: number,
    values: number[]
  ): ChartScale {
    const biggestValue = Math.ceil(Math.max(...values));
    let maxValue = biggestValue - 1;
    let numberOfSteps: number | null = null;

    if (biggestValue > 0) {
      const steps = this.getSteps(biggestValue);

      while (numberOfSteps === null) {
        maxValue++;
        let found = false;
        let index = 0;
        while (index < steps.length && !found) {
          const possibleStepSize = steps[index];
          if (
            maxValue % possibleStepSize === 0 &&
            maxValue / possibleStepSize <= maxNumOfSeps &&
            maxValue / possibleStepSize >= minNumOfSteps
          ) {
            numberOfSteps = maxValue / possibleStepSize;
            found = true;
          }
          index++;
        }
      }
    }
    return { numberOfSteps, maxValue };
  }

  private static getSteps(biggestValue: number): number[] {
    let startingStepSize = 1;
    const steps: number[] = [];
    steps.push(startingStepSize);
    let factor = 5;

    // create mathematical series: 1, 5, 10, 50, 100, 500, 1000 ...
    while (startingStepSize < biggestValue) {
      startingStepSize = startingStepSize * factor;
      if (factor === 2) {
        factor = 5;
      } else {
        factor = 2;
      }
      steps.push(startingStepSize);
    }

    // now create mathematical series: 25, 250, 2500, 25000 ...
    startingStepSize = 25;
    steps.push(startingStepSize);
    factor = 10;
    while (startingStepSize < biggestValue) {
      startingStepSize = startingStepSize * factor;
      steps.push(startingStepSize);
    }
    return steps.sort();
  }
}
