import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { GoogleTagManagerService } from 'angular-google-tag-manager';

import { environment } from 'environments/environment';
import { LoggerService } from 'shared/helper/logger.service';
import { GtmEventInfo, GtmLayer } from './gtm-layer.interface';

@Injectable({
  providedIn: 'root',
})
export class TrackingWrapperService {
  constructor(
    private readonly _gtmService: GoogleTagManagerService,
    private readonly _translateService: TranslateService
  ) {
    this._gtmService.addGtmToDom();
  }

  public addGTM(eventInfo: GtmEventInfo, breadcrumbs: string[]): void {
    const gtmTag: GtmLayer = {
      event: eventInfo.eventCategory,
      eventInfo,
      page: {
        pageInfo: {
          pageName: document.title,
          hostname: document.location.hostname,
          documentURL: window.location.href || document.URL,
          sysEnv: environment.trackingEnv,
          breadcrumbs: breadcrumbs.join('|'),
          language: this._translateService.getBrowserLang() || navigator.language,
          referrer: document.referrer,
        },
      },
    };

    this._gtmService
      .pushTag(gtmTag)
      // .then((status) => LoggerService.log('gtmService status', status))
      .catch((error) => LoggerService.warn('gtmService Error', error));
    // LoggerService.log('data layer', this._gtmService.getDataLayer());
  }
}
