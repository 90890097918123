import { LanguageKeyHelper } from '../helper/language-key-helper';
import { FilterSubTab, FilterTab } from '../ui/filter/filter-tab.interface';
import { IconHelper } from '../helper/icon-helper';

export const filterOverview: FilterTab = {
  left: [
    {
      label: 'tabs.overview',
      icon: IconHelper.get('tab-overview'),
      active: false,
    },
  ],
  selectedYear: { from: '2020', until: '' },
};

export const filterDataGap: FilterTab = {
  left: [
    {
      label: 'tabs.data-gap',
      icon: IconHelper.get('tab-overview'),
      active: false,
    },
  ],
  selectedYear: { from: '2020', until: '' },
};

export enum FilterCORSType {
  CONSUMPTIONS = 'consumption',
  EMISSIONS = 'emissions',
  COSTS = 'costs',
}

export const filterCORS: FilterTab = {
  left: [
    {
      label: 'tabs.consumption',
      icon: IconHelper.get('tab-overview'),
      value: FilterCORSType.CONSUMPTIONS,
      active: true,
    },
    {
      label: 'tabs.emission',
      icon: IconHelper.get('co2'),
      value: FilterCORSType.EMISSIONS,
    },
    {
      label: 'tabs.cost',
      icon: IconHelper.get('euro'),
      value: FilterCORSType.COSTS,
    },
  ],
  selectedYear: { from: '', until: '' },
};

export const filterCORSSub: FilterSubTab = [
  {
    label: LanguageKeyHelper.get('heatingAndHotWater') || 'Wärme',
    icon: IconHelper.get('tab-overview'),
  },
  {
    label: LanguageKeyHelper.get('electricity') || 'Strom',
    icon: IconHelper.get('co2'),
  },
  {
    label: LanguageKeyHelper.get('water') || 'Wasser',
    icon: IconHelper.get('water'),
  },
  {
    label: LanguageKeyHelper.get('waste') || 'Abfall',
    icon: IconHelper.get('waste'),
    active: true,
    childs: [
      { label: 'Alle Abfälle' },
      { label: 'Restabfall' },
      { label: 'Gewerbeabfall' },
      { label: 'Papier + Pappe' },
      { label: 'Duales System' },
      { label: 'Speisereste' },
    ],
  },
];

export const filterRegion: FilterTab = {
  left: [
    {
      label: 'filter.portfolio-objects',
      icon: './assets/icons/navigation/monitoring.svg',
      active: false,
    },
  ],
  selectedYear: { from: '2021', until: '' },
};
