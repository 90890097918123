<div #tagEditWrapper [formGroup]="form">
  <mat-form-field class="w-full">
    <input
      [class.ng-invalid]="isNameAlreadyExistError"
      [errorStateMatcher]="tagInputErrorMatcher"
      [placeholder]="'tags.create-placeholder' | translate"
      autofocus
      class="text-base font-extrabold text-slate-900 placeholder:text-slate-400"
      formControlName="name"
      matInput
    />
    <mat-icon
      (click)="onDeleteClick()"
      [class.pointer-events-none]="form.disabled"
      class="icon-size-6 cursor-pointer hover:text-primary"
    >
      delete
    </mat-icon>
    @if (isRequiredError || isMaxLengthError) {
      <mat-error>
        {{ 'tags.create-tag-error-message' | translate }}
      </mat-error>
    }
    @if (isNameAlreadyExistError) {
      <mat-error>
        {{ 'tags.create-tag-name-exist-error-message' | translate }}
      </mat-error>
    }
  </mat-form-field>
</div>
