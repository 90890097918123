import { Component, EventEmitter, Input, Output } from '@angular/core';

import { BaseUi } from '../../base-ui/base-ui';

@Component({
  selector: 'ista-daytona-file-upload-step-upload',
  templateUrl: './file-upload-step-upload.component.html',
  styleUrls: ['./file-upload-step-upload.component.scss'],
})
export class FileUploadStepUploadComponent extends BaseUi {
  @Input() label = '';
  @Output() abortUploading = new EventEmitter<null>();
  public abortUpload(): void {
    this.abortUploading.next(null);
  }
}
