import { RouterStateSerializer } from '@ngrx/router-store';
import { ReducedRouteState } from './reduced-route-state';
import { ActivatedRouteSnapshot, Data, RouterStateSnapshot } from '@angular/router';

export class ReducedRouteSerialzer implements RouterStateSerializer<ReducedRouteState> {
  serialize(routerState: RouterStateSnapshot): ReducedRouteState {
    let route = routerState.root;

    while (route.firstChild) {
      route = route.firstChild;
    }

    const {
      url,
      root: { queryParams },
    } = routerState;
    const { params } = route;

    return {
      url,
      data: this.mergeRouteData(routerState.root),
      queryParams: queryParams ?? {},
      params: params ?? {},
    };
  }

  private mergeRouteData(route: ActivatedRouteSnapshot | null): Data {
    if (!route) {
      return {};
    }

    const currentData = route.data;
    const primaryChild = route.children.find((c) => c.outlet === 'primary') || route.firstChild;
    return { ...currentData, ...this.mergeRouteData(primaryChild) };
  }
}
