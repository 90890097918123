import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { BarComponent } from './bar/bar.component';
import { CircleComponent } from './circle/circle.component';
import { LoaderComponent } from './loader.component';
import { MatProgressSpinner } from '@angular/material/progress-spinner';
import { MatProgressBar } from '@angular/material/progress-bar';

@NgModule({
  imports: [CommonModule, MatProgressSpinner, MatProgressBar, BarComponent, CircleComponent, LoaderComponent],
  declarations: [],
  exports: [LoaderComponent],
})
export class LoaderModule {}
