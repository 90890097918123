<div class="bg-white p-8 rounded-md">
  <div class="flex justify-between">
    <a (click)="historyBack($event)" class="text-base text-primary flex items-center">
      <mat-icon class="icon-size-6 text-primary">arrow_back</mat-icon>
      <span class="ml-1 mt-auto">{{ 'no-environment-overlay.back' | translate }}</span>
    </a>
    <a class="text-base text-primary flex items-center" href="/logout">
      <mat-icon class="icon-size-6 text-primary">logout</mat-icon>
      <span class="ml-1 mt-auto">{{ 'no-environment-overlay.logout' | translate }}</span>
    </a>
  </div>
  <div class="flex justify-center items-center flex-col my-20 mx-24">
    <div class="mb-8">
      <mat-icon class="text-warn icon-size-16">person_search</mat-icon>
    </div>
    <h3 class="text-warn">{{ 'no-environment-overlay.headline' | translate }}</h3>
    <p
      [innerHTML]="'no-environment-overlay.subheadline' | translate | safe"
      class="text-center h4 my-8 font-normal"
    ></p>
    <p class="font-extrabold mb-8">{{ 'no-environment-overlay.info' | translate }}</p>
    <div class="flex justify-center items-center">
      <a (click)="copyEmailIntoClipBoard()" class="mr-12">{{
          'no-environment-overlay.esg-email' | translate
        }}</a>
      <ista-daytona-button
        (click)="openEmailClientProgram()"
        [label]="'no-environment-overlay.email' | translate"
      ></ista-daytona-button>
    </div>
  </div>
</div>
