import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { VisibilityObserveDirective } from 'shared/directives/visibility-observe.directive';

import { BoxShadowDirective } from './box-shadow.directive';
import { ScrollToDirective } from './scroll-to.directive';
import { VarDirective } from './var.directive';

const DIRECTIVES = [
  BoxShadowDirective,
  ScrollToDirective,
  VisibilityObserveDirective,
  VarDirective,
];

@NgModule({
  imports: [CommonModule],
  declarations: [...DIRECTIVES],
  exports: [...DIRECTIVES],
})
export class DirectivesModule {}
