import { CommonModule, NgOptimizedImage } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatIconModule } from '@angular/material/icon';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { DirectivesModule } from 'shared/directives/directives.module';
import { PipesModule } from 'shared/pipes/pipes.module';
import { ButtonDataAddComponent } from 'shared/ui/basic/button-data-add/button-data-add.component';
import { KeyValueCardComponent } from 'shared/ui/basic/key-value-card/key-value-card.component';

import { AverageDisplayComponent } from './average-display/average-display.component';
import { ButtonDeleteObjectComponent } from './button-delete-object/button-delete-object.component';
import { ButtonEditComponent } from './button-edit/button-edit.component';
import { ButtonExportComponent } from './button-export/button-export.component';
import { ButtonModule } from './button/button.module';
import { CardComponent } from './card/card.component';
import { CheckboxComponent } from './checkbox/checkbox.component';
import { ContentCardOpenComponent } from './content-card-open/content-card-open.component';
import { ContentCardComponent } from './content-card/content-card.component';
import { ContentOffsetComponent } from './content-offset/content-offset.component';
import { DropdownModule } from './dropdown/dropdown.module';
import { FullWidthSelectComponent } from './full-width-select/full-width-select.component';
import { IconModule } from './icon/icon.module';
import { InputSearchModule } from './input-search/input-search.module';
import { InputModule } from './input/input.module';
import { LabelModule } from './label/label.module';
import { LoaderModule } from './loader/loader.module';
import { PaginationAlphabetComponent } from './pagination-alphabet/pagination-alphabet.component';
import { RadiobuttonComponent } from './radiobutton/radiobutton.component';
import { SelectComponent } from './select/select.component';
import { SelectionComponent } from './selection/selection.component';
import { TableModule } from './table/table.module';
import { TextareaComponent } from './textarea/textarea.component';
import { TooltipModule } from './tooltip/tooltip.module';
import { ValueUnitModule } from './value-unit/value-unit.module';
import { MatCheckbox } from '@angular/material/checkbox';
import { MatButton } from '@angular/material/button';

const imExport = [
  ContentCardComponent,
  CardComponent,
  ContentOffsetComponent,
  ButtonExportComponent,
  TextareaComponent,
  AverageDisplayComponent,
  ButtonDataAddComponent,
  SelectionComponent,
  SelectComponent,
  ButtonDeleteObjectComponent,
  ButtonEditComponent,
  KeyValueCardComponent,
  FullWidthSelectComponent,
];

@NgModule({
  exports: [...imExport, ButtonModule],
  declarations: [...imExport],
  imports: [
    PaginationAlphabetComponent,
    ContentCardOpenComponent,
    RadiobuttonComponent,
    CommonModule,
    FormsModule,
    RouterModule,
    TranslateModule,
    MatRadioModule,
    TableModule,
    TooltipModule,
    DropdownModule,
    InputModule,
    IconModule,
    ButtonModule,
    LoaderModule,
    DirectivesModule,
    HttpClientModule,
    PipesModule,
    ValueUnitModule,
    LabelModule,
    InputSearchModule,
    CheckboxComponent,
    MatSelectModule,
    MatIconModule,
    NgOptimizedImage,
    MatCheckbox,
    MatButton,
  ],
})
export class BasicModule {}
