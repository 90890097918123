import { CustomColors } from 'shared/constants/colors';

export class CustomColorsHelper {
  static getColorVariable(key: string, shade: string = 'DEFAULT'): string {
    try {
      if (CustomColors.hasOwnProperty(key)) {
        const colorVar = CustomColors[key][shade];
        return colorVar ? colorVar.split('(')[1].split(')')[0] : '';
      }
      return '';
    } catch (e) {
      console.error('getColorVariable', e);
      return '';
    }
  }

  static getColorHex(key: string, shade: string = 'DEFAULT'): string {
    try {
      const rootStyles = getComputedStyle(document.documentElement);
      if (CustomColors.hasOwnProperty(key)) {
        const colorVariableName = CustomColorsHelper.getColorVariable(key, shade);
        return rootStyles.getPropertyValue(colorVariableName).trim();
      }
      return '';
    } catch (e) {
      console.error('getColorHex', e);
      return '';
    }
  }
}
