type TailwindColors = {
  [colorName: string]: {
    [shade: string]: string;
  };
};

export const CustomColors: TailwindColors = {
  'heating-district': {
    800: 'var(--heating-district-800)',
    DEFAULT: 'var(--heating-district-800)',
  },
  'heating-electricity': {
    800: 'var(--heating-electricity-800)',
    DEFAULT: 'var(--heating-electricity-800)',
  },
  electricity: {
    800: 'var(--electricity-800)',
    500: 'var(--electricity-500)',
    '500/50': 'var(--electricity-500-50)',
    '500/20': 'var(--electricity-500-20)',
    DEFAULT: 'var(--electricity-800)',
  },
  gas: {
    800: 'var(--gas-800)',
    500: 'var(--gas-500)',
    '500/50': 'var(--gas-500-50)',
    '500/20': 'var(--gas-500-20)',
    DEFAULT: 'var(--gas-800)',
  },
  water: {
    800: 'var(--water-800)',
    500: 'var(--water-500)',
    '500/50': 'var(--water-500-50)',
    '500/20': 'var(--water-500-20)',
    DEFAULT: 'var(--water-800)',
  },
  waste: {
    800: 'var(--waste-800)',
    500: 'var(--waste-500)',
    '500/50': 'var(--waste-500-50)',
    '500/20': 'var(--waste-500-20)',
    DEFAULT: 'var(--waste-800)',
  },
  cold: {
    800: 'var(--cold-800)',
    500: 'var(--cold-500)',
    '500/50': 'var(--cold-500-50)',
    '500/20': 'var(--cold-500-20)',
    DEFAULT: 'var(--cold-800)',
  },
  heating: {
    800: 'var(--heating-800)',
    500: 'var(--heating-500)',
    '500/50': 'var(--heating-500-50)',
    '500/20': 'var(--heating-500-20)',
    DEFAULT: 'var(--heating-800)',
  },
  cost: {
    800: 'var(--cost-800)',
    500: 'var(--cost-500)',
    '500/50': 'var(--cost-500-50)',
    '500/20': 'var(--cost-500-20)',
    DEFAULT: 'var(--cost-800)',
  },
  co2: {
    800: 'var(--co2-800)',
    500: 'var(--co2-500)',
    '500/50': 'var(--co2-500-50)',
    '500/20': 'var(--co2-500-20)',
    DEFAULT: 'var(--co2-800)',
  },
  biomass: {
    800: 'var(--biomass-800)',
    500: 'var(--biomass-500)',
    '500/50': 'var(--biomass-500-50)',
    '500/20': 'var(--biomass-500-20)',
    DEFAULT: 'var(--biomass-800)',
  },
  other: {
    800: 'var(--other-800)',
    500: 'var(--other-500)',
    '500/50': 'var(--other-500-50)',
    '500/20': 'var(--other-500-20)',
    DEFAULT: 'var(--other-800)',
  },
  neutral: {
    800: 'var(--neutral-800)',
    500: 'var(--neutral-500)',
    '500/50': 'var(--neutral-500-50)',
    '500/20': 'var(--neutral-500-20)',
    DEFAULT: 'var(--neutral-800)',
  },
  oil: {
    800: 'var(--oil-800)',
    500: 'var(--oil-500)',
    '500/50': 'var(--oil-500-50)',
    '500/20': 'var(--oil-500-20)',
    DEFAULT: 'var(--oil-800)',
  },
  crrem: {
    DEFAULT: 'var(--crrem-line)',
  },
  'crrem-portfolio': {
    DEFAULT: 'var(--crrem-line-portfolio)',
  },
  'crrem-stranding-circle': {
    DEFAULT: 'var(--crrem-stranding-circle)',
  },
};
