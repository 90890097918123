<ng-container *ngIf="selectYearsWithDataSortedDesc$ | async as years">
  <button
    mat-flat-button [matMenuTriggerFor]="yearSelectionMenu"
    color="primary"
    class="pl-4 pr-2.5 py-2"
    (menuOpened)="chevronType = 'arrow_drop_up'"
    (menuClosed)="chevronType = 'arrow_drop_down'"
  >
    <div class="flex flex-row gap-1 items-center">
      <span [innerHTML]="selectedYear"></span>
      <mat-icon [innerHTML]="chevronType"></mat-icon>
    </div>
  </button>
  <mat-menu #yearSelectionMenu="matMenu" class="max-h-80">
    <ng-container *ngFor="let year of years">
      <div>
        <button
          (click)="onSelectYearChange({value: year})"
          mat-menu-item
          [value]="year"
        >
          {{ year }}
        </button>
      </div>
    </ng-container>
  </mat-menu>
</ng-container>
