<ista-daytona-content-card-open [labels]="['Usercentrics']">
  <p>
    {{ 'usercentrics.block1' | translate }}
  </p>
  <h4>{{ 'usercentrics.subheadline1' | translate }}</h4>
  <p>
    {{ 'usercentrics.block2' | translate }}
  </p>
  <ul>
    <li>
      <span
        ><span
          ><span
            ><span
              ><span>{{ 'usercentrics.list1' | translate }}</span></span
            ></span
          ></span
        ></span
      >
    </li>
    <li>
      <span
        ><span
          ><span
            ><span
              ><span>{{ 'usercentrics.list2' | translate }}</span></span
            ></span
          ></span
        ></span
      >
    </li>
  </ul>
  <h4>{{ 'usercentrics.subheadline2' | translate }}</h4>
  <ul>
    <li>
      <span
        ><span
          ><span
            ><span
              ><span>{{ 'usercentrics.list3' | translate }}</span></span
            ></span
          ></span
        ></span
      >
    </li>
    <li>
      <span
        ><span
          ><span
            ><span
              ><span>{{ 'usercentrics.list4' | translate }}</span></span
            ></span
          ></span
        ></span
      >
    </li>
  </ul>
  <h4>{{ 'usercentrics.subheadline3' | translate }}</h4>
  <p>
    {{ 'usercentrics.block3' | translate }}
  </p>
  <ul>
    <li>
      <span
        ><span
          ><span
            ><span
              ><span>{{ 'usercentrics.list5' | translate }}</span></span
            ></span
          ></span
        ></span
      >
    </li>
    <li>
      <span
        ><span
          ><span
            ><span
              ><span>{{ 'usercentrics.list6' | translate }}</span></span
            ></span
          ></span
        ></span
      >
    </li>
    <li>
      <span
        ><span
          ><span
            ><span
              ><span>{{ 'usercentrics.list7' | translate }}</span></span
            ></span
          ></span
        ></span
      >
    </li>
    <li>
      <span
        ><span
          ><span
            ><span
              ><span>{{ 'usercentrics.list8' | translate }}</span></span
            ></span
          ></span
        ></span
      >
    </li>
    <li>
      <span
        ><span
          ><span
            ><span
              ><span>{{ 'usercentrics.list9' | translate }}</span></span
            ></span
          ></span
        ></span
      >
    </li>
    <li>
      <span
        ><span
          ><span
            ><span
              ><span>{{ 'usercentrics.list10' | translate }}</span></span
            ></span
          ></span
        ></span
      >
    </li>
    <li>
      <span
        ><span
          ><span
            ><span
              ><span>{{ 'usercentrics.list11' | translate }}</span></span
            ></span
          ></span
        ></span
      >
    </li>
  </ul>
  <h4>{{ 'usercentrics.subheadline4' | translate }}</h4>
  <p>
    {{ 'usercentrics.block4' | translate }}
  </p>
  <ul>
    <li>
      <span
        ><span
          ><span
            ><span
              ><span>{{ 'usercentrics.list12' | translate }}</span></span
            ></span
          ></span
        ></span
      >
    </li>
  </ul>
  <h4>{{ 'usercentrics.subheadline5' | translate }}</h4>
  <p>{{ 'usercentrics.block5' | translate }}</p>
  <h4>{{ 'usercentrics.subheadline6' | translate }}</h4>
  <p>
    {{ 'usercentrics.block6' | translate }}
  </p>
  <p>
    {{ 'usercentrics.block7' | translate }}
  </p>
  <h4>{{ 'usercentrics.subheadline7' | translate }}</h4>
  <ul>
    <li>
      <span
        ><span
          ><span
            ><span
              ><span>{{ 'usercentrics.list13' | translate }}</span></span
            ></span
          ></span
        ></span
      >
    </li>
  </ul>
  <h4>{{ 'usercentrics.subheadline8' | translate }}</h4>
  <p>
    {{ 'usercentrics.block8' | translate }}
  </p>
  <p>
    <span class="align-left"
      ><a href="mailto:datenschutz@usercentrics.com" target="_blank"
        >datenschutz&#64;usercentrics.com</a
      ></span
    >
  </p>
  <p>
    <strong>{{ 'usercentrics.block9' | translate }}</strong
    ><br /><span class="align-left"
      ><u
        ><a href="https://usercentrics.com/privacy-policy/" target="_blank" rel="noopener"
          >https://usercentrics.com/privacy-policy/</a
        ></u
      ></span
    >
  </p>
  <p>
    <strong>{{ 'usercentrics.block10' | translate }}</strong>
  </p>
  <a class="btn-primary mt-2" onclick="UC_UI.showSecondLayer();">
    {{ 'usercentrics.link-text' | translate }}
  </a>
</ista-daytona-content-card-open>
