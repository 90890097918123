@if (item) {
  <div>
    <div
      (dragOver)="handleDragOver({ event: $event, parentGroup })"
      (dragStart)="dragSiteStart($event)"
      drag
      [enableDrag]="enableDrag"
      (drop)="handleDrop($event)"
      class="mat-tree-node justify-center flex-col text-sm font-medium cursor-pointer hover:text-cta site-item-wrapper"
    >
      @if (showEditingOptions) {
        <ista-daytona-editing-item-options
          (delete)="
            deleteSite({
              event: $event,
              site: item
            })
          "
          [item]="item"
          [showDragIcon]="showDragIcon"
          class="w-full inline-flex self-stretch pr-1"
        ></ista-daytona-editing-item-options>
      } @else {
        <div
          (click)="onItemClick(item)"
          [ngClass]="{
                   'text-primary-500': isNodeActive$ | async,
                   'text-base-900': !(isNodeActive$ | async),
                  }"
          class="flex items-center hover:text-cta w-full text-sm font-medium site-item-label"
        >
          <mat-icon [svgIcon]="'buildings'" class="icon-size-6 mr-4 text-inherit"></mat-icon>
          <span [title]="item.name" class="truncate">{{ item.name }}</span>
        </div>
      }
    </div>
    @if (item.sites.length) {
      <div class="flex flex-col pl-2">
        @for (child of item.sites; track child.id) {
          <div>
            <ista-daytona-site-item
              (dragOver)="handleDragOver($event)"
              [parentGroup]="parentGroup"
              (dropOver)="handleDrop($event)"
              [enableDrag]="false"
              [item]="child"
              [showDragIcon]="false"
              [showEditingOptions]="showEditingOptions"
              (delete)="deleteSite($event)"
              (itemClick)="onItemClick($event)"
            ></ista-daytona-site-item>
          </div>
        }
      </div>
    }
  </div>
}
