import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { DecimalPipe, NgClass } from '@angular/common';
import { Unit } from 'shared/models/unit.enum';
import { PipesModule } from 'shared/pipes/pipes.module';
import { nullValueDisplay } from 'core/constants';
import { Utils } from 'shared/helper/utils';

@Component({
  selector: 'ista-daytona-value-unit-card',
  templateUrl: 'value-unit-card.component.html',
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [NgClass, PipesModule, DecimalPipe],
})
export class ValueUnitCardComponent {
  @Input() size: 'small' | 'big' = 'small';
  @Input({ required: true }) value?: string | number | null;
  @Input() unit?: Unit;

  nullValueDisplay = nullValueDisplay;

  get isSmall(): boolean {
    return this.size === 'small';
  }

  getFormatNumberDigits(value?: string | number | null): string {
    return Utils.getFormatNumberDigits(value);
  }
}
