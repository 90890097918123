import {
  ConnectedPosition,
  Overlay,
  OverlayPositionBuilder,
  OverlayRef,
} from '@angular/cdk/overlay';
import { ComponentPortal } from '@angular/cdk/portal';
import {
  ComponentRef,
  Directive,
  ElementRef,
  HostListener,
  inject,
  Input,
  OnDestroy,
  OnInit,
  TemplateRef,
} from '@angular/core';

import { TooltipComponent } from './tooltip.component';

/* eslint-disable @typescript-eslint/member-ordering */
@Directive({
  selector: '[customToolTip]',
})
export class ToolTipRendererDirective implements OnInit, OnDestroy {
  /** This will be used to show tooltip or not This can be used to show the tooltip conditionally */
  @Input() showToolTip = true;

  // If this is specified then the specified text will be shown in the tooltip
  @Input() text!: string;

  // If this is specified then specified template will be rendered in the tooltip
  @Input() contentTemplate!: TemplateRef<any>;

  @Input() position: ConnectedPosition | undefined;

  @Input() indicatorPosition: 'top' | 'right' | 'bottom' | 'left' = 'bottom';

  private _overlayRef!: OverlayRef;
  private _overlay = inject(Overlay);
  private _overlayPositionBuilder = inject(OverlayPositionBuilder);
  private _elementRef = inject(ElementRef);

  /** Init life cycle event handler */
  ngOnInit(): void {
    if (!this.showToolTip) {
      return;
    }

    // you can take the position as an input to adjust the position
    // , for now, it will show at the bottom always; but you can adjust your code as per your need
    const positionStrategy = this._overlayPositionBuilder
      .flexibleConnectedTo(this._elementRef)
      .withPositions(
        this.position
          ? [this.position]
          : [
              {
                originX: 'center',
                originY: 'top',
                overlayX: 'center',
                overlayY: 'bottom',
                offsetY: -5,
              },
            ]
      );

    this._overlayRef = this._overlay.create({ positionStrategy });
  }

  /**
   * This method will be called whenever the mouse enters in the Host element i.e. where this
   * directive is applied This method will show the tooltip by instantiating the
   * CustomToolTipComponent and attaching to the overlay
   */
  @HostListener('mouseenter')
  show(): void {
    // attach the component if it has not already attached to the overlay
    if (this._overlayRef && !this._overlayRef.hasAttached()) {
      const tooltipRef: ComponentRef<TooltipComponent> = this._overlayRef.attach(
        new ComponentPortal(TooltipComponent)
      );
      tooltipRef.instance.text = this.text;
      tooltipRef.instance.contentTemplate = this.contentTemplate;
      tooltipRef.instance.indicatorPosition = this.indicatorPosition;
    }
  }

  /**
   * This method will be called when the mouse goes out of the host element i.e. where this
   * directive is applied This method will close the tooltip by detaching the overlay from the view
   */
  @HostListener('mouseleave')
  hide(): void {
    this.closeToolTip();
  }

  /** Destroy lifecycle event handler This method will make sure to close the tooltip */
  ngOnDestroy(): void {
    this.closeToolTip();
  }

  /** This method will close the tooltip by detaching the component from the overlay */
  private closeToolTip(): void {
    if (this._overlayRef) {
      this._overlayRef.detach();
    }
  }
}
