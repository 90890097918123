import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { NgxFileDropModule } from 'ngx-file-drop';

import { BasicModule } from '../basic/basic.module';
import { FileUploadStepFileComponent } from './file-upload-step-file/file-upload-step-file.component';
import { FileUploadStepFinishedComponent } from './file-upload-step-finished/file-upload-step-finished.component';
import { FileUploadResultBillingComponent } from './file-upload-step-result/file-upload-result-billing/file-upload-result-billing.component';
import { FileUploadResultEfileComponent } from './file-upload-step-result/file-upload-result-efile/file-upload-result-efile.component';
import { FileUploadStepResultComponent } from './file-upload-step-result/file-upload-step-result.component';
import { FileUploadStepUploadComponent } from './file-upload-step-upload/file-upload-step-upload.component';
import { FileUploadComponent } from './file-upload.component';
import { FileUploadService } from './file-upload.service';
import { LoaderModule } from 'shared/ui/basic/loader/loader.module';
import { ButtonModule } from 'shared/ui/basic/button/button.module';

@NgModule({
  imports: [
    CommonModule,
    NgxFileDropModule,
    BasicModule,
    HttpClientModule,
    RouterModule,
    TranslateModule,
    LoaderModule,
    ButtonModule,
  ],
  declarations: [
    FileUploadComponent,
    FileUploadStepFileComponent,
    FileUploadStepResultComponent,
    FileUploadStepUploadComponent,
    FileUploadStepFinishedComponent,
    FileUploadResultEfileComponent,
    FileUploadResultBillingComponent,
  ],
  exports: [FileUploadComponent],
  providers: [FileUploadService],
})
export class FileUploadModule {}
