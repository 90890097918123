<div class="finish-info-container">
  <div class="grid centered finish-info-container--content">
    <ng-container [ngSwitch]="isDataValid">
      <div *ngSwitchCase="fileUploadValidStatus.VALID" class="grid middle">
        <h4>{{ 'file-upload.step.success' | translate }}</h4>
        <img src="assets/icons/circle-success.svg" alt="yea baby" title="yea baby" />
      </div>

      <div *ngSwitchDefault class="grid middle">
        <h4 *ngIf="isDataValid === fileUploadValidStatus.INVALID">
          {{ 'file-upload.step.invalid' | translate }}
        </h4>

        <h4
          *ngIf="isDataValid === fileUploadValidStatus.INVALD_TO_UPLOAD"
          [innerHTML]="'file-upload.step.invalid-upload' | translate"
        ></h4>

        <img src="assets/icons/warning.svg" alt="oh oh some error" title="oh oh some error" />
      </div>
    </ng-container>
    <div class="button-group--horizontal">
      <ista-daytona-button
        [label]="'file-upload.step.report-button-label' | translate"
        (buttonClicked)="loadErrorReport.emit(null)"
        *ngIf="isDataValid === fileUploadValidStatus.INVALD_TO_UPLOAD"
      ></ista-daytona-button>
      <ista-daytona-button
        [label]="'file-upload.step.close-button-label' | translate"
        type="secondary"
        (buttonClicked)="abortUploading.emit(null)"
      ></ista-daytona-button>
    </div>
  </div>
</div>
