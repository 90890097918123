import { Injectable } from '@angular/core';
import { MultipleChartItem } from '../chart.interface';
import { FilterDateRange } from '../../filter/filter-tab.interface';
import { ChartConfiguration } from 'chart.js';
import { ChartHelperService } from '../chart-helper.service';
import { ChartHelper } from 'shared/helper/chart-helper';

@Injectable()
export class BarHorizontalMultiChartService {
  constructor(private chartHelperService: ChartHelperService) {}

  getMultiBarChartData(
    multipleData: MultipleChartItem,
    filterBy: string,
    selectedYear: FilterDateRange,
    overwriteColor: string[],
    overwriteLabels: string[]
  ): ChartConfiguration['data'] {
    try {
      const datasets: any[] = [];
      let selectedYearlabels: string[] = [];

      let index = 0;
      Object.keys(multipleData).forEach((key) => {
        const data = multipleData[key];
        if (data && selectedYear) {
          selectedYearlabels = data[0]?.data
            .filter((item) => item.year >= selectedYear.from && item.year <= selectedYear.until)
            .map((item) => item.year);

          const displayData = data?.filter((item) => item.value === filterBy);
          displayData?.forEach((item) => {
            datasets.push(
              this.chartHelperService.getDataSetSkeleton(
                ChartHelper.getValueByFilterDates(item.data, selectedYearlabels),
                overwriteLabels[index] || item.label || item.value || '',
                overwriteColor[index] || item.color,
                0.5,
                0.8
              )
            );
          });
        }
        index++;
      });

      return {
        datasets,
        labels: selectedYearlabels,
      };
    } catch (e) {
      console.error('getMultiBarChartData', e);
      return {
        datasets: [],
        labels: [],
      };
    }
  }
}
