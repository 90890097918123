import { Overlay } from '@angular/cdk/overlay';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { DropdownComponent } from './dropdown.component';
import { DropdownDirective } from './dropdown.directive';

@NgModule({
  imports: [CommonModule],
  declarations: [DropdownComponent, DropdownDirective],
  exports: [DropdownComponent, DropdownDirective],
  providers: [Overlay],
})
export class DropdownModule {}
