/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/no-empty-function */
/* eslint-disable @typescript-eslint/member-ordering */
/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable no-bitwise */
import { Component, Input } from '@angular/core';
import { ControlValueAccessor } from '@angular/forms';

@Component({ template: '' })
export abstract class BaseInputComponent<T> implements ControlValueAccessor {
  @Input() label = '';
  @Input() isDisabled = false;
  @Input() dataQa = 'input-' + this._getUuidv4();
  @Input() maxLength = 1400;
  get value(): T {
    return this._value;
  }
  set value(val: T) {
    this._value = val;
    this.onChange(val);
    this.onTouch(val);
  }

  public onChange: any = () => {};
  public onTouch: any = () => {};
  public uuid = this._getUuidv4();
  private _value!: T;

  public writeValue(value: T): void {
    if (value instanceof String) {
      this.value = value?.substring(1, this.maxLength) as unknown as T;
    } else {
      this.value = value;
    }
  }

  public registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  public registerOnTouched(fn: any): void {
    this.onTouch = fn;
  }

  private _getUuidv4(): string {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, (c) => {
      const r = (Math.random() * 16) | 0;
      const v = c === 'x' ? r : (r & 0x3) | 0x8;

      return v.toString(16);
    });
  }
}
