import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  HostListener,
  Input,
  ViewChild,
} from '@angular/core';
import { ChartConfiguration, ChartType } from 'chart.js';

import { CardHeaderConfig } from 'shared/models/card-header-config.interface';
import { ValueItem } from 'shared/models/value-item.interface';
import { AbstractChartComponent } from '../abstract-chart.component';
import { DoughnutOptions } from './doughnut-chart-options';
import * as _ from 'lodash';
import { DoughnutChartMapper } from 'shared/ui/chart/doughnut-chart/doughnut-chart.mapper';
import { CustomIcon } from 'shared/interfaces/custom-icon.interface';
import { Utils } from 'shared/helper/utils';

/* eslint-disable @typescript-eslint/member-ordering */

/* eslint-disable @typescript-eslint/no-unsafe-call */
@Component({
  selector: 'ista-daytona-doughnut-chart',
  templateUrl: './doughnut-chart.component.html',
  styleUrls: ['./doughnut-chart.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DoughnutChartComponent extends AbstractChartComponent implements AfterViewInit {
  @ViewChild('chart') chart!: ElementRef<HTMLElement>;
  @ViewChild('infoText') infoText!: ElementRef<HTMLElement>;
  @Input() icon?: CustomIcon;
  public doughnutOptions = DoughnutOptions;
  public doughnutType: ChartType = 'doughnut';
  public infoTextLeft = 0;
  public doughnutChartData!: ChartConfiguration['data'];
  public value!: ValueItem;

  constructor(private readonly _ref: ChangeDetectorRef) {
    super();
  }

  private _config!: CardHeaderConfig;

  get config(): CardHeaderConfig {
    return this._config;
  }

  @Input() set config(val: CardHeaderConfig) {
    this._config = val;
    this.value = {
      value: parseFloat(this._config.label),
      unit: this._config.unit,
    };
  }

  getFormatNumberDigits(value?: string | number | null): string {
    return Utils.getFormatNumberDigits(value);
  }

  @HostListener('window:resize', ['$event'])
  onResize(): void {
    this.setLabelPosition();
  }

  ngAfterViewInit(): void {
    this.setLabelPosition();
  }

  public override _refreshData(): void {
    const newChartData = DoughnutChartMapper.getDoughnutChartData(this.data, this.selectedYear);
    if (!_.isEqual(this.doughnutChartData, newChartData)) this.doughnutChartData = newChartData;
    this._ref.detectChanges();
  }

  public setLabelPosition(): void {
    const infoWidth = this.infoText?.nativeElement?.clientWidth;
    const chartWidth = this.chart?.nativeElement?.clientWidth;
    this.infoTextLeft = (chartWidth - infoWidth) / 2;
    this._ref.detectChanges();
  }
}
