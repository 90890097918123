<div class="distribution-chart--container">
  <ng-container *ngIf="data">
    <div class="chart">
      <div
        class="chart--item"
        *ngFor="let item of data; let first = first; let last = last; let index = index"
        [style.background-color]="item.color"
        [style.width.%]="getItemWidth(item.data)"
        [class]="{
          'corner-left': isLeftRadius(index),
          'corner-right': isRightRadius(index)
        }"
        [title]="getItemWidth(item.data) + ' %'"
      ></div>
    </div>
    <div class="legend">
      <ng-container *ngFor="let item of data">
        <div class="legend--item" *ngIf="getItemWidth(item.data) > 0">
          <span class="box" [style.background-color]="item.color"></span>
          <span class="label" [innerText]="item.label | translate"></span>
        </div>
      </ng-container>
    </div>
  </ng-container>
</div>
