@if (envs.length > 1) {
  <div class="environment-container">
    <ista-daytona-environment-select
      [envs]="envs"
      [selectedEnv]="selectedEnv"
      (selectedEnvChanged)="onSelectChange($event)"
    />
  </div>
} @else {
  <p>{{ 'environment.envFailure' | translate }}</p>
}
