<div class="flex gap-4 items-center justify-end">
  <ng-container *ngIf="showBoxShadow; else withoutBoxShadow">
    <button addBoxShadow>
      <img
        src="/assets/icons/prev.svg"
        [alt]="'common.prev-page' | translate"
        [title]="'common.prev-page' | translate"
        (click)="prevPage()"
        [class.disabled]="!isPrevPagePossible()"
      />
    </button>
    <ng-container *ngTemplateOutlet="linkToContent"></ng-container>
    <button addBoxShadow>
      <img
        src="/assets/icons/next.svg"
        [alt]="'common.next-page' | translate"
        [title]="'common.next-page' | translate"
        (click)="nextPage()"
        [class.disabled]="!isNextPagePossible()"
      />
    </button>
  </ng-container>
  <ng-template #withoutBoxShadow>
    <button>
      <img
        src="/assets/icons/prev.svg"
        [alt]="'common.prev-page' | translate"
        [title]="'common.prev-page' | translate"
        (click)="prevPage()"
        [class.disabled]="!isPrevPagePossible()"
      />
    </button>
    <ng-container *ngTemplateOutlet="linkToContent"></ng-container>
    <button>
      <img
        src="/assets/icons/next.svg"
        [alt]="'common.next-page' | translate"
        [title]="'common.next-page' | translate"
        (click)="nextPage()"
        [class.disabled]="!isNextPagePossible()"
      />
    </button>
  </ng-template>

  <ng-template #linkToContent>
    <ng-content></ng-content>
  </ng-template>
</div>
