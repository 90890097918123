<ista-daytona-content-card-open [labels]="['Google Tag Manager']">
  <p>
    {{ 'google.block1' | translate }}
  </p>
  <h4>{{ 'google.subheadline1' | translate }}</h4>
  <p>
    {{ 'google.block2' | translate }}
  </p>
  <ul>
    <li>
      <span
        ><span
          ><span
            ><span
              ><span>{{ 'google.list1' | translate }}</span></span
            ></span
          ></span
        ></span
      >
    </li>
  </ul>
  <h4>{{ 'google.subheadline2' | translate }}</h4>
  <ul>
    <li>
      <span
        ><span
          ><span
            ><span><span>Java Script</span></span></span
          ></span
        ></span
      >
    </li>
  </ul>
  <h4>{{ 'google.subheadline3' | translate }}</h4>
  <ul>
    <li>
      <span
        ><span
          ><span
            ><span
              ><span>{{ 'google.list2' | translate }}</span></span
            ></span
          ></span
        ></span
      >
    </li>
  </ul>
  <h4>{{ 'google.subheadline4' | translate }}</h4>
  <p>
    {{ 'google.block3' | translate }}
  </p>
  <ul>
    <li>
      <span
        ><span
          ><span
            ><span
              ><span>{{ 'google.list3' | translate }}</span></span
            ></span
          ></span
        ></span
      >
    </li>
  </ul>
  <h4>{{ 'google.subheadline5' | translate }}</h4>
  <ul>
    <li>
      <span
        ><span
          ><span
            ><span>{{ 'google.list4' | translate }}</span></span
          ></span
        ></span
      >
    </li>
  </ul>
  <h4>{{ 'google.subheadline6' | translate }}</h4>
  <p>
    {{ 'google.block4' | translate }}
  </p>
  <p>
    {{ 'google.block5' | translate }}
  </p>
  <h4>{{ 'google.subheadline7' | translate }}</h4>
  <ul>
    <li>
      <span
        ><span
          ><span
            ><span>{{ 'google.list5' | translate }}</span></span
          ></span
        ></span
      >
    </li>
  </ul></ista-daytona-content-card-open
>
