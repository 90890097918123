<ista-daytona-content-card-open [labels]="['Hotjar']">
  <p class="mb-6">
    {{ 'hotjar.block1' | translate }}
  </p>

  <h4 class="mb-2">{{ 'hotjar.subheadline1' | translate }}</h4>
  <p class="mb-6">{{ 'hotjar.block2' | translate }}</p>
  <ul class="mb-6">
    <li>{{ 'hotjar.list1' | translate }}</li>
    <li>{{ 'hotjar.list2' | translate }}</li>
  </ul>

  <h4 class="mb-2">{{ 'hotjar.subheadline2' | translate }}</h4>
  <ul class="mb-6">
    <li>{{ 'hotjar.list3' | translate }}</li>
  </ul>

  <h4 class="mb-2">{{ 'hotjar.subheadline3' | translate }}</h4>
  <p class="mb-6">{{ 'hotjar.block3' | translate }}</p>

  <ul class="mb-6">
    <li>{{ 'hotjar.list4' | translate }}</li>
    <li>{{ 'hotjar.list5' | translate }}</li>
    <li>{{ 'hotjar.list6' | translate }}</li>
    <li>{{ 'hotjar.list7' | translate }}</li>
    <li>{{ 'hotjar.list13' | translate }}</li>
    <li>{{ 'hotjar.list8' | translate }}</li>
    <li>{{ 'hotjar.list9' | translate }}</li>
    <li>{{ 'hotjar.list10' | translate }}</li>
    <li>{{ 'hotjar.list11' | translate }}</li>
    <li>{{ 'hotjar.list12' | translate }}</li>
  </ul>

  <h4 class="mb-2">{{ 'hotjar.subheadline4' | translate }}</h4>
  <p class="mb-6">{{ 'hotjar.block4' | translate }}</p>
  <ul class="mb-6">
    <li>Art. 6 Abs. 1 S. 1 lit. a DSGVO</li>
  </ul>

  <h4 class="mb-2">{{ 'hotjar.subheadline5' | translate }}</h4>
  <p class="mb-6">{{ 'hotjar.block5' | translate }}</p>

  <h4 class="mb-2">{{ 'hotjar.subheadline6' | translate }}</h4>
  <p class="mb-6">{{ 'hotjar.block6' | translate }}</p>
  <p class="mb-6">{{ 'hotjar.block7' | translate }}</p>

  <h4 class="mb-2">{{ 'hotjar.subheadline7' | translate }}</h4>
  <ul class="mb-6">
    <li>Hotjar Ltd.</li>
  </ul>

  <h4 class="mb-2">{{ 'hotjar.subheadline8' | translate }}</h4>
  <p class="mb-6">{{ 'hotjar.block8' | translate }}</p>
  <a class="mb-6 block" href="mailto:dpo@hotjar.com">dpo&#64;hotjar.com</a>

  <h4 class="mb-6">
    {{ 'hotjar.subheadline9' | translate }}
    <a href="https://www.hotjar.com/legal/policies/privacy/" rel="noopener" target="_blank"
    >https://www.hotjar.com/legal/policies/privacy/</a
    >
  </h4>

  <h4 class="mb-6">
    {{ 'hotjar.subheadline10' | translate }}
    <a href="https://www.hotjar.com/policies/do-not-track/" rel="noopener" target="_blank"
    >https://www.hotjar.com/policies/do-not-track/</a
    >
  </h4>

  <h4 class="mb-6">
    {{ 'hotjar.subheadline11' | translate }}
    <a
      href="https://www.hotjar.com/legal/policies/cookie-information"
      rel="noopener"
      target="_blank"
    >https://www.hotjar.com/legal/policies/cookie-information</a
    >
  </h4>

  <p class="mb-6">{{ 'hotjar.block9' | translate }}</p>

  <a
    class="btn-primary"
    href="https://www.hotjar.com/policies/do-not-track/"
    rel="noopener"
    target="_blank"
  >Hotjar Opt-Out</a
  >
</ista-daytona-content-card-open>
