/* eslint-disable @typescript-eslint/member-ordering */
import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  forwardRef,
  HostListener,
  Input,
  Output,
  ViewChild,
} from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';

import { HelperService } from '../../../helper/helper.service';
import { BaseInputComponent } from '../../base-input/base-input.component';
import { Unit } from '../../../models/unit.enum';
import { IconType } from '../icon/icon-type.enum';
import { InputHeight, InputTypes } from './input-types.enum';

/* eslint-disable @typescript-eslint/no-unsafe-call */
@Component({
  selector: 'ista-daytona-input',
  templateUrl: './input.component.html',
  styleUrls: ['./input.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      // eslint-disable-next-line no-use-before-define
      useExisting: forwardRef(() => InputComponent),
      multi: true,
    },
  ],
})
export class InputComponent extends BaseInputComponent<string> implements AfterViewInit {
  @Input() autocomplete = true;
  @Input() placeholder = '';
  @Input() type: InputTypes = InputTypes.TEXT;
  @Input() isInvalid = false;
  @Input() invalidText = '';
  @Input() name!: string;
  @Input() autofocus = false;
  @Input() classes = '';
  @Input() inputHeight = InputHeight.NORMAL;
  @Output() setFocus = new EventEmitter<boolean>(false);
  @Output() setBlur = new EventEmitter<boolean>(false);
  @Output() setEnter = new EventEmitter<boolean>(false);
  @Output() deleteButtonClicked = new EventEmitter<boolean>(false);
  @ViewChild('input') myInputField!: ElementRef<HTMLInputElement>;
  public inputTypes = InputTypes;
  public isFocused = false;
  public pwdType: InputTypes = InputTypes.PWD;
  public readonly iconType = IconType;
  public readonly _inputHeight = InputHeight;
  public transformedUnit!: string;

  get setInputFocus(): boolean {
    return this.isFocused;
  }

  @Input() set setInputFocus(value: boolean) {
    if (this.myInputField && !!value) {
      this.myInputField.nativeElement.focus();
    }
    this.isFocused = value;
  }

  private _unit!: Unit;

  get unit(): Unit {
    return this._unit;
  }

  @Input() set unit(value: Unit) {
    this._unit = value;
    this.transformedUnit = HelperService.getUnitEnumValue(value);
  }

  public get pattern(): string {
    if (this.type === InputTypes.NUMBER) {
      return '^d+(,d{1,2})?$';
    }

    return '';
  }

  public get inputValue(): string {
    switch (this.type) {
      case this.inputTypes.PWD:
        return this.pwdType;
      default:
        return 'text';
    }
  }

  @HostListener('keydown.enter')
  onKeydownHandler(): void {
    this.setEnter.emit(true);
  }

  ngAfterViewInit(): void {
    if (this.autofocus) {
      this.myInputField?.nativeElement?.focus();
    }
  }

  public deleteValue(event: Event): void {
    this.deleteButtonClicked.emit(true);
    event.preventDefault();
    this.value = '';
    this.isFocused = true;
    this.myInputField?.nativeElement?.focus();
  }

  public showPwd(): void {
    this.pwdType = InputTypes.TEXT;
  }

  public hidePwd(): void {
    this.pwdType = InputTypes.PWD;
  }

  public onBlur(): void {
    this.isFocused = false;
    this.setFocus.emit(false);
    this.setBlur.emit(true);
  }

  public onFocus(): void {
    this.isFocused = true;
    this.setFocus.emit(true);
    this.setBlur.emit(false);
  }

  public hasActionButton(): boolean {
    return (
      (this.type === this.inputTypes.PWD ||
        this.type === this.inputTypes.SEARCH ||
        (this.type === this.inputTypes.TEXT && !!this.value && !!this.isFocused)) &&
      !this.unit
    );
  }

  public hasDeleteButton(): boolean {
    return (
      !!this.value &&
      !!this.isFocused &&
      (this.type === this.inputTypes.TEXT || this.type === this.inputTypes.SEARCH) &&
      !this.unit
    );
  }
}
