import { Injectable } from '@angular/core';

import { Address } from '../interfaces/site.interface';
import { RGBA } from '../models/rgba.interface';
import { ChartItem } from '../ui/chart/chart.interface';
import { FilterDateRange, FilterTab, FilterTabItem } from '../ui/filter/filter-tab.interface';
import { UnitWithPrefix } from '../models/unit.enum';

@Injectable({
  providedIn: 'root',
})
export class HelperService {
  public static readonly allLabel = 'all';
  public static readonly heatingLabel = 'heatingAndHotWater';
  public static readonly heatingDistribution = [
    'districtHeating',
    'oil',
    'gas',
    'bioMass',
    'heatingElectricity',
    'otherFuels',
  ];
  static readonly hotWaterEnergy = 'hotWaterEnergy';

  public static getUnitEnumValue(value: string): string {
    return Object.entries(UnitWithPrefix).find(([key]) => key === value)?.[1] || '';
  }

  public static getActualYear(): string {
    return new Date().getFullYear().toString();
  }

  public static getActualYearDate(): FilterDateRange {
    return {
      from: this.getActualYear(),
      until: '',
    };
  }

  public static getActualYearRange(): FilterDateRange {
    return {
      from: (new Date().getFullYear() - 3).toString(),
      until: this.getActualYear(),
    };
  }

  public static getSelectedYearDummy(): FilterDateRange {
    return {
      from: '',
      until: '',
    };
  }

  public static transformFilterTabItemToCharItem(data: FilterTabItem[] | undefined): ChartItem[] {
    return data as ChartItem[];
    // return (
    //   data?.map((item) => ({
    //     label: item.label,
    //     value: item?.value || item.label,
    //     childs: this.transformFilterTabItemToCharItem(item.childs) || [],
    //     data: [],
    //     active: !!item?.active,
    //     color: ColorHelper.get(item.value || item.label) || '',
    //   })) || []
    // );
  }

  public static setFilterConfig(
    filterCount: number,
    fromYear: string,
    until?: string,
    leftValue?: string
  ): FilterTab[] {
    const result: FilterTab[] = [];
    for (let i = 0; i < filterCount; i++) {
      result.push({
        left: leftValue ? this.getInitLeftFilter(leftValue) : [],
        selectedYear: { from: fromYear, until: until || '' },
      });
    }

    return [...result];
  }

  public static getInitLeftFilter(leftValue: string): FilterTabItem[] {
    return [
      {
        label: leftValue,
        value: leftValue,
      },
    ];
  }

  public static convertHEXtoRGBA(hexValue: string, alpha: number = 100): RGBA {
    const hex = hexValue?.toUpperCase().replace('#', '');
    const hexLength = hex.length;
    if (hexLength > 0 && hexLength <= 6) {
      const h = '0123456789ABCDEF';
      const r = (h.indexOf(hex[0]) * 16 + h.indexOf(hex[1])).toString();
      const g = (h.indexOf(hex[2]) * 16 + h.indexOf(hex[3])).toString();
      const b = (h.indexOf(hex[4]) * 16 + h.indexOf(hex[5])).toString();
      return { r, g, b, a: alpha };
    }

    return {
      r: '',
      g: '',
      b: '',
      a: 0,
    };
  }

  public static getRoundedValue(value: number | null): number | null {
    if (value !== undefined && value !== null) {
      const result = Math.round((value + Number.EPSILON) * 100) / 100;
      return result;
    }

    return null;
  }

  public static getNumberCount(value: number | null): number {
    if (value !== undefined && value !== null) {
      const cleanValue = Math.floor(Math.abs(value));

      return cleanValue.toString().length;
    }

    return 0;
  }

  public static scrollToTop(): void {
    window.scroll({
      top: 0,
      left: 0,
      behavior: 'smooth',
    });
  }

  public static scrollToElement(element: HTMLElement): void {
    element.scrollIntoView({
      behavior: 'smooth',
      block: 'start',
      inline: 'start',
    });
  }

  public static sameArray<T>(arr1: T[] | null | undefined, arr2: T[] | null | undefined): boolean {
    if (arr1 === null && arr2 === null) {
      return true;
    }

    if (arr1 === null || arr1 === undefined) {
      return false;
    }

    if (arr2 === null || arr2 === undefined) {
      return false;
    }

    if (arr1.length !== arr2.length) {
      return false;
    }

    let result = true;
    let index = 0;
    while (index < arr1.length && result) {
      result = arr1[index] === arr2[index];
      index++;
    }

    return result;
  }

  public static createExcelWindowBlob(dataBlob: Blob | never[], fileName: string): void {
    const blob = new Blob([dataBlob as unknown as Blob], {
      type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    });

    const url = window.URL.createObjectURL(blob);
    const a = document.createElement('a');
    document.body.appendChild(a);
    a.setAttribute('style', 'display: none');
    a.href = url;
    a.download = `${fileName}.xlsx`;
    a.click();
    window.URL.revokeObjectURL(url);
    a.remove();
  }

  public static createCSVWindowBlob(csv: string, fileName: string): void {
    const blob = new Blob([csv], {
      type: 'text/csv',
    });

    const url = window.URL.createObjectURL(blob);
    const a = document.createElement('a');
    document.body.appendChild(a);
    a.setAttribute('style', 'display: none');
    a.href = url;
    a.download = `${fileName}`;
    a.click();
    window.URL.revokeObjectURL(url);
    a.remove();
  }

  public static transformAddressToString(address: Address | undefined): string {
    if (!address) {
      return '';
    }

    return `${address.street || ''} ${address.houseNumber || ''} - ${address.postalCode || ''} ${
      address.city || ''
    }`.trim();
  }
}
