<ng-container *ngIf="config">
  <div class="tab-sub-filter-container">
    <div
      *ngFor="let item of config"
      class="filters--item"
      (click)="setActiveMainMenu(item)"
      [class]="{
        active: activeMainTab?.label === item.label
      }"
    >
      <h3>
        <img [src]="item?.icon" *ngIf="item?.icon" [alt]="item?.label || ''" /><span
          [innerHTML]="item?.label || '' | translate"
        ></span>
      </h3>
    </div>
  </div>
  <div
    class="tab-sub-sub-filter-container"
    [class.background-active]="(activeMainTab?.childs || []).length > 0"
    *ngIf="activeMainTab?.childs && (activeMainTab?.childs || []).length > 0"
  >
    <div class="inner-content">
      <div
        *ngFor="let item of activeMainTab?.childs"
        class="filters--item"
        (click)="setActiveSubMenu(item)"
        [class.active]="activeSubTab?.label === item.label"
      >
        <h4>
          <span [innerHTML]="item?.label || '' | translate"></span>
        </h4>
      </div>
    </div>
  </div>
</ng-container>
