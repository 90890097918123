<ng-container *ngIf="data?.length">
  <div class="bg-base-100/90 p-4 rounded-tr-lg rounded-tl-lg flex justify-between">
    <div class="flex min-w-100">
      <mat-form-field class="flex-auto fuse-mat-dense">
        <input #input (input)="valueChange($event)" matInput />
        <button (click)="input.value = ''; searchStr = ''" [ngClass]="input.value ? '' : 'hidden'" mat-icon-button>
          <mat-icon>close</mat-icon>
        </button>
        <mat-icon class="text-base-500" matSuffix>search</mat-icon>
      </mat-form-field>
    </div>

    <div [customParamsValues]="[ReportingConsumptionTabParamLabel]" [targetId]="DeepLinkingIds.REPORTING"
         class="flex items-center"
         deepLinkingSender
         redirectUrl="reporting"
         role="link">
      <mat-icon class="icon-size-6 text-primary-500">ios_share</mat-icon>
    </div>
  </div>

  <ista-daytona-table [data]="data" [filter]="searchStr" [header]="headerData"></ista-daytona-table>
</ng-container>
