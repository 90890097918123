import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';

import { BasicModule } from '../basic/basic.module';
import { PageStepperComponent } from './page-stepper.component';

@NgModule({
  imports: [CommonModule, TranslateModule, BasicModule],
  declarations: [PageStepperComponent],
  exports: [PageStepperComponent],
})
export class PageStepperModule {}
