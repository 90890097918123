import { Injectable } from '@angular/core';
import { Observable, ReplaySubject } from 'rxjs';

import { Notification, NotificationType } from './notification.interface';

@Injectable({
  providedIn: 'root',
})
export class NotificationService {
  private notifications$: ReplaySubject<Notification> = new ReplaySubject<Notification>();

  public createNofification(headline: string, text: string, type: NotificationType): Notification {
    return {
      headline,
      text,
      type,
    };
  }

  public addNotification(notification: Notification): void {
    if (notification) {
      this.notifications$.next(notification);
    } else {
      // LoggerService.warn('cannot add notification: ', notification);
    }
  }

  public clearNotification(): void {
    // this.notifications$.next({ headline: 'blub', text: 'kuckuck', type: NotificationType.CONFIRMATION });
  }

  public getNotification$(): Observable<Notification> {
    return this.notifications$.asObservable();
  }
}
