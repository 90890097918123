@if (item) {
  <div class="flex">
    @if (item.icon) {
      <div
        class="w-10 h-10 rounded-full flex justify-center items-center z-20 icon-wrapper"
        [class]="item.iconBackgroundColor"
      >
        @if (item.icon.isSvgIcon) {
          <mat-icon
            [svgIcon]="item.icon.name"
            [ngClass]="{
              'fill-white': item.icon.isFillRelated,
              'stroke-white': item.icon.isStrokeRelated,
            }"
            class="icon-size-6 custom-svg-icon"
          ></mat-icon>
        } @else {
          <mat-icon class="icon-size-6 text-white custom-icon" [class]="item.icon.colorClass"
          >{{ item.icon.name }}
          </mat-icon>
        }
      </div>
    }
    @if (item.value | formatNumber: item.unit; as formattedValue) {
      <div
        class="-ml-5 z-10 border border-base-200 pr-5 pl-7 text-primary-1000 font-extrabold flex items-center bg-base-50 grow label-element"
      >
        <span
          [innerText]="formattedValue.value | number : getFormatNumberDigits(formattedValue.value) : 'de'"
          class="text-3xl label-element-rounded-value"
        ></span>
        <span class="inline-block text-xl">%&nbsp;{{ item.label | translate }}</span>
      </div>
    }
  </div>
}
