import { registerLocaleData } from '@angular/common';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import localeDe from '@angular/common/locales/de';
import { ErrorHandler, NgModule } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { provideUiAngular } from '@ista-ui/angular';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { GoogleTagManagerModule } from 'angular-google-tag-manager';
import { NgrxStoreRouterModule } from 'app/+state/router/ngrx-store-router.module';
import { CoreEffects } from 'core/+state/core.effects';
import { CoreModule } from 'core/core.module';
import { NoEnvironmentAvailableOverlayComponent } from 'core/environment/no-environment-available-overlay/no-environment-available-overlay.component';
import { GlobalErrorHandler } from 'core/error-handling/global-error-handler';
import { NgChartsModule } from 'ng2-charts';
import { SharedModule } from 'shared/shared.module';
import { ROOT_REDUCER_TOKEN } from './+state/app.reducer';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { FeaturesModule } from './features/features.module';
import { StaticPagesModule } from './static-pages/static-pages.module';
import { SelectedPeriodInfoComponent } from 'core/selected-period-info/selected-period-info.component';
import { provideComponentStore } from '@ngrx/component-store';
import { BreadcrumbStore } from 'shared/ui/breadcrumb-navigation/breadcrumb.store';
import { BreadcrumbNavigationService } from 'shared/ui/breadcrumb-navigation/breadcrumb-navigation.service';

export const version = {
  hash: (+new Date()).toString(36), // generates a unique alphanumeric string representing the current timestamp in base 36
};

registerLocaleData(localeDe, 'de');

/* eslint-disable prefer-arrow/prefer-arrow-functions */

/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
export function createTranslateLoader(http: HttpClient) {
  // append a hash value to the translation file url in order to force a cache refresh
  return new TranslateHttpLoader(http, './assets/i18n/', '.json?v=' + version.hash);
}

@NgModule({
  declarations: [AppComponent],
  imports: [
    AppRoutingModule,
    BrowserAnimationsModule,
    FeaturesModule,
    CoreModule,
    SharedModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: createTranslateLoader,
        deps: [HttpClient],
      },
      defaultLanguage: 'de',
    }),
    HttpClientModule,
    NgChartsModule,
    StaticPagesModule,
    GoogleTagManagerModule.forRoot({
      id: 'GTM-MMSSHDX',
    }),
    StoreModule.forRoot(ROOT_REDUCER_TOKEN, {
      runtimeChecks: {
        strictStateImmutability: true,
        strictActionImmutability: true,
        strictStateSerializability: true,
        strictActionSerializability: true,
      },
    }),
    EffectsModule.forRoot([CoreEffects]),
    StoreDevtoolsModule.instrument({
      maxAge: 25, // Retains last 25 states
      logOnly: false, // Restrict extension to log-only mode
    }),
    NgrxStoreRouterModule,
    NoEnvironmentAvailableOverlayComponent,
    SelectedPeriodInfoComponent,
  ],
  providers: [
    {
      provide: ErrorHandler,
      useClass: GlobalErrorHandler,
    },
    provideUiAngular(),
    provideComponentStore(BreadcrumbStore),
    BreadcrumbNavigationService,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
