import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Store } from '@ngrx/store';
import { RootState } from 'app/+state/app.reducer';
import { selectYearsWithData } from 'core/+state/core.selectors';
import { takeUntil } from 'rxjs';
import { DestroyAbstractService } from 'shared/helper/destroy-abstract.service';

import { storeSelectedAvailableYear } from '../../../../core/+state/core.actions';
import { FilterDateRange } from '../filter-tab.interface';
import { FilterTimeMode } from './filter-time-mode.interface';

/* eslint-disable @typescript-eslint/member-ordering */
@Component({
  selector: 'ista-daytona-filter-time-series',
  templateUrl: './filter-time-series.component.html',
  styleUrls: ['./filter-time-series.component.scss'],
})
export class FilterTimeSeriesComponent extends DestroyAbstractService {
  get mode(): FilterTimeMode {
    return this._mode;
  }
  @Input() set mode(mode: FilterTimeMode | undefined) {
    if (mode) {
      this._mode = mode;
      this.transformTimeSeries();
      if (mode.yearRange) this.setActive(mode.yearRange);
    }
  }
  @Input() selectedYear!: FilterDateRange | undefined | null;
  @Input() setRange!: FilterDateRange;
  @Input() displayDropDown = false;

  @Output() clickedFilter = new EventEmitter<FilterDateRange>();
  public timeSeries: FilterDateRange[] = [];
  private timeSeriesOriginal: string[] = [];
  public readonly displayMaxFilterItems = 2;
  private _mode!: FilterTimeMode;

  constructor(private readonly ngrxStore: Store<RootState>) {
    super();
    this.ngrxStore
      .select(selectYearsWithData)
      .pipe(takeUntil(this._onDestroy$))
      .subscribe((data) => {
        this.timeSeriesOriginal = [];
        data?.forEach((item) => this.timeSeriesOriginal.push(item.toString()));
        this.transformTimeSeries();

        const len = this.timeSeriesOriginal.length;
        if (len > 0) {
          this.ngrxStore.dispatch(
            storeSelectedAvailableYear({
              selectedYear: {
                from: this.timeSeriesOriginal[len - 1],
                until: this.timeSeriesOriginal[len - 1],
              },
            })
          );
        }
      });
  }

  public setActive(value: FilterDateRange): void {
    this.selectedYear = value;
    this.clickedFilter.emit(value);
    this.ngrxStore.dispatch(storeSelectedAvailableYear({ selectedYear: value }));
  }

  private getTimeSeriesReserved(): string[] {
    return [...this.timeSeriesOriginal]?.reverse();
  }

  private transformToYearSlot(): void {
    if (this.mode?.yearSlot) {
      const yearSlot =
        this.mode.yearSlot >= 0 && this.mode.yearSlot <= this.timeSeriesOriginal.length
          ? this.mode.yearSlot
          : this.timeSeriesOriginal.length;
      const len = this.timeSeriesOriginal.length;

      if (
        this.mode.yearSlot > this.timeSeriesOriginal.length &&
        this.timeSeriesOriginal.length > 0
      ) {
        this.timeSeries.push({
          from: this.timeSeriesOriginal[0],
          until: this.timeSeriesOriginal[this.timeSeriesOriginal.length - 1],
        });
      } else {
        const timeSeriesReserved = this.getTimeSeriesReserved();
        timeSeriesReserved.forEach((item, index) => {
          const start = index - 1 + yearSlot;

          if (start <= len && start >= 0 && timeSeriesReserved[start] !== undefined) {
            this.timeSeries.push({
              from: timeSeriesReserved[start] || '',
              until: item,
            });
          }
        });
      }
    }
  }

  private transformTimeSeries(): void {
    this.timeSeries = [];

    if (!this.mode || !!this.mode?.singleMode) {
      this.timeSeriesOriginal?.forEach((item) => {
        if (
          !this.mode ||
          (this.mode && !this.mode.yearRange) ||
          (this.mode.yearRange &&
            item >= this.mode?.yearRange?.from &&
            item < this.mode?.yearRange?.until)
        ) {
          this.timeSeries.push({ from: item, until: item });
        }
      });
    } else if (this.mode.yearSlot) {
      this.transformToYearSlot();
    }
  }
}
