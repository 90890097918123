import {
  ConsumptionInfo,
  YearlyConsumptionBoard,
} from 'app/features/portfolio/portfolio-consumption/consumption.interface';
import {
  monitoringCardsSkeleton,
  MonitoringCardsSkeletonType,
  MonitoringCardType,
} from 'shared/ui/monitoring-card/monitoring-card.skeleton';
import { MonitoringCardPortfolioType } from 'shared/ui/monitoring-card/monitoring-card-data.interface';
import { Unit } from 'shared/models/unit.enum';
import { ValueItem } from 'shared/models/value-item.interface';
import { cloneDeep } from 'lodash';
import { ValueBarDiagnoseCard } from 'shared/ui/value-bar-diagnose-card/value-bar-diagnose-card.interface';

export class PortfolioMonitoringMapper {
  static consumptionInfoToValueDiagnoseCardValues(
    skeleton: Record<string, ValueBarDiagnoseCard>,
    data: ConsumptionInfo,
    selectedYear: number,
    yearsRange: string[]
  ): Record<string, ValueBarDiagnoseCard> {
    const result = cloneDeep(skeleton);
    Object.keys(data)
      .filter((year) => !!year && yearsRange.includes(year))
      .sort((a, b) => b.localeCompare(a))
      .forEach((year) => {
        Object.keys(result).forEach((key) => {
          const valueItem =
            this._getConsumptionValue(data[year], key as MonitoringCardPortfolioType) ?? undefined;

          if (year === `${selectedYear}`) {
            result[key].value = valueItem?.value as number;
            result[key].unit = this._getConsumptionUnit(
              data[year],
              key as MonitoringCardPortfolioType
            );
            result[key as MonitoringCardsSkeletonType].siteCount =
              valueItem?.numberOfAffectedObjects;
          }

          result[key].bars.push({
            label: year,
            value: valueItem?.value as number,
          });
        });
      });

    return result;
  }

  static getTransformedConsumptionData(data: ConsumptionInfo): MonitoringCardType {
    const result = cloneDeep(monitoringCardsSkeleton);
    for (const year in data) {
      if (year) {
        Object.keys(result).forEach((key) => {
          result[key as MonitoringCardsSkeletonType].unit = this._getConsumptionUnit(
            data[year],
            key as MonitoringCardPortfolioType
          );

          result[key as MonitoringCardsSkeletonType].bars.push({
            label: year,
            value: this._getConsumptionValue(data[year], key as MonitoringCardPortfolioType) || {
              value: null,
              unit: Unit.DEFAULT,
            },
          });
        });
      }
    }

    return result;
  }

  private static _getConsumptionUnit(
    data: YearlyConsumptionBoard,
    resultType: MonitoringCardPortfolioType
  ): Unit | undefined {
    switch (resultType) {
      case MonitoringCardPortfolioType.costs:
        return data?.costs?.valuesByCategory?.total?.unit;
      case MonitoringCardPortfolioType.emissions:
        return data?.emissions?.valuesByCategory?.total?.unit;
      default:
        return data?.consumption?.valuesByCategory[resultType]?.unit as Unit;
    }
  }

  private static _getConsumptionValue(
    data: YearlyConsumptionBoard,
    resultType: MonitoringCardPortfolioType
  ): ValueItem | undefined {
    switch (resultType) {
      case MonitoringCardPortfolioType.costs:
        return data?.costs?.valuesByCategory?.total;
      case MonitoringCardPortfolioType.emissions:
        return data?.emissions?.valuesByCategory?.total;
      default:
        return data?.consumption?.valuesByCategory[resultType];
    }
  }
}
