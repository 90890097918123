import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { RouterLink } from '@angular/router';

import { BasicModule } from '../basic/basic.module';
import { FilterModule } from './../filter/filter.module';
import { TableSiteComponent } from './table-site.component';
import { TableModule } from 'shared/ui/basic/table/table.module';
import { InputSearchModule } from 'shared/ui/basic/input-search/input-search.module';
import { MatFormField, MatSuffix } from '@angular/material/form-field';
import { MatInput } from '@angular/material/input';
import { MatIconButton } from '@angular/material/button';
import { MatIcon } from '@angular/material/icon';
import { TranslateModule } from '@ngx-translate/core';
import { DeepLinkingSenderDirective } from 'core/deep-linking/deep-linking-sender.directive';

@NgModule({
  imports: [
    CommonModule,
    BasicModule,
    FormsModule,
    FilterModule,
    RouterLink,
    TableModule,
    InputSearchModule,
    MatFormField,
    MatInput,
    MatIconButton,
    MatIcon,
    MatSuffix,
    TranslateModule,
    DeepLinkingSenderDirective,
  ],
  declarations: [TableSiteComponent],
  exports: [TableSiteComponent],
})
export class TableSiteModule {}
