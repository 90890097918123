<div class="overlay-container">
  <button (click)="closeWindow()">
    <img src="/assets/icons/close.svg" class="close" title="close" alt="close" />
  </button>
  <ng-container *ngIf="showFilesDataImportCard || showExcelDataImportCard">
    <div class="overlay-content">
      <ista-daytona-file-upload
        [showExcelDataImportCard]="showExcelDataImportCard"
        [showFilesDataImportCard]="showFilesDataImportCard"
      ></ista-daytona-file-upload>
    </div>
  </ng-container>
  <ng-container *ngIf="showDeleteObjectCard">
    <div class="overlay-content">
      <ista-daytona-delete-object></ista-daytona-delete-object>
    </div>
  </ng-container>
</div>
