import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { TrackingWrapperService } from 'core/tracking/tracking-wrapper.service';
import { Observable, Subject, takeUntil } from 'rxjs';
import { FilterTabStoreService } from 'shared/ui/filter/filter-tab/filter-tab-store.service';

import { FilterTab } from '../filter-tab.interface';
import { FilterTimeMode } from '../filter-time-series/filter-time-mode.interface';
import { FilterDateRange, FilterTabItem } from './../filter-tab.interface';

/* eslint-disable @typescript-eslint/member-ordering */
@Component({
  selector: 'ista-daytona-filter-tab',
  templateUrl: './filter-tab.component.html',
  styleUrls: ['./filter-tab.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FilterTabComponent implements OnInit, OnDestroy, OnChanges {
  private destroy$ = new Subject<void>();
  @Input() timeMode!: FilterTimeMode;
  @Input() displayTimeSeries = true;
  @Input() displayBorderBottom = true;
  @Input() smallVersion = false;
  @Input() instanceId = '';

  get config(): FilterTab {
    return this._config;
  }

  @Input() set config(value: FilterTab) {
    this._config = { ...value };
    this.setActiveTabs();
  }

  @Output() clickedFilter = new EventEmitter<FilterTab>();
  public activeTab$: Observable<FilterTab>;
  private _config!: FilterTab;

  constructor(
    private readonly trackingService: TrackingWrapperService,
    private filterTabStoreService: FilterTabStoreService
  ) {
    this.instanceId = this.filterTabStoreService.registerFilterTabInstanceId(this.instanceId);
    this.activeTab$ = this.filterTabStoreService.selectActiveFilterTab(this.instanceId);
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['instanceId']?.currentValue) {
      this.activeTab$ = this.filterTabStoreService.selectActiveFilterTab(
        changes['instanceId'].currentValue as string
      );
    }
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  ngOnInit(): void {
    this.filterTabStoreService
      .selectActiveFilterTab(this.instanceId)
      .pipe(takeUntil(this.destroy$))
      .subscribe((activeTab) => {
        this.clickedFilter.emit(activeTab);
      });
  }

  public setActiveLeft(item: FilterTabItem): void {
    if (this._config?.left?.length > 1) {
      this.filterTabStoreService.updateActiveFilterTabLeft(this.instanceId, [
        { value: item.value, label: item.label },
      ]);
    }
    this.trackingService.addGTM(
      {
        eventCategory: 'elementClick',
        eventLabel: item.value || item.label,
        eventAction: 'tabNavigationClick',
      },
      [item.value || item.label]
    );
  }

  public setActiveRight(selectedYear: FilterDateRange): void {
    this.filterTabStoreService.updateActiveFilterTabSelectedYear(this.instanceId, selectedYear);
  }

  private setActiveTabs(): void {
    this._config?.left?.forEach((item) => {
      if (!!item.active) {
        this.filterTabStoreService.updateActiveFilterTabLeft(this.instanceId, [
          { label: item.label, value: item.value },
        ]);
      }
    });
  }
}
