import { createAction, props } from '@ngrx/store';
import { EnvironmentSettings } from 'core/environment-settings/interfaces';
import { ReportingPeriod } from 'core/environment/reporting-period.interface';
import { Languages } from 'core/language/language.interface';
import { UserSettings } from 'core/user-settings/interfaces';
import { FilterDateRange } from 'shared/ui/filter/filter-tab.interface';

import { Environment } from '../environment/environment.interface';
import { Group, Site, SiteOfGroup } from '../groups/groups.interface';
import { Tag } from '../tags/interface';

// export const load = createAction('[TimeSeries] load TimeSeries');

export const storeFrontendConfigType = '[Core] Store Frontend Config';
export const storeUserTokenType = '[Core] Store User Token';
export const storeEnvironmentReadinessType = '[Core] Store Environment Readiness';
export const storeEnvironmentsType = '[Core] Store Environments';
export const storeSelectedEnvironmentType = '[Core] Store selected Environment';
export const storeAvailableYearsType = '[Core] Store available Years';
export const storeReportingPeriodsType = '[Core] Store Reporting Periods Type';
export const storeGroupsType = '[Core] Store Groups';
export const storeSearchTreeNavigationType = '[Core] Store Search Tree Navigation';
export const resetSearchTreeNavigationType = '[Core] reset Search Tree Navigation';
export const storeSelectedGroupType = '[Core] Store selected Group';
export const storeSitesOfGroupsType = '[Core] Store Sites of Groups';
export const deleteSiteFromGroupType = '[Core] delete Site from Group';
export const updateLoadSitesStateType = '[Core] Update Load Sites State';
export const clearLoadSitesStateType = '[Core] Clear Load Sites State';
export const storeGroupSearchStatusType = '[Core] Store Group Search Status';
export const updateShowNavigationMenuType = '[Core] Update NavigationMenu';
export const updateSubNavigationSelectedHeadlineType =
  '[Core] Update subNavigation Selected Headline';
export const updateGlobalSelectedYearType = '[Core] Update Global Selected Year';

export const updateSelectedLanguageType = '[Core] Update Selected Language';
export const storeSelectedSiteType = '[Core] Store Selected Site';
export const storeSelectedGroupOrSiteType = '[Core] Store Selected Group Or Site';

export const removeSelectedSiteType = '[Site] Remove Selected';
export const moveAndAddSiteType = '[Core] Move Site';
export const moveAndAddGroupType = '[Core] Move Group';
export const moveAndAddGroupToRootType = '[Core] Move Group To Root';
export const updateUnassignedSitesType = '[Core] Update unassigned Sites';
export const addUnassignedSitesType = '[Core] Add Sites To unassigned Sites';
export const addNewGroupToGroupType = '[Core] Add New Group';
export const deleteGroupFromGroupType = '[Core] Delete Group From Group';
export const updateUserSettingsType = '[Core] Update User Settings';
export const updateEnvironmentSettingsType = '[Core] Update Environment Settings';
export const renameGroupType = '[Core] Rename Group';
export const updateTagsType = '[Core] Update Tags';
export const addTagType = '[Core] Add Tag';
export const resetTagsType = '[Core] Reset Tags';
export const setTagsType = '[Core] Set Tags';

export const updateSearchTreeNavigationType = '[Core] update Search Tree Navigation';

export const storeFrontendConfig = createAction(
  storeFrontendConfigType,
  props<{
    frontendConfig: {
      keycloakUrl: string;
      keycloakClientId: string;
      keycloakMockEnabled: boolean;
    };
  }>()
);

export const storeUserToken = createAction(storeUserTokenType, props<{ userToken: string }>());

export const storeEnvironmentReadiness = createAction(
  storeEnvironmentReadinessType,
  props<{ environmentReady: boolean }>()
);

export const storeEnvironments = createAction(
  storeEnvironmentsType,
  props<{ availableEnvironments: Environment[] }>()
);

export const storeSelectedEnvironment = createAction(
  storeSelectedEnvironmentType,
  props<{ selectedEnvironment: Environment }>()
);

export const storeAvailableYears = createAction(
  storeAvailableYearsType,
  props<{ yearsWithData: number[]; preSelectedYear?: number }>()
);

export const storeReportingPeriods = createAction(
  storeReportingPeriodsType,
  props<{ reportingPeriods: ReportingPeriod[] }>()
);

export const storeGroups = createAction(storeGroupsType, props<{ groups: Group[] }>());
export const storeSearchTreeNavigationGroups = createAction(
  storeSearchTreeNavigationType,
  props<{
    groups: Group[];
    unassignedSites: SiteOfGroup[];
  }>()
);

export const resetSearchTreeNavigation = createAction(resetSearchTreeNavigationType);

export const storeSelectedGroup = createAction(
  storeSelectedGroupType,
  props<{ selectedGroupId: number }>()
);

export const storeSelectedAvailableYear = createAction(
  '[Core] Store selected available Year',
  props<{ selectedYear: FilterDateRange }>()
);

export const storeSitesOfGroups = createAction(
  storeSitesOfGroupsType,
  props<{ groupId: number; sites: SiteOfGroup[] }>()
);

export const deleteSiteFromGroup = createAction(
  deleteSiteFromGroupType,
  props<{ parentGroup: Group; site: SiteOfGroup }>()
);

export const updateLoadSitesState = createAction(
  updateLoadSitesStateType,
  props<{ state: boolean }>()
);

export const clearLoadSitesState = createAction(clearLoadSitesStateType);

export const storeGroupSearchStatus = createAction(
  storeGroupSearchStatusType,
  props<{ isRunning: boolean }>()
);

export const updateShowNavigationMenu = createAction(
  updateShowNavigationMenuType,
  props<{ showNavigationMenu: boolean }>()
);

export const updateSubNavigationSelectedHeadline = createAction(
  updateSubNavigationSelectedHeadlineType,
  props<{ subNavigationSelectedHeadline: string }>()
);

export const updateGlobalSelectedYear = createAction(
  updateGlobalSelectedYearType,
  props<{ globalSelectedYear: number }>()
);

export const updateSelectedLanguage = createAction(
  updateSelectedLanguageType,
  props<{
    selectedLanguage: Languages;
  }>()
);

export const storeSelectedSite = createAction(
  storeSelectedSiteType,
  props<{ selectedSite: Site | undefined }>()
);

export const storeSelectedGroupOrSite = createAction(
  storeSelectedGroupOrSiteType,
  props<{ selectedGroupOrSite: Group | undefined }>()
);

export const removeSelectedSite = createAction(removeSelectedSiteType);
export const moveAndAddSite = createAction(
  moveAndAddSiteType,
  props<{
    site: SiteOfGroup;
    targetGroup: Group;
  }>()
);

export const moveAndAddGroup = createAction(
  moveAndAddGroupType,
  props<{
    group: Group;
    targetGroup: Group;
  }>()
);
export const moveAndAddGroupToRoot = createAction(
  moveAndAddGroupToRootType,
  props<{
    group: Group;
    targetGroup: Group;
  }>()
);

export const updateUnassignedSites = createAction(
  updateUnassignedSitesType,
  props<{
    unassignedSites: SiteOfGroup[];
  }>()
);

export const addSitesToUnassignedSites = createAction(
  addUnassignedSitesType,
  props<{
    unassignedSites: SiteOfGroup[];
  }>()
);

export const addNewGroupToGroups = createAction(
  addNewGroupToGroupType,
  props<{
    newGroup: Group;
    parentGroupId: number;
  }>()
);

export const deleteGroupFromGroup = createAction(
  deleteGroupFromGroupType,
  props<{
    groupId: number;
  }>()
);

export const updateEnvironmentSettings = createAction(
  updateEnvironmentSettingsType,
  props<{ environmentSettings: EnvironmentSettings }>()
);

export const updateUserSettings = createAction(
  updateUserSettingsType,
  props<{
    settings: UserSettings;
  }>()
);

export const renameGroup = createAction(
  renameGroupType,
  props<{
    name: string;
    groupId: number;
  }>()
);

export const updateTags = createAction(
  updateTagsType,
  props<{
    tags: Tag[];
  }>()
);

export const setTags = createAction(
  setTagsType,
  props<{
    tags: Tag[];
  }>()
);

export const addTag = createAction(
  addTagType,
  props<{
    tag: Tag;
  }>()
);

export const resetTags = createAction(resetTagsType);

export const updateSearchTreeNavigation = createAction(
  updateSearchTreeNavigationType,
  props<{
    group: Group[];
    unassignedSites: SiteOfGroup[];
  }>()
);
