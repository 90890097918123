<div class="file-upload-container grid">
  <h2>{{ 'portfolio.deleteObjectTitle' | translate }}</h2>
  <div class="file-upload-container--content">
    <ng-container>
      <ista-daytona-card
        [displayBoxShadow]="false"
        [minWidth]="'840px'"
        [colorStyle]="colorStyle.BLUE_LIGHT"
      >
        <div class="flex flex-col gap-y-6 items-center justify-center min-h-96">
          <img src="assets/icons/notification/Warning.svg" class="w-16" alt="Warning" />
          <h3>
            {{ 'portfolio.deleteObjectConfirmMessagePart1' | translate }} {{ selectedObject }}
            {{ 'portfolio.deleteObjectConfirmMessagePart2' | translate }}
          </h3>

          <div class="flex flex-row gap-x-4">
            <ista-daytona-button
              [label]="'portfolio.button.cancel' | translate"
              [type]="'secondary'"
              (buttonClicked)="closeWindow()"
            ></ista-daytona-button>
            <ista-daytona-button
              [label]="'portfolio.button.deleteObject' | translate"
              [color]="'warn'"
              (buttonClicked)="deleteObject()"
            ></ista-daytona-button>
          </div>
        </div>
      </ista-daytona-card>
    </ng-container>
  </div>
  <p class="contact-info">
    {{ 'portfolio.deleteObjectHelpMessage' | translate }}
    <a [routerLink]="'contact'" (click)="closeWindow()">{{ 'navigation.contact' | translate }}</a>
  </p>
</div>
