import { LanguageKeyHelper } from 'shared/helper/language-key-helper';
import { TableHeader } from 'shared/ui/basic/table/table.interface';
import { DeepLinkingIds } from 'core/deep-linking/deep-linking.interface';

export const headerMasterdataConfig: TableHeader[] = [
  {
    label: LanguageKeyHelper.get('object-id') ?? 'Objekt-Id',
    id: 'externalReference',
    linking: {
      url: '/portfolio/site/',
      customParamsValues: ['1'],
      targetId: DeepLinkingIds.PORTFOLIO_SITE_TABS,
    },
  },
  {
    label: LanguageKeyHelper.get('istaPropertyNumber') ?? 'istaPropertyNumber',
    id: 'istaPropertyNumber',
  },
  {
    label: LanguageKeyHelper.get('street') ?? 'Straße',
    id: 'street',
  },
  {
    label: LanguageKeyHelper.get('city') ?? 'Stadt',
    id: 'city',
  },
  {
    label: LanguageKeyHelper.get('numberOfUnits') ?? 'numberOfUnits',
    id: 'numberOfUnits',
  },
  {
    label: LanguageKeyHelper.get('space') ?? 'space',
    id: 'space',
  },
];
