<div *ngIf="item" class="editing-item-wrapper flex items-center w-full">
  @if (showDragIcon) {
    <mat-icon
      class="icon-size-4 mr-1 inline-block hover-icon drag-icon"
      color="primary"
      [matTooltip]="'group-tree-navigation.drag-tooltip-text' | translate"
      matTooltipPosition="above"
    >
      swap_vert
    </mat-icon>
  } @else {
    <div class="w-4 mr-1 drag-icon-placeholder"></div>
  }
  <div #element [title]="item.name" class="w-full block truncate">{{ item.name }}</div>
  <mat-icon
    (click)="onDelete()"
    class="icon-size-6 hover-icon editing-item-wrapper-delete"
    color="primary"
  >delete
  </mat-icon>
</div>
