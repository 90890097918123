<div class="w-192">
  <div class="flex w-11/12 bg-blue-100 rounded-md border-solid border-blue-400 border mt-6">
    <div class="flex flex-row p-4 w-full">
      <div>
        <mat-icon class="text-primary font-size-16">info</mat-icon>
      </div>
      <div class="flex flex-col ml-4 my-1 min-h-8">
        <div class="font-extrabold text-xl text-blue-950">{{ headline }}</div>
        <div class="mt-2 text-blue-950">{{ text }}</div>
      </div>
      <div (click)="hideInfobox()" class="ml-auto hover:cursor-pointer">
        <img alt="Close" src="assets/icons/close.svg" />
      </div>
    </div>
  </div>
</div>
