<div
  [ngClass]="{
    'bg-transparent': !enableEditingMode,
    'bg-ultra-light py-3 mt-2 pl-1 pr-1': enableEditingMode
  }"
  class="relative pl-4"
>
  <ista-daytona-add-group
    (createGroup)="createGroup($event)"
    *ngIf="enableEditingMode"
    class="add-group-wrapper block pl-5"
  ></ista-daytona-add-group>

  <mat-tree
    [dataSource]="dataSource"
    [treeControl]="treeControl"
    class="group-tree-navigation-wrapper max-h-screen overflow-y-auto relative bg-transparent"
  >
    <!-- This is the tree node template for expandable nodes -->
    <mat-nested-tree-node *matTreeNodeDef="let node" class="block">
      <div
        (dragOver)="handleDragOver($event, node)"
        (dragStart)="dragGroupStart($event, node)"
        (dragend)="dragend()"
        (dragleave)="handleDragLeave()"
        (drop)="handleDrop($event, node)"
        [class.drop-below]="node.id === dropZoneGroup?.id"
        [class.text-tertiary]="shouldHighlightGroup(node)"
        [enableDrag]="enableEditingMode"
        class="mat-tree-node text-sm text-slate-900 font-extrabold"
        drag
      >
        <div class="cursor-pointer hover:text-cta flex items-center w-full" role="button">
          <div
            *ngIf="shouldShowUnassignedGroup(node)"
            [class.pl-5]="enableEditingMode"
            class="flex items-center flex-grow text-primary-1000 text-sm font-extrabold pl-1 hover:text-primary-500 unassigned-group"
          >
            <span [title]="node.name" class="truncate">{{ node.name | translate }}</span>
          </div>
          <div
            (click)="navigateToGroup(node)"
            *ngIf="showInReadonlyModeAndForNotUnassignedGroup(node)"
            [class.group-wrapper--active]="isNodeActive(node.id, 'group')"
            class="flex items-center flex-grow group-wrapper max-w-[calc(100%-40px)]"
          >
            <mat-icon [svgIcon]="'marker'"
                      class="icon-size-6 mr-4 fill-base-900 marker-icon"
            ></mat-icon>
            <span [title]="node.name" class="truncate text-sm text-base-900 font-extrabold"
                  data-cy="portfolio-group-navigation-tree-item">{{ node.name }}
            </span>
          </div>
          <ista-daytona-editing-item-options
            (delete)="deleteGroup($event, node)"
            *ngIf="showInEditingModeAndForNotUnassignedGroup(node)"
            [item]="node"
            class="w-full self-stretch inline-flex max-w-[calc(100%-40px)]"
          ></ista-daytona-editing-item-options>
          <div class="p-3 ml-auto flex items-center">
            <mat-icon
              (click)="toggleNodeAndLoadSites(node)"
              *ngIf="shouldEnableOpening(node)"
              class="mat-icon-rtl-mirror text-inherit icon-size-4"
            >
              {{ isExpanded(node) ? 'expand_more' : 'chevron_right' }}
            </mat-icon>
          </div>
        </div>
      </div>
      <!-- There is inline padding applied to this div using styles.
          This padding value depends on the mat-icon-button width.  -->
      <div [class.hidden]="!isExpanded(node)" class="site-tree-node" role="group">
        <ista-daytona-add-group
          (createGroup)="createGroup($event, node.id)"
          *ngIf="showInEditingModeAndForNotUnassignedGroup(node)"
          class="add-group-wrapper block pl-5"
        ></ista-daytona-add-group>
        <ng-container matTreeNodeOutlet></ng-container>
        @if (node?.sites?.length) {
          @for (site of node.sites; track site.id) {
            <ista-daytona-site-item [item]="site" [showEditingOptions]="enableEditingMode"
                                    [parentGroup]="node"
                                    (itemClick)="navigateToSite($event)"
                                    (delete)="deleteSite($event, node)"
                                    (dragOver)="handleSiteDragOver($event)"
                                    (dragStart)="dragSiteStart($event, site, node.id)"
                                    (dropOver)="handleDrop($event, node)"
                                    [enableDrag]="enableEditingMode"></ista-daytona-site-item>
          }
        }
      </div>
    </mat-nested-tree-node>
  </mat-tree>
</div>
