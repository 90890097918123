<mat-button-toggle-group (valueChange)="updateSelectedPeriodType.emit($event)"
                         [value]="environmentSettingsType"
                         aria-label="environmentSettings"
                         class="ml-8 my-2 font-extrabold"
                         name="selectedPeriod">
  <mat-button-toggle [checked]="selectedPeriodType === 'CUSTOM_REPORT_YEAR'"
                     value="CUSTOM_REPORT_YEAR">{{ 'common.selectablePeriods.customReportYear' | translate }}
  </mat-button-toggle>
  <mat-button-toggle [checked]="selectedPeriodType !== 'CUSTOM_REPORT_YEAR'"
                     value="CALENDAR_YEAR">{{ 'common.selectablePeriods.calendarYear' | translate }}
  </mat-button-toggle>
</mat-button-toggle-group>
