import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { OverlayService } from 'shared/ui/overlay/overlay.service';

@Component({
  selector: 'ista-daytona-button-data-add',
  templateUrl: 'button-data-add.component.html',
  styleUrls: ['button-data-add.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ButtonDataAddComponent {
  @Input() stickyStyle = false;
  constructor(private readonly overlayService: OverlayService) {}
  showExcelImportOverlay(): void {
    this.overlayService.open(840, 840, {
      showExcelDataImportCard: true,
    });
  }

  showFilesImportOverlay(): void {
    this.overlayService.open(840, 840, {
      showFilesDataImportCard: true,
    });
  }
}
