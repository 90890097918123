<div class="flex flex-wrap gap-12">
  <div>
    <div class="flex gap-10">
      <div class="doughnut-chart">
        <ista-daytona-doughnut-chart
          [config]="doughnutConfig"
          [data]="filteredChartData"
          [icon]="config?.icon"
          [selectedYear]="selectedYear"
        ></ista-daytona-doughnut-chart>
      </div>
      <div class="distribution-media">
        <h4 class="font-extrabold text-xl text-base-700 mb-6">
          {{ 'charts.balance-distribution-media' | translate }}
        </h4>
        <div class="flex flex-col gap-2">
          @if (filteredChartData.length) {
            @for (filteredChartDataItem of toIconValueLabel(filteredChartData); track filteredChartDataItem.label) {
              <ista-daytona-icon-value-label class="block w-full" data-cy="distribution-media--item"
                                             [item]="filteredChartDataItem"></ista-daytona-icon-value-label>
            }
          }
        </div>
      </div>
    </div>
  </div>
  <div class="compared-previous-years flex-1 min-w-[250px]">
    <h4 class="font-extrabold text-xl text-base-700 mb-6">
      {{ 'charts.balance-compare-prev-years' | translate }}
    </h4>
    <ista-daytona-bar-chart
      [data]="[totalSumChartData]"
      [label]="config?.label"
      [selectedYear]="selectedYear"
      [unitLabel]="config?.unitLabel || ''"
      data-cy="distribution-media-bar-chart"
    ></ista-daytona-bar-chart>
  </div>
</div>
