<div class="flex flex-wrap gap-1 p-1 bg-white rounded-xl border">
  <button
    *ngFor="let selection of selections"
    class="h-8 leading-8 rounded"
    [class.active]="selection === selectedTab"
    (click)="setSelectedTab(selection)"
  >
    <span class="font-semibold text-primary px-4 py-2">{{ selection.label | translate }}</span>
  </button>
</div>
