export enum LoadingState {
  PENDING = 'PENDING',
  PROGRESS = 'IN_PROGRESS',
  DONE = 'DONE',
  READY = 'READY',
  ABORT = 'ERROR',
}

export interface LoadStatus {
  progress: number;
  state: LoadingState;
  id?: string;
}
