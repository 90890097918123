import { Component, Inject } from '@angular/core';
import { TagsComponent } from 'core/tags/tags.component';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'ista-daytona-tag-dialog',
  templateUrl: 'tag-dialog.component.html',
  styleUrls: ['tag-dialog.component.scss'],
  imports: [TagsComponent],
  standalone: true,
})
export class TagDialogComponent {
  selectedIds: number[] = [];

  constructor(@Inject(MAT_DIALOG_DATA) public data: { initiallySelectedIds: number[] }) {
    this.selectedIds = data?.initiallySelectedIds ?? [];
  }

  activeStateChanged(ids: number[]): void {
    this.selectedIds = ids;
  }
}
