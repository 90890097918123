<div class="file-upload-container grid">
  <h2 [innerText]="'file-upload.headline' | translate"></h2>
  <div class="file-upload-container--content">
    <ng-container [ngSwitch]="actualStep">
      <ista-daytona-file-upload-step-file
        (selectedFileUpload)="upload($event)"
        *ngSwitchCase="1"
        [showExcelDataImportCard]="showExcelDataImportCard"
        [showFilesDataImportCard]="showFilesDataImportCard"
      ></ista-daytona-file-upload-step-file>
      <ista-daytona-file-upload-step-upload
        (abortUploading)="gotoStart()"
        *ngSwitchCase="2"
        [label]="'file-upload.step.check' | translate"
      ></ista-daytona-file-upload-step-upload>
      <ista-daytona-file-upload-step-result
        (abortUploading)="gotoStart()"
        (closeWindow)="closeWindow()"
        (uploadValidData)="upload(fileUpload, false)"
        *ngSwitchCase="3"
        [fileUploadResponse]="fileUploadResponse"
        [fileUpload]="fileUpload"
      ></ista-daytona-file-upload-step-result>
      <ista-daytona-file-upload-step-upload
        (abortUploading)="gotoStart()"
        *ngSwitchCase="4"
        [label]="'file-upload.step.progress' | translate"
      ></ista-daytona-file-upload-step-upload>
      <ista-daytona-file-upload-step-finished
        (abortUploading)="closeWindow()"
        (loadErrorReport)="loadErrorReport()"
        *ngSwitchCase="5"
        [fileUploadResponse]="fileUploadResponse"
        [fileUpload]="fileUpload"
      ></ista-daytona-file-upload-step-finished>
    </ng-container>
  </div>
  <p class="contact-info">
    {{ 'file-upload.help.subheadline1' | translate }}
    <a (click)="closeWindow()" [routerLink]="'help'">{{'file-upload.help.help' | translate}}</a>
    {{ 'file-upload.help.subheadline2' | translate }}
    <a (click)="closeWindow()" [routerLink]="'contact'">{{'file-upload.help.contact' | translate}}</a>
    {{ 'file-upload.help.subheadline3' | translate }}
  </p>
</div>
