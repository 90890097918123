<div class="result-billing-container grid gap-2 leading-6">
  <div
    [class.error]="detectedBilling.validationHints.length > 0"
    class="grid grid--col-2 grid--gap-4 error-align"
  >
    <img
      *ngIf="detectedBilling.validationHints.length > 0"
      alt="warning"
      class="icon"
      src="assets/icons/warning.svg"
      title="warning"
    />
    <span [class.strong]="detectedBilling.validationHints.length > 0"
      >Abrechnung {{ detectedBilling.propertyNumber }}, {{ detectedBilling.street }},
      <span class="no-break"
        >{{ detectedBilling.periodBegin | date }}-{{ detectedBilling.periodEnd | date }}</span
      ></span
    >
  </div>

  <div [class.error]="detectedBilling.validationHints.length > 0" class="grid">
    <span
      *ngFor="let hint of detectedBilling.validationHints"
      [innerText]="'errorMsg.fileUpload.eFile.' + $any(hint.validationHintCode) | translate"
    ></span>
  </div>
</div>
