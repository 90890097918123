import { Component, forwardRef, OnDestroy, OnInit } from '@angular/core';
import {
  ControlValueAccessor,
  FormControl,
  NG_VALUE_ACCESSOR,
  ReactiveFormsModule,
} from '@angular/forms';
import { distinctUntilChanged, Observer, Subject, takeUntil } from 'rxjs';
import { TagForm } from '../interface';
import { MatCheckbox } from '@angular/material/checkbox';

@Component({
  selector: 'ista-daytona-tag-active-state',
  templateUrl: 'tag-active-state.component.html',
  styleUrls: ['tag-active-state.component.scss'],
  standalone: true,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      // eslint-disable-next-line no-use-before-define
      useExisting: forwardRef(() => TagActiveStateComponent),
      multi: true,
    },
  ],
  imports: [MatCheckbox, ReactiveFormsModule],
})
export class TagActiveStateComponent implements OnInit, ControlValueAccessor, OnDestroy {
  checkboxCtrl = new FormControl<boolean>(false);
  tag?: TagForm;
  private onChange = new Subject<TagForm>();
  private onTouch = new Subject<TagForm>();
  private destroy$ = new Subject<void>();

  get label(): string {
    return this.tag?.name ?? '';
  }

  ngOnInit(): void {
    this.checkboxCtrl.valueChanges
      .pipe(distinctUntilChanged(), takeUntil(this.destroy$))
      .subscribe(() => {
        const editedTag: TagForm = {
          ...(this.tag as TagForm),
          checked: this.checkboxCtrl.value as boolean,
        };
        this.onChange.next(editedTag);
      });
  }

  writeValue(tag: TagForm): void {
    if (tag) {
      this.tag = tag;
      this.checkboxCtrl.setValue(tag.checked, {
        emitEvent: false,
        onlySelf: true,
      });
    }
  }

  registerOnChange(fn: Partial<Observer<TagForm>> | undefined): void {
    this.onChange.subscribe(fn);
  }

  registerOnTouched(fn: Partial<Observer<TagForm>> | undefined): void {
    this.onTouch.subscribe(fn);
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
