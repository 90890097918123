import { Component } from '@angular/core';
import { ButtonModule } from 'shared/ui/basic/button/button.module';
import { MatIconModule } from '@angular/material/icon';
import { NgIf } from '@angular/common';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { PipesModule } from 'shared/pipes/pipes.module';
import { NotificationService } from 'shared/ui/notification/notification.service';
import { NotificationType } from 'shared/ui/notification/notification.interface';

@Component({
  selector: 'ista-daytona-no-environment-overlay',
  templateUrl: 'no-environment-available-overlay.component.html',
  standalone: true,
  imports: [ButtonModule, MatIconModule, NgIf, TranslateModule, PipesModule],
})
export class NoEnvironmentAvailableOverlayComponent {
  constructor(
    private translateService: TranslateService,
    private notificationService: NotificationService
  ) {}
  historyBack(event: MouseEvent): void {
    event.preventDefault();
    history.back();
  }

  openEmailClientProgram(): void {
    window.location.href = `mailto:${
      this.translateService.instant('no-environment-overlay.esg-email') as string
    }`;
  }

  copyEmailIntoClipBoard(): void {
    navigator.clipboard.writeText(
      this.translateService.instant('no-environment-overlay.esg-email') as string
    );

    this.notificationService.addNotification({
      headline: this.translateService.instant('no-environment-overlay.notification') as string,
      type: NotificationType.CONFIRMATION,
    });
  }
}
