import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';

import { SharedModule } from 'shared/shared.module';
import { EnvironmentComponent } from './environment.component';
import { EnvironmentContainerComponent } from './environment-container.component';
import { TranslateModule } from '@ngx-translate/core';
import { EnvironmentDropdownPipe } from 'shared/pipes/environment-dropdown.pipe';
import { EnvironmentSelectComponent } from 'core/environment/environment-select/environment-select.component';

@NgModule({
  imports: [
    CommonModule,
    HttpClientModule,
    FormsModule,
    SharedModule,
    TranslateModule,
    EnvironmentDropdownPipe,
    EnvironmentSelectComponent,
  ],
  declarations: [EnvironmentContainerComponent, EnvironmentComponent],
  exports: [EnvironmentContainerComponent],
  providers: [],
})
export class EnvironmentModule {}
