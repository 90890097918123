import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { StickyHeaderService } from 'core/sticky-header/sticky-header.service';
import {
  StickyHeaderElementType,
  StickyHeaderInterface,
} from 'core/sticky-header/sticky-header.interface';
import { filter, Subject, takeUntil } from 'rxjs';

@Component({
  selector: 'ista-daytona-global-sticky-header',
  templateUrl: 'sticky-header.component.html',
  styleUrls: ['sticky-header.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class StickyHeaderComponent implements OnInit, OnDestroy {
  @Input() showSubNavigationLayout = false;
  items: StickyHeaderInterface<any>[] = [];
  protected readonly StickyHeaderElementType = StickyHeaderElementType;
  private destroy$ = new Subject<void>();

  constructor(
    private stickyHeaderService: StickyHeaderService,
    private readonly ref: ChangeDetectorRef
  ) {}

  itemById(index: number, item: StickyHeaderInterface<any>): string {
    return item.id;
  }

  getInputProp(item: StickyHeaderInterface<any>, prop: string): any | undefined {
    return item?.inputs && item?.inputs.hasOwnProperty(prop) ? item.inputs[prop] : undefined;
  }

  getOutputProp(item: StickyHeaderInterface<any>, prop: string): EventEmitter<any> {
    return item?.inputs && item?.inputs.hasOwnProperty(prop)
      ? (item.inputs[prop] as EventEmitter<any>)
      : new EventEmitter<any>();
  }

  outputWrapper(value: unknown, item: StickyHeaderInterface<any>, prop: string): void {
    const output = this.getOutputProp(item, prop);
    output.emit(value);
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  isElementAlreadyExist(item: StickyHeaderInterface<any>): boolean {
    return !!this.items.find((i) => i.id === item.id);
  }

  ngOnInit(): void {
    this.stickyHeaderService.addItemSource$
      .pipe(
        filter((item) => !this.isElementAlreadyExist(item)),
        takeUntil(this.destroy$)
      )
      .subscribe((item) => {
        this.items = [...this.items, item];
        this.ref.markForCheck();
      });

    this.stickyHeaderService.deleteItemSource$
      .pipe(
        filter((item) => this.isElementAlreadyExist(item)),
        takeUntil(this.destroy$)
      )
      .subscribe((item) => {
        this.items = this.items.filter((i) => i.id !== item.id);
        this.ref.markForCheck();
      });

    this.stickyHeaderService.resetHeaderSource$.pipe(takeUntil(this.destroy$)).subscribe(() => {
      this.items = [];
      this.ref.markForCheck();
    });
  }
}
