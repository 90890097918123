import { Component, Input, TemplateRef } from '@angular/core';

@Component({
  selector: 'ista-daytona-tooltip',
  templateUrl: './tooltip.component.html',
  styleUrls: ['./tooltip.component.scss'],
})
export class TooltipComponent {
  /** This is simple text which is to be shown in the tooltip */
  @Input() text!: string;

  /**
   * This provides finer control on the content to be visible on the tooltip This template will be
   * injected in ToolTipRenderer directive in the consumer template <ng-template #template>
   * content..... </ng-template>
   */
  @Input() contentTemplate!: TemplateRef<any>;

  @Input() indicatorPosition: 'top' | 'right' | 'bottom' | 'left' = 'bottom';
}
