<div class="input--container" [ngClass]="classes">
  <div class="label" *ngIf="label">
    <label [attr.for]="uuid" tabindex="-1" [class.disabled]="isDisabled">{{ label }}</label>
  </div>
  <div class="field" [ngClass]="{ invalid: isInvalid, disabled: isDisabled, active: isFocused }">
    <div
      class="field-inner"
      [ngClass]="{
        'two-action-buttons': type === inputTypes.SEARCH && !!value && !!isFocused,
        'no-action-buttons': !hasActionButton(),
        'value-unit': !!unit,
        normal: inputHeight === _inputHeight.NORMAL,
        small: inputHeight === _inputHeight.SMALL
      }"
    >
      <input
        #input
        [attr.autocomplete]="autocomplete ? 'on' : 'off'"
        [attr.id]="uuid"
        [attr.maxlength]="maxLength"
        [type]="inputValue"
        [class.disabled]="isDisabled"
        [value]="value || ''"
        [placeholder]="placeholder"
        [disabled]="isDisabled"
        [attr.data-qa]="dataQa"
        [name]="name"
        [pattern]="pattern"
        (input)="value = $any($event.target)?.value"
        (focus)="onFocus()"
        (blur)="hidePwd(); onBlur()"
      />
      <div class="input-unit" [class.disabled]="isDisabled" *ngIf="transformedUnit">
        <span [innerText]="transformedUnit"></span>
      </div>
      <div class="icon-button" [class.disabled]="isDisabled" *ngIf="hasActionButton()">
        <ng-container *ngIf="type === inputTypes.PWD">
          <span (click)="showPwd()" *ngIf="pwdType === inputTypes.PWD">
            <ista-daytona-icon [iconType]="iconType.EYE_OPEN"></ista-daytona-icon>
          </span>

          <span (click)="hidePwd()" *ngIf="pwdType === inputTypes.TEXT">
            <ista-daytona-icon [iconType]="iconType.EYE_CLOSE"></ista-daytona-icon>
          </span>
        </ng-container>
        <ng-container *ngIf="hasDeleteButton()">
          <span (mousedown)="deleteValue($event)">
            <ista-daytona-icon [iconType]="iconType.CLOSE"></ista-daytona-icon>
          </span>
        </ng-container>
        <ng-container *ngIf="type === inputTypes.SEARCH">
          <span>
            <ista-daytona-icon [iconType]="iconType.SEARCH"></ista-daytona-icon>
          </span>
        </ng-container>
      </div>
    </div>
  </div>

  <div class="invalid-text" *ngIf="isInvalid && invalidText">
    <span [ngClass]="{ invalid: isInvalid, disabled: isDisabled }">
      {{ invalidText }}
    </span>
  </div>
</div>
