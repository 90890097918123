<div
  [class.box-shadow]="displayBoxShadow"
  [ngClass]="colorStyle"
  [style.min-width]="minWidth"
  class="bg-white px-6 pb-6 rounded-lg border border-base-300 mb-6"
>
  <div [class.pt-6]="shouldAddOffsetTop">
    <ng-content></ng-content>
  </div>
</div>
