import { Component, EventEmitter, Input, Output } from '@angular/core';

import { SelectItem } from '../select/select.interface';

@Component({
  selector: 'ista-daytona-full-width-select',
  templateUrl: './full-width-select.component.html',
  styleUrls: ['./full-width-select.component.scss'],
})
export class FullWidthSelectComponent {
  @Input() items: SelectItem[] = [];
  @Input() selectedItem = 0;
  @Output() selectedItemChange = new EventEmitter<SelectItem>();

  public onSelectChange(itemId: number): void {
    const value = this.items?.find((item) => item.id === itemId) || this.items[0];
    this.selectedItemChange.emit(value);
  }
}
