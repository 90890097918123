import { MedianCustomIcons } from 'shared/helper/icon-helper';
import { LanguageKeyHelper } from 'shared/helper/language-key-helper';
import { TableHeader } from 'shared/ui/basic/table/table.interface';
import { DeepLinkingIds } from 'core/deep-linking/deep-linking.interface';

export const headerDataConfig: TableHeader[] = [
  {
    label: LanguageKeyHelper.get('object-id') ?? 'Objekt-Id',
    id: 'externalReference',
    linking: {
      url: '/portfolio/site/',
      customParamsValues: [],
      targetId: DeepLinkingIds.PORTFOLIO_OVERVIEW,
    },
  },
  {
    label: LanguageKeyHelper.get('street') ?? 'Straße',
    id: 'street',
    linking: {
      url: '/portfolio/site/',
      customParamsValues: [],
      targetId: DeepLinkingIds.PORTFOLIO_OVERVIEW,
    },
  },
  {
    label: LanguageKeyHelper.get('costs') || 'Kosten',
    icon: MedianCustomIcons['cost'],
    id: 'totalCosts',
  },
  {
    label: LanguageKeyHelper.get('co2') || 'CO2e',
    icon: MedianCustomIcons['co2'],
    id: 'totalEmissions',
  },
  {
    label: LanguageKeyHelper.get('heatingAndHotWater') || 'Wärme',
    icon: MedianCustomIcons['heatingAndHotWater'],
    id: 'heatingAndHotWaterConsumption',
    linking: {
      url: '/portfolio/site/',
      customParamsValues: [],
      targetId: DeepLinkingIds.PORTFOLIO_CONSUMPTION_CHART,
    },
  },
  {
    label: LanguageKeyHelper.get('electricity') || 'Strom',
    icon: MedianCustomIcons['electricity'],
    id: 'electricityConsumption',
    linking: {
      url: '/portfolio/site/',
      customParamsValues: ['electricity'],
      targetId: DeepLinkingIds.PORTFOLIO_CONSUMPTION_CHART,
    },
  },
  {
    label: LanguageKeyHelper.get('water') || 'Wasser',
    icon: MedianCustomIcons['water'],
    id: 'waterConsumption',
    linking: {
      url: '/portfolio/site/',
      customParamsValues: ['water'],
      targetId: DeepLinkingIds.PORTFOLIO_CONSUMPTION_CHART,
    },
  },
  {
    label: LanguageKeyHelper.get('waste') || 'Abfall',
    icon: MedianCustomIcons['waste'],
    linking: {
      url: '/portfolio/site/',
      customParamsValues: ['waste'],
      targetId: DeepLinkingIds.PORTFOLIO_CONSUMPTION_CHART,
    },
    id: 'wasteConsumption',
  },
];
