import { Component, Input } from '@angular/core';

@Component({
  selector: 'ista-daytona-section',
  templateUrl: 'section.component.html',
})
export class SectionComponent {
  @Input() headline?: string;
  @Input() icon?: string;
}
