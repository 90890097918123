import { CoreState } from '../interface';
import { Group, SiteOfGroup } from 'core/groups/groups.interface';
import { TreeNavigation } from 'core/interface';
import * as _ from 'lodash';
import { cloneDeep } from 'lodash';
import { GroupHelper } from 'core/groups-tree-navigation/utils/group-helper';
import { DEFAULT_UNASSIGNED_SITES_GROUP, UNASSIGNED_SITES_GROUP_ID } from 'core/constants';

export class CoreStateHelper {
  static moveAndAddSite(
    site: SiteOfGroup,
    targetGroup: Group,
    treeNavigation: TreeNavigation,
    searchTreeNavigation?: TreeNavigation
  ): Partial<CoreState> {
    const treeNavigationGroup = cloneDeep([
      treeNavigation.unassignedSitesGroup,
      ...treeNavigation.groups,
    ]);
    const searchTreeNavigationGroup: Group[] = searchTreeNavigation
      ? cloneDeep([searchTreeNavigation.unassignedSitesGroup, ...searchTreeNavigation.groups])
      : [];
    if (treeNavigationGroup.length) {
      GroupHelper.deleteSiteFromChildGroup(treeNavigationGroup, site);
      GroupHelper.addSiteToGroup(treeNavigationGroup, site, targetGroup.id);
    }
    if (searchTreeNavigationGroup.length) {
      GroupHelper.deleteSiteFromChildGroup(searchTreeNavigationGroup, site);
      GroupHelper.addSiteToGroup(searchTreeNavigationGroup, site, targetGroup.id);
    }
    const [treeUnassignedSitesGroup, ...treeNavigationGroups] = treeNavigationGroup;
    const [searchTreeUnassignedSitesGroup, ...searchTreeNavigationGroups] =
      searchTreeNavigationGroup;
    return {
      treeNavigation: {
        groups: treeNavigationGroups,
        unassignedSitesGroup: treeUnassignedSitesGroup,
      },
      searchTreeNavigation: searchTreeNavigation
        ? {
            groups: searchTreeNavigationGroups,
            unassignedSitesGroup: searchTreeUnassignedSitesGroup,
          }
        : undefined,
    };
  }

  static moveAndAddGroup(
    group: Group,
    targetGroup: Group,
    treeNavigation: TreeNavigation,
    searchTreeNavigation?: TreeNavigation
  ): Partial<CoreState> {
    let treeNavigationGroups = cloneDeep(treeNavigation.groups);
    let searchTreeNavigationGroups: Group[] = searchTreeNavigation
      ? cloneDeep(searchTreeNavigation.groups)
      : [];

    if (treeNavigationGroups.length) {
      treeNavigationGroups = GroupHelper.addGroupToGroup(
        GroupHelper.deleteGroupFromChildGroup(treeNavigationGroups, group.id),
        group,
        targetGroup.id
      );
    }

    if (searchTreeNavigationGroups.length) {
      searchTreeNavigationGroups = GroupHelper.addGroupToGroup(
        GroupHelper.deleteGroupFromChildGroup(searchTreeNavigationGroups, group.id),
        group,
        targetGroup.id
      );
    }

    return {
      treeNavigation: {
        ...treeNavigation,
        groups: treeNavigationGroups,
      },
      searchTreeNavigation: searchTreeNavigation
        ? {
            ...searchTreeNavigation,
            groups: searchTreeNavigationGroups,
          }
        : undefined,
    };
  }

  static moveAndAddGroupToRoot(
    group: Group,
    treeNavigation: TreeNavigation,
    searchTreeNavigation?: TreeNavigation
  ): Partial<CoreState> {
    let treeNavigationGroups = cloneDeep(treeNavigation.groups);
    let searchTreeNavigationGroups: Group[] = searchTreeNavigation
      ? cloneDeep(searchTreeNavigation.groups)
      : [];

    if (treeNavigationGroups.length) {
      const treeNavigationGroupsAfterDelete = GroupHelper.deleteGroupFromChildGroup(
        treeNavigationGroups,
        group.id
      );
      treeNavigationGroups = [...treeNavigationGroupsAfterDelete, group];
    }

    if (searchTreeNavigationGroups.length) {
      const searchTreeNavigationGroupsAfterDelete = GroupHelper.deleteGroupFromChildGroup(
        searchTreeNavigationGroups,
        group.id
      );
      searchTreeNavigationGroups = [...searchTreeNavigationGroupsAfterDelete, group];
    }

    return {
      treeNavigation: {
        ...treeNavigation,
        groups: treeNavigationGroups,
      },
      searchTreeNavigation: searchTreeNavigation
        ? {
            ...searchTreeNavigation,
            groups: searchTreeNavigationGroups,
          }
        : undefined,
    };
  }

  static deleteGroupFromGroup(
    groupId: number,
    treeNavigation: TreeNavigation,
    searchTreeNavigation?: TreeNavigation
  ): Partial<CoreState> {
    let treeNavigationGroups = cloneDeep(treeNavigation.groups);
    let searchTreeNavigationGroups: Group[] = searchTreeNavigation
      ? cloneDeep(searchTreeNavigation.groups)
      : [];

    if (treeNavigationGroups.length) {
      treeNavigationGroups = GroupHelper.deleteGroupFromChildGroup(treeNavigationGroups, groupId);
    }

    if (searchTreeNavigationGroups.length) {
      searchTreeNavigationGroups = GroupHelper.deleteGroupFromChildGroup(
        searchTreeNavigationGroups,
        groupId
      );
    }

    return {
      treeNavigation: {
        ...treeNavigation,
        groups: treeNavigationGroups,
      },
      searchTreeNavigation: searchTreeNavigation
        ? {
            ...searchTreeNavigation,
            groups: searchTreeNavigationGroups,
          }
        : undefined,
    };
  }

  static addSitesToUnassignedSites(
    unassignedSites: SiteOfGroup[],
    treeNavigation: TreeNavigation,
    searchTreeNavigation?: TreeNavigation
  ): Partial<CoreState> {
    return {
      treeNavigation: {
        ...treeNavigation,
        unassignedSitesGroup: {
          ...treeNavigation.unassignedSitesGroup,
          sites: [...unassignedSites, ...(treeNavigation.unassignedSitesGroup.sites ?? [])],
        },
      },
      searchTreeNavigation: searchTreeNavigation
        ? {
            ...searchTreeNavigation,
            unassignedSitesGroup: {
              ...searchTreeNavigation.unassignedSitesGroup,
              sites: [
                ...unassignedSites,
                ...(searchTreeNavigation.unassignedSitesGroup.sites ?? []),
              ],
            },
          }
        : undefined,
    };
  }

  static addNewGroupToGroups(
    newGroup: Group,
    parentGroupId: number,
    treeNavigation: TreeNavigation,
    searchTreeNavigation?: TreeNavigation
  ): Partial<CoreState> {
    if (!parentGroupId) {
      return {
        treeNavigation: {
          ...treeNavigation,
          groups: [newGroup, ...(treeNavigation?.groups ?? [])],
        },
        searchTreeNavigation: searchTreeNavigation
          ? {
              ...searchTreeNavigation,
              groups: [newGroup, ...(searchTreeNavigation?.groups ?? [])],
            }
          : undefined,
      };
    }

    return {
      treeNavigation: {
        ...treeNavigation,
        groups: GroupHelper.addGroupToGroup(
          cloneDeep(treeNavigation.groups ?? []),
          newGroup,
          parentGroupId
        ),
      },
      searchTreeNavigation: searchTreeNavigation
        ? {
            ...searchTreeNavigation,
            groups: GroupHelper.addGroupToGroup(
              cloneDeep(searchTreeNavigation.groups ?? []),
              newGroup,
              parentGroupId
            ),
          }
        : undefined,
    };
  }

  static deleteSiteFromGroup(
    parentGroup: Group,
    site: SiteOfGroup,
    treeNavigation: TreeNavigation,
    searchTreeNavigation?: TreeNavigation
  ): Partial<CoreState> {
    const childSites = site.sites;
    const filteredSites = GroupHelper.deleteChildSiteFromGroupSites(
      parentGroup.sites ?? [],
      site.id
    );

    if (parentGroup.id !== UNASSIGNED_SITES_GROUP_ID) {
      return {
        treeNavigation: {
          ...treeNavigation,
          groups: CoreStateHelper.mergeSitesIntoGroups(
            treeNavigation.groups ?? [],
            parentGroup.id,
            filteredSites
          ),
          unassignedSitesGroup: {
            ...treeNavigation.unassignedSitesGroup,
            sites: [...childSites, ...(treeNavigation.unassignedSitesGroup.sites ?? [])],
          },
        },
        searchTreeNavigation: searchTreeNavigation
          ? {
              ...searchTreeNavigation,
              groups: CoreStateHelper.mergeSitesIntoGroups(
                searchTreeNavigation.groups ?? [],
                parentGroup.id,
                filteredSites
              ),
              unassignedSitesGroup: {
                ...searchTreeNavigation.unassignedSitesGroup,
                sites: [...childSites, ...(searchTreeNavigation.unassignedSitesGroup.sites ?? [])],
              },
            }
          : undefined,
      };
    }

    return {
      treeNavigation: {
        ...treeNavigation,
        unassignedSitesGroup: {
          ...DEFAULT_UNASSIGNED_SITES_GROUP,
          sites: [...childSites, ...filteredSites],
        },
      },

      searchTreeNavigation: searchTreeNavigation
        ? {
            ...searchTreeNavigation,
            unassignedSitesGroup: {
              ...DEFAULT_UNASSIGNED_SITES_GROUP,
              sites: [...childSites, ...filteredSites],
            },
          }
        : undefined,
    };
  }

  static mergeSitesIntoGroupsRecursion(
    groups: Group[],
    targetGroupId: number,
    sites: SiteOfGroup[],
    sitesAlreadyLoaded: boolean
  ): void {
    let index = 0;
    let targetGroupFound = false;
    while (index < groups.length && !targetGroupFound) {
      if (groups[index].id === targetGroupId) {
        groups[index].sites = sites;
        groups[index].sitesAlreadyLoaded = sitesAlreadyLoaded;
        targetGroupFound = true;
      } else if (groups[index].groups !== undefined && groups[index].groups.length > 0) {
        CoreStateHelper.mergeSitesIntoGroupsRecursion(
          groups[index].groups,
          targetGroupId,
          sites,
          sitesAlreadyLoaded
        );
      }
      index++;
    }
  }

  static mergeSitesIntoGroups(
    groups: Group[],
    targetGroupId: number,
    sites: SiteOfGroup[],
    sitesAlreadyLoaded = false
  ): Group[] {
    const result = _.cloneDeep(groups);
    CoreStateHelper.mergeSitesIntoGroupsRecursion(result, targetGroupId, sites, sitesAlreadyLoaded);
    return result;
  }
}
