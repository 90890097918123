import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { RootState } from 'app/+state/app.reducer';
import { environment } from 'environments/environment';
import {
  catchError,
  filter,
  first,
  firstValueFrom,
  forkJoin,
  map,
  mergeMap,
  NEVER,
  Observable,
  of,
  tap,
} from 'rxjs';
import { DestroyAbstractService } from 'shared/helper/destroy-abstract.service';

import {
  clearLoadSitesState,
  storeGroups,
  storeGroupSearchStatus,
  storeSearchTreeNavigationGroups,
  storeSitesOfGroups,
  updateLoadSitesState,
  updateUnassignedSites,
} from '../+state/core.actions';
import {
  selectAllowLoadSites,
  selectSelectedEnvironment,
  selectSelectedEnvironmentId,
  selectTreeNavigationSpecificGroup,
} from '../+state/core.selectors';
import { Environment } from '../environment/environment.interface';
import { ErrorHandlerService } from '../error-handling/error-handler.service';
import { RequestTrackingService } from '../request-tracking.service';
import { GroupAndSite, GroupSearch, SiteOfGroup } from './groups.interface';
import { updateTagsInSitesAreChanged } from '../../features/portfolio/+state/portfolio.actions';

@Injectable({
  providedIn: 'root',
})
export class GroupService extends DestroyAbstractService {
  private readonly baseUrl = environment?.apiUrl;

  constructor(
    private readonly ngrxStore: Store<RootState>,
    private readonly _httpClient: HttpClient,
    private readonly requestTrackingService: RequestTrackingService,
    private readonly _errorHandler: ErrorHandlerService
  ) {
    super();
  }

  public async loadGroupsOfEnvironment(selectedEnvironment?: Environment): Promise<void> {
    if (selectedEnvironment) {
      const requestId = this.requestTrackingService.registerRequest('loadGroupsOfEnvironment');
      try {
        const result = await firstValueFrom(
          this._httpClient.get<GroupAndSite>(`${this.baseUrl}/api/${selectedEnvironment.id}/groups`)
        );
        if (this.requestTrackingService.isRequestStillValid('loadGroupsOfEnvironment', requestId)) {
          this.ngrxStore.dispatch(storeGroups({ groups: result.groups }));
          this.ngrxStore.dispatch(updateUnassignedSites({ unassignedSites: result.sites }));
          this.ngrxStore.dispatch(clearLoadSitesState());
        }
      } catch (error) {
        this._errorHandler.handleError(error, 'LOAD_GROUPS');
      }
    }
  }

  public async loadSitesOfGroup(groupId: number): Promise<void> {
    const envId = await firstValueFrom(this.ngrxStore.select(selectSelectedEnvironmentId));
    const allowToLoadSites = await firstValueFrom(this.ngrxStore.select(selectAllowLoadSites));

    const group = await firstValueFrom(
      this.ngrxStore.select(selectTreeNavigationSpecificGroup(groupId))
    );
    if (envId && allowToLoadSites && group && group.hasSites && !group.sitesAlreadyLoaded) {
      const requestId = this.requestTrackingService.registerRequest(
        'loadSitesOfGroup:' + groupId.toString()
      );
      try {
        const result = await firstValueFrom(
          this._httpClient.get<SiteOfGroup[]>(
            `${this.baseUrl}/api/${envId}/groups/${groupId}/sites`
          )
        );

        if (
          this.requestTrackingService.isRequestStillValid(
            'loadSitesOfGroup:' + groupId.toString(),
            requestId
          )
        ) {
          this.ngrxStore.dispatch(storeSitesOfGroups({ groupId, sites: result }));
        }
      } catch (error) {
        this._errorHandler.handleError(error, 'LOAD_GROUP_SITES');
      }
    }
  }

  // @Deprecated
  public async searchGroupTree(searchTerm: string): Promise<void> {
    const envId = await firstValueFrom(this.ngrxStore.select(selectSelectedEnvironmentId));

    if (envId) {
      this.ngrxStore.dispatch(storeGroupSearchStatus({ isRunning: true }));
      const requestId = this.requestTrackingService.registerRequest('searchGroupTree');
      try {
        const result = await firstValueFrom(
          this._httpClient.post<GroupAndSite>(`${this.baseUrl}/api/${envId}/groups/search`, {
            searchTerm,
          })
        );

        if (this.requestTrackingService.isRequestStillValid('searchGroupTree', requestId)) {
          this.ngrxStore.dispatch(storeGroups({ groups: result.groups }));
          this.ngrxStore.dispatch(updateUnassignedSites({ unassignedSites: result.sites }));
          this.ngrxStore.dispatch(storeGroupSearchStatus({ isRunning: false }));
        }
      } catch (error) {
        this.ngrxStore.dispatch(storeGroupSearchStatus({ isRunning: false }));
        this._errorHandler.handleError(error, 'LOAD_GROUPS');
      }
    }
  }

  searchGroupsHttp(envId: number, search: GroupSearch): Observable<GroupAndSite> {
    return this._httpClient.post<GroupAndSite>(
      `${this.baseUrl}/api/${envId}/groups/search`,
      search
    );
  }

  searchGroups(search: GroupSearch): Observable<unknown> {
    return this.ngrxStore.select(selectSelectedEnvironmentId).pipe(
      filter(Boolean),
      first(),
      tap(() => {
        this.ngrxStore.dispatch(storeGroupSearchStatus({ isRunning: true }));
      }),
      mergeMap((envId) => forkJoin([of(envId), this.searchGroupsHttp(envId, search)])),
      mergeMap(([envId, response]) =>
        forkJoin([
          this.ngrxStore.select(selectSelectedEnvironmentId).pipe(
            first(),
            map((currentEnvId) => currentEnvId === envId)
          ),
          of(response),
        ])
      ),
      tap(() => {
        this.ngrxStore.dispatch(storeGroupSearchStatus({ isRunning: false }));
      }),
      filter(([sameEnv]) => !!sameEnv),
      map(([, res]) => res),
      tap((result) => {
        this.ngrxStore.dispatch(
          storeSearchTreeNavigationGroups({
            groups: result.groups,
            unassignedSites: result.sites,
          })
        );
        this.ngrxStore.dispatch(updateLoadSitesState({ state: false }));
        this.ngrxStore.dispatch(updateTagsInSitesAreChanged({ tagsInSitesAreChanged: false }));
      }),
      catchError((err) => {
        console.error('searchGroups', err);
        this._errorHandler.handleError(err, 'LOAD_GROUPS');
        this.ngrxStore.dispatch(storeGroupSearchStatus({ isRunning: false }));
        return NEVER;
      })
    );
  }

  public getInitialGroupsState(): Observable<unknown> {
    return this.ngrxStore.select(selectSelectedEnvironment).pipe(
      first(),
      filter(Boolean),
      mergeMap((envId) => this.loadGroupsOfEnvironment(envId))
    );
  }
}
