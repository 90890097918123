<div
  [ngClass]="
    displaySmall
      ? 'navigation-width--small px-1.5'
      : 'w-navigation-tablet navigation-lg:w-navigation-desktop'
  "
  class="navigation-container"
>
  <div class="content-inner">
    <div
      [ngClass]="{
        'my-6 px-4 navigation-lg:pl-8': !displaySmall,
        'flex justify-center items-center pt-5 h-18': displaySmall
      }"
      class="logo"
    >
      <mat-icon
        [ngClass]="{
          'w-[100px] h-auto': !displaySmall,
          'icon-size-8': displaySmall
        }"
        [svgIcon]="displaySmall ? 'logo_small' : 'logo'"
      ></mat-icon>
    </div>
    <ul [class.p-0]="displaySmall" [class.px-4]="!displaySmall" class="list-none m-0">
      <li *ngFor="let item of navigationItems" class="m-0 mb-1">
        <a
          *ngIf="item.link && item.name; else showDivider"
          [class.active-link]="getIsLinkActive(item.link)"
          [link]="item.link"
          [ngClass]="{
            'flex-col p-2 text-xs rounded-lg': displaySmall,
            'p-2 navigation-lg:px-4 navigation-lg:py-0 rounded-md navigation-lg:rounded-0 text-sm  navigation-lg:justify-normal':
              !displaySmall
          }"
          class="flex items-center test123 !flex-col navigation-lg:flex-row gap-2 navigation-lg:gap-0 justify-center min-h-11 font-extrabold text-slate-900 no-underline hover:active-link"
          data-cy="navigation-item"
          linkQueryParams
        >
          @if (item.icon; as icon) {
            @if (icon.isSvgIcon) {
              <mat-icon
                [ngClass]="{
              'mr-0 navigation-lg:mr-4 icon-size-5 navigation-lg:icon-size-6': !displaySmall,
              'icon-size-6': displaySmall,
              'fill-primary-1000 fill-related': icon.isFillRelated,
              'stroke-primary-1000 stroke-related': icon.isStrokeRelated
            }"
                class="custom-svg-icon"
                [svgIcon]="icon.name"
              >
              </mat-icon>
            } @else {
              <mat-icon
                [ngClass]="{
              'mr-0 navigation-lg:mr-4 icon-size-5 navigation-lg:icon-size-6': !displaySmall,
              'icon-size-6': displaySmall
            }"
                class="text-primary-1000"
              >{{ icon.name }}
              </mat-icon>
            }
          }

          <div
            *ngIf="!displaySmall"
            [innerText]="item.name | translate"
            class="label text-primary-1000 text-center text-sm navigation-lg:text-base font-extrabold navigation-lg:truncate w-full navigation-lg:text-left"
          ></div>
        </a>
        <ng-template #showDivider>
          <div class="h-[1px] my-2 bg-base-200"></div>
        </ng-template>
      </li>
    </ul>
  </div>
</div>
