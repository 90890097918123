import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'nullToUndefined',
})
// todo: should be removed but all child components of the dashboard component must be adapted to handle null beforehand
export class NullToUndefinedPipe implements PipeTransform {
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  transform(value: any): any {
    return value === null ? undefined : value;
  }
}
