<button
  (click)="buttonClick.emit()"
  [color]="color"
  [disabled]="disabled"
  mat-icon-button
>
  @if (icon.isSvgIcon) {
    <mat-icon [matBadgeColor]="badgeColor"
              [matBadgeHidden]="badgeHidden"
              [matBadgePosition]="badgePosition"
              [matBadgeSize]="badgeSize"
              [matBadge]="badgeContent"
              class="icon-size-6" matBadge="8" matBadgeColor="primary" [svgIcon]="icon.name" [class]="icon.colorClass">
    </mat-icon>
  } @else {
    <mat-icon [matBadgeColor]="badgeColor"
              [matBadgeHidden]="badgeHidden"
              [matBadgePosition]="badgePosition"
              [matBadgeSize]="badgeSize"
              [matBadge]="badgeContent"
              class="icon-size-6" matBadge="8" matBadgeColor="primary">{{ icon.name }}
    </mat-icon>
  }
</button>
