import { Overlay } from '@angular/cdk/overlay';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { TooltipComponent } from './tooltip.component';
import { ToolTipRendererDirective } from './tooltip.directive';

@NgModule({
  imports: [CommonModule],
  declarations: [TooltipComponent, ToolTipRendererDirective],
  exports: [TooltipComponent, ToolTipRendererDirective],
  providers: [Overlay],
})
export class TooltipModule {}
