import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'environments/environment';
import { EnvironmentSettings } from 'core/environment-settings/interfaces';

@Injectable({
  providedIn: 'root',
})
export class EnvironmentSettingsHttpService {
  constructor(private readonly httpClient: HttpClient) {}

  getEnvironmentSettings(envId: number): Observable<EnvironmentSettings> {
    return this.httpClient.get<EnvironmentSettings>(
      `${environment.apiUrl}/api/${envId}/environment-settings`
    );
  }

  updateEnvironmentSettings(
    envId: number,
    settings: EnvironmentSettings
  ): Observable<EnvironmentSettings> {
    return this.httpClient.post<EnvironmentSettings>(
      `${environment.apiUrl}/api/${envId}/environment-settings`,
      settings
    );
  }
}
