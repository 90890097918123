import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { IconModule } from './../icon/icon.module';
import { ButtonComponent } from './button.component';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { MatBadge } from '@angular/material/badge';
import { UiBadgeVerticallyCenteredDirective } from '@ista-ui/angular';

@NgModule({
  imports: [
    CommonModule,
    IconModule,
    MatIconModule,
    MatButtonModule,
    MatBadge,
    UiBadgeVerticallyCenteredDirective,
  ],
  declarations: [ButtonComponent],
  exports: [ButtonComponent],
})
export class ButtonModule {}
