import { Component } from '@angular/core';
import { MatIcon } from '@angular/material/icon';
import { TranslateModule } from '@ngx-translate/core';
import { AsyncPipe, NgIf } from '@angular/common';
import { TooltipModule } from 'shared/ui/basic/tooltip/tooltip.module';
import { ConnectedPosition } from '@angular/cdk/overlay';
import { SelectedPeriodInfoService } from 'core/selected-period-info/selected-period-info.service';

@Component({
  selector: 'ista-daytona-selected-period-info',
  templateUrl: 'selected-period-info.component.html',
  imports: [MatIcon, TranslateModule, AsyncPipe, NgIf, TooltipModule],
  providers: [SelectedPeriodInfoService],
  standalone: true,
  // changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SelectedPeriodInfoComponent {
  selectIsCustomYearSelected$ = this.selectedPeriodInfoService.selectIsCustomYearSelected$;
  selectCalendarYearStartDate$ = this.selectedPeriodInfoService.selectCalendarYearStartDate$;
  selectCalendarYearEndDate$ = this.selectedPeriodInfoService.selectCalendarYearEndDate$;
  selectCustomYearStartDate$ = this.selectedPeriodInfoService.selectCustomYearStartDate$;
  selectCustomYearEndDate$ = this.selectedPeriodInfoService.selectCustomYearEndDate$;
  position: ConnectedPosition = {
    originX: 'center',
    originY: 'top',
    overlayX: 'center',
    overlayY: 'bottom',
    offsetY: 70,
  };

  constructor(private readonly selectedPeriodInfoService: SelectedPeriodInfoService) {
  }
}
