import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

import { LoadingState, LoadStatus } from './load-status.interface';

@Injectable({
  providedIn: 'root',
})
export class LoadStatusService {
  private readonly _initialState: LoadStatus = {
    id: '',
    progress: 0,
    state: LoadingState.READY,
  };
  private _progressStatus$ = new BehaviorSubject<LoadStatus>(this._initialState);

  public getProgressStatus(): Observable<LoadStatus> {
    return this._progressStatus$.asObservable();
  }
  public setProgressStatus(status: LoadStatus): void {
    this._progressStatus$.next(status);
  }

  public setInitalState(): void {
    this.setProgressStatus(this._initialState);
  }

  public setInProgressState(id: string): void {
    this.setProgressStatus({
      id,
      progress: 0,
      state: LoadingState.PROGRESS,
    });
  }
}
