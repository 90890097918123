import { NgModule } from '@angular/core';
import { TabComponent } from './tab.component';
import { MatTabsModule } from '@angular/material/tabs';
import { MatIconModule } from '@angular/material/icon';
import { NgClass, NgForOf, NgIf } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';

@NgModule({
  declarations: [TabComponent],
  imports: [MatTabsModule, MatIconModule, NgForOf, NgIf, TranslateModule, NgClass],
  exports: [TabComponent],
})
export class TabModule {}
