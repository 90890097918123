/* eslint-disable @typescript-eslint/member-ordering */
import { booleanAttribute, Component, Input } from '@angular/core';
import {
  MatAccordion,
  MatExpansionPanel,
  MatExpansionPanelHeader,
  MatExpansionPanelTitle,
} from '@angular/material/expansion';
import { NgClass, NgForOf } from '@angular/common';
import { PipesModule } from 'shared/pipes/pipes.module';

@Component({
  selector: 'ista-daytona-content-card-open',
  templateUrl: './content-card-open.component.html',
  imports: [
    MatExpansionPanel,
    MatExpansionPanelHeader,
    MatExpansionPanelTitle,
    MatAccordion,
    NgForOf,
    NgClass,
    PipesModule,
  ],
  standalone: true,
})
export class ContentCardOpenComponent {
  @Input() labels: string[] = [];
  @Input() content: string[] = [];
  @Input({ transform: booleanAttribute }) addBorder = true;
  public isOpened = false;

  public _setOpen = false;

  get setOpen(): boolean {
    return this._setOpen;
  }

  @Input() set setOpen(status: boolean) {
    this.isOpened = status;
    this._setOpen = status;
  }
}
