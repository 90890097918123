import { DashboardInfo } from '../dashboard.interface';
import { ConsumptionInfo } from 'app/features/portfolio/portfolio-consumption/consumption.interface';
import { IconValueLabel } from 'shared/ui/icon-value-label/icon-value-label.interface';
import { ChartItem } from 'shared/ui/chart/chart.interface';
import { MedianBackgroundCustomIconsClass, MedianCustomIcons } from 'shared/helper/icon-helper';
import { Unit } from 'shared/models/unit.enum';
import { ValueBarDiagnoseCard } from 'shared/ui/value-bar-diagnose-card/value-bar-diagnose-card.interface';
import { Utils } from 'shared/helper/utils';

export class DashboardHelper {
  public static checkWhichYearsToLoad(
    alreadyPresentDashboardData: DashboardInfo | ConsumptionInfo | undefined,
    requestedYears: number[]
  ): number[] {
    if (!alreadyPresentDashboardData) return requestedYears;
    return requestedYears.filter((year) => !alreadyPresentDashboardData[year]);
  }

  static getBarsDisplayedYearsKeys(dashboard: DashboardInfo, globalSelectedYear: number): string[] {
    return Utils.getBarsDisplayedYearsKeys(dashboard, globalSelectedYear);
  }

  static chartItemToIconValueLabel(
    item: ChartItem,
    valueItem: { value?: number | null; unit: string }
  ): IconValueLabel {
    return {
      value: valueItem.value as number,
      unit: valueItem.unit as Unit,
      label: `types.${item.label}`,
      icon: MedianCustomIcons[item.label],
      iconBackgroundColor: MedianBackgroundCustomIconsClass[item.label] ?? '',
    };
  }

  static getTargetTabNameByLabel(card: ValueBarDiagnoseCard): string {
    return card.label?.startsWith('types.') ? card.label.split('types.')[1] : '';
  }
}
