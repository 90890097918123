import { Component, Input } from '@angular/core';

@Component({
  selector: 'ista-daytona-button-edit',
  templateUrl: './button-edit.component.html',
  styleUrls: ['./button-edit.component.scss'],
})
export class ButtonEditComponent {
  @Input() icon = '';
  @Input() stickyStyle = false;
}
