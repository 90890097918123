export enum ColorStyle {
  WHITE = 'white',
  BLUE = 'blue',
  BLUE_LIGHT = 'blue-light',
  BLUE_DARK = 'blue-dark',
  BLUE_CTA = 'blue-cta',
  GREEN_LIGHT = 'green-light',
  GREEN = 'green',
  ORANGE = 'orange',
  RED = 'red',
}
