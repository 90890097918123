import {
  AfterViewInit,
  Directive,
  ElementRef,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { delay, filter, Subject } from 'rxjs';
import { VisibilityCallback } from 'core/sticky-header/sticky-header.interface';
import { Utils } from 'shared/helper/utils';

/*
 * this Directive observes the Intersection between the Element and the Root Element (for now is Viewport)
 * in Future if it's needed we can inject a custom RootElement
 * depends on the Initial Config, the Directive invoke a Callback when the Intersection between the Element and the Root under the Config happens
 * for Further Info please check: https://developer.mozilla.org/en-US/docs/Web/API/IntersectionObserver
 * */
@Directive({
  selector: '[visibilityObserve]',
})
export class VisibilityObserveDirective implements OnInit, OnDestroy, AfterViewInit {
  @Input() threshold = 0.01;
  @Input() debounceTime = 10;
  @Input() rootMargin = '0px'; // A string, formatted similarly to the CSS margin property's value

  @Output() visibilityIntersectionChange = new EventEmitter<VisibilityCallback>();

  private observer: IntersectionObserver | undefined;
  private subject$ = new Subject<{
    entry: IntersectionObserverEntry;
    observer: IntersectionObserver;
  }>();

  constructor(private element: ElementRef) {}

  ngOnInit(): void {
    this.createObserver();
  }

  ngAfterViewInit(): void {
    this.generateIdAndSetIdAsDataset();
    this.startObservingElements();
  }

  ngOnDestroy(): void {
    if (this.observer) {
      this.observer.disconnect();
      this.observer = undefined;
    }

    this.subject$.complete();
  }

  private createObserver() {
    const options = {
      rootMargin: this.rootMargin,
      threshold: this.threshold,
    };
    this.observer = new IntersectionObserver((entries, observer) => {
      entries.forEach((entry) => {
        this.subject$.next({ entry, observer });
      });
    }, options);
  }

  private startObservingElements() {
    if (!this.observer) {
      return;
    }

    this.observer.observe(this.element.nativeElement as Element);

    this.subject$.pipe(delay(this.debounceTime), filter(Boolean)).subscribe(({ entry }) => {
      const target = entry.target as HTMLElement;
      const isHidden = entry.intersectionRatio === 0; // element is hidden
      this.visibilityIntersectionChange.emit({
        element: target,
        isVisible: !isHidden,
      });
    });
  }

  /*
   * generate Random ID
   * the ID will be used to add/delete Elements into/from the Sticky Header, for Further Info: please check the sticky Header component
   * if the data-id is already exist, the exist ID will be used. ->
   * this could be Helpful for Implementation Purposes like Component Store. please Check for FilterTab Component
   * */
  private generateIdAndSetIdAsDataset(): void {
    /* eslint-disable @typescript-eslint/no-unsafe-assignment */
    if (!this.element.nativeElement.dataset.id) {
      this.element.nativeElement.dataset.id =
        this.element.nativeElement?.firstChild?.dataset?.id || Utils.generateRandomId();
    }
  }
}
