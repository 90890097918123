import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatIcon } from '@angular/material/icon';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'ista-daytona-delete-revert-dialog',
  templateUrl: 'delete-revert-dialog.component.html',
  imports: [MatIcon, TranslateModule],
  standalone: true,
})
export class DeleteRevertDialogComponent {
  constructor(
    private dialogRef: MatDialogRef<DeleteRevertDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { itemName: string }
  ) {}

  revert(): void {
    this.dialogRef.close(true);
  }
}
