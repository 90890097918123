export enum InputTypes {
  PWD = 'password',
  TEXT = 'text',
  VALUE = 'value',
  SEARCH = 'search',
  NUMBER = 'number',
}

export enum InputHeight {
  NORMAL = 'normal',
  SMALL = 'small',
}
