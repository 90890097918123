import { Injectable, OnDestroy } from '@angular/core';
import { Subject } from 'rxjs';

/**
 * This special type of Subject is used to destroy Observables on the end of the angular component
 * lifecycle. The next() method is called in the OnDestroy() method of the component. After it emits
 * the subject completes itself without further do.
 */
export class DestroySubject extends Subject<void> {
  public override next(value: void): void {
    super.next(value);
    super.complete();
  }
}

@Injectable({
  providedIn: 'root',
})
export class DestroyAbstractService implements OnDestroy {
  protected _onDestroy$ = new DestroySubject();

  ngOnDestroy(): void {
    this._onDestroy$.next();
  }
}
