import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';

import { InputModule } from '../input/input.module';
import { InputSearchComponent } from './input-search.component';

@NgModule({
  imports: [CommonModule, FormsModule, InputModule],
  declarations: [InputSearchComponent],
  exports: [InputSearchComponent],
})
export class InputSearchModule {}
