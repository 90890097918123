import { SiteDetails } from 'app/features/portfolio/portfolio-site-masterdata/interfaces/site-details.interface';
import { Address } from '../interfaces/site.interface';

export class OsmHelper {
	static transformSiteDetailsToAddress(siteDetails: SiteDetails): Address {
		return {
			id: 0,
			street: siteDetails.street,
			houseNumber: siteDetails.houseNumber,
			postalCode: siteDetails.postalCode,
			city: siteDetails.city,
			country: 'Deutschland',
		};
	}

	static getAddressStringForGeoApi(address: Address): string {
		const country = 'Deutschland';
		const streetname = address.street?.toLowerCase() || '';
		const houseNumber: string = address.houseNumber?.toLowerCase() || '';
		const street = houseNumber.length
			? streetname + ' ' + houseNumber
			: streetname;
		const plz = address.postalCode;
		const city = address.city;
		return `${street} ${plz} ${city} ${country}`;
	}
}
