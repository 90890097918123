<div *ngIf="value; else defaultValue" [ngClass]="classContainer" class="value-item--container">
  <span
    [innerHTML]="
      (getTransformedValue().value | number : getFormatNumberDigits() : 'de') || nullValueDisplay
    "
    [ngClass]="classValue"
    data-cy="value-unit"
  ></span>
  <span>&nbsp;</span>
  <span [innerHTML]="getTransformedValue().unitWithPrefix" [ngClass]="classUnit"></span>
</div>

<ng-template #defaultValue>
  <div [ngClass]="classContainer" class="value-item--container">
    <span [innerHTML]="nullValueDisplay" [ngClass]="classValue" data-cy="value-unit"></span>
  </div>
</ng-template>
