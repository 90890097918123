import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import * as _ from 'lodash';

import { ColorHelper } from '../../helper/color-helper';
import { HelperService } from '../../helper/helper.service';
import { ValueItem } from '../../models/value-item.interface';
import { Unit } from '../../models/unit.enum';
import { RGBA } from '../../models/rgba.interface';
import { FilterDateRange } from '../filter/filter-tab.interface';
import { BarItem, MonitoringCardData } from './monitoring-card-data.interface';
import { IconHelper } from '../../helper/icon-helper';

/* eslint-disable @typescript-eslint/member-ordering */
@Component({
  selector: 'ista-daytona-monitoring-card',
  templateUrl: './monitoring-card.component.html',
  styleUrls: ['./monitoring-card.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MonitoringCardComponent {
  @Input() sortDataByLabel = true;
  @Input() selectedYear: FilterDateRange | undefined | null;
  @Input() displayBars = true;
  @Input() displayValue = true;
  @Input() displaySiteCount = true;
  public isHover = false;
  public borderColor = ColorHelper.get('background-medium');
  protected readonly IconHelper = IconHelper;
  private _maxBarValue = 0;
  private _rgbaColor!: RGBA;

  private _data!: MonitoringCardData;

  get data(): MonitoringCardData {
    return this._data;
  }

  @Input() set data(value: MonitoringCardData) {
    this._data = _.cloneDeep(this.sortDataByLabel ? this.sortDataByLabelAscending(value) : value);
    this._rgbaColor = HelperService.convertHEXtoRGBA(this._data?.color);
    this._data?.bars?.forEach((bar) => {
      if (bar.value?.value && bar.value?.value > this._maxBarValue) {
        this._maxBarValue = bar.value?.value;
      }
    });
  }

  // @TODO this is a quick workaround for SiteCount, the Component should be refactored because it seems like a mix between Smart and Dumb Component
  get siteCount(): number | undefined {
    try {
      const index = this.data.bars?.findIndex((item) => item.label === this.selectedYear?.from);

      return index >= 0 ? this.data.bars[index]?.value?.numberOfAffectedObjects : undefined;
    } catch (e) {
      console.error(e);
      return undefined;
    }
  }

  public getBarWidth(bar: BarItem): string {
    const index = this._data?.bars.findIndex(
      (item) => item.label === bar.label && item.value === bar.value
    );

    if (
      this._data?.bars[index]?.value?.value === null ||
      this._data?.bars[index]?.value?.value === undefined
    ) {
      return '0';
    }

    return (
      (((this._data?.bars[index]?.value?.value || 0) / this._maxBarValue) * 100).toString() + '%'
    );
  }

  public getBackgroundColor(index: number): string {
    return `rgba(${this._rgbaColor.r}, ${this._rgbaColor.g}, ${this._rgbaColor.b}, ${
      index > 0 ? 0.6 : 1
    }`;
  }

  public getValueItemOfSelectedYear(): ValueItem | undefined {
    return this.data.bars.find((item) => item.label === this.selectedYear?.from)?.value;
  }

  public getUnitOfSelectedYear(): Unit {
    return (
      this.data.bars.find((item) => item.label === this.selectedYear?.from)?.value?.unit ||
      Unit.DEFAULT
    );
  }

  public getDisplayedBars(): BarItem[] {
    const index = this.data.bars?.findIndex((item) => item.label === this.selectedYear?.from);

    const start = index - 2 >= 0 ? index - 2 : 0;
    let end = index < 0 ? 0 : index + 1;
    if (start === 0) {
      end = 3;
    }

    return this.data.bars.slice(start, end);
  }

  sortDataByLabelAscending(value: MonitoringCardData): MonitoringCardData {
    try {
      return {
        ...value,
        bars: value.bars.sort((a, b) => b.label.localeCompare(a.label)),
      };
    } catch (e) {
      console.error('sortDataByLabelAscending', e);
      return value;
    }
  }
}
