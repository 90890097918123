<ng-container [ngSwitch]="type">
  <ista-daytona-circle
    *ngSwitchCase="loaderType.CIRCLE"
    [label]="label"
    [color]="color"
    [value]="value"
    [diameter]="diameter"
    [circleMode]="spinnerMode"
  ></ista-daytona-circle>
  <ista-daytona-bar
    *ngSwitchCase="loaderType.BAR"
    [color]="color"
    [barSize]="barSize"
    [value]="value"
    [barMode]="barMode"
  ></ista-daytona-bar>
</ng-container>
